.wp-block-audio figcaption {
    margin-top: .5em;
    margin-bottom: 1em;
    color: #555d66;
    text-align: center;
    font-size: 13px
}

.wp-block-audio audio {
    width: 100%;
    min-width: 300px
}

.editor-block-list__layout .reusable-block-edit-panel {
    align-items: center;
    background: #f8f9f9;
    color: #555d66;
    display: flex;
    flex-wrap: wrap;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-size: 13px;
    position: relative;
    top: -14px;
    margin: 0 -14px;
    padding: 8px 14px;
    position: relative;
    z-index: 7
}

.editor-block-list__layout .editor-block-list__layout .reusable-block-edit-panel {
    margin: 0 -14px;
    padding: 8px 14px
}

.editor-block-list__layout .reusable-block-edit-panel .reusable-block-edit-panel__spinner {
    margin: 0 5px
}

.editor-block-list__layout .reusable-block-edit-panel .reusable-block-edit-panel__info {
    margin-right: auto
}

.editor-block-list__layout .reusable-block-edit-panel .reusable-block-edit-panel__label {
    margin-right: 8px;
    white-space: nowrap;
    font-weight: 600
}

.editor-block-list__layout .reusable-block-edit-panel .reusable-block-edit-panel__title {
    flex: 1 1 100%;
    font-size: 14px;
    height: 30px;
    margin: 4px 0 8px
}

.editor-block-list__layout .reusable-block-edit-panel .components-button.reusable-block-edit-panel__button {
    flex-shrink: 0
}

@media (min-width:960px) {
    .editor-block-list__layout .reusable-block-edit-panel {
        flex-wrap: nowrap
    }

    .editor-block-list__layout .reusable-block-edit-panel .reusable-block-edit-panel__title {
        margin: 0
    }

    .editor-block-list__layout .reusable-block-edit-panel .components-button.reusable-block-edit-panel__button {
        margin: 0 0 0 5px
    }
}

.editor-block-list__layout .reusable-block-indicator {
    background: #fff;
    border-left: 1px dashed #e2e4e7;
    color: #555d66;
    border-bottom: 1px dashed #e2e4e7;
    top: -14px;
    height: 30px;
    padding: 4px;
    position: absolute;
    z-index: 1;
    width: 30px;
    right: -14px
}

.wp-block-button {
    color: #fff;
    margin-bottom: 1.5em
}

.wp-block-button.aligncenter {
    text-align: center
}

.wp-block-button.alignright {
    text-align: right
}

.wp-block-button__link {
    background-color: #32373c;
    border: none;
    border-radius: 28px;
    box-shadow: none;
    color: inherit;
    cursor: pointer;
    display: inline-block;
    font-size: 18px;
    margin: 0;
    padding: 12px 24px;
    text-align: center;
    text-decoration: none;
    white-space: normal;
    overflow-wrap: break-word
}

.wp-block-button__link:active,
.wp-block-button__link:focus,
.wp-block-button__link:hover {
    color: inherit
}

.is-style-squared .wp-block-button__link {
    border-radius: 0
}

.is-style-outline {
    color: #32373c
}

.is-style-outline .wp-block-button__link {
    background: 0 0;
    border: 2px solid currentcolor
}

.wp-block-categories.alignleft {
    margin-right: 2em
}

.wp-block-categories.alignright {
    margin-left: 2em
}

.wp-block-columns {
    display: flex;
    flex-wrap: wrap
}

@media (min-width:782px) {
    .wp-block-columns {
        flex-wrap: nowrap
    }
}

.wp-block-column {
    flex: 1;
    margin-bottom: 1em;
    flex-basis: 100%;
    min-width: 0;
    word-break: break-word;
    overflow-wrap: break-word
}

@media (min-width:600px) {
    .wp-block-column {
        flex-basis: 50%;
        flex-grow: 0
    }
}

@media (min-width:600px) {
    .wp-block-column:nth-child(odd) {
        margin-right: 32px
    }

    .wp-block-column:nth-child(even) {
        margin-left: 32px
    }

    .wp-block-column:not(:first-child) {
        margin-left: 32px
    }

    .wp-block-column:not(:last-child) {
        margin-right: 32px
    }
}

.wp-block-cover,
.wp-block-cover-image {
    position: relative;
    background-color: #000;
    background-size: cover;
    background-position: center center;
    min-height: 430px;
    width: 100%;
    margin: 0 0 1.5em 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden
}

.wp-block-cover-image.has-left-content,
.wp-block-cover.has-left-content {
    justify-content: flex-start
}

.wp-block-cover-image.has-left-content .wp-block-cover-image-text,
.wp-block-cover-image.has-left-content .wp-block-cover-text,
.wp-block-cover-image.has-left-content h2,
.wp-block-cover.has-left-content .wp-block-cover-image-text,
.wp-block-cover.has-left-content .wp-block-cover-text,
.wp-block-cover.has-left-content h2 {
    margin-left: 0;
    text-align: left
}

.wp-block-cover-image.has-right-content,
.wp-block-cover.has-right-content {
    justify-content: flex-end
}

.wp-block-cover-image.has-right-content .wp-block-cover-image-text,
.wp-block-cover-image.has-right-content .wp-block-cover-text,
.wp-block-cover-image.has-right-content h2,
.wp-block-cover.has-right-content .wp-block-cover-image-text,
.wp-block-cover.has-right-content .wp-block-cover-text,
.wp-block-cover.has-right-content h2 {
    margin-right: 0;
    text-align: right
}

.wp-block-cover .wp-block-cover-image-text,
.wp-block-cover .wp-block-cover-text,
.wp-block-cover h2,
.wp-block-cover-image .wp-block-cover-image-text,
.wp-block-cover-image .wp-block-cover-text,
.wp-block-cover-image h2 {
    color: #fff;
    font-size: 2em;
    line-height: 1.25;
    z-index: 1;
    margin-bottom: 0;
    max-width: 610px;
    padding: 14px;
    text-align: center
}

.wp-block-cover .wp-block-cover-image-text a,
.wp-block-cover .wp-block-cover-image-text a:active,
.wp-block-cover .wp-block-cover-image-text a:focus,
.wp-block-cover .wp-block-cover-image-text a:hover,
.wp-block-cover .wp-block-cover-text a,
.wp-block-cover .wp-block-cover-text a:active,
.wp-block-cover .wp-block-cover-text a:focus,
.wp-block-cover .wp-block-cover-text a:hover,
.wp-block-cover h2 a,
.wp-block-cover h2 a:active,
.wp-block-cover h2 a:focus,
.wp-block-cover h2 a:hover,
.wp-block-cover-image .wp-block-cover-image-text a,
.wp-block-cover-image .wp-block-cover-image-text a:active,
.wp-block-cover-image .wp-block-cover-image-text a:focus,
.wp-block-cover-image .wp-block-cover-image-text a:hover,
.wp-block-cover-image .wp-block-cover-text a,
.wp-block-cover-image .wp-block-cover-text a:active,
.wp-block-cover-image .wp-block-cover-text a:focus,
.wp-block-cover-image .wp-block-cover-text a:hover,
.wp-block-cover-image h2 a,
.wp-block-cover-image h2 a:active,
.wp-block-cover-image h2 a:focus,
.wp-block-cover-image h2 a:hover {
    color: #fff
}

.wp-block-cover-image.has-parallax,
.wp-block-cover.has-parallax {
    background-attachment: fixed
}

@supports (-webkit-overflow-scrolling:touch) {

    .wp-block-cover-image.has-parallax,
    .wp-block-cover.has-parallax {
        background-attachment: scroll
    }
}

.wp-block-cover-image.has-background-dim::before,
.wp-block-cover.has-background-dim::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: inherit;
    opacity: .5;
    z-index: 1
}

.wp-block-cover-image.has-background-dim.has-background-dim-10::before,
.wp-block-cover.has-background-dim.has-background-dim-10::before {
    opacity: .1
}

.wp-block-cover-image.has-background-dim.has-background-dim-20::before,
.wp-block-cover.has-background-dim.has-background-dim-20::before {
    opacity: .2
}

.wp-block-cover-image.has-background-dim.has-background-dim-30::before,
.wp-block-cover.has-background-dim.has-background-dim-30::before {
    opacity: .3
}

.wp-block-cover-image.has-background-dim.has-background-dim-40::before,
.wp-block-cover.has-background-dim.has-background-dim-40::before {
    opacity: .4
}

.wp-block-cover-image.has-background-dim.has-background-dim-50::before,
.wp-block-cover.has-background-dim.has-background-dim-50::before {
    opacity: .5
}

.wp-block-cover-image.has-background-dim.has-background-dim-60::before,
.wp-block-cover.has-background-dim.has-background-dim-60::before {
    opacity: .6
}

.wp-block-cover-image.has-background-dim.has-background-dim-70::before,
.wp-block-cover.has-background-dim.has-background-dim-70::before {
    opacity: .7
}

.wp-block-cover-image.has-background-dim.has-background-dim-80::before,
.wp-block-cover.has-background-dim.has-background-dim-80::before {
    opacity: .8
}

.wp-block-cover-image.has-background-dim.has-background-dim-90::before,
.wp-block-cover.has-background-dim.has-background-dim-90::before {
    opacity: .9
}

.wp-block-cover-image.has-background-dim.has-background-dim-100::before,
.wp-block-cover.has-background-dim.has-background-dim-100::before {
    opacity: 1
}

.wp-block-cover-image.alignleft,
.wp-block-cover-image.alignright,
.wp-block-cover.alignleft,
.wp-block-cover.alignright {
    max-width: 305px;
    width: 100%
}

.wp-block-cover-image::after,
.wp-block-cover::after {
    display: block;
    content: "";
    font-size: 0;
    min-height: inherit
}

@supports ((position:-webkit-sticky) or (position:sticky)) {

    .wp-block-cover-image::after,
    .wp-block-cover::after {
        content: none
    }
}

.wp-block-cover-image.aligncenter,
.wp-block-cover-image.alignleft,
.wp-block-cover-image.alignright,
.wp-block-cover.aligncenter,
.wp-block-cover.alignleft,
.wp-block-cover.alignright {
    display: flex
}

.wp-block-cover__video-background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 100%;
    height: 100%;
    z-index: 0;
    -o-object-fit: cover;
    object-fit: cover
}

.editor-block-list__block[data-type="core/embed"][data-align=left] .editor-block-list__block-edit,
.editor-block-list__block[data-type="core/embed"][data-align=right] .editor-block-list__block-edit,
.wp-block-embed.alignleft,
.wp-block-embed.alignright {
    max-width: 360px;
    width: 100%
}

.wp-block-embed {
    margin-bottom: 1em
}

.wp-block-embed figcaption {
    margin-top: .5em;
    margin-bottom: 1em;
    color: #555d66;
    text-align: center;
    font-size: 13px
}

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-1-1 .wp-block-embed__wrapper,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-1-2 .wp-block-embed__wrapper,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-16-9 .wp-block-embed__wrapper,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-18-9 .wp-block-embed__wrapper,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-21-9 .wp-block-embed__wrapper,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-4-3 .wp-block-embed__wrapper,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-9-16 .wp-block-embed__wrapper {
    position: relative
}

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-1-1 .wp-block-embed__wrapper::before,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-1-2 .wp-block-embed__wrapper::before,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-16-9 .wp-block-embed__wrapper::before,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-18-9 .wp-block-embed__wrapper::before,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-21-9 .wp-block-embed__wrapper::before,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-4-3 .wp-block-embed__wrapper::before,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-9-16 .wp-block-embed__wrapper::before {
    content: "";
    display: block;
    padding-top: 50%
}

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-1-1 .wp-block-embed__wrapper iframe,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-1-2 .wp-block-embed__wrapper iframe,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-16-9 .wp-block-embed__wrapper iframe,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-18-9 .wp-block-embed__wrapper iframe,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-21-9 .wp-block-embed__wrapper iframe,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-4-3 .wp-block-embed__wrapper iframe,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-9-16 .wp-block-embed__wrapper iframe {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-21-9 .wp-block-embed__wrapper::before {
    padding-top: 42.85%
}

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-18-9 .wp-block-embed__wrapper::before {
    padding-top: 50%
}

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-16-9 .wp-block-embed__wrapper::before {
    padding-top: 56.25%
}

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-4-3 .wp-block-embed__wrapper::before {
    padding-top: 75%
}

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-1-1 .wp-block-embed__wrapper::before {
    padding-top: 100%
}

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-9-6 .wp-block-embed__wrapper::before {
    padding-top: 66.66%
}

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-1-2 .wp-block-embed__wrapper::before {
    padding-top: 200%
}

.wp-block-file {
    margin-bottom: 1.5em
}

.wp-block-file.aligncenter {
    text-align: center
}

.wp-block-file.alignright {
    text-align: right
}

.wp-block-file .wp-block-file__button {
    background: #32373c;
    border-radius: 2em;
    color: #fff;
    font-size: 13px;
    padding: .5em 1em
}

.wp-block-file a.wp-block-file__button {
    text-decoration: none
}

.wp-block-file a.wp-block-file__button:active,
.wp-block-file a.wp-block-file__button:focus,
.wp-block-file a.wp-block-file__button:hover,
.wp-block-file a.wp-block-file__button:visited {
    box-shadow: none;
    color: #fff;
    opacity: .85;
    text-decoration: none
}

.wp-block-file *+.wp-block-file__button {
    margin-left: .75em
}

.wp-block-gallery {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0
}

.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
    margin: 0 16px 16px 0;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    position: relative
}

.wp-block-gallery .blocks-gallery-image figure,
.wp-block-gallery .blocks-gallery-item figure {
    margin: 0;
    height: 100%
}

@supports ((position:-webkit-sticky) or (position:sticky)) {

    .wp-block-gallery .blocks-gallery-image figure,
    .wp-block-gallery .blocks-gallery-item figure {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start
    }
}

.wp-block-gallery .blocks-gallery-image img,
.wp-block-gallery .blocks-gallery-item img {
    display: block;
    max-width: 100%;
    height: auto
}

.wp-block-gallery .blocks-gallery-image img,
.wp-block-gallery .blocks-gallery-item img {
    width: 100%
}

@supports ((position:-webkit-sticky) or (position:sticky)) {

    .wp-block-gallery .blocks-gallery-image img,
    .wp-block-gallery .blocks-gallery-item img {
        width: auto
    }
}

.wp-block-gallery .blocks-gallery-image figcaption,
.wp-block-gallery .blocks-gallery-item figcaption {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-height: 100%;
    overflow: auto;
    padding: 40px 10px 5px;
    color: #fff;
    text-align: center;
    font-size: 13px;
    background: linear-gradient(0deg, rgba(0, 0, 0, .7) 0, rgba(0, 0, 0, .3) 60%, transparent)
}

.wp-block-gallery .blocks-gallery-image figcaption img,
.wp-block-gallery .blocks-gallery-item figcaption img {
    display: inline
}

.wp-block-gallery.is-cropped .blocks-gallery-image a,
.wp-block-gallery.is-cropped .blocks-gallery-image img,
.wp-block-gallery.is-cropped .blocks-gallery-item a,
.wp-block-gallery.is-cropped .blocks-gallery-item img {
    width: 100%
}

@supports ((position:-webkit-sticky) or (position:sticky)) {

    .wp-block-gallery.is-cropped .blocks-gallery-image a,
    .wp-block-gallery.is-cropped .blocks-gallery-image img,
    .wp-block-gallery.is-cropped .blocks-gallery-item a,
    .wp-block-gallery.is-cropped .blocks-gallery-item img {
        height: 100%;
        flex: 1;
        -o-object-fit: cover;
        object-fit: cover
    }
}

.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
    width: calc((100% - 16px)/ 2)
}

.wp-block-gallery .blocks-gallery-image:nth-of-type(even),
.wp-block-gallery .blocks-gallery-item:nth-of-type(even) {
    margin-right: 0
}

.wp-block-gallery.columns-1 .blocks-gallery-image,
.wp-block-gallery.columns-1 .blocks-gallery-item {
    width: 100%;
    margin-right: 0
}

@media (min-width:600px) {

    .wp-block-gallery.columns-3 .blocks-gallery-image,
    .wp-block-gallery.columns-3 .blocks-gallery-item {
        width: calc((100% - 16px * 2)/ 3);
        margin-right: 16px
    }

    .wp-block-gallery.columns-4 .blocks-gallery-image,
    .wp-block-gallery.columns-4 .blocks-gallery-item {
        width: calc((100% - 16px * 3)/ 4);
        margin-right: 16px
    }

    .wp-block-gallery.columns-5 .blocks-gallery-image,
    .wp-block-gallery.columns-5 .blocks-gallery-item {
        width: calc((100% - 16px * 4)/ 5);
        margin-right: 16px
    }

    .wp-block-gallery.columns-6 .blocks-gallery-image,
    .wp-block-gallery.columns-6 .blocks-gallery-item {
        width: calc((100% - 16px * 5)/ 6);
        margin-right: 16px
    }

    .wp-block-gallery.columns-7 .blocks-gallery-image,
    .wp-block-gallery.columns-7 .blocks-gallery-item {
        width: calc((100% - 16px * 6)/ 7);
        margin-right: 16px
    }

    .wp-block-gallery.columns-8 .blocks-gallery-image,
    .wp-block-gallery.columns-8 .blocks-gallery-item {
        width: calc((100% - 16px * 7)/ 8);
        margin-right: 16px
    }

    .wp-block-gallery.columns-1 .blocks-gallery-image:nth-of-type(1n),
    .wp-block-gallery.columns-1 .blocks-gallery-item:nth-of-type(1n) {
        margin-right: 0
    }

    .wp-block-gallery.columns-2 .blocks-gallery-image:nth-of-type(2n),
    .wp-block-gallery.columns-2 .blocks-gallery-item:nth-of-type(2n) {
        margin-right: 0
    }

    .wp-block-gallery.columns-3 .blocks-gallery-image:nth-of-type(3n),
    .wp-block-gallery.columns-3 .blocks-gallery-item:nth-of-type(3n) {
        margin-right: 0
    }

    .wp-block-gallery.columns-4 .blocks-gallery-image:nth-of-type(4n),
    .wp-block-gallery.columns-4 .blocks-gallery-item:nth-of-type(4n) {
        margin-right: 0
    }

    .wp-block-gallery.columns-5 .blocks-gallery-image:nth-of-type(5n),
    .wp-block-gallery.columns-5 .blocks-gallery-item:nth-of-type(5n) {
        margin-right: 0
    }

    .wp-block-gallery.columns-6 .blocks-gallery-image:nth-of-type(6n),
    .wp-block-gallery.columns-6 .blocks-gallery-item:nth-of-type(6n) {
        margin-right: 0
    }

    .wp-block-gallery.columns-7 .blocks-gallery-image:nth-of-type(7n),
    .wp-block-gallery.columns-7 .blocks-gallery-item:nth-of-type(7n) {
        margin-right: 0
    }

    .wp-block-gallery.columns-8 .blocks-gallery-image:nth-of-type(8n),
    .wp-block-gallery.columns-8 .blocks-gallery-item:nth-of-type(8n) {
        margin-right: 0
    }
}

.wp-block-gallery .blocks-gallery-image:last-child,
.wp-block-gallery .blocks-gallery-item:last-child {
    margin-right: 0
}

.wp-block-gallery .blocks-gallery-item.has-add-item-button {
    width: 100%
}

.wp-block-gallery.alignleft,
.wp-block-gallery.alignright {
    max-width: 305px;
    width: 100%
}

.wp-block-gallery.aligncenter,
.wp-block-gallery.alignleft,
.wp-block-gallery.alignright {
    display: flex
}

.wp-block-gallery.aligncenter .blocks-gallery-item figure {
    justify-content: center
}

.wp-block-image {
    max-width: 100%;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0
}

.wp-block-image img {
    max-width: 100%
}

.wp-block-image.aligncenter {
    text-align: center
}

.wp-block-image.alignfull img,
.wp-block-image.alignwide img {
    width: 100%
}

.wp-block-image .aligncenter,
.wp-block-image .alignleft,
.wp-block-image .alignright,
.wp-block-image.is-resized {
    display: table;
    margin-left: 0;
    margin-right: 0
}

.wp-block-image .aligncenter>figcaption,
.wp-block-image .alignleft>figcaption,
.wp-block-image .alignright>figcaption,
.wp-block-image.is-resized>figcaption {
    display: table-caption;
    caption-side: bottom
}

.wp-block-image .alignleft {
    float: left;
    margin-right: 1em
}

.wp-block-image .alignright {
    float: right;
    margin-left: 1em
}

.wp-block-image .aligncenter {
    margin-left: auto;
    margin-right: auto
}

.wp-block-image figcaption {
    margin-top: .5em;
    margin-bottom: 1em;
    color: #555d66;
    text-align: center;
    font-size: 13px
}

.wp-block-latest-comments__comment {
    font-size: 15px;
    line-height: 1.1;
    list-style: none;
    margin-bottom: 1em
}

.has-avatars .wp-block-latest-comments__comment {
    min-height: 36px;
    list-style: none
}

.has-avatars .wp-block-latest-comments__comment .wp-block-latest-comments__comment-excerpt,
.has-avatars .wp-block-latest-comments__comment .wp-block-latest-comments__comment-meta {
    margin-left: 52px
}

.has-dates .wp-block-latest-comments__comment,
.has-excerpts .wp-block-latest-comments__comment {
    line-height: 1.5
}

.wp-block-latest-comments__comment-excerpt p {
    font-size: 14px;
    line-height: 1.8;
    margin: 5px 0 20px
}

.wp-block-latest-comments__comment-date {
    color: #8f98a1;
    display: block;
    font-size: 12px
}

.wp-block-latest-comments .avatar,
.wp-block-latest-comments__comment-avatar {
    border-radius: 24px;
    display: block;
    float: left;
    height: 40px;
    margin-right: 12px;
    width: 40px
}

.wp-block-latest-posts.alignleft {
    margin-right: 2em
}

.wp-block-latest-posts.alignright {
    margin-left: 2em
}

.wp-block-latest-posts.is-grid {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    list-style: none
}

.wp-block-latest-posts.is-grid li {
    margin: 0 16px 16px 0;
    width: 100%
}

@media (min-width:600px) {
    .wp-block-latest-posts.columns-2 li {
        width: calc((100% / 2) - 16px)
    }

    .wp-block-latest-posts.columns-3 li {
        width: calc((100% / 3) - 16px)
    }

    .wp-block-latest-posts.columns-4 li {
        width: calc((100% / 4) - 16px)
    }

    .wp-block-latest-posts.columns-5 li {
        width: calc((100% / 5) - 16px)
    }

    .wp-block-latest-posts.columns-6 li {
        width: calc((100% / 6) - 16px)
    }
}

.wp-block-latest-posts__post-date {
    display: block;
    color: #6c7781;
    font-size: 13px
}

.wp-block-media-text {
    display: grid
}

.wp-block-media-text {
    grid-template-rows: auto;
    align-items: center;
    grid-template-areas: "media-text-media media-text-content";
    grid-template-columns: 50% auto
}

.wp-block-media-text.has-media-on-the-right {
    grid-template-areas: "media-text-content media-text-media";
    grid-template-columns: auto 50%
}

.wp-block-media-text .wp-block-media-text__media {
    grid-area: media-text-media;
    margin: 0
}

.wp-block-media-text .wp-block-media-text__content {
    word-break: break-word;
    grid-area: media-text-content;
    padding: 0 8% 0 8%
}

.wp-block-media-text>figure>img,
.wp-block-media-text>figure>video {
    max-width: unset;
    width: 100%;
    vertical-align: middle
}

@media (max-width:600px) {
    .wp-block-media-text.is-stacked-on-mobile {
        grid-template-columns: 100% !important;
        grid-template-areas: "media-text-media""media-text-content"
    }

    .wp-block-media-text.is-stacked-on-mobile.has-media-on-the-right {
        grid-template-areas: "media-text-content""media-text-media"
    }
}

p.is-small-text {
    font-size: 14px
}

p.is-regular-text {
    font-size: 16px
}

p.is-large-text {
    font-size: 36px
}

p.is-larger-text {
    font-size: 48px
}

p.has-drop-cap:not(:focus)::first-letter {
    float: left;
    font-size: 8.4em;
    line-height: .68;
    font-weight: 100;
    margin: .05em .1em 0 0;
    text-transform: uppercase;
    font-style: normal
}

p.has-drop-cap:not(:focus)::after {
    content: "";
    display: table;
    clear: both;
    padding-top: 14px
}

p.has-background {
    padding: 20px 30px
}

p.has-text-color a {
    color: inherit
}

.wp-block-pullquote {
    padding: 3em 0;
    margin-left: 0;
    margin-right: 0;
    text-align: center
}

.wp-block-pullquote.alignleft,
.wp-block-pullquote.alignright {
    max-width: 305px
}

.wp-block-pullquote.alignleft p,
.wp-block-pullquote.alignright p {
    font-size: 20px
}

.wp-block-pullquote p {
    font-size: 28px;
    line-height: 1.6
}

.wp-block-pullquote cite,
.wp-block-pullquote footer {
    position: relative
}

.wp-block-pullquote .has-text-color a {
    color: inherit
}

.wp-block-pullquote:not(.is-style-solid-color) {
    background: 0 0
}

.wp-block-pullquote.is-style-solid-color {
    border: none
}

.wp-block-pullquote.is-style-solid-color blockquote {
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    max-width: 60%
}

.wp-block-pullquote.is-style-solid-color blockquote p {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 32px
}

.wp-block-pullquote.is-style-solid-color blockquote cite {
    text-transform: none;
    font-style: normal
}

.wp-block-pullquote cite {
    color: inherit
}

.wp-block-quote.is-large,
.wp-block-quote.is-style-large {
    margin: 0 0 16px;
    padding: 0 1em
}

.wp-block-quote.is-large p,
.wp-block-quote.is-style-large p {
    font-size: 24px;
    font-style: italic;
    line-height: 1.6
}

.wp-block-quote.is-large cite,
.wp-block-quote.is-large footer,
.wp-block-quote.is-style-large cite,
.wp-block-quote.is-style-large footer {
    font-size: 18px;
    text-align: right
}

.wp-block-separator.is-style-wide {
    border-bottom-width: 1px
}

.wp-block-separator.is-style-dots {
    background: 0 0;
    border: none;
    text-align: center;
    max-width: none;
    line-height: 1;
    height: auto
}

.wp-block-separator.is-style-dots::before {
    content: "\00b7 \00b7 \00b7";
    color: #191e23;
    font-size: 20px;
    letter-spacing: 2em;
    padding-left: 2em;
    font-family: serif
}

p.wp-block-subhead {
    font-size: 1.1em;
    font-style: italic;
    opacity: .75
}

.wp-block-table.has-fixed-layout {
    table-layout: fixed;
    width: 100%
}

.wp-block-table.aligncenter,
.wp-block-table.alignleft,
.wp-block-table.alignright {
    display: table;
    width: auto
}

.wp-block-table.is-style-stripes {
    border-spacing: 0;
    border-collapse: inherit;
    border-bottom: 1px solid #f3f4f5
}

.wp-block-table.is-style-stripes tr:nth-child(odd) {
    background-color: #f3f4f5
}

.wp-block-table.is-style-stripes td {
    border-color: transparent
}

.wp-block-text-columns {
    display: flex
}

.wp-block-text-columns.aligncenter {
    display: flex
}

.wp-block-text-columns .wp-block-column {
    margin: 0 16px;
    padding: 0
}

.wp-block-text-columns .wp-block-column:first-child {
    margin-left: 0
}

.wp-block-text-columns .wp-block-column:last-child {
    margin-right: 0
}

.wp-block-text-columns.columns-2 .wp-block-column {
    width: calc(100% / 2)
}

.wp-block-text-columns.columns-3 .wp-block-column {
    width: calc(100% / 3)
}

.wp-block-text-columns.columns-4 .wp-block-column {
    width: calc(100% / 4)
}

pre.wp-block-verse {
    white-space: nowrap;
    overflow: auto
}

.wp-block-video {
    margin-left: 0;
    margin-right: 0
}

.wp-block-video video {
    max-width: 100%
}

@supports ((position:-webkit-sticky) or (position:sticky)) {
    .wp-block-video [poster] {
        -o-object-fit: cover;
        object-fit: cover
    }
}

.wp-block-video.aligncenter {
    text-align: center
}

.wp-block-video figcaption {
    margin-top: .5em;
    margin-bottom: 1em;
    color: #555d66;
    text-align: center;
    font-size: 13px
}

.has-pale-pink-background-color.has-pale-pink-background-color {
    background-color: #f78da7
}

.has-vivid-red-background-color.has-vivid-red-background-color {
    background-color: #cf2e2e
}

.has-luminous-vivid-orange-background-color.has-luminous-vivid-orange-background-color {
    background-color: #ff6900
}

.has-luminous-vivid-amber-background-color.has-luminous-vivid-amber-background-color {
    background-color: #fcb900
}

.has-light-green-cyan-background-color.has-light-green-cyan-background-color {
    background-color: #7bdcb5
}

.has-vivid-green-cyan-background-color.has-vivid-green-cyan-background-color {
    background-color: #00d084
}

.has-pale-cyan-blue-background-color.has-pale-cyan-blue-background-color {
    background-color: #8ed1fc
}

.has-vivid-cyan-blue-background-color.has-vivid-cyan-blue-background-color {
    background-color: #0693e3
}

.has-very-light-gray-background-color.has-very-light-gray-background-color {
    background-color: #eee
}

.has-cyan-bluish-gray-background-color.has-cyan-bluish-gray-background-color {
    background-color: #abb8c3
}

.has-very-dark-gray-background-color.has-very-dark-gray-background-color {
    background-color: #313131
}

.has-pale-pink-color.has-pale-pink-color {
    color: #f78da7
}

.has-vivid-red-color.has-vivid-red-color {
    color: #cf2e2e
}

.has-luminous-vivid-orange-color.has-luminous-vivid-orange-color {
    color: #ff6900
}

.has-luminous-vivid-amber-color.has-luminous-vivid-amber-color {
    color: #fcb900
}

.has-light-green-cyan-color.has-light-green-cyan-color {
    color: #7bdcb5
}

.has-vivid-green-cyan-color.has-vivid-green-cyan-color {
    color: #00d084
}

.has-pale-cyan-blue-color.has-pale-cyan-blue-color {
    color: #8ed1fc
}

.has-vivid-cyan-blue-color.has-vivid-cyan-blue-color {
    color: #0693e3
}

.has-very-light-gray-color.has-very-light-gray-color {
    color: #eee
}

.has-cyan-bluish-gray-color.has-cyan-bluish-gray-color {
    color: #abb8c3
}

.has-very-dark-gray-color.has-very-dark-gray-color {
    color: #313131
}

.has-small-font-size {
    font-size: 13px
}

.has-normal-font-size,
.has-regular-font-size {
    font-size: 16px
}

.has-medium-font-size {
    font-size: 20px
}

.has-large-font-size {
    font-size: 36px
}

.has-huge-font-size,
.has-larger-font-size {
    font-size: 42px
}

div.wpcf7 {
    margin: 0;
    padding: 0
}

div.wpcf7-response-output {
    margin: 2em .5em 1em;
    padding: .2em 1em
}

div.wpcf7 .screen-reader-response {
    position: absolute;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    width: 1px;
    margin: 0;
    padding: 0;
    border: 0
}

div.wpcf7-mail-sent-ok {
    border: 2px solid #398f14
}

div.wpcf7-mail-sent-ng {
    border: 2px solid red
}

div.wpcf7-spam-blocked {
    border: 2px solid #ffa500
}

div.wpcf7-validation-errors {
    border: 2px solid #f7e700
}

.wpcf7-form-control-wrap {
    position: relative
}

span.wpcf7-not-valid-tip {
    color: red;
    font-size: 1em;
    font-weight: 400;
    display: block
}

.use-floating-validation-tip span.wpcf7-not-valid-tip {
    position: absolute;
    top: 20%;
    left: 20%;
    z-index: 100;
    border: 1px solid red;
    background: #fff;
    padding: .2em .8em
}

span.wpcf7-list-item {
    display: inline-block;
    margin: 0 0 0 1em
}

span.wpcf7-list-item-label::before,
span.wpcf7-list-item-label::after {
    content: " "
}

.wpcf7-display-none {
    display: none
}

div.wpcf7 .ajax-loader {
    visibility: hidden;
    display: inline-block;
    background-image: url(../../../../../wp-content/plugins/contact-form-7/images/ajax-loader.gif);
    width: 16px;
    height: 16px;
    border: none;
    padding: 0;
    margin: 0 0 0 4px;
    vertical-align: middle
}

div.wpcf7 .ajax-loader.is-active {
    visibility: visible
}

div.wpcf7 div.ajax-error {
    display: none
}

div.wpcf7 .placeheld {
    color: #888
}

div.wpcf7 .wpcf7-recaptcha iframe {
    margin-bottom: 0
}

div.wpcf7 input[type="file"] {
    cursor: pointer
}

div.wpcf7 input[type="file"]:disabled {
    cursor: default
}

.woocommerce .woocommerce-error .button,
.woocommerce .woocommerce-info .button,
.woocommerce .woocommerce-message .button,
.woocommerce-page .woocommerce-error .button,
.woocommerce-page .woocommerce-info .button,
.woocommerce-page .woocommerce-message .button {
    float: right
}

.woocommerce .col2-set,
.woocommerce-page .col2-set {
    width: 100%
}

.woocommerce .col2-set::after,
.woocommerce .col2-set::before,
.woocommerce-page .col2-set::after,
.woocommerce-page .col2-set::before {
    content: ' ';
    display: table
}

.woocommerce .col2-set::after,
.woocommerce-page .col2-set::after {
    clear: both
}

.woocommerce .col2-set .col-1,
.woocommerce-page .col2-set .col-1 {
    float: left;
    width: 48%
}

.woocommerce .col2-set .col-2,
.woocommerce-page .col2-set .col-2 {
    float: right;
    width: 48%
}

.woocommerce img,
.woocommerce-page img {
    height: auto;
    max-width: 100%
}

.woocommerce #content div.product div.images,
.woocommerce div.product div.images,
.woocommerce-page #content div.product div.images,
.woocommerce-page div.product div.images {
    float: left;
    width: 48%
}

.woocommerce #content div.product div.thumbnails::after,
.woocommerce #content div.product div.thumbnails::before,
.woocommerce div.product div.thumbnails::after,
.woocommerce div.product div.thumbnails::before,
.woocommerce-page #content div.product div.thumbnails::after,
.woocommerce-page #content div.product div.thumbnails::before,
.woocommerce-page div.product div.thumbnails::after,
.woocommerce-page div.product div.thumbnails::before {
    content: ' ';
    display: table
}

.woocommerce #content div.product div.thumbnails::after,
.woocommerce div.product div.thumbnails::after,
.woocommerce-page #content div.product div.thumbnails::after,
.woocommerce-page div.product div.thumbnails::after {
    clear: both
}

.woocommerce #content div.product div.thumbnails a,
.woocommerce div.product div.thumbnails a,
.woocommerce-page #content div.product div.thumbnails a,
.woocommerce-page div.product div.thumbnails a {
    float: left;
    width: 30.75%;
    margin-right: 3.8%;
    margin-bottom: 1em
}

.woocommerce #content div.product div.thumbnails a.last,
.woocommerce div.product div.thumbnails a.last,
.woocommerce-page #content div.product div.thumbnails a.last,
.woocommerce-page div.product div.thumbnails a.last {
    margin-right: 0
}

.woocommerce #content div.product div.thumbnails a.first,
.woocommerce div.product div.thumbnails a.first,
.woocommerce-page #content div.product div.thumbnails a.first,
.woocommerce-page div.product div.thumbnails a.first {
    clear: both
}

.woocommerce #content div.product div.thumbnails.columns-1 a,
.woocommerce div.product div.thumbnails.columns-1 a,
.woocommerce-page #content div.product div.thumbnails.columns-1 a,
.woocommerce-page div.product div.thumbnails.columns-1 a {
    width: 100%;
    margin-right: 0;
    float: none
}

.woocommerce #content div.product div.thumbnails.columns-2 a,
.woocommerce div.product div.thumbnails.columns-2 a,
.woocommerce-page #content div.product div.thumbnails.columns-2 a,
.woocommerce-page div.product div.thumbnails.columns-2 a {
    width: 48%
}

.woocommerce #content div.product div.thumbnails.columns-4 a,
.woocommerce div.product div.thumbnails.columns-4 a,
.woocommerce-page #content div.product div.thumbnails.columns-4 a,
.woocommerce-page div.product div.thumbnails.columns-4 a {
    width: 22.05%
}

.woocommerce #content div.product div.thumbnails.columns-5 a,
.woocommerce div.product div.thumbnails.columns-5 a,
.woocommerce-page #content div.product div.thumbnails.columns-5 a,
.woocommerce-page div.product div.thumbnails.columns-5 a {
    width: 16.9%
}

.woocommerce #content div.product div.summary,
.woocommerce div.product div.summary,
.woocommerce-page #content div.product div.summary,
.woocommerce-page div.product div.summary {
    float: right;
    width: 48%;
    clear: none
}

.woocommerce #content div.product .woocommerce-tabs,
.woocommerce div.product .woocommerce-tabs,
.woocommerce-page #content div.product .woocommerce-tabs,
.woocommerce-page div.product .woocommerce-tabs {
    clear: both
}

.woocommerce #content div.product .woocommerce-tabs ul.tabs::after,
.woocommerce #content div.product .woocommerce-tabs ul.tabs::before,
.woocommerce div.product .woocommerce-tabs ul.tabs::after,
.woocommerce div.product .woocommerce-tabs ul.tabs::before,
.woocommerce-page #content div.product .woocommerce-tabs ul.tabs::after,
.woocommerce-page #content div.product .woocommerce-tabs ul.tabs::before,
.woocommerce-page div.product .woocommerce-tabs ul.tabs::after,
.woocommerce-page div.product .woocommerce-tabs ul.tabs::before {
    content: ' ';
    display: table
}

.woocommerce #content div.product .woocommerce-tabs ul.tabs::after,
.woocommerce div.product .woocommerce-tabs ul.tabs::after,
.woocommerce-page #content div.product .woocommerce-tabs ul.tabs::after,
.woocommerce-page div.product .woocommerce-tabs ul.tabs::after {
    clear: both
}

.woocommerce #content div.product .woocommerce-tabs ul.tabs li,
.woocommerce div.product .woocommerce-tabs ul.tabs li,
.woocommerce-page #content div.product .woocommerce-tabs ul.tabs li,
.woocommerce-page div.product .woocommerce-tabs ul.tabs li {
    display: inline-block
}

.woocommerce #content div.product #reviews .comment::after,
.woocommerce #content div.product #reviews .comment::before,
.woocommerce div.product #reviews .comment::after,
.woocommerce div.product #reviews .comment::before,
.woocommerce-page #content div.product #reviews .comment::after,
.woocommerce-page #content div.product #reviews .comment::before,
.woocommerce-page div.product #reviews .comment::after,
.woocommerce-page div.product #reviews .comment::before {
    content: ' ';
    display: table
}

.woocommerce #content div.product #reviews .comment::after,
.woocommerce div.product #reviews .comment::after,
.woocommerce-page #content div.product #reviews .comment::after,
.woocommerce-page div.product #reviews .comment::after {
    clear: both
}

.woocommerce #content div.product #reviews .comment img,
.woocommerce div.product #reviews .comment img,
.woocommerce-page #content div.product #reviews .comment img,
.woocommerce-page div.product #reviews .comment img {
    float: right;
    height: auto
}

.woocommerce ul.products,
.woocommerce-page ul.products {
    clear: both
}

.woocommerce ul.products::after,
.woocommerce ul.products::before,
.woocommerce-page ul.products::after,
.woocommerce-page ul.products::before {
    content: ' ';
    display: table
}

.woocommerce ul.products::after,
.woocommerce-page ul.products::after {
    clear: both
}

.woocommerce ul.products li.product,
.woocommerce-page ul.products li.product {
    float: left;
    margin: 0 3.8% 2.992em 0;
    padding: 0;
    position: relative;
    width: 22.05%;
    margin-left: 0
}

.woocommerce ul.products li.first,
.woocommerce-page ul.products li.first {
    clear: both
}

.woocommerce ul.products li.last,
.woocommerce-page ul.products li.last {
    margin-right: 0
}

.woocommerce ul.products.columns-1 li.product,
.woocommerce-page ul.products.columns-1 li.product {
    width: 100%;
    margin-right: 0
}

.woocommerce ul.products.columns-2 li.product,
.woocommerce-page ul.products.columns-2 li.product {
    width: 48%
}

.woocommerce ul.products.columns-3 li.product,
.woocommerce-page ul.products.columns-3 li.product {
    width: 30.75%
}

.woocommerce ul.products.columns-5 li.product,
.woocommerce-page ul.products.columns-5 li.product {
    width: 16.95%
}

.woocommerce ul.products.columns-6 li.product,
.woocommerce-page ul.products.columns-6 li.product {
    width: 13.5%
}

.woocommerce-page.columns-1 ul.products li.product,
.woocommerce.columns-1 ul.products li.product {
    width: 100%;
    margin-right: 0
}

.woocommerce-page.columns-2 ul.products li.product,
.woocommerce.columns-2 ul.products li.product {
    width: 48%
}

.woocommerce-page.columns-3 ul.products li.product,
.woocommerce.columns-3 ul.products li.product {
    width: 30.75%
}

.woocommerce-page.columns-5 ul.products li.product,
.woocommerce.columns-5 ul.products li.product {
    width: 16.95%
}

.woocommerce-page.columns-6 ul.products li.product,
.woocommerce.columns-6 ul.products li.product {
    width: 13.5%
}

.woocommerce .woocommerce-result-count,
.woocommerce-page .woocommerce-result-count {
    float: left
}

.woocommerce .woocommerce-ordering,
.woocommerce-page .woocommerce-ordering {
    float: right
}

.woocommerce .woocommerce-pagination ul.page-numbers::after,
.woocommerce .woocommerce-pagination ul.page-numbers::before,
.woocommerce-page .woocommerce-pagination ul.page-numbers::after,
.woocommerce-page .woocommerce-pagination ul.page-numbers::before {
    content: ' ';
    display: table
}

.woocommerce .woocommerce-pagination ul.page-numbers::after,
.woocommerce-page .woocommerce-pagination ul.page-numbers::after {
    clear: both
}

.woocommerce .woocommerce-pagination ul.page-numbers li,
.woocommerce-page .woocommerce-pagination ul.page-numbers li {
    display: inline-block
}

.woocommerce #content table.cart img,
.woocommerce table.cart img,
.woocommerce-page #content table.cart img,
.woocommerce-page table.cart img {
    height: auto
}

.woocommerce #content table.cart td.actions,
.woocommerce table.cart td.actions,
.woocommerce-page #content table.cart td.actions,
.woocommerce-page table.cart td.actions {
    text-align: right
}

.woocommerce #content table.cart td.actions .input-text,
.woocommerce table.cart td.actions .input-text,
.woocommerce-page #content table.cart td.actions .input-text,
.woocommerce-page table.cart td.actions .input-text {
    width: 80px
}

.woocommerce #content table.cart td.actions .coupon,
.woocommerce table.cart td.actions .coupon,
.woocommerce-page #content table.cart td.actions .coupon,
.woocommerce-page table.cart td.actions .coupon {
    float: left
}

.woocommerce #content table.cart td.actions .coupon label,
.woocommerce table.cart td.actions .coupon label,
.woocommerce-page #content table.cart td.actions .coupon label,
.woocommerce-page table.cart td.actions .coupon label {
    display: none
}

.woocommerce .cart-collaterals,
.woocommerce-page .cart-collaterals {
    width: 100%
}

.woocommerce .cart-collaterals::after,
.woocommerce .cart-collaterals::before,
.woocommerce-page .cart-collaterals::after,
.woocommerce-page .cart-collaterals::before {
    content: ' ';
    display: table
}

.woocommerce .cart-collaterals::after,
.woocommerce-page .cart-collaterals::after {
    clear: both
}

.woocommerce .cart-collaterals .related,
.woocommerce-page .cart-collaterals .related {
    width: 30.75%;
    float: left
}

.woocommerce .cart-collaterals .cross-sells,
.woocommerce-page .cart-collaterals .cross-sells {
    width: 48%;
    float: left
}

.woocommerce .cart-collaterals .cross-sells ul.products,
.woocommerce-page .cart-collaterals .cross-sells ul.products {
    float: none
}

.woocommerce .cart-collaterals .cross-sells ul.products li,
.woocommerce-page .cart-collaterals .cross-sells ul.products li {
    width: 48%
}

.woocommerce .cart-collaterals .shipping_calculator,
.woocommerce-page .cart-collaterals .shipping_calculator {
    width: 48%;
    clear: right;
    float: right
}

.woocommerce .cart-collaterals .shipping_calculator::after,
.woocommerce .cart-collaterals .shipping_calculator::before,
.woocommerce-page .cart-collaterals .shipping_calculator::after,
.woocommerce-page .cart-collaterals .shipping_calculator::before {
    content: ' ';
    display: table
}

.woocommerce .cart-collaterals .shipping_calculator::after,
.woocommerce-page .cart-collaterals .shipping_calculator::after {
    clear: both
}

.woocommerce .cart-collaterals .shipping_calculator .col2-set .col-1,
.woocommerce .cart-collaterals .shipping_calculator .col2-set .col-2,
.woocommerce-page .cart-collaterals .shipping_calculator .col2-set .col-1,
.woocommerce-page .cart-collaterals .shipping_calculator .col2-set .col-2 {
    width: 47%
}

.woocommerce .cart-collaterals .cart_totals,
.woocommerce-page .cart-collaterals .cart_totals {
    float: right;
    width: 48%
}

.woocommerce ul.cart_list li::after,
.woocommerce ul.cart_list li::before,
.woocommerce ul.product_list_widget li::after,
.woocommerce ul.product_list_widget li::before,
.woocommerce-page ul.cart_list li::after,
.woocommerce-page ul.cart_list li::before,
.woocommerce-page ul.product_list_widget li::after,
.woocommerce-page ul.product_list_widget li::before {
    content: ' ';
    display: table
}

.woocommerce ul.cart_list li::after,
.woocommerce ul.product_list_widget li::after,
.woocommerce-page ul.cart_list li::after,
.woocommerce-page ul.product_list_widget li::after {
    clear: both
}

.woocommerce ul.cart_list li img,
.woocommerce ul.product_list_widget li img,
.woocommerce-page ul.cart_list li img,
.woocommerce-page ul.product_list_widget li img {
    float: right;
    height: auto
}

.woocommerce form .form-row::after,
.woocommerce form .form-row::before,
.woocommerce-page form .form-row::after,
.woocommerce-page form .form-row::before {
    content: ' ';
    display: table
}

.woocommerce form .form-row::after,
.woocommerce-page form .form-row::after {
    clear: both
}

.woocommerce form .form-row label,
.woocommerce-page form .form-row label {
    display: block
}

.woocommerce form .form-row label.checkbox,
.woocommerce-page form .form-row label.checkbox {
    display: inline
}

.woocommerce form .form-row select,
.woocommerce-page form .form-row select {
    width: 100%
}

.woocommerce form .form-row .input-text,
.woocommerce-page form .form-row .input-text {
    box-sizing: border-box;
    width: 100%
}

.woocommerce form .form-row-first,
.woocommerce form .form-row-last,
.woocommerce-page form .form-row-first,
.woocommerce-page form .form-row-last {
    width: 47%;
    overflow: visible
}

.woocommerce form .form-row-first,
.woocommerce-page form .form-row-first {
    float: left
}

.woocommerce form .form-row-last,
.woocommerce-page form .form-row-last {
    float: right
}

.woocommerce form .form-row-wide,
.woocommerce-page form .form-row-wide {
    clear: both
}

.woocommerce #payment .form-row select,
.woocommerce-page #payment .form-row select {
    width: auto
}

.woocommerce #payment .terms,
.woocommerce #payment .wc-terms-and-conditions,
.woocommerce-page #payment .terms,
.woocommerce-page #payment .wc-terms-and-conditions {
    text-align: left;
    padding: 0 1em 0 0;
    float: left
}

.woocommerce #payment #place_order,
.woocommerce-page #payment #place_order {
    float: right
}

.woocommerce .woocommerce-billing-fields::after,
.woocommerce .woocommerce-billing-fields::before,
.woocommerce .woocommerce-shipping-fields::after,
.woocommerce .woocommerce-shipping-fields::before,
.woocommerce-page .woocommerce-billing-fields::after,
.woocommerce-page .woocommerce-billing-fields::before,
.woocommerce-page .woocommerce-shipping-fields::after,
.woocommerce-page .woocommerce-shipping-fields::before {
    content: ' ';
    display: table
}

.woocommerce .woocommerce-billing-fields::after,
.woocommerce .woocommerce-shipping-fields::after,
.woocommerce-page .woocommerce-billing-fields::after,
.woocommerce-page .woocommerce-shipping-fields::after {
    clear: both
}

.woocommerce .woocommerce-terms-and-conditions,
.woocommerce-page .woocommerce-terms-and-conditions {
    margin-bottom: 1.618em;
    padding: 1.618em
}

.woocommerce .woocommerce-oembed,
.woocommerce-page .woocommerce-oembed {
    position: relative
}

.woocommerce-account .woocommerce-MyAccount-navigation {
    float: left;
    width: 30%
}

.woocommerce-account .woocommerce-MyAccount-content {
    float: right;
    width: 68%
}

.woocommerce-page.left-sidebar #content.twentyeleven {
    width: 58.4%;
    margin: 0 7.6%;
    float: right
}

.woocommerce-page.right-sidebar #content.twentyeleven {
    margin: 0 7.6%;
    width: 58.4%;
    float: left
}

.twentyfourteen .tfwc {
    padding: 12px 10px 0;
    max-width: 474px;
    margin: 0 auto
}

.twentyfourteen .tfwc .product .entry-summary {
    padding: 0 !important;
    margin: 0 0 1.618em !important
}

.twentyfourteen .tfwc div.product.hentry.has-post-thumbnail {
    margin-top: 0
}

@media screen and (min-width:673px) {
    .twentyfourteen .tfwc {
        padding-right: 30px;
        padding-left: 30px
    }
}

@media screen and (min-width:1040px) {
    .twentyfourteen .tfwc {
        padding-right: 15px;
        padding-left: 15px
    }
}

@media screen and (min-width:1110px) {
    .twentyfourteen .tfwc {
        padding-right: 30px;
        padding-left: 30px
    }
}

@media screen and (min-width:1218px) {
    .twentyfourteen .tfwc {
        margin-right: 54px
    }

    .full-width .twentyfourteen .tfwc {
        margin-right: auto
    }
}

.twentyfifteen .t15wc {
    padding-left: 7.6923%;
    padding-right: 7.6923%;
    padding-top: 7.6923%;
    margin-bottom: 7.6923%;
    background: #fff;
    box-shadow: 0 0 1px rgba(0, 0, 0, .15)
}

.twentyfifteen .t15wc .page-title {
    margin-left: 0
}

@media screen and (min-width:38.75em) {
    .twentyfifteen .t15wc {
        margin-right: 7.6923%;
        margin-left: 7.6923%;
        margin-top: 8.3333%
    }
}

@media screen and (min-width:59.6875em) {
    .twentyfifteen .t15wc {
        margin-left: 8.3333%;
        margin-right: 8.3333%;
        padding: 10%
    }

    .single-product .twentyfifteen .entry-summary {
        padding: 0 !important
    }
}

.twentysixteen .site-main {
    margin-right: 7.6923%;
    margin-left: 7.6923%
}

.twentysixteen .entry-summary {
    margin-right: 0;
    margin-left: 0
}

#content .twentysixteen div.product div.images,
#content .twentysixteen div.product div.summary {
    width: 46.42857%
}

@media screen and (min-width:44.375em) {
    .twentysixteen .site-main {
        margin-right: 23.0769%
    }
}

@media screen and (min-width:56.875em) {
    .twentysixteen .site-main {
        margin-right: 0;
        margin-left: 0
    }

    .no-sidebar .twentysixteen .site-main {
        margin-right: 15%;
        margin-left: 15%
    }

    .no-sidebar .twentysixteen .entry-summary {
        margin-right: 0;
        margin-left: 0
    }
}

.rtl .woocommerce .col2-set .col-1,
.rtl .woocommerce-page .col2-set .col-1 {
    float: right
}

.rtl .woocommerce .col2-set .col-2,
.rtl .woocommerce-page .col2-set .col-2 {
    float: left
}

@charset "UTF-8";

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@font-face {
    font-family: star;
    src: url(../../../plugins/woocommerce/assets/fonts/star.eot);
    src: url(../../../plugins/woocommerce/assets/fonts/star.eot?) format("embedded-opentype"), url(../../../plugins/woocommerce/assets/fonts/star.woff) format("woff"), url(../../../plugins/woocommerce/assets/fonts/star.ttf) format("truetype"), url(../../../plugins/woocommerce/assets/fonts/star.svg) format("svg");
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: WooCommerce;
    src: url(../../../plugins/woocommerce/assets/fonts/WooCommerce.eot);
    src: url(../../../plugins/woocommerce/assets/fonts/WooCommerce.eot?) format("embedded-opentype"), url(../../../plugins/woocommerce/assets/fonts/WooCommerce.woff) format("woff"), url(../../../plugins/woocommerce/assets/fonts/WooCommerce.ttf) format("truetype"), url(../../../plugins/woocommerce/assets/fonts/WooCommerce.svg) format("svg");
    font-weight: 400;
    font-style: normal
}

.woocommerce-store-notice,
p.demo_store {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0;
    width: 100%;
    font-size: 1em;
    padding: 1em 0;
    text-align: center;
    background-color: #a46497;
    color: #fff;
    z-index: 99998;
    box-shadow: 0 1px 1em rgba(0, 0, 0, .2);
    display: none
}

.woocommerce-store-notice a,
p.demo_store a {
    color: #fff;
    text-decoration: underline
}

.screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px;
    word-wrap: normal !important
}

.admin-bar p.demo_store {
    top: 32px
}

.clear {
    clear: both
}

.woocommerce .blockUI.blockOverlay {
    position: relative
}

.woocommerce .blockUI.blockOverlay::before {
    height: 1em;
    width: 1em;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -.5em;
    margin-top: -.5em;
    content: '';
    -webkit-animation: spin 1s ease-in-out infinite;
    animation: spin 1s ease-in-out infinite;
    background: url(../../../../../wp-content/plugins/woocommerce/assets/images/icons/loader.svg) center center;
    background-size: cover;
    line-height: 1;
    text-align: center;
    font-size: 2em;
    color: rgba(0, 0, 0, .75)
}

.woocommerce .loader::before {
    height: 1em;
    width: 1em;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -.5em;
    margin-top: -.5em;
    content: '';
    -webkit-animation: spin 1s ease-in-out infinite;
    animation: spin 1s ease-in-out infinite;
    background: url(../../../../../wp-content/plugins/woocommerce/assets/images/icons/loader.svg) center center;
    background-size: cover;
    line-height: 1;
    text-align: center;
    font-size: 2em;
    color: rgba(0, 0, 0, .75)
}

.woocommerce a.remove {
    display: block;
    font-size: 1.5em;
    height: 1em;
    width: 1em;
    text-align: center;
    line-height: 1;
    border-radius: 100%;
    color: red !important;
    text-decoration: none;
    font-weight: 700;
    border: 0
}

.woocommerce a.remove:hover {
    color: #fff !important;
    background: red
}

.woocommerce small.note {
    display: block;
    color: #777;
    font-size: .857em;
    margin-top: 10px
}

.woocommerce .woocommerce-breadcrumb {
    margin: 0 0 1em;
    padding: 0;
    font-size: .92em;
    color: #777
}

.woocommerce .woocommerce-breadcrumb::after,
.woocommerce .woocommerce-breadcrumb::before {
    content: ' ';
    display: table
}

.woocommerce .woocommerce-breadcrumb::after {
    clear: both
}

.woocommerce .woocommerce-breadcrumb a {
    color: #777
}

.woocommerce .quantity .qty {
    width: 3.631em;
    text-align: center
}

.woocommerce div.product {
    margin-bottom: 0;
    position: relative
}

.woocommerce div.product .product_title {
    clear: none;
    margin-top: 0;
    padding: 0
}

.woocommerce div.product p.price,
.woocommerce div.product span.price {
    color: #77a464;
    font-size: 1.25em
}

.woocommerce div.product p.price ins,
.woocommerce div.product span.price ins {
    background: inherit;
    font-weight: 700;
    display: inline-block
}

.woocommerce div.product p.price del,
.woocommerce div.product span.price del {
    opacity: .5;
    display: inline-block
}

.woocommerce div.product p.stock {
    font-size: .92em
}

.woocommerce div.product .stock {
    color: #77a464
}

.woocommerce div.product .out-of-stock {
    color: red
}

.woocommerce div.product .woocommerce-product-rating {
    margin-bottom: 1.618em
}

.woocommerce div.product div.images {
    margin-bottom: 2em
}

.woocommerce div.product div.images img {
    display: block;
    width: 100%;
    height: auto;
    box-shadow: none
}

.woocommerce div.product div.images div.thumbnails {
    padding-top: 1em
}

.woocommerce div.product div.images.woocommerce-product-gallery {
    position: relative
}

.woocommerce div.product div.images .woocommerce-product-gallery__wrapper {
    -webkit-transition: all cubic-bezier(.795, -.035, 0, 1) .5s;
    transition: all cubic-bezier(.795, -.035, 0, 1) .5s;
    margin: 0;
    padding: 0
}

.woocommerce div.product div.images .woocommerce-product-gallery__wrapper .zoomImg {
    background-color: #fff;
    opacity: 0
}

.woocommerce div.product div.images .woocommerce-product-gallery__image:nth-child(n+2) {
    width: 25%;
    display: inline-block
}

.woocommerce div.product div.images .woocommerce-product-gallery__trigger {
    position: absolute;
    top: .5em;
    right: .5em;
    font-size: 2em;
    z-index: 9;
    width: 36px;
    height: 36px;
    background: #fff;
    text-indent: -9999px;
    border-radius: 100%;
    box-sizing: content-box
}

.woocommerce div.product div.images .woocommerce-product-gallery__trigger:before {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border: 2px solid #000;
    border-radius: 100%;
    position: absolute;
    top: 9px;
    left: 9px;
    box-sizing: content-box
}

.woocommerce div.product div.images .woocommerce-product-gallery__trigger:after {
    content: "";
    display: block;
    width: 2px;
    height: 8px;
    background: #000;
    border-radius: 6px;
    position: absolute;
    top: 19px;
    left: 22px;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    box-sizing: content-box
}

.woocommerce div.product div.images .flex-control-thumbs {
    overflow: hidden;
    zoom: 1;
    margin: 0;
    padding: 0
}

.woocommerce div.product div.images .flex-control-thumbs li {
    width: 25%;
    float: left;
    margin: 0;
    list-style: none
}

.woocommerce div.product div.images .flex-control-thumbs li img {
    cursor: pointer;
    opacity: .5;
    margin: 0
}

.woocommerce div.product div.images .flex-control-thumbs li img.flex-active,
.woocommerce div.product div.images .flex-control-thumbs li img:hover {
    opacity: 1
}

.woocommerce div.product .woocommerce-product-gallery--columns-3 .flex-control-thumbs li:nth-child(3n+1) {
    clear: left
}

.woocommerce div.product .woocommerce-product-gallery--columns-4 .flex-control-thumbs li:nth-child(4n+1) {
    clear: left
}

.woocommerce div.product .woocommerce-product-gallery--columns-5 .flex-control-thumbs li:nth-child(5n+1) {
    clear: left
}

.woocommerce div.product div.summary {
    margin-bottom: 2em
}

.woocommerce div.product div.social {
    text-align: right;
    margin: 0 0 1em
}

.woocommerce div.product div.social span {
    margin: 0 0 0 2px
}

.woocommerce div.product div.social span span {
    margin: 0
}

.woocommerce div.product div.social span .stButton .chicklets {
    padding-left: 16px;
    width: 0
}

.woocommerce div.product div.social iframe {
    float: left;
    margin-top: 3px
}

.woocommerce div.product .woocommerce-tabs ul.tabs {
    list-style: none;
    padding: 0 0 0 1em;
    margin: 0 0 1.618em;
    overflow: hidden;
    position: relative
}

.woocommerce div.product .woocommerce-tabs ul.tabs li {
    border: 1px solid #d3ced2;
    background-color: #ebe9eb;
    display: inline-block;
    position: relative;
    z-index: 0;
    border-radius: 4px 4px 0 0;
    margin: 0 -5px;
    padding: 0 1em
}

.woocommerce div.product .woocommerce-tabs ul.tabs li a {
    display: inline-block;
    padding: .5em 0;
    font-weight: 700;
    color: #515151;
    text-decoration: none
}

.woocommerce div.product .woocommerce-tabs ul.tabs li a:hover {
    text-decoration: none;
    color: #6b6a6b
}

.woocommerce div.product .woocommerce-tabs ul.tabs li.active {
    background: #fff;
    z-index: 2;
    border-bottom-color: #fff
}

.woocommerce div.product .woocommerce-tabs ul.tabs li.active a {
    color: inherit;
    text-shadow: inherit
}

.woocommerce div.product .woocommerce-tabs ul.tabs li.active::before {
    box-shadow: 2px 2px 0 #fff
}

.woocommerce div.product .woocommerce-tabs ul.tabs li.active::after {
    box-shadow: -2px 2px 0 #fff
}

.woocommerce div.product .woocommerce-tabs ul.tabs li::after,
.woocommerce div.product .woocommerce-tabs ul.tabs li::before {
    border: 1px solid #d3ced2;
    position: absolute;
    bottom: -1px;
    width: 5px;
    height: 5px;
    content: ' ';
    box-sizing: border-box
}

.woocommerce div.product .woocommerce-tabs ul.tabs li::before {
    left: -5px;
    border-bottom-right-radius: 4px;
    border-width: 0 1px 1px 0;
    box-shadow: 2px 2px 0 #ebe9eb
}

.woocommerce div.product .woocommerce-tabs ul.tabs li::after {
    right: -5px;
    border-bottom-left-radius: 4px;
    border-width: 0 0 1px 1px;
    box-shadow: -2px 2px 0 #ebe9eb
}

.woocommerce div.product .woocommerce-tabs ul.tabs::before {
    position: absolute;
    content: ' ';
    width: 100%;
    bottom: 0;
    left: 0;
    border-bottom: 1px solid #d3ced2;
    z-index: 1
}

.woocommerce div.product .woocommerce-tabs .panel {
    margin: 0 0 2em;
    padding: 0
}

.woocommerce div.product p.cart {
    margin-bottom: 2em
}

.woocommerce div.product p.cart::after,
.woocommerce div.product p.cart::before {
    content: ' ';
    display: table
}

.woocommerce div.product p.cart::after {
    clear: both
}

.woocommerce div.product form.cart {
    margin-bottom: 2em
}

.woocommerce div.product form.cart::after,
.woocommerce div.product form.cart::before {
    content: ' ';
    display: table
}

.woocommerce div.product form.cart::after {
    clear: both
}

.woocommerce div.product form.cart div.quantity {
    float: left;
    margin: 0 4px 0 0
}

.woocommerce div.product form.cart table {
    border-width: 0 0 1px
}

.woocommerce div.product form.cart table td {
    padding-left: 0
}

.woocommerce div.product form.cart table div.quantity {
    float: none;
    margin: 0
}

.woocommerce div.product form.cart table small.stock {
    display: block;
    float: none
}

.woocommerce div.product form.cart .variations {
    margin-bottom: 1em;
    border: 0;
    width: 100%
}

.woocommerce div.product form.cart .variations td,
.woocommerce div.product form.cart .variations th {
    border: 0;
    vertical-align: top;
    line-height: 2em
}

.woocommerce div.product form.cart .variations label {
    font-weight: 700
}

.woocommerce div.product form.cart .variations select {
    max-width: 100%;
    min-width: 75%;
    display: inline-block;
    margin-right: 1em
}

.woocommerce div.product form.cart .variations td.label {
    padding-right: 1em
}

.woocommerce div.product form.cart .woocommerce-variation-description p {
    margin-bottom: 1em
}

.woocommerce div.product form.cart .reset_variations {
    visibility: hidden;
    font-size: .83em
}

.woocommerce div.product form.cart .wc-no-matching-variations {
    display: none
}

.woocommerce div.product form.cart .button {
    vertical-align: middle;
    float: left
}

.woocommerce div.product form.cart .group_table td.woocommerce-grouped-product-list-item__label {
    padding-right: 1em;
    padding-left: 1em
}

.woocommerce div.product form.cart .group_table td {
    vertical-align: top;
    padding-bottom: .5em;
    border: 0
}

.woocommerce div.product form.cart .group_table td:first-child {
    width: 4em;
    text-align: center
}

.woocommerce div.product form.cart .group_table .wc-grouped-product-add-to-cart-checkbox {
    display: inline-block;
    width: auto;
    margin: 0 auto;
    -webkit-transform: scale(1.5, 1.5);
    -ms-transform: scale(1.5, 1.5);
    transform: scale(1.5, 1.5)
}

.woocommerce span.onsale {
    min-height: 3.236em;
    min-width: 3.236em;
    padding: .202em;
    font-size: 1em;
    font-weight: 700;
    position: absolute;
    text-align: center;
    line-height: 3.236;
    top: -.5em;
    left: -.5em;
    margin: 0;
    border-radius: 100%;
    background-color: #77a464;
    color: #fff;
    font-size: .857em;
    z-index: 9
}

.woocommerce .products ul,
.woocommerce ul.products {
    margin: 0 0 1em;
    padding: 0;
    list-style: none outside;
    clear: both
}

.woocommerce .products ul::after,
.woocommerce .products ul::before,
.woocommerce ul.products::after,
.woocommerce ul.products::before {
    content: ' ';
    display: table
}

.woocommerce .products ul::after,
.woocommerce ul.products::after {
    clear: both
}

.woocommerce .products ul li,
.woocommerce ul.products li {
    list-style: none outside
}

.woocommerce ul.products li.product .onsale {
    top: 0;
    right: 0;
    left: auto;
    margin: -.5em -.5em 0 0
}

.woocommerce ul.products li.product .woocommerce-loop-category__title,
.woocommerce ul.products li.product .woocommerce-loop-product__title,
.woocommerce ul.products li.product h3 {
    padding: .5em 0;
    margin: 0;
    font-size: 1em
}

.woocommerce ul.products li.product a {
    text-decoration: none
}

.woocommerce ul.products li.product a img {
    width: 100%;
    height: auto;
    display: block;
    margin: 0 0 1em;
    box-shadow: none
}

.woocommerce ul.products li.product strong {
    display: block
}

.woocommerce ul.products li.product .star-rating {
    font-size: .857em
}

.woocommerce ul.products li.product .button {
    margin-top: 1em
}

.woocommerce ul.products li.product .price {
    color: #77a464;
    display: block;
    font-weight: 400;
    margin-bottom: .5em;
    font-size: .857em
}

.woocommerce ul.products li.product .price del {
    color: inherit;
    opacity: .5;
    display: inline-block
}

.woocommerce ul.products li.product .price ins {
    background: 0 0;
    font-weight: 700;
    display: inline-block
}

.woocommerce ul.products li.product .price .from {
    font-size: .67em;
    margin: -2px 0 0 0;
    text-transform: uppercase;
    color: rgba(132, 132, 132, .5)
}

.woocommerce .woocommerce-result-count {
    margin: 0 0 1em
}

.woocommerce .woocommerce-ordering {
    margin: 0 0 1em
}

.woocommerce .woocommerce-ordering select {
    vertical-align: top
}

.woocommerce nav.woocommerce-pagination {
    text-align: center
}

.woocommerce nav.woocommerce-pagination ul {
    display: inline-block;
    white-space: nowrap;
    padding: 0;
    clear: both;
    border: 1px solid #d3ced2;
    border-right: 0;
    margin: 1px
}

.woocommerce nav.woocommerce-pagination ul li {
    border-right: 1px solid #d3ced2;
    padding: 0;
    margin: 0;
    float: left;
    display: inline;
    overflow: hidden
}

.woocommerce nav.woocommerce-pagination ul li a,
.woocommerce nav.woocommerce-pagination ul li span {
    margin: 0;
    text-decoration: none;
    padding: 0;
    line-height: 1;
    font-size: 1em;
    font-weight: 400;
    padding: .5em;
    min-width: 1em;
    display: block
}

.woocommerce nav.woocommerce-pagination ul li a:focus,
.woocommerce nav.woocommerce-pagination ul li a:hover,
.woocommerce nav.woocommerce-pagination ul li span.current {
    background: #ebe9eb;
    color: #8a7e88
}

.woocommerce #respond input#submit,
.woocommerce a.button,
.woocommerce button.button,
.woocommerce input.button {
    font-size: 100%;
    margin: 0;
    line-height: 1;
    cursor: pointer;
    position: relative;
    text-decoration: none;
    overflow: visible;
    padding: .618em 1em;
    font-weight: 700;
    border-radius: 3px;
    left: auto;
    color: #515151;
    background-color: #ebe9eb;
    border: 0;
    display: inline-block;
    background-image: none;
    box-shadow: none;
    text-shadow: none
}

.woocommerce #respond input#submit.loading,
.woocommerce a.button.loading,
.woocommerce button.button.loading,
.woocommerce input.button.loading {
    opacity: .25;
    padding-right: 2.618em
}

.woocommerce #respond input#submit.loading::after,
.woocommerce a.button.loading::after,
.woocommerce button.button.loading::after,
.woocommerce input.button.loading::after {
    font-family: WooCommerce;
    content: '\e01c';
    vertical-align: top;
    font-weight: 400;
    position: absolute;
    top: .618em;
    right: 1em;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite
}

.woocommerce #respond input#submit.added::after,
.woocommerce a.button.added::after,
.woocommerce button.button.added::after,
.woocommerce input.button.added::after {
    font-family: WooCommerce;
    content: '\e017';
    margin-left: .53em;
    vertical-align: bottom
}

.woocommerce #respond input#submit:hover,
.woocommerce a.button:hover,
.woocommerce button.button:hover,
.woocommerce input.button:hover {
    background-color: #dad8da;
    text-decoration: none;
    background-image: none;
    color: #515151
}

.woocommerce #respond input#submit.alt,
.woocommerce a.button.alt,
.woocommerce button.button.alt,
.woocommerce input.button.alt {
    background-color: #a46497;
    color: #fff;
    -webkit-font-smoothing: antialiased
}

.woocommerce #respond input#submit.alt:hover,
.woocommerce a.button.alt:hover,
.woocommerce button.button.alt:hover,
.woocommerce input.button.alt:hover {
    background-color: #935386;
    color: #fff
}

.woocommerce #respond input#submit.alt.disabled,
.woocommerce #respond input#submit.alt.disabled:hover,
.woocommerce #respond input#submit.alt:disabled,
.woocommerce #respond input#submit.alt:disabled:hover,
.woocommerce #respond input#submit.alt:disabled[disabled],
.woocommerce #respond input#submit.alt:disabled[disabled]:hover,
.woocommerce a.button.alt.disabled,
.woocommerce a.button.alt.disabled:hover,
.woocommerce a.button.alt:disabled,
.woocommerce a.button.alt:disabled:hover,
.woocommerce a.button.alt:disabled[disabled],
.woocommerce a.button.alt:disabled[disabled]:hover,
.woocommerce button.button.alt.disabled,
.woocommerce button.button.alt.disabled:hover,
.woocommerce button.button.alt:disabled,
.woocommerce button.button.alt:disabled:hover,
.woocommerce button.button.alt:disabled[disabled],
.woocommerce button.button.alt:disabled[disabled]:hover,
.woocommerce input.button.alt.disabled,
.woocommerce input.button.alt.disabled:hover,
.woocommerce input.button.alt:disabled,
.woocommerce input.button.alt:disabled:hover,
.woocommerce input.button.alt:disabled[disabled],
.woocommerce input.button.alt:disabled[disabled]:hover {
    background-color: #a46497;
    color: #fff
}

.woocommerce #respond input#submit.disabled,
.woocommerce #respond input#submit:disabled,
.woocommerce #respond input#submit:disabled[disabled],
.woocommerce a.button.disabled,
.woocommerce a.button:disabled,
.woocommerce a.button:disabled[disabled],
.woocommerce button.button.disabled,
.woocommerce button.button:disabled,
.woocommerce button.button:disabled[disabled],
.woocommerce input.button.disabled,
.woocommerce input.button:disabled,
.woocommerce input.button:disabled[disabled] {
    color: inherit;
    cursor: not-allowed;
    opacity: .5;
    padding: .618em 1em
}

.woocommerce #respond input#submit.disabled:hover,
.woocommerce #respond input#submit:disabled:hover,
.woocommerce #respond input#submit:disabled[disabled]:hover,
.woocommerce a.button.disabled:hover,
.woocommerce a.button:disabled:hover,
.woocommerce a.button:disabled[disabled]:hover,
.woocommerce button.button.disabled:hover,
.woocommerce button.button:disabled:hover,
.woocommerce button.button:disabled[disabled]:hover,
.woocommerce input.button.disabled:hover,
.woocommerce input.button:disabled:hover,
.woocommerce input.button:disabled[disabled]:hover {
    color: inherit;
    background-color: #ebe9eb
}

.woocommerce .cart .button,
.woocommerce .cart input.button {
    float: none
}

.woocommerce a.added_to_cart {
    padding-top: .5em;
    display: inline-block
}

.woocommerce #reviews h2 small {
    float: right;
    color: #777;
    font-size: 15px;
    margin: 10px 0 0
}

.woocommerce #reviews h2 small a {
    text-decoration: none;
    color: #777
}

.woocommerce #reviews h3 {
    margin: 0
}

.woocommerce #reviews #respond {
    margin: 0;
    border: 0;
    padding: 0
}

.woocommerce #reviews #comment {
    height: 75px
}

.woocommerce #reviews #comments .add_review::after,
.woocommerce #reviews #comments .add_review::before {
    content: ' ';
    display: table
}

.woocommerce #reviews #comments .add_review::after {
    clear: both
}

.woocommerce #reviews #comments h2 {
    clear: none
}

.woocommerce #reviews #comments ol.commentlist {
    margin: 0;
    width: 100%;
    background: 0 0;
    list-style: none
}

.woocommerce #reviews #comments ol.commentlist::after,
.woocommerce #reviews #comments ol.commentlist::before {
    content: ' ';
    display: table
}

.woocommerce #reviews #comments ol.commentlist::after {
    clear: both
}

.woocommerce #reviews #comments ol.commentlist li {
    padding: 0;
    margin: 0 0 20px;
    border: 0;
    position: relative;
    background: 0;
    border: 0
}

.woocommerce #reviews #comments ol.commentlist li .meta {
    color: #777;
    font-size: .75em
}

.woocommerce #reviews #comments ol.commentlist li img.avatar {
    float: left;
    position: absolute;
    top: 0;
    left: 0;
    padding: 3px;
    width: 32px;
    height: auto;
    background: #ebe9eb;
    border: 1px solid #e4e1e3;
    margin: 0;
    box-shadow: none
}

.woocommerce #reviews #comments ol.commentlist li .comment-text {
    margin: 0 0 0 50px;
    border: 1px solid #e4e1e3;
    border-radius: 4px;
    padding: 1em 1em 0
}

.woocommerce #reviews #comments ol.commentlist li .comment-text::after,
.woocommerce #reviews #comments ol.commentlist li .comment-text::before {
    content: ' ';
    display: table
}

.woocommerce #reviews #comments ol.commentlist li .comment-text::after {
    clear: both
}

.woocommerce #reviews #comments ol.commentlist li .comment-text p {
    margin: 0 0 1em
}

.woocommerce #reviews #comments ol.commentlist li .comment-text p.meta {
    font-size: .83em
}

.woocommerce #reviews #comments ol.commentlist ul.children {
    list-style: none outside;
    margin: 20px 0 0 50px
}

.woocommerce #reviews #comments ol.commentlist ul.children .star-rating {
    display: none
}

.woocommerce #reviews #comments ol.commentlist #respond {
    border: 1px solid #e4e1e3;
    border-radius: 4px;
    padding: 1em 1em 0;
    margin: 20px 0 0 50px
}

.woocommerce #reviews #comments .commentlist>li::before {
    content: ''
}

.woocommerce .star-rating {
    float: right;
    overflow: hidden;
    position: relative;
    height: 1em;
    line-height: 1;
    font-size: 1em;
    width: 5.4em;
    font-family: star
}

.woocommerce .star-rating::before {
    content: '\73\73\73\73\73';
    color: #d3ced2;
    float: left;
    top: 0;
    left: 0;
    position: absolute
}

.woocommerce .star-rating span {
    overflow: hidden;
    float: left;
    top: 0;
    left: 0;
    position: absolute;
    padding-top: 1.5em
}

.woocommerce .star-rating span::before {
    content: '\53\53\53\53\53';
    top: 0;
    position: absolute;
    left: 0
}

.woocommerce .woocommerce-product-rating {
    line-height: 2;
    display: block
}

.woocommerce .woocommerce-product-rating::after,
.woocommerce .woocommerce-product-rating::before {
    content: ' ';
    display: table
}

.woocommerce .woocommerce-product-rating::after {
    clear: both
}

.woocommerce .woocommerce-product-rating .star-rating {
    margin: .5em 4px 0 0;
    float: left
}

.woocommerce .products .star-rating {
    display: block;
    margin: 0 0 .5em;
    float: none
}

.woocommerce .hreview-aggregate .star-rating {
    margin: 10px 0 0
}

.woocommerce #review_form #respond {
    position: static;
    margin: 0;
    width: auto;
    padding: 0;
    background: transparent none;
    border: 0
}

.woocommerce #review_form #respond::after,
.woocommerce #review_form #respond::before {
    content: ' ';
    display: table
}

.woocommerce #review_form #respond::after {
    clear: both
}

.woocommerce #review_form #respond p {
    margin: 0 0 10px
}

.woocommerce #review_form #respond .form-submit input {
    left: auto
}

.woocommerce #review_form #respond textarea {
    box-sizing: border-box;
    width: 100%
}

.woocommerce p.stars a {
    position: relative;
    height: 1em;
    width: 1em;
    text-indent: -999em;
    display: inline-block;
    text-decoration: none
}

.woocommerce p.stars a::before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 1em;
    height: 1em;
    line-height: 1;
    font-family: WooCommerce;
    content: '\e021';
    text-indent: 0
}

.woocommerce p.stars a:hover~a::before {
    content: '\e021'
}

.woocommerce p.stars:hover a::before {
    content: '\e020'
}

.woocommerce p.stars.selected a.active::before {
    content: '\e020'
}

.woocommerce p.stars.selected a.active~a::before {
    content: '\e021'
}

.woocommerce p.stars.selected a:not(.active)::before {
    content: '\e020'
}

.woocommerce table.shop_attributes {
    border: 0;
    border-top: 1px dotted rgba(0, 0, 0, .1);
    margin-bottom: 1.618em;
    width: 100%
}

.woocommerce table.shop_attributes th {
    width: 150px;
    font-weight: 700;
    padding: 8px;
    border-top: 0;
    border-bottom: 1px dotted rgba(0, 0, 0, .1);
    margin: 0;
    line-height: 1.5
}

.woocommerce table.shop_attributes td {
    font-style: italic;
    padding: 0;
    border-top: 0;
    border-bottom: 1px dotted rgba(0, 0, 0, .1);
    margin: 0;
    line-height: 1.5
}

.woocommerce table.shop_attributes td p {
    margin: 0;
    padding: 8px 0
}

.woocommerce table.shop_attributes tr:nth-child(even) td,
.woocommerce table.shop_attributes tr:nth-child(even) th {
    background: rgba(0, 0, 0, .025)
}

.woocommerce table.shop_table {
    border: 1px solid rgba(0, 0, 0, .1);
    margin: 0 -1px 24px 0;
    text-align: left;
    width: 100%;
    border-collapse: separate;
    border-radius: 5px
}

.woocommerce table.shop_table th {
    font-weight: 700;
    padding: 9px 12px
}

.woocommerce table.shop_table td {
    border-top: 1px solid rgba(0, 0, 0, .1);
    padding: 6px 12px;
    vertical-align: middle
}

.woocommerce table.shop_table td small {
    font-weight: 400
}

.woocommerce table.shop_table tbody:first-child tr:first-child td,
.woocommerce table.shop_table tbody:first-child tr:first-child th {
    border-top: 0
}

.woocommerce table.shop_table tbody th,
.woocommerce table.shop_table tfoot td,
.woocommerce table.shop_table tfoot th {
    font-weight: 700;
    border-top: 1px solid rgba(0, 0, 0, .1)
}

.woocommerce table.my_account_orders {
    font-size: .85em
}

.woocommerce table.my_account_orders td,
.woocommerce table.my_account_orders th {
    padding: 4px 8px;
    vertical-align: middle
}

.woocommerce table.my_account_orders .button {
    white-space: nowrap
}

.woocommerce table.my_account_orders .order-actions {
    text-align: right
}

.woocommerce table.my_account_orders .order-actions .button {
    margin: .125em 0 .125em .25em
}

.woocommerce table.woocommerce-MyAccount-downloads td,
.woocommerce table.woocommerce-MyAccount-downloads th {
    vertical-align: top;
    text-align: center
}

.woocommerce table.woocommerce-MyAccount-downloads td:first-child,
.woocommerce table.woocommerce-MyAccount-downloads th:first-child {
    text-align: left
}

.woocommerce table.woocommerce-MyAccount-downloads td:last-child,
.woocommerce table.woocommerce-MyAccount-downloads th:last-child {
    text-align: left
}

.woocommerce table.woocommerce-MyAccount-downloads td .woocommerce-MyAccount-downloads-file::before,
.woocommerce table.woocommerce-MyAccount-downloads th .woocommerce-MyAccount-downloads-file::before {
    content: '\2193';
    display: inline-block
}

.woocommerce td.product-name .wc-item-meta,
.woocommerce td.product-name dl.variation {
    list-style: none outside
}

.woocommerce td.product-name .wc-item-meta .wc-item-meta-label,
.woocommerce td.product-name .wc-item-meta dt,
.woocommerce td.product-name dl.variation .wc-item-meta-label,
.woocommerce td.product-name dl.variation dt {
    float: left;
    clear: both;
    margin-right: .25em;
    display: inline-block;
    list-style: none outside
}

.woocommerce td.product-name .wc-item-meta dd,
.woocommerce td.product-name dl.variation dd {
    margin: 0
}

.woocommerce td.product-name .wc-item-meta p,
.woocommerce td.product-name .wc-item-meta:last-child,
.woocommerce td.product-name dl.variation p,
.woocommerce td.product-name dl.variation:last-child {
    margin-bottom: 0
}

.woocommerce td.product-name p.backorder_notification {
    font-size: .83em
}

.woocommerce td.product-quantity {
    min-width: 80px
}

.woocommerce ul.cart_list,
.woocommerce ul.product_list_widget {
    list-style: none outside;
    padding: 0;
    margin: 0
}

.woocommerce ul.cart_list li,
.woocommerce ul.product_list_widget li {
    padding: 4px 0;
    margin: 0;
    list-style: none
}

.woocommerce ul.cart_list li::after,
.woocommerce ul.cart_list li::before,
.woocommerce ul.product_list_widget li::after,
.woocommerce ul.product_list_widget li::before {
    content: ' ';
    display: table
}

.woocommerce ul.cart_list li::after,
.woocommerce ul.product_list_widget li::after {
    clear: both
}

.woocommerce ul.cart_list li a,
.woocommerce ul.product_list_widget li a {
    display: block;
    font-weight: 700
}

.woocommerce ul.cart_list li img,
.woocommerce ul.product_list_widget li img {
    float: right;
    margin-left: 4px;
    width: 32px;
    height: auto;
    box-shadow: none
}

.woocommerce ul.cart_list li dl,
.woocommerce ul.product_list_widget li dl {
    margin: 0;
    padding-left: 1em;
    border-left: 2px solid rgba(0, 0, 0, .1)
}

.woocommerce ul.cart_list li dl::after,
.woocommerce ul.cart_list li dl::before,
.woocommerce ul.product_list_widget li dl::after,
.woocommerce ul.product_list_widget li dl::before {
    content: ' ';
    display: table
}

.woocommerce ul.cart_list li dl::after,
.woocommerce ul.product_list_widget li dl::after {
    clear: both
}

.woocommerce ul.cart_list li dl dd,
.woocommerce ul.cart_list li dl dt,
.woocommerce ul.product_list_widget li dl dd,
.woocommerce ul.product_list_widget li dl dt {
    display: inline-block;
    float: left;
    margin-bottom: 1em
}

.woocommerce ul.cart_list li dl dt,
.woocommerce ul.product_list_widget li dl dt {
    font-weight: 700;
    padding: 0 0 .25em;
    margin: 0 4px 0 0;
    clear: left
}

.woocommerce ul.cart_list li dl dd,
.woocommerce ul.product_list_widget li dl dd {
    padding: 0 0 .25em
}

.woocommerce ul.cart_list li dl dd p:last-child,
.woocommerce ul.product_list_widget li dl dd p:last-child {
    margin-bottom: 0
}

.woocommerce ul.cart_list li .star-rating,
.woocommerce ul.product_list_widget li .star-rating {
    float: none
}

.woocommerce .widget_shopping_cart .total,
.woocommerce.widget_shopping_cart .total {
    border-top: 3px double #ebe9eb;
    padding: 4px 0 0
}

.woocommerce .widget_shopping_cart .total strong,
.woocommerce.widget_shopping_cart .total strong {
    min-width: 40px;
    display: inline-block
}

.woocommerce .widget_shopping_cart .cart_list li,
.woocommerce.widget_shopping_cart .cart_list li {
    padding-left: 2em;
    position: relative;
    padding-top: 0
}

.woocommerce .widget_shopping_cart .cart_list li a.remove,
.woocommerce.widget_shopping_cart .cart_list li a.remove {
    position: absolute;
    top: 0;
    left: 0
}

.woocommerce .widget_shopping_cart .buttons::after,
.woocommerce .widget_shopping_cart .buttons::before,
.woocommerce.widget_shopping_cart .buttons::after,
.woocommerce.widget_shopping_cart .buttons::before {
    content: ' ';
    display: table
}

.woocommerce .widget_shopping_cart .buttons::after,
.woocommerce.widget_shopping_cart .buttons::after {
    clear: both
}

.woocommerce .widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons a {
    margin-right: 5px;
    margin-bottom: 5px
}

.woocommerce form .form-row {
    padding: 3px;
    margin: 0 0 6px
}

.woocommerce form .form-row [placeholder]:focus::-webkit-input-placeholder {
    -webkit-transition: opacity .5s .5s ease;
    transition: opacity .5s .5s ease;
    opacity: 0
}

.woocommerce form .form-row label {
    line-height: 2
}

.woocommerce form .form-row label.hidden {
    visibility: hidden
}

.woocommerce form .form-row label.inline {
    display: inline
}

.woocommerce form .form-row .woocommerce-input-wrapper .description {
    background: #1e85be;
    color: #fff;
    border-radius: 3px;
    padding: 1em;
    margin: .5em 0 0;
    clear: both;
    display: none;
    position: relative
}

.woocommerce form .form-row .woocommerce-input-wrapper .description a {
    color: #fff;
    text-decoration: underline;
    border: 0;
    box-shadow: none
}

.woocommerce form .form-row .woocommerce-input-wrapper .description:before {
    left: 50%;
    top: 0;
    margin-top: -4px;
    -webkit-transform: translatex(-50%) rotate(180deg);
    -ms-transform: translatex(-50%) rotate(180deg);
    transform: translatex(-50%) rotate(180deg);
    content: "";
    position: absolute;
    border-width: 4px 6px 0 6px;
    border-style: solid;
    border-color: #1e85be transparent transparent transparent;
    z-index: 100;
    display: block
}

.woocommerce form .form-row select {
    cursor: pointer;
    margin: 0
}

.woocommerce form .form-row .required {
    color: red;
    font-weight: 700;
    border: 0 !important;
    text-decoration: none;
    visibility: hidden
}

.woocommerce form .form-row .optional {
    visibility: visible
}

.woocommerce form .form-row .input-checkbox {
    display: inline;
    margin: -2px 8px 0 0;
    text-align: center;
    vertical-align: middle
}

.woocommerce form .form-row input.input-text,
.woocommerce form .form-row textarea {
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    outline: 0;
    line-height: normal
}

.woocommerce form .form-row textarea {
    height: 4em;
    line-height: 1.5;
    display: block;
    box-shadow: none
}

.woocommerce form .form-row .select2-container {
    width: 100%;
    line-height: 2em
}

.woocommerce form .form-row.woocommerce-invalid label {
    color: #a00
}

.woocommerce form .form-row.woocommerce-invalid .select2-container,
.woocommerce form .form-row.woocommerce-invalid input.input-text,
.woocommerce form .form-row.woocommerce-invalid select {
    border-color: #a00
}

.woocommerce form .form-row.woocommerce-validated .select2-container,
.woocommerce form .form-row.woocommerce-validated input.input-text,
.woocommerce form .form-row.woocommerce-validated select {
    border-color: #69bf29
}

.woocommerce form .form-row ::-webkit-input-placeholder {
    line-height: normal
}

.woocommerce form .form-row :-moz-placeholder {
    line-height: normal
}

.woocommerce form .form-row :-ms-input-placeholder {
    line-height: normal
}

.woocommerce form.checkout_coupon,
.woocommerce form.login,
.woocommerce form.register {
    border: 1px solid #d3ced2;
    padding: 20px;
    margin: 2em 0;
    text-align: left;
    border-radius: 5px
}

.woocommerce ul#shipping_method {
    list-style: none outside;
    margin: 0;
    padding: 0
}

.woocommerce ul#shipping_method li {
    margin: 0;
    padding: .25em 0 .25em 22px;
    text-indent: -22px;
    list-style: none outside
}

.woocommerce ul#shipping_method li input {
    margin: 3px .5ex
}

.woocommerce ul#shipping_method li label {
    display: inline
}

.woocommerce ul#shipping_method .amount {
    font-weight: 700
}

.woocommerce p.woocommerce-shipping-contents {
    margin: 0
}

.woocommerce ul.order_details {
    margin: 0 0 3em;
    list-style: none
}

.woocommerce ul.order_details::after,
.woocommerce ul.order_details::before {
    content: ' ';
    display: table
}

.woocommerce ul.order_details::after {
    clear: both
}

.woocommerce ul.order_details li {
    float: left;
    margin-right: 2em;
    text-transform: uppercase;
    font-size: .715em;
    line-height: 1;
    border-right: 1px dashed #d3ced2;
    padding-right: 2em;
    margin-left: 0;
    padding-left: 0;
    list-style-type: none
}

.woocommerce ul.order_details li strong {
    display: block;
    font-size: 1.4em;
    text-transform: none;
    line-height: 1.5
}

.woocommerce ul.order_details li:last-of-type {
    border: none
}

.woocommerce .wc-bacs-bank-details-account-name {
    font-weight: 700
}

.woocommerce .woocommerce-customer-details,
.woocommerce .woocommerce-order-details,
.woocommerce .woocommerce-order-downloads {
    margin-bottom: 2em
}

.woocommerce .woocommerce-customer-details :last-child,
.woocommerce .woocommerce-order-details :last-child,
.woocommerce .woocommerce-order-downloads :last-child {
    margin-bottom: 0
}

.woocommerce .woocommerce-customer-details address {
    font-style: normal;
    margin-bottom: 0;
    border: 1px solid rgba(0, 0, 0, .1);
    border-bottom-width: 2px;
    border-right-width: 2px;
    text-align: left;
    width: 100%;
    border-radius: 5px;
    padding: 6px 12px
}

.woocommerce .woocommerce-customer-details .woocommerce-customer-details--email,
.woocommerce .woocommerce-customer-details .woocommerce-customer-details--phone {
    margin-bottom: 0;
    padding-left: 1.5em
}

.woocommerce .woocommerce-customer-details .woocommerce-customer-details--phone::before {
    font-family: WooCommerce;
    speak: none;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    margin-right: .618em;
    content: "";
    text-decoration: none;
    margin-left: -1.5em;
    line-height: 1.75;
    position: absolute
}

.woocommerce .woocommerce-customer-details .woocommerce-customer-details--email::before {
    font-family: WooCommerce;
    speak: none;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    margin-right: .618em;
    content: "";
    text-decoration: none;
    margin-left: -1.5em;
    line-height: 1.75;
    position: absolute
}

.woocommerce .woocommerce-widget-layered-nav-list {
    margin: 0;
    padding: 0;
    border: 0;
    list-style: none outside
}

.woocommerce .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item {
    padding: 0 0 1px;
    list-style: none
}

.woocommerce .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item::after,
.woocommerce .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item::before {
    content: ' ';
    display: table
}

.woocommerce .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item::after {
    clear: both
}

.woocommerce .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item a,
.woocommerce .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item span {
    padding: 1px 0
}

.woocommerce .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item--chosen a::before {
    font-family: WooCommerce;
    speak: none;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    margin-right: .618em;
    content: "";
    text-decoration: none;
    color: #a00
}

.woocommerce .woocommerce-widget-layered-nav-dropdown__submit {
    margin-top: 1em
}

.woocommerce .widget_layered_nav_filters ul {
    margin: 0;
    padding: 0;
    border: 0;
    list-style: none outside;
    overflow: hidden;
    zoom: 1
}

.woocommerce .widget_layered_nav_filters ul li {
    float: left;
    padding: 0 1em 1px 1px;
    list-style: none
}

.woocommerce .widget_layered_nav_filters ul li a {
    text-decoration: none
}

.woocommerce .widget_layered_nav_filters ul li a::before {
    font-family: WooCommerce;
    speak: none;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    margin-right: .618em;
    content: "";
    text-decoration: none;
    color: #a00;
    vertical-align: inherit;
    margin-right: .5em
}

.woocommerce .widget_price_filter .price_slider {
    margin-bottom: 1em
}

.woocommerce .widget_price_filter .price_slider_amount {
    text-align: right;
    line-height: 2.4;
    font-size: .8751em
}

.woocommerce .widget_price_filter .price_slider_amount .button {
    font-size: 1.15em;
    float: left
}

.woocommerce .widget_price_filter .ui-slider {
    position: relative;
    text-align: left;
    margin-left: .5em;
    margin-right: .5em
}

.woocommerce .widget_price_filter .ui-slider .ui-slider-handle {
    position: absolute;
    z-index: 2;
    width: 1em;
    height: 1em;
    background-color: #a46497;
    border-radius: 1em;
    cursor: ew-resize;
    outline: 0;
    top: -.3em;
    margin-left: -.5em
}

.woocommerce .widget_price_filter .ui-slider .ui-slider-range {
    position: absolute;
    z-index: 1;
    font-size: .7em;
    display: block;
    border: 0;
    border-radius: 1em;
    background-color: #a46497
}

.woocommerce .widget_price_filter .price_slider_wrapper .ui-widget-content {
    border-radius: 1em;
    background-color: #602053;
    border: 0
}

.woocommerce .widget_price_filter .ui-slider-horizontal {
    height: .5em
}

.woocommerce .widget_price_filter .ui-slider-horizontal .ui-slider-range {
    top: 0;
    height: 100%
}

.woocommerce .widget_price_filter .ui-slider-horizontal .ui-slider-range-min {
    left: -1px
}

.woocommerce .widget_price_filter .ui-slider-horizontal .ui-slider-range-max {
    right: -1px
}

.woocommerce .widget_rating_filter ul {
    margin: 0;
    padding: 0;
    border: 0;
    list-style: none outside
}

.woocommerce .widget_rating_filter ul li {
    padding: 0 0 1px;
    list-style: none
}

.woocommerce .widget_rating_filter ul li::after,
.woocommerce .widget_rating_filter ul li::before {
    content: ' ';
    display: table
}

.woocommerce .widget_rating_filter ul li::after {
    clear: both
}

.woocommerce .widget_rating_filter ul li a {
    padding: 1px 0;
    text-decoration: none
}

.woocommerce .widget_rating_filter ul li .star-rating {
    float: none;
    display: inline-block
}

.woocommerce .widget_rating_filter ul li.chosen a::before {
    font-family: WooCommerce;
    speak: none;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    margin-right: .618em;
    content: "";
    text-decoration: none;
    color: #a00
}

.woocommerce-no-js form.woocommerce-form-coupon,
.woocommerce-no-js form.woocommerce-form-login {
    display: block !important
}

.woocommerce-no-js .showcoupon,
.woocommerce-no-js .woocommerce-form-coupon-toggle,
.woocommerce-no-js .woocommerce-form-login-toggle {
    display: none !important
}

.woocommerce-error,
.woocommerce-info,
.woocommerce-message {
    padding: 1em 2em 1em 3.5em;
    margin: 0 0 2em;
    position: relative;
    background-color: #f7f6f7;
    color: #515151;
    border-top: 3px solid #a46497;
    list-style: none outside;
    width: auto;
    word-wrap: break-word
}

.woocommerce-error::after,
.woocommerce-error::before,
.woocommerce-info::after,
.woocommerce-info::before,
.woocommerce-message::after,
.woocommerce-message::before {
    content: ' ';
    display: table
}

.woocommerce-error::after,
.woocommerce-info::after,
.woocommerce-message::after {
    clear: both
}

.woocommerce-error::before,
.woocommerce-info::before,
.woocommerce-message::before {
    font-family: WooCommerce;
    content: '\e028';
    display: inline-block;
    position: absolute;
    top: 1em;
    left: 1.5em
}

.woocommerce-error .button,
.woocommerce-info .button,
.woocommerce-message .button {
    float: right
}

.woocommerce-error li,
.woocommerce-info li,
.woocommerce-message li {
    list-style: none outside !important;
    padding-left: 0 !important;
    margin-left: 0 !important
}

.rtl.woocommerce .price_label,
.rtl.woocommerce .price_label span {
    direction: ltr;
    unicode-bidi: embed
}

.woocommerce-message {
    border-top-color: #8fae1b
}

.woocommerce-message::before {
    content: '\e015';
    color: #8fae1b
}

.woocommerce-info {
    border-top-color: #1e85be
}

.woocommerce-info::before {
    color: #1e85be
}

.woocommerce-error {
    border-top-color: #b81c23
}

.woocommerce-error::before {
    content: '\e016';
    color: #b81c23
}

.woocommerce-account .woocommerce::after,
.woocommerce-account .woocommerce::before {
    content: ' ';
    display: table
}

.woocommerce-account .woocommerce::after {
    clear: both
}

.woocommerce-account .addresses .title::after,
.woocommerce-account .addresses .title::before {
    content: ' ';
    display: table
}

.woocommerce-account .addresses .title::after {
    clear: both
}

.woocommerce-account .addresses .title h3 {
    float: left
}

.woocommerce-account .addresses .title .edit {
    float: right
}

.woocommerce-account ol.commentlist.notes li.note p.meta {
    font-weight: 700;
    margin-bottom: 0
}

.woocommerce-account ol.commentlist.notes li.note .description p:last-child {
    margin-bottom: 0
}

.woocommerce-account ul.digital-downloads {
    margin-left: 0;
    padding-left: 0
}

.woocommerce-account ul.digital-downloads li {
    list-style: none;
    margin-left: 0;
    padding-left: 0
}

.woocommerce-account ul.digital-downloads li::before {
    font-family: WooCommerce;
    speak: none;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    margin-right: .618em;
    content: "";
    text-decoration: none
}

.woocommerce-account ul.digital-downloads li .count {
    float: right
}

#add_payment_method table.cart .product-thumbnail,
.woocommerce-cart table.cart .product-thumbnail,
.woocommerce-checkout table.cart .product-thumbnail {
    min-width: 32px
}

#add_payment_method table.cart img,
.woocommerce-cart table.cart img,
.woocommerce-checkout table.cart img {
    width: 32px;
    box-shadow: none
}

#add_payment_method table.cart td,
#add_payment_method table.cart th,
.woocommerce-cart table.cart td,
.woocommerce-cart table.cart th,
.woocommerce-checkout table.cart td,
.woocommerce-checkout table.cart th {
    vertical-align: middle
}

#add_payment_method table.cart td.actions .coupon .input-text,
.woocommerce-cart table.cart td.actions .coupon .input-text,
.woocommerce-checkout table.cart td.actions .coupon .input-text {
    float: left;
    box-sizing: border-box;
    border: 1px solid #d3ced2;
    padding: 6px 6px 5px;
    margin: 0 4px 0 0;
    outline: 0
}

#add_payment_method table.cart input,
.woocommerce-cart table.cart input,
.woocommerce-checkout table.cart input {
    margin: 0;
    vertical-align: middle
}

#add_payment_method .wc-proceed-to-checkout,
.woocommerce-cart .wc-proceed-to-checkout,
.woocommerce-checkout .wc-proceed-to-checkout {
    padding: 1em 0
}

#add_payment_method .wc-proceed-to-checkout::after,
#add_payment_method .wc-proceed-to-checkout::before,
.woocommerce-cart .wc-proceed-to-checkout::after,
.woocommerce-cart .wc-proceed-to-checkout::before,
.woocommerce-checkout .wc-proceed-to-checkout::after,
.woocommerce-checkout .wc-proceed-to-checkout::before {
    content: ' ';
    display: table
}

#add_payment_method .wc-proceed-to-checkout::after,
.woocommerce-cart .wc-proceed-to-checkout::after,
.woocommerce-checkout .wc-proceed-to-checkout::after {
    clear: both
}

#add_payment_method .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-checkout .wc-proceed-to-checkout a.checkout-button {
    display: block;
    text-align: center;
    margin-bottom: 1em;
    font-size: 1.25em;
    padding: 1em
}

#add_payment_method .cart-collaterals .shipping_calculator .button,
.woocommerce-cart .cart-collaterals .shipping_calculator .button,
.woocommerce-checkout .cart-collaterals .shipping_calculator .button {
    width: 100%;
    float: none;
    display: block
}

#add_payment_method .cart-collaterals .shipping_calculator .shipping-calculator-button::after,
.woocommerce-cart .cart-collaterals .shipping_calculator .shipping-calculator-button::after,
.woocommerce-checkout .cart-collaterals .shipping_calculator .shipping-calculator-button::after {
    font-family: WooCommerce;
    speak: none;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    margin-left: .618em;
    content: "";
    text-decoration: none
}

#add_payment_method .cart-collaterals .cart_totals p small,
.woocommerce-cart .cart-collaterals .cart_totals p small,
.woocommerce-checkout .cart-collaterals .cart_totals p small {
    color: #777;
    font-size: .83em
}

#add_payment_method .cart-collaterals .cart_totals table,
.woocommerce-cart .cart-collaterals .cart_totals table,
.woocommerce-checkout .cart-collaterals .cart_totals table {
    border-collapse: separate;
    margin: 0 0 6px;
    padding: 0
}

#add_payment_method .cart-collaterals .cart_totals table tr:first-child td,
#add_payment_method .cart-collaterals .cart_totals table tr:first-child th,
.woocommerce-cart .cart-collaterals .cart_totals table tr:first-child td,
.woocommerce-cart .cart-collaterals .cart_totals table tr:first-child th,
.woocommerce-checkout .cart-collaterals .cart_totals table tr:first-child td,
.woocommerce-checkout .cart-collaterals .cart_totals table tr:first-child th {
    border-top: 0
}

#add_payment_method .cart-collaterals .cart_totals table th,
.woocommerce-cart .cart-collaterals .cart_totals table th,
.woocommerce-checkout .cart-collaterals .cart_totals table th {
    width: 40%
}

#add_payment_method .cart-collaterals .cart_totals table td,
#add_payment_method .cart-collaterals .cart_totals table th,
.woocommerce-cart .cart-collaterals .cart_totals table td,
.woocommerce-cart .cart-collaterals .cart_totals table th,
.woocommerce-checkout .cart-collaterals .cart_totals table td,
.woocommerce-checkout .cart-collaterals .cart_totals table th {
    vertical-align: top;
    border-left: 0;
    border-right: 0;
    line-height: 1.5em
}

#add_payment_method .cart-collaterals .cart_totals table small,
.woocommerce-cart .cart-collaterals .cart_totals table small,
.woocommerce-checkout .cart-collaterals .cart_totals table small {
    color: #777
}

#add_payment_method .cart-collaterals .cart_totals table select,
.woocommerce-cart .cart-collaterals .cart_totals table select,
.woocommerce-checkout .cart-collaterals .cart_totals table select {
    width: 100%
}

#add_payment_method .cart-collaterals .cart_totals .discount td,
.woocommerce-cart .cart-collaterals .cart_totals .discount td,
.woocommerce-checkout .cart-collaterals .cart_totals .discount td {
    color: #77a464
}

#add_payment_method .cart-collaterals .cart_totals tr td,
#add_payment_method .cart-collaterals .cart_totals tr th,
.woocommerce-cart .cart-collaterals .cart_totals tr td,
.woocommerce-cart .cart-collaterals .cart_totals tr th,
.woocommerce-checkout .cart-collaterals .cart_totals tr td,
.woocommerce-checkout .cart-collaterals .cart_totals tr th {
    border-top: 1px solid #ebe9eb
}

#add_payment_method .cart-collaterals .cross-sells ul.products li.product,
.woocommerce-cart .cart-collaterals .cross-sells ul.products li.product,
.woocommerce-checkout .cart-collaterals .cross-sells ul.products li.product {
    margin-top: 0
}

#add_payment_method .checkout .col-2 h3#ship-to-different-address,
.woocommerce-cart .checkout .col-2 h3#ship-to-different-address,
.woocommerce-checkout .checkout .col-2 h3#ship-to-different-address {
    float: left;
    clear: none
}

#add_payment_method .checkout .col-2 .notes,
.woocommerce-cart .checkout .col-2 .notes,
.woocommerce-checkout .checkout .col-2 .notes {
    clear: left
}

#add_payment_method .checkout .col-2 .form-row-first,
.woocommerce-cart .checkout .col-2 .form-row-first,
.woocommerce-checkout .checkout .col-2 .form-row-first {
    clear: left
}

#add_payment_method .checkout .create-account small,
.woocommerce-cart .checkout .create-account small,
.woocommerce-checkout .checkout .create-account small {
    font-size: 11px;
    color: #777;
    font-weight: 400
}

#add_payment_method .checkout div.shipping-address,
.woocommerce-cart .checkout div.shipping-address,
.woocommerce-checkout .checkout div.shipping-address {
    padding: 0;
    clear: left;
    width: 100%
}

#add_payment_method .checkout .shipping_address,
.woocommerce-cart .checkout .shipping_address,
.woocommerce-checkout .checkout .shipping_address {
    clear: both
}

#add_payment_method #payment,
.woocommerce-cart #payment,
.woocommerce-checkout #payment {
    background: #ebe9eb;
    border-radius: 5px
}

#add_payment_method #payment ul.payment_methods,
.woocommerce-cart #payment ul.payment_methods,
.woocommerce-checkout #payment ul.payment_methods {
    text-align: left;
    padding: 1em;
    border-bottom: 1px solid #d3ced2;
    margin: 0;
    list-style: none outside
}

#add_payment_method #payment ul.payment_methods::after,
#add_payment_method #payment ul.payment_methods::before,
.woocommerce-cart #payment ul.payment_methods::after,
.woocommerce-cart #payment ul.payment_methods::before,
.woocommerce-checkout #payment ul.payment_methods::after,
.woocommerce-checkout #payment ul.payment_methods::before {
    content: ' ';
    display: table
}

#add_payment_method #payment ul.payment_methods::after,
.woocommerce-cart #payment ul.payment_methods::after,
.woocommerce-checkout #payment ul.payment_methods::after {
    clear: both
}

#add_payment_method #payment ul.payment_methods li,
.woocommerce-cart #payment ul.payment_methods li,
.woocommerce-checkout #payment ul.payment_methods li {
    line-height: 2;
    text-align: left;
    margin: 0;
    font-weight: 400
}

#add_payment_method #payment ul.payment_methods li input,
.woocommerce-cart #payment ul.payment_methods li input,
.woocommerce-checkout #payment ul.payment_methods li input {
    margin: 0 1em 0 0
}

#add_payment_method #payment ul.payment_methods li img,
.woocommerce-cart #payment ul.payment_methods li img,
.woocommerce-checkout #payment ul.payment_methods li img {
    vertical-align: middle;
    margin: -2px 0 0 .5em;
    padding: 0;
    position: relative;
    box-shadow: none
}

#add_payment_method #payment ul.payment_methods li img+img,
.woocommerce-cart #payment ul.payment_methods li img+img,
.woocommerce-checkout #payment ul.payment_methods li img+img {
    margin-left: 2px
}

#add_payment_method #payment ul.payment_methods li:not(.woocommerce-notice)::after,
#add_payment_method #payment ul.payment_methods li:not(.woocommerce-notice)::before,
.woocommerce-cart #payment ul.payment_methods li:not(.woocommerce-notice)::after,
.woocommerce-cart #payment ul.payment_methods li:not(.woocommerce-notice)::before,
.woocommerce-checkout #payment ul.payment_methods li:not(.woocommerce-notice)::after,
.woocommerce-checkout #payment ul.payment_methods li:not(.woocommerce-notice)::before {
    content: ' ';
    display: table
}

#add_payment_method #payment ul.payment_methods li:not(.woocommerce-notice)::after,
.woocommerce-cart #payment ul.payment_methods li:not(.woocommerce-notice)::after,
.woocommerce-checkout #payment ul.payment_methods li:not(.woocommerce-notice)::after {
    clear: both
}

#add_payment_method #payment div.form-row,
.woocommerce-cart #payment div.form-row,
.woocommerce-checkout #payment div.form-row {
    padding: 1em
}

#add_payment_method #payment div.payment_box,
.woocommerce-cart #payment div.payment_box,
.woocommerce-checkout #payment div.payment_box {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    padding: 1em;
    margin: 1em 0;
    font-size: .92em;
    border-radius: 2px;
    line-height: 1.5;
    background-color: #dfdcde;
    color: #515151
}

#add_payment_method #payment div.payment_box input.input-text,
#add_payment_method #payment div.payment_box textarea,
.woocommerce-cart #payment div.payment_box input.input-text,
.woocommerce-cart #payment div.payment_box textarea,
.woocommerce-checkout #payment div.payment_box input.input-text,
.woocommerce-checkout #payment div.payment_box textarea {
    border-color: #c7c1c6;
    border-top-color: #bbb3b9
}

#add_payment_method #payment div.payment_box ::-webkit-input-placeholder,
.woocommerce-cart #payment div.payment_box ::-webkit-input-placeholder,
.woocommerce-checkout #payment div.payment_box ::-webkit-input-placeholder {
    color: #bbb3b9
}

#add_payment_method #payment div.payment_box :-moz-placeholder,
.woocommerce-cart #payment div.payment_box :-moz-placeholder,
.woocommerce-checkout #payment div.payment_box :-moz-placeholder {
    color: #bbb3b9
}

#add_payment_method #payment div.payment_box :-ms-input-placeholder,
.woocommerce-cart #payment div.payment_box :-ms-input-placeholder,
.woocommerce-checkout #payment div.payment_box :-ms-input-placeholder {
    color: #bbb3b9
}

#add_payment_method #payment div.payment_box .woocommerce-SavedPaymentMethods,
.woocommerce-cart #payment div.payment_box .woocommerce-SavedPaymentMethods,
.woocommerce-checkout #payment div.payment_box .woocommerce-SavedPaymentMethods {
    list-style: none outside;
    margin: 0
}

#add_payment_method #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-new,
#add_payment_method #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-token,
.woocommerce-cart #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-new,
.woocommerce-cart #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-token,
.woocommerce-checkout #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-new,
.woocommerce-checkout #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-token {
    margin: 0 0 .5em
}

#add_payment_method #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-new label,
#add_payment_method #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-token label,
.woocommerce-cart #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-new label,
.woocommerce-cart #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-token label,
.woocommerce-checkout #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-new label,
.woocommerce-checkout #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-token label {
    cursor: pointer
}

#add_payment_method #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-tokenInput,
.woocommerce-cart #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-tokenInput,
.woocommerce-checkout #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-tokenInput {
    vertical-align: middle;
    margin: -3px 1em 0 0;
    position: relative
}

#add_payment_method #payment div.payment_box .wc-credit-card-form,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form {
    border: 0;
    padding: 0;
    margin: 1em 0 0
}

#add_payment_method #payment div.payment_box .wc-credit-card-form-card-cvc,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-expiry,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-number,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-cvc,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-expiry,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-number,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number {
    font-size: 1.5em;
    padding: 8px;
    background-repeat: no-repeat;
    background-position: right .618em center;
    background-size: 32px 20px
}

#add_payment_method #payment div.payment_box .wc-credit-card-form-card-cvc.visa,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-expiry.visa,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-number.visa,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-cvc.visa,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-expiry.visa,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-number.visa,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc.visa,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry.visa,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number.visa {
    background-image: url(../../../../../wp-content/plugins/woocommerce/assets/images/icons/credit-cards/visa.svg)
}

#add_payment_method #payment div.payment_box .wc-credit-card-form-card-cvc.mastercard,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-expiry.mastercard,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-number.mastercard,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-cvc.mastercard,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-expiry.mastercard,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-number.mastercard,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc.mastercard,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry.mastercard,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number.mastercard {
    background-image: url(../../../../../wp-content/plugins/woocommerce/assets/images/icons/credit-cards/mastercard.svg)
}

#add_payment_method #payment div.payment_box .wc-credit-card-form-card-cvc.laser,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-expiry.laser,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-number.laser,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-cvc.laser,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-expiry.laser,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-number.laser,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc.laser,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry.laser,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number.laser {
    background-image: url(../../../../../wp-content/plugins/woocommerce/assets/images/icons/credit-cards/laser.svg)
}

#add_payment_method #payment div.payment_box .wc-credit-card-form-card-cvc.dinersclub,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-expiry.dinersclub,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-number.dinersclub,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-cvc.dinersclub,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-expiry.dinersclub,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-number.dinersclub,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc.dinersclub,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry.dinersclub,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number.dinersclub {
    background-image: url(../../../../../wp-content/plugins/woocommerce/assets/images/icons/credit-cards/diners.svg)
}

#add_payment_method #payment div.payment_box .wc-credit-card-form-card-cvc.maestro,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-expiry.maestro,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-number.maestro,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-cvc.maestro,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-expiry.maestro,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-number.maestro,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc.maestro,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry.maestro,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number.maestro {
    background-image: url(../../../../../wp-content/plugins/woocommerce/assets/images/icons/credit-cards/maestro.svg)
}

#add_payment_method #payment div.payment_box .wc-credit-card-form-card-cvc.jcb,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-expiry.jcb,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-number.jcb,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-cvc.jcb,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-expiry.jcb,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-number.jcb,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc.jcb,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry.jcb,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number.jcb {
    background-image: url(../../../../../wp-content/plugins/woocommerce/assets/images/icons/credit-cards/jcb.svg)
}

#add_payment_method #payment div.payment_box .wc-credit-card-form-card-cvc.amex,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-expiry.amex,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-number.amex,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-cvc.amex,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-expiry.amex,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-number.amex,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc.amex,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry.amex,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number.amex {
    background-image: url(../../../../../wp-content/plugins/woocommerce/assets/images/icons/credit-cards/amex.svg)
}

#add_payment_method #payment div.payment_box .wc-credit-card-form-card-cvc.discover,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-expiry.discover,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-number.discover,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-cvc.discover,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-expiry.discover,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-number.discover,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc.discover,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry.discover,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number.discover {
    background-image: url(../../../../../wp-content/plugins/woocommerce/assets/images/icons/credit-cards/discover.svg)
}

#add_payment_method #payment div.payment_box span.help,
.woocommerce-cart #payment div.payment_box span.help,
.woocommerce-checkout #payment div.payment_box span.help {
    font-size: .857em;
    color: #777;
    font-weight: 400
}

#add_payment_method #payment div.payment_box .form-row,
.woocommerce-cart #payment div.payment_box .form-row,
.woocommerce-checkout #payment div.payment_box .form-row {
    margin: 0 0 1em
}

#add_payment_method #payment div.payment_box p:last-child,
.woocommerce-cart #payment div.payment_box p:last-child,
.woocommerce-checkout #payment div.payment_box p:last-child {
    margin-bottom: 0
}

#add_payment_method #payment div.payment_box::before,
.woocommerce-cart #payment div.payment_box::before,
.woocommerce-checkout #payment div.payment_box::before {
    content: '';
    display: block;
    border: 1em solid #dfdcde;
    border-right-color: transparent;
    border-left-color: transparent;
    border-top-color: transparent;
    position: absolute;
    top: -.75em;
    left: 0;
    margin: -1em 0 0 2em
}

#add_payment_method #payment .payment_method_paypal .about_paypal,
.woocommerce-cart #payment .payment_method_paypal .about_paypal,
.woocommerce-checkout #payment .payment_method_paypal .about_paypal {
    float: right;
    line-height: 52px;
    font-size: .83em
}

#add_payment_method #payment .payment_method_paypal img,
.woocommerce-cart #payment .payment_method_paypal img,
.woocommerce-checkout #payment .payment_method_paypal img {
    max-height: 52px;
    vertical-align: middle
}

.woocommerce-terms-and-conditions {
    border: 1px solid rgba(0, 0, 0, .2);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
    background: rgba(0, 0, 0, .05)
}

.woocommerce-invalid #terms {
    outline: 2px solid red;
    outline-offset: 2px
}

.woocommerce-password-strength {
    text-align: center;
    font-weight: 600;
    padding: 3px .5em;
    font-size: 1em
}

.woocommerce-password-strength.strong {
    background-color: #c1e1b9;
    border-color: #83c373
}

.woocommerce-password-strength.short {
    background-color: #f1adad;
    border-color: #e35b5b
}

.woocommerce-password-strength.bad {
    background-color: #fbc5a9;
    border-color: #f78b53
}

.woocommerce-password-strength.good {
    background-color: #ffe399;
    border-color: #ffc733
}

.woocommerce-password-hint {
    margin: .5em 0 0;
    display: block
}

#content.twentyeleven .woocommerce-pagination a {
    font-size: 1em;
    line-height: 1
}

.single-product .twentythirteen #reply-title,
.single-product .twentythirteen #respond #commentform,
.single-product .twentythirteen .entry-summary {
    padding: 0
}

.single-product .twentythirteen p.stars {
    clear: both
}

.twentythirteen .woocommerce-breadcrumb {
    padding-top: 40px
}

.twentyfourteen ul.products li.product {
    margin-top: 0 !important
}

body:not(.search-results) .twentysixteen .entry-summary {
    color: inherit;
    font-size: inherit;
    line-height: inherit
}

.twentysixteen .price ins {
    background: inherit;
    color: inherit
}

@charset "UTF-8";

.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

.animated.infinite {
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite
}

.animated.hinge {
    -webkit-animation-duration: 2s;
    animation-duration: 2s
}

.animated.bounceIn,
.animated.bounceOut,
.animated.flipOutX,
.animated.flipOutY {
    -webkit-animation-duration: .75s;
    animation-duration: .75s
}

@-webkit-keyframes bounce {

    20%,
    53%,
    80%,
    from,
    to {
        -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    40%,
    43% {
        -webkit-animation-timing-function: cubic-bezier(.755, .050, .855, .060);
        animation-timing-function: cubic-bezier(.755, .050, .855, .060);
        -webkit-transform: translate3d(0, -30px, 0);
        transform: translate3d(0, -30px, 0)
    }

    70% {
        -webkit-animation-timing-function: cubic-bezier(.755, .050, .855, .060);
        animation-timing-function: cubic-bezier(.755, .050, .855, .060);
        -webkit-transform: translate3d(0, -15px, 0);
        transform: translate3d(0, -15px, 0)
    }

    90% {
        -webkit-transform: translate3d(0, -4px, 0);
        transform: translate3d(0, -4px, 0)
    }
}

@keyframes bounce {

    20%,
    53%,
    80%,
    from,
    to {
        -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    40%,
    43% {
        -webkit-animation-timing-function: cubic-bezier(.755, .050, .855, .060);
        animation-timing-function: cubic-bezier(.755, .050, .855, .060);
        -webkit-transform: translate3d(0, -30px, 0);
        transform: translate3d(0, -30px, 0)
    }

    70% {
        -webkit-animation-timing-function: cubic-bezier(.755, .050, .855, .060);
        animation-timing-function: cubic-bezier(.755, .050, .855, .060);
        -webkit-transform: translate3d(0, -15px, 0);
        transform: translate3d(0, -15px, 0)
    }

    90% {
        -webkit-transform: translate3d(0, -4px, 0);
        transform: translate3d(0, -4px, 0)
    }
}

.bounce {
    -webkit-animation-name: bounce;
    animation-name: bounce;
    -webkit-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    transform-origin: center bottom
}

@-webkit-keyframes flash {

    50%,
    from,
    to {
        opacity: 1
    }

    25%,
    75% {
        opacity: 0
    }
}

@keyframes flash {

    50%,
    from,
    to {
        opacity: 1
    }

    25%,
    75% {
        opacity: 0
    }
}

.flash {
    -webkit-animation-name: flash;
    animation-name: flash
}

@-webkit-keyframes pulse {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }

    50% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05)
    }

    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
}

@keyframes pulse {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }

    50% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05)
    }

    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
}

.pulse {
    -webkit-animation-name: pulse;
    animation-name: pulse
}

@-webkit-keyframes rubberBand {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }

    30% {
        -webkit-transform: scale3d(1.25, .75, 1);
        transform: scale3d(1.25, .75, 1)
    }

    40% {
        -webkit-transform: scale3d(.75, 1.25, 1);
        transform: scale3d(.75, 1.25, 1)
    }

    50% {
        -webkit-transform: scale3d(1.15, .85, 1);
        transform: scale3d(1.15, .85, 1)
    }

    65% {
        -webkit-transform: scale3d(.95, 1.05, 1);
        transform: scale3d(.95, 1.05, 1)
    }

    75% {
        -webkit-transform: scale3d(1.05, .95, 1);
        transform: scale3d(1.05, .95, 1)
    }

    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
}

@keyframes rubberBand {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }

    30% {
        -webkit-transform: scale3d(1.25, .75, 1);
        transform: scale3d(1.25, .75, 1)
    }

    40% {
        -webkit-transform: scale3d(.75, 1.25, 1);
        transform: scale3d(.75, 1.25, 1)
    }

    50% {
        -webkit-transform: scale3d(1.15, .85, 1);
        transform: scale3d(1.15, .85, 1)
    }

    65% {
        -webkit-transform: scale3d(.95, 1.05, 1);
        transform: scale3d(.95, 1.05, 1)
    }

    75% {
        -webkit-transform: scale3d(1.05, .95, 1);
        transform: scale3d(1.05, .95, 1)
    }

    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
}

.rubberBand {
    -webkit-animation-name: rubberBand;
    animation-name: rubberBand
}

@-webkit-keyframes shake {

    from,
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0)
    }

    20%,
    40%,
    60%,
    80% {
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(10px, 0, 0)
    }
}

@keyframes shake {

    from,
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0)
    }

    20%,
    40%,
    60%,
    80% {
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(10px, 0, 0)
    }
}

.shake {
    -webkit-animation-name: shake;
    animation-name: shake
}

@-webkit-keyframes swing {
    20% {
        -webkit-transform: rotate3d(0, 0, 1, 15deg);
        transform: rotate3d(0, 0, 1, 15deg)
    }

    40% {
        -webkit-transform: rotate3d(0, 0, 1, -10deg);
        transform: rotate3d(0, 0, 1, -10deg)
    }

    60% {
        -webkit-transform: rotate3d(0, 0, 1, 5deg);
        transform: rotate3d(0, 0, 1, 5deg)
    }

    80% {
        -webkit-transform: rotate3d(0, 0, 1, -5deg);
        transform: rotate3d(0, 0, 1, -5deg)
    }

    to {
        -webkit-transform: rotate3d(0, 0, 1, 0deg);
        transform: rotate3d(0, 0, 1, 0deg)
    }
}

@keyframes swing {
    20% {
        -webkit-transform: rotate3d(0, 0, 1, 15deg);
        transform: rotate3d(0, 0, 1, 15deg)
    }

    40% {
        -webkit-transform: rotate3d(0, 0, 1, -10deg);
        transform: rotate3d(0, 0, 1, -10deg)
    }

    60% {
        -webkit-transform: rotate3d(0, 0, 1, 5deg);
        transform: rotate3d(0, 0, 1, 5deg)
    }

    80% {
        -webkit-transform: rotate3d(0, 0, 1, -5deg);
        transform: rotate3d(0, 0, 1, -5deg)
    }

    to {
        -webkit-transform: rotate3d(0, 0, 1, 0deg);
        transform: rotate3d(0, 0, 1, 0deg)
    }
}

.swing {
    -webkit-transform-origin: top center;
    -ms-transform-origin: top center;
    transform-origin: top center;
    -webkit-animation-name: swing;
    animation-name: swing
}

@-webkit-keyframes tada {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }

    10%,
    20% {
        -webkit-transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg)
    }

    30%,
    50%,
    70%,
    90% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg)
    }

    40%,
    60%,
    80% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg)
    }

    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
}

@keyframes tada {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }

    10%,
    20% {
        -webkit-transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg)
    }

    30%,
    50%,
    70%,
    90% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg)
    }

    40%,
    60%,
    80% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg)
    }

    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
}

.tada {
    -webkit-animation-name: tada;
    animation-name: tada
}

@-webkit-keyframes wobble {
    from {
        -webkit-transform: none;
        transform: none
    }

    15% {
        -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
        transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg)
    }

    30% {
        -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
        transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg)
    }

    45% {
        -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
        transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg)
    }

    60% {
        -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
        transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg)
    }

    75% {
        -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
        transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg)
    }

    to {
        -webkit-transform: none;
        transform: none
    }
}

@keyframes wobble {
    from {
        -webkit-transform: none;
        transform: none
    }

    15% {
        -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
        transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg)
    }

    30% {
        -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
        transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg)
    }

    45% {
        -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
        transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg)
    }

    60% {
        -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
        transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg)
    }

    75% {
        -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
        transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg)
    }

    to {
        -webkit-transform: none;
        transform: none
    }
}

.wobble {
    -webkit-animation-name: wobble;
    animation-name: wobble
}

@-webkit-keyframes jello {

    11.1%,
    from,
    to {
        -webkit-transform: none;
        transform: none
    }

    22.2% {
        -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
        transform: skewX(-12.5deg) skewY(-12.5deg)
    }

    33.3% {
        -webkit-transform: skewX(6.25deg) skewY(6.25deg);
        transform: skewX(6.25deg) skewY(6.25deg)
    }

    44.4% {
        -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
        transform: skewX(-3.125deg) skewY(-3.125deg)
    }

    55.5% {
        -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
        transform: skewX(1.5625deg) skewY(1.5625deg)
    }

    66.6% {
        -webkit-transform: skewX(-.78125deg) skewY(-.78125deg);
        transform: skewX(-.78125deg) skewY(-.78125deg)
    }

    77.7% {
        -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
        transform: skewX(0.390625deg) skewY(0.390625deg)
    }

    88.8% {
        -webkit-transform: skewX(-.1953125deg) skewY(-.1953125deg);
        transform: skewX(-.1953125deg) skewY(-.1953125deg)
    }
}

@keyframes jello {

    11.1%,
    from,
    to {
        -webkit-transform: none;
        transform: none
    }

    22.2% {
        -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
        transform: skewX(-12.5deg) skewY(-12.5deg)
    }

    33.3% {
        -webkit-transform: skewX(6.25deg) skewY(6.25deg);
        transform: skewX(6.25deg) skewY(6.25deg)
    }

    44.4% {
        -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
        transform: skewX(-3.125deg) skewY(-3.125deg)
    }

    55.5% {
        -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
        transform: skewX(1.5625deg) skewY(1.5625deg)
    }

    66.6% {
        -webkit-transform: skewX(-.78125deg) skewY(-.78125deg);
        transform: skewX(-.78125deg) skewY(-.78125deg)
    }

    77.7% {
        -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
        transform: skewX(0.390625deg) skewY(0.390625deg)
    }

    88.8% {
        -webkit-transform: skewX(-.1953125deg) skewY(-.1953125deg);
        transform: skewX(-.1953125deg) skewY(-.1953125deg)
    }
}

.jello {
    -webkit-animation-name: jello;
    animation-name: jello;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center
}

@-webkit-keyframes bounceIn {

    20%,
    40%,
    60%,
    80%,
    from,
    to {
        -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }

    0% {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3)
    }

    20% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1)
    }

    40% {
        -webkit-transform: scale3d(.9, .9, .9);
        transform: scale3d(.9, .9, .9)
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(1.03, 1.03, 1.03);
        transform: scale3d(1.03, 1.03, 1.03)
    }

    80% {
        -webkit-transform: scale3d(.97, .97, .97);
        transform: scale3d(.97, .97, .97)
    }

    to {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
}

@keyframes bounceIn {

    20%,
    40%,
    60%,
    80%,
    from,
    to {
        -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }

    0% {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3)
    }

    20% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1)
    }

    40% {
        -webkit-transform: scale3d(.9, .9, .9);
        transform: scale3d(.9, .9, .9)
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(1.03, 1.03, 1.03);
        transform: scale3d(1.03, 1.03, 1.03)
    }

    80% {
        -webkit-transform: scale3d(.97, .97, .97);
        transform: scale3d(.97, .97, .97)
    }

    to {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
}

.bounceIn {
    -webkit-animation-name: bounceIn;
    animation-name: bounceIn
}

@-webkit-keyframes bounceInDown {

    60%,
    75%,
    90%,
    from,
    to {
        -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }

    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -3000px, 0);
        transform: translate3d(0, -3000px, 0)
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, 25px, 0);
        transform: translate3d(0, 25px, 0)
    }

    75% {
        -webkit-transform: translate3d(0, -10px, 0);
        transform: translate3d(0, -10px, 0)
    }

    90% {
        -webkit-transform: translate3d(0, 5px, 0);
        transform: translate3d(0, 5px, 0)
    }

    to {
        -webkit-transform: none;
        transform: none
    }
}

@keyframes bounceInDown {

    60%,
    75%,
    90%,
    from,
    to {
        -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }

    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -3000px, 0);
        transform: translate3d(0, -3000px, 0)
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, 25px, 0);
        transform: translate3d(0, 25px, 0)
    }

    75% {
        -webkit-transform: translate3d(0, -10px, 0);
        transform: translate3d(0, -10px, 0)
    }

    90% {
        -webkit-transform: translate3d(0, 5px, 0);
        transform: translate3d(0, 5px, 0)
    }

    to {
        -webkit-transform: none;
        transform: none
    }
}

.bounceInDown {
    -webkit-animation-name: bounceInDown;
    animation-name: bounceInDown
}

@-webkit-keyframes bounceInLeft {

    60%,
    75%,
    90%,
    from,
    to {
        -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }

    0% {
        opacity: 0;
        -webkit-transform: translate3d(-3000px, 0, 0);
        transform: translate3d(-3000px, 0, 0)
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(25px, 0, 0);
        transform: translate3d(25px, 0, 0)
    }

    75% {
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0)
    }

    90% {
        -webkit-transform: translate3d(5px, 0, 0);
        transform: translate3d(5px, 0, 0)
    }

    to {
        -webkit-transform: none;
        transform: none
    }
}

@keyframes bounceInLeft {

    60%,
    75%,
    90%,
    from,
    to {
        -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }

    0% {
        opacity: 0;
        -webkit-transform: translate3d(-3000px, 0, 0);
        transform: translate3d(-3000px, 0, 0)
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(25px, 0, 0);
        transform: translate3d(25px, 0, 0)
    }

    75% {
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0)
    }

    90% {
        -webkit-transform: translate3d(5px, 0, 0);
        transform: translate3d(5px, 0, 0)
    }

    to {
        -webkit-transform: none;
        transform: none
    }
}

.bounceInLeft {
    -webkit-animation-name: bounceInLeft;
    animation-name: bounceInLeft
}

@-webkit-keyframes bounceInRight {

    60%,
    75%,
    90%,
    from,
    to {
        -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }

    from {
        opacity: 0;
        -webkit-transform: translate3d(3000px, 0, 0);
        transform: translate3d(3000px, 0, 0)
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(-25px, 0, 0);
        transform: translate3d(-25px, 0, 0)
    }

    75% {
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(10px, 0, 0)
    }

    90% {
        -webkit-transform: translate3d(-5px, 0, 0);
        transform: translate3d(-5px, 0, 0)
    }

    to {
        -webkit-transform: none;
        transform: none
    }
}

@keyframes bounceInRight {

    60%,
    75%,
    90%,
    from,
    to {
        -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }

    from {
        opacity: 0;
        -webkit-transform: translate3d(3000px, 0, 0);
        transform: translate3d(3000px, 0, 0)
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(-25px, 0, 0);
        transform: translate3d(-25px, 0, 0)
    }

    75% {
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(10px, 0, 0)
    }

    90% {
        -webkit-transform: translate3d(-5px, 0, 0);
        transform: translate3d(-5px, 0, 0)
    }

    to {
        -webkit-transform: none;
        transform: none
    }
}

.bounceInRight {
    -webkit-animation-name: bounceInRight;
    animation-name: bounceInRight
}

@-webkit-keyframes bounceInUp {

    60%,
    75%,
    90%,
    from,
    to {
        -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }

    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 3000px, 0);
        transform: translate3d(0, 3000px, 0)
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0)
    }

    75% {
        -webkit-transform: translate3d(0, 10px, 0);
        transform: translate3d(0, 10px, 0)
    }

    90% {
        -webkit-transform: translate3d(0, -5px, 0);
        transform: translate3d(0, -5px, 0)
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

@keyframes bounceInUp {

    60%,
    75%,
    90%,
    from,
    to {
        -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }

    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 3000px, 0);
        transform: translate3d(0, 3000px, 0)
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0)
    }

    75% {
        -webkit-transform: translate3d(0, 10px, 0);
        transform: translate3d(0, 10px, 0)
    }

    90% {
        -webkit-transform: translate3d(0, -5px, 0);
        transform: translate3d(0, -5px, 0)
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

.bounceInUp {
    -webkit-animation-name: bounceInUp;
    animation-name: bounceInUp
}

@-webkit-keyframes bounceOut {
    20% {
        -webkit-transform: scale3d(.9, .9, .9);
        transform: scale3d(.9, .9, .9)
    }

    50%,
    55% {
        opacity: 1;
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1)
    }

    to {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3)
    }
}

@keyframes bounceOut {
    20% {
        -webkit-transform: scale3d(.9, .9, .9);
        transform: scale3d(.9, .9, .9)
    }

    50%,
    55% {
        opacity: 1;
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1)
    }

    to {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3)
    }
}

.bounceOut {
    -webkit-animation-name: bounceOut;
    animation-name: bounceOut
}

@-webkit-keyframes bounceOutDown {
    20% {
        -webkit-transform: translate3d(0, 10px, 0);
        transform: translate3d(0, 10px, 0)
    }

    40%,
    45% {
        opacity: 1;
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0)
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0);
        transform: translate3d(0, 2000px, 0)
    }
}

@keyframes bounceOutDown {
    20% {
        -webkit-transform: translate3d(0, 10px, 0);
        transform: translate3d(0, 10px, 0)
    }

    40%,
    45% {
        opacity: 1;
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0)
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0);
        transform: translate3d(0, 2000px, 0)
    }
}

.bounceOutDown {
    -webkit-animation-name: bounceOutDown;
    animation-name: bounceOutDown
}

@-webkit-keyframes bounceOutLeft {
    20% {
        opacity: 1;
        -webkit-transform: translate3d(20px, 0, 0);
        transform: translate3d(20px, 0, 0)
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-2000px, 0, 0);
        transform: translate3d(-2000px, 0, 0)
    }
}

@keyframes bounceOutLeft {
    20% {
        opacity: 1;
        -webkit-transform: translate3d(20px, 0, 0);
        transform: translate3d(20px, 0, 0)
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-2000px, 0, 0);
        transform: translate3d(-2000px, 0, 0)
    }
}

.bounceOutLeft {
    -webkit-animation-name: bounceOutLeft;
    animation-name: bounceOutLeft
}

@-webkit-keyframes bounceOutRight {
    20% {
        opacity: 1;
        -webkit-transform: translate3d(-20px, 0, 0);
        transform: translate3d(-20px, 0, 0)
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0);
        transform: translate3d(2000px, 0, 0)
    }
}

@keyframes bounceOutRight {
    20% {
        opacity: 1;
        -webkit-transform: translate3d(-20px, 0, 0);
        transform: translate3d(-20px, 0, 0)
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0);
        transform: translate3d(2000px, 0, 0)
    }
}

.bounceOutRight {
    -webkit-animation-name: bounceOutRight;
    animation-name: bounceOutRight
}

@-webkit-keyframes bounceOutUp {
    20% {
        -webkit-transform: translate3d(0, -10px, 0);
        transform: translate3d(0, -10px, 0)
    }

    40%,
    45% {
        opacity: 1;
        -webkit-transform: translate3d(0, 20px, 0);
        transform: translate3d(0, 20px, 0)
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -2000px, 0);
        transform: translate3d(0, -2000px, 0)
    }
}

@keyframes bounceOutUp {
    20% {
        -webkit-transform: translate3d(0, -10px, 0);
        transform: translate3d(0, -10px, 0)
    }

    40%,
    45% {
        opacity: 1;
        -webkit-transform: translate3d(0, 20px, 0);
        transform: translate3d(0, 20px, 0)
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -2000px, 0);
        transform: translate3d(0, -2000px, 0)
    }
}

.bounceOutUp {
    -webkit-animation-name: bounceOutUp;
    animation-name: bounceOutUp
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes fadeIn {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn
}

@-webkit-keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown
}

@-webkit-keyframes fadeInDownBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -2000px, 0);
        transform: translate3d(0, -2000px, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes fadeInDownBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -2000px, 0);
        transform: translate3d(0, -2000px, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.fadeInDownBig {
    -webkit-animation-name: fadeInDownBig;
    animation-name: fadeInDownBig
}

@-webkit-keyframes fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft
}

@-webkit-keyframes fadeInLeftBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-2000px, 0, 0);
        transform: translate3d(-2000px, 0, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes fadeInLeftBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-2000px, 0, 0);
        transform: translate3d(-2000px, 0, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.fadeInLeftBig {
    -webkit-animation-name: fadeInLeftBig;
    animation-name: fadeInLeftBig
}

@-webkit-keyframes fadeInRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight
}

@-webkit-keyframes fadeInRightBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0);
        transform: translate3d(2000px, 0, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes fadeInRightBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0);
        transform: translate3d(2000px, 0, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.fadeInRightBig {
    -webkit-animation-name: fadeInRightBig;
    animation-name: fadeInRightBig
}

@-webkit-keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp
}

@-webkit-keyframes fadeInUpBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0);
        transform: translate3d(0, 2000px, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes fadeInUpBig {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0);
        transform: translate3d(0, 2000px, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.fadeInUpBig {
    -webkit-animation-name: fadeInUpBig;
    animation-name: fadeInUpBig
}

@-webkit-keyframes fadeOut {
    from {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

@keyframes fadeOut {
    from {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

.fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut
}

@-webkit-keyframes fadeOutDown {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0)
    }
}

@keyframes fadeOutDown {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0)
    }
}

.fadeOutDown {
    -webkit-animation-name: fadeOutDown;
    animation-name: fadeOutDown
}

@-webkit-keyframes fadeOutDownBig {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0);
        transform: translate3d(0, 2000px, 0)
    }
}

@keyframes fadeOutDownBig {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0);
        transform: translate3d(0, 2000px, 0)
    }
}

.fadeOutDownBig {
    -webkit-animation-name: fadeOutDownBig;
    animation-name: fadeOutDownBig
}

@-webkit-keyframes fadeOutLeft {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0)
    }
}

@keyframes fadeOutLeft {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0)
    }
}

.fadeOutLeft {
    -webkit-animation-name: fadeOutLeft;
    animation-name: fadeOutLeft
}

@-webkit-keyframes fadeOutLeftBig {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-2000px, 0, 0);
        transform: translate3d(-2000px, 0, 0)
    }
}

@keyframes fadeOutLeftBig {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-2000px, 0, 0);
        transform: translate3d(-2000px, 0, 0)
    }
}

.fadeOutLeftBig {
    -webkit-animation-name: fadeOutLeftBig;
    animation-name: fadeOutLeftBig
}

@-webkit-keyframes fadeOutRight {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0)
    }
}

@keyframes fadeOutRight {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0)
    }
}

.fadeOutRight {
    -webkit-animation-name: fadeOutRight;
    animation-name: fadeOutRight
}

@-webkit-keyframes fadeOutRightBig {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0);
        transform: translate3d(2000px, 0, 0)
    }
}

@keyframes fadeOutRightBig {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0);
        transform: translate3d(2000px, 0, 0)
    }
}

.fadeOutRightBig {
    -webkit-animation-name: fadeOutRightBig;
    animation-name: fadeOutRightBig
}

@-webkit-keyframes fadeOutUp {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0)
    }
}

@keyframes fadeOutUp {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0)
    }
}

.fadeOutUp {
    -webkit-animation-name: fadeOutUp;
    animation-name: fadeOutUp
}

@-webkit-keyframes fadeOutUpBig {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -2000px, 0);
        transform: translate3d(0, -2000px, 0)
    }
}

@keyframes fadeOutUpBig {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -2000px, 0);
        transform: translate3d(0, -2000px, 0)
    }
}

.fadeOutUpBig {
    -webkit-animation-name: fadeOutUpBig;
    animation-name: fadeOutUpBig
}

@-webkit-keyframes flip {
    from {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }

    40% {
        -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
        transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }

    50% {
        -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
        transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }

    80% {
        -webkit-transform: perspective(400px) scale3d(.95, .95, .95);
        transform: perspective(400px) scale3d(.95, .95, .95);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }

    to {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }
}

@keyframes flip {
    from {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }

    40% {
        -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
        transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }

    50% {
        -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
        transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }

    80% {
        -webkit-transform: perspective(400px) scale3d(.95, .95, .95);
        transform: perspective(400px) scale3d(.95, .95, .95);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }

    to {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }
}

.animated.flip {
    -webkit-backface-visibility: visible;
    backface-visibility: visible;
    -webkit-animation-name: flip;
    animation-name: flip
}

@-webkit-keyframes flipInX {
    from {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0
    }

    40% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }

    60% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1
    }

    80% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg)
    }

    to {
        -webkit-transform: perspective(400px);
        transform: perspective(400px)
    }
}

@keyframes flipInX {
    from {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0
    }

    40% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }

    60% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1
    }

    80% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg)
    }

    to {
        -webkit-transform: perspective(400px);
        transform: perspective(400px)
    }
}

.flipInX {
    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important;
    -webkit-animation-name: flipInX;
    animation-name: flipInX
}

@-webkit-keyframes flipInY {
    from {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0
    }

    40% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }

    60% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
        transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
        opacity: 1
    }

    80% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -5deg)
    }

    to {
        -webkit-transform: perspective(400px);
        transform: perspective(400px)
    }
}

@keyframes flipInY {
    from {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0
    }

    40% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }

    60% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
        transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
        opacity: 1
    }

    80% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -5deg)
    }

    to {
        -webkit-transform: perspective(400px);
        transform: perspective(400px)
    }
}

.flipInY {
    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important;
    -webkit-animation-name: flipInY;
    animation-name: flipInY
}

@-webkit-keyframes flipOutX {
    from {
        -webkit-transform: perspective(400px);
        transform: perspective(400px)
    }

    30% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        opacity: 1
    }

    to {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        opacity: 0
    }
}

@keyframes flipOutX {
    from {
        -webkit-transform: perspective(400px);
        transform: perspective(400px)
    }

    30% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        opacity: 1
    }

    to {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        opacity: 0
    }
}

.flipOutX {
    -webkit-animation-name: flipOutX;
    animation-name: flipOutX;
    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important
}

@-webkit-keyframes flipOutY {
    from {
        -webkit-transform: perspective(400px);
        transform: perspective(400px)
    }

    30% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
        opacity: 1
    }

    to {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        opacity: 0
    }
}

@keyframes flipOutY {
    from {
        -webkit-transform: perspective(400px);
        transform: perspective(400px)
    }

    30% {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
        transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
        opacity: 1
    }

    to {
        -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
        opacity: 0
    }
}

.flipOutY {
    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important;
    -webkit-animation-name: flipOutY;
    animation-name: flipOutY
}

@-webkit-keyframes lightSpeedIn {
    from {
        -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
        transform: translate3d(100%, 0, 0) skewX(-30deg);
        opacity: 0
    }

    60% {
        -webkit-transform: skewX(20deg);
        transform: skewX(20deg);
        opacity: 1
    }

    80% {
        -webkit-transform: skewX(-5deg);
        transform: skewX(-5deg);
        opacity: 1
    }

    to {
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

@keyframes lightSpeedIn {
    from {
        -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
        transform: translate3d(100%, 0, 0) skewX(-30deg);
        opacity: 0
    }

    60% {
        -webkit-transform: skewX(20deg);
        transform: skewX(20deg);
        opacity: 1
    }

    80% {
        -webkit-transform: skewX(-5deg);
        transform: skewX(-5deg);
        opacity: 1
    }

    to {
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

.lightSpeedIn {
    -webkit-animation-name: lightSpeedIn;
    animation-name: lightSpeedIn;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out
}

@-webkit-keyframes lightSpeedOut {
    from {
        opacity: 1
    }

    to {
        -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
        transform: translate3d(100%, 0, 0) skewX(30deg);
        opacity: 0
    }
}

@keyframes lightSpeedOut {
    from {
        opacity: 1
    }

    to {
        -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
        transform: translate3d(100%, 0, 0) skewX(30deg);
        opacity: 0
    }
}

.lightSpeedOut {
    -webkit-animation-name: lightSpeedOut;
    animation-name: lightSpeedOut;
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in
}

@-webkit-keyframes rotateIn {
    from {
        -webkit-transform-origin: center;
        transform-origin: center;
        -webkit-transform: rotate3d(0, 0, 1, -200deg);
        transform: rotate3d(0, 0, 1, -200deg);
        opacity: 0
    }

    to {
        -webkit-transform-origin: center;
        transform-origin: center;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

@keyframes rotateIn {
    from {
        -webkit-transform-origin: center;
        transform-origin: center;
        -webkit-transform: rotate3d(0, 0, 1, -200deg);
        transform: rotate3d(0, 0, 1, -200deg);
        opacity: 0
    }

    to {
        -webkit-transform-origin: center;
        transform-origin: center;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

.rotateIn {
    -webkit-animation-name: rotateIn;
    animation-name: rotateIn
}

@-webkit-keyframes rotateInDownLeft {
    from {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate3d(0, 0, 1, -45deg);
        transform: rotate3d(0, 0, 1, -45deg);
        opacity: 0
    }

    to {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

@keyframes rotateInDownLeft {
    from {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate3d(0, 0, 1, -45deg);
        transform: rotate3d(0, 0, 1, -45deg);
        opacity: 0
    }

    to {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

.rotateInDownLeft {
    -webkit-animation-name: rotateInDownLeft;
    animation-name: rotateInDownLeft
}

@-webkit-keyframes rotateInDownRight {
    from {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate3d(0, 0, 1, 45deg);
        transform: rotate3d(0, 0, 1, 45deg);
        opacity: 0
    }

    to {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

@keyframes rotateInDownRight {
    from {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate3d(0, 0, 1, 45deg);
        transform: rotate3d(0, 0, 1, 45deg);
        opacity: 0
    }

    to {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

.rotateInDownRight {
    -webkit-animation-name: rotateInDownRight;
    animation-name: rotateInDownRight
}

@-webkit-keyframes rotateInUpLeft {
    from {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate3d(0, 0, 1, 45deg);
        transform: rotate3d(0, 0, 1, 45deg);
        opacity: 0
    }

    to {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

@keyframes rotateInUpLeft {
    from {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate3d(0, 0, 1, 45deg);
        transform: rotate3d(0, 0, 1, 45deg);
        opacity: 0
    }

    to {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

.rotateInUpLeft {
    -webkit-animation-name: rotateInUpLeft;
    animation-name: rotateInUpLeft
}

@-webkit-keyframes rotateInUpRight {
    from {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate3d(0, 0, 1, -90deg);
        transform: rotate3d(0, 0, 1, -90deg);
        opacity: 0
    }

    to {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

@keyframes rotateInUpRight {
    from {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate3d(0, 0, 1, -90deg);
        transform: rotate3d(0, 0, 1, -90deg);
        opacity: 0
    }

    to {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: none;
        transform: none;
        opacity: 1
    }
}

.rotateInUpRight {
    -webkit-animation-name: rotateInUpRight;
    animation-name: rotateInUpRight
}

@-webkit-keyframes rotateOut {
    from {
        -webkit-transform-origin: center;
        transform-origin: center;
        opacity: 1
    }

    to {
        -webkit-transform-origin: center;
        transform-origin: center;
        -webkit-transform: rotate3d(0, 0, 1, 200deg);
        transform: rotate3d(0, 0, 1, 200deg);
        opacity: 0
    }
}

@keyframes rotateOut {
    from {
        -webkit-transform-origin: center;
        transform-origin: center;
        opacity: 1
    }

    to {
        -webkit-transform-origin: center;
        transform-origin: center;
        -webkit-transform: rotate3d(0, 0, 1, 200deg);
        transform: rotate3d(0, 0, 1, 200deg);
        opacity: 0
    }
}

.rotateOut {
    -webkit-animation-name: rotateOut;
    animation-name: rotateOut
}

@-webkit-keyframes rotateOutDownLeft {
    from {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        opacity: 1
    }

    to {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate3d(0, 0, 1, 45deg);
        transform: rotate3d(0, 0, 1, 45deg);
        opacity: 0
    }
}

@keyframes rotateOutDownLeft {
    from {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        opacity: 1
    }

    to {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate3d(0, 0, 1, 45deg);
        transform: rotate3d(0, 0, 1, 45deg);
        opacity: 0
    }
}

.rotateOutDownLeft {
    -webkit-animation-name: rotateOutDownLeft;
    animation-name: rotateOutDownLeft
}

@-webkit-keyframes rotateOutDownRight {
    from {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        opacity: 1
    }

    to {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate3d(0, 0, 1, -45deg);
        transform: rotate3d(0, 0, 1, -45deg);
        opacity: 0
    }
}

@keyframes rotateOutDownRight {
    from {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        opacity: 1
    }

    to {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate3d(0, 0, 1, -45deg);
        transform: rotate3d(0, 0, 1, -45deg);
        opacity: 0
    }
}

.rotateOutDownRight {
    -webkit-animation-name: rotateOutDownRight;
    animation-name: rotateOutDownRight
}

@-webkit-keyframes rotateOutUpLeft {
    from {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        opacity: 1
    }

    to {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate3d(0, 0, 1, -45deg);
        transform: rotate3d(0, 0, 1, -45deg);
        opacity: 0
    }
}

@keyframes rotateOutUpLeft {
    from {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        opacity: 1
    }

    to {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate3d(0, 0, 1, -45deg);
        transform: rotate3d(0, 0, 1, -45deg);
        opacity: 0
    }
}

.rotateOutUpLeft {
    -webkit-animation-name: rotateOutUpLeft;
    animation-name: rotateOutUpLeft
}

@-webkit-keyframes rotateOutUpRight {
    from {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        opacity: 1
    }

    to {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate3d(0, 0, 1, 90deg);
        transform: rotate3d(0, 0, 1, 90deg);
        opacity: 0
    }
}

@keyframes rotateOutUpRight {
    from {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        opacity: 1
    }

    to {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate3d(0, 0, 1, 90deg);
        transform: rotate3d(0, 0, 1, 90deg);
        opacity: 0
    }
}

.rotateOutUpRight {
    -webkit-animation-name: rotateOutUpRight;
    animation-name: rotateOutUpRight
}

@-webkit-keyframes hinge {
    0% {
        -webkit-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out
    }

    20%,
    60% {
        -webkit-transform: rotate3d(0, 0, 1, 80deg);
        transform: rotate3d(0, 0, 1, 80deg);
        -webkit-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out
    }

    40%,
    80% {
        -webkit-transform: rotate3d(0, 0, 1, 60deg);
        transform: rotate3d(0, 0, 1, 60deg);
        -webkit-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
        opacity: 1
    }

    to {
        -webkit-transform: translate3d(0, 700px, 0);
        transform: translate3d(0, 700px, 0);
        opacity: 0
    }
}

@keyframes hinge {
    0% {
        -webkit-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out
    }

    20%,
    60% {
        -webkit-transform: rotate3d(0, 0, 1, 80deg);
        transform: rotate3d(0, 0, 1, 80deg);
        -webkit-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out
    }

    40%,
    80% {
        -webkit-transform: rotate3d(0, 0, 1, 60deg);
        transform: rotate3d(0, 0, 1, 60deg);
        -webkit-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
        opacity: 1
    }

    to {
        -webkit-transform: translate3d(0, 700px, 0);
        transform: translate3d(0, 700px, 0);
        opacity: 0
    }
}

.hinge {
    -webkit-animation-name: hinge;
    animation-name: hinge
}

@-webkit-keyframes rollIn {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
        transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes rollIn {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
        transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.rollIn {
    -webkit-animation-name: rollIn;
    animation-name: rollIn
}

@-webkit-keyframes rollOut {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
        transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg)
    }
}

@keyframes rollOut {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
        transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg)
    }
}

.rollOut {
    -webkit-animation-name: rollOut;
    animation-name: rollOut
}

@-webkit-keyframes zoomIn {
    from {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3)
    }

    50% {
        opacity: 1
    }
}

@keyframes zoomIn {
    from {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3)
    }

    50% {
        opacity: 1
    }
}

.zoomIn {
    -webkit-animation-name: zoomIn;
    animation-name: zoomIn
}

@-webkit-keyframes zoomInDown {
    from {
        opacity: 0;
        -webkit-transform: scale3d(.1, .1, .1) translate3d(0, -1000px, 0);
        transform: scale3d(.1, .1, .1) translate3d(0, -1000px, 0);
        -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
        animation-timing-function: cubic-bezier(.55, .055, .675, .19)
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
        transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
        -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
        animation-timing-function: cubic-bezier(.175, .885, .32, 1)
    }
}

@keyframes zoomInDown {
    from {
        opacity: 0;
        -webkit-transform: scale3d(.1, .1, .1) translate3d(0, -1000px, 0);
        transform: scale3d(.1, .1, .1) translate3d(0, -1000px, 0);
        -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
        animation-timing-function: cubic-bezier(.55, .055, .675, .19)
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
        transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
        -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
        animation-timing-function: cubic-bezier(.175, .885, .32, 1)
    }
}

.zoomInDown {
    -webkit-animation-name: zoomInDown;
    animation-name: zoomInDown
}

@-webkit-keyframes zoomInLeft {
    from {
        opacity: 0;
        -webkit-transform: scale3d(.1, .1, .1) translate3d(-1000px, 0, 0);
        transform: scale3d(.1, .1, .1) translate3d(-1000px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
        animation-timing-function: cubic-bezier(.55, .055, .675, .19)
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(10px, 0, 0);
        transform: scale3d(.475, .475, .475) translate3d(10px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
        animation-timing-function: cubic-bezier(.175, .885, .32, 1)
    }
}

@keyframes zoomInLeft {
    from {
        opacity: 0;
        -webkit-transform: scale3d(.1, .1, .1) translate3d(-1000px, 0, 0);
        transform: scale3d(.1, .1, .1) translate3d(-1000px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
        animation-timing-function: cubic-bezier(.55, .055, .675, .19)
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(10px, 0, 0);
        transform: scale3d(.475, .475, .475) translate3d(10px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
        animation-timing-function: cubic-bezier(.175, .885, .32, 1)
    }
}

.zoomInLeft {
    -webkit-animation-name: zoomInLeft;
    animation-name: zoomInLeft
}

@-webkit-keyframes zoomInRight {
    from {
        opacity: 0;
        -webkit-transform: scale3d(.1, .1, .1) translate3d(1000px, 0, 0);
        transform: scale3d(.1, .1, .1) translate3d(1000px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
        animation-timing-function: cubic-bezier(.55, .055, .675, .19)
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(-10px, 0, 0);
        transform: scale3d(.475, .475, .475) translate3d(-10px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
        animation-timing-function: cubic-bezier(.175, .885, .32, 1)
    }
}

@keyframes zoomInRight {
    from {
        opacity: 0;
        -webkit-transform: scale3d(.1, .1, .1) translate3d(1000px, 0, 0);
        transform: scale3d(.1, .1, .1) translate3d(1000px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
        animation-timing-function: cubic-bezier(.55, .055, .675, .19)
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(-10px, 0, 0);
        transform: scale3d(.475, .475, .475) translate3d(-10px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
        animation-timing-function: cubic-bezier(.175, .885, .32, 1)
    }
}

.zoomInRight {
    -webkit-animation-name: zoomInRight;
    animation-name: zoomInRight
}

@-webkit-keyframes zoomInUp {
    from {
        opacity: 0;
        -webkit-transform: scale3d(.1, .1, .1) translate3d(0, 1000px, 0);
        transform: scale3d(.1, .1, .1) translate3d(0, 1000px, 0);
        -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
        animation-timing-function: cubic-bezier(.55, .055, .675, .19)
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
        transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
        -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
        animation-timing-function: cubic-bezier(.175, .885, .32, 1)
    }
}

@keyframes zoomInUp {
    from {
        opacity: 0;
        -webkit-transform: scale3d(.1, .1, .1) translate3d(0, 1000px, 0);
        transform: scale3d(.1, .1, .1) translate3d(0, 1000px, 0);
        -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
        animation-timing-function: cubic-bezier(.55, .055, .675, .19)
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
        transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
        -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
        animation-timing-function: cubic-bezier(.175, .885, .32, 1)
    }
}

.zoomInUp {
    -webkit-animation-name: zoomInUp;
    animation-name: zoomInUp
}

@-webkit-keyframes zoomOut {
    from {
        opacity: 1
    }

    50% {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3)
    }

    to {
        opacity: 0
    }
}

@keyframes zoomOut {
    from {
        opacity: 1
    }

    50% {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3)
    }

    to {
        opacity: 0
    }
}

.zoomOut {
    -webkit-animation-name: zoomOut;
    animation-name: zoomOut
}

@-webkit-keyframes zoomOutDown {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
        transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
        -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
        animation-timing-function: cubic-bezier(.55, .055, .675, .19)
    }

    to {
        opacity: 0;
        -webkit-transform: scale3d(.1, .1, .1) translate3d(0, 2000px, 0);
        transform: scale3d(.1, .1, .1) translate3d(0, 2000px, 0);
        -webkit-transform-origin: center bottom;
        transform-origin: center bottom;
        -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
        animation-timing-function: cubic-bezier(.175, .885, .32, 1)
    }
}

@keyframes zoomOutDown {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
        transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
        -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
        animation-timing-function: cubic-bezier(.55, .055, .675, .19)
    }

    to {
        opacity: 0;
        -webkit-transform: scale3d(.1, .1, .1) translate3d(0, 2000px, 0);
        transform: scale3d(.1, .1, .1) translate3d(0, 2000px, 0);
        -webkit-transform-origin: center bottom;
        transform-origin: center bottom;
        -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
        animation-timing-function: cubic-bezier(.175, .885, .32, 1)
    }
}

.zoomOutDown {
    -webkit-animation-name: zoomOutDown;
    animation-name: zoomOutDown
}

@-webkit-keyframes zoomOutLeft {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(42px, 0, 0);
        transform: scale3d(.475, .475, .475) translate3d(42px, 0, 0)
    }

    to {
        opacity: 0;
        -webkit-transform: scale(.1) translate3d(-2000px, 0, 0);
        transform: scale(.1) translate3d(-2000px, 0, 0);
        -webkit-transform-origin: left center;
        transform-origin: left center
    }
}

@keyframes zoomOutLeft {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(42px, 0, 0);
        transform: scale3d(.475, .475, .475) translate3d(42px, 0, 0)
    }

    to {
        opacity: 0;
        -webkit-transform: scale(.1) translate3d(-2000px, 0, 0);
        transform: scale(.1) translate3d(-2000px, 0, 0);
        -webkit-transform-origin: left center;
        transform-origin: left center
    }
}

.zoomOutLeft {
    -webkit-animation-name: zoomOutLeft;
    animation-name: zoomOutLeft
}

@-webkit-keyframes zoomOutRight {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(-42px, 0, 0);
        transform: scale3d(.475, .475, .475) translate3d(-42px, 0, 0)
    }

    to {
        opacity: 0;
        -webkit-transform: scale(.1) translate3d(2000px, 0, 0);
        transform: scale(.1) translate3d(2000px, 0, 0);
        -webkit-transform-origin: right center;
        transform-origin: right center
    }
}

@keyframes zoomOutRight {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(-42px, 0, 0);
        transform: scale3d(.475, .475, .475) translate3d(-42px, 0, 0)
    }

    to {
        opacity: 0;
        -webkit-transform: scale(.1) translate3d(2000px, 0, 0);
        transform: scale(.1) translate3d(2000px, 0, 0);
        -webkit-transform-origin: right center;
        transform-origin: right center
    }
}

.zoomOutRight {
    -webkit-animation-name: zoomOutRight;
    animation-name: zoomOutRight
}

@-webkit-keyframes zoomOutUp {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
        transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
        -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
        animation-timing-function: cubic-bezier(.55, .055, .675, .19)
    }

    to {
        opacity: 0;
        -webkit-transform: scale3d(.1, .1, .1) translate3d(0, -2000px, 0);
        transform: scale3d(.1, .1, .1) translate3d(0, -2000px, 0);
        -webkit-transform-origin: center bottom;
        transform-origin: center bottom;
        -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
        animation-timing-function: cubic-bezier(.175, .885, .32, 1)
    }
}

@keyframes zoomOutUp {
    40% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
        transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
        -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
        animation-timing-function: cubic-bezier(.55, .055, .675, .19)
    }

    to {
        opacity: 0;
        -webkit-transform: scale3d(.1, .1, .1) translate3d(0, -2000px, 0);
        transform: scale3d(.1, .1, .1) translate3d(0, -2000px, 0);
        -webkit-transform-origin: center bottom;
        transform-origin: center bottom;
        -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
        animation-timing-function: cubic-bezier(.175, .885, .32, 1)
    }
}

.zoomOutUp {
    -webkit-animation-name: zoomOutUp;
    animation-name: zoomOutUp
}

@-webkit-keyframes slideInDown {
    from {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
        visibility: visible
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

@keyframes slideInDown {
    from {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
        visibility: visible
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

.slideInDown {
    -webkit-animation-name: slideInDown;
    animation-name: slideInDown
}

@-webkit-keyframes slideInLeft {
    from {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        visibility: visible
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

@keyframes slideInLeft {
    from {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        visibility: visible
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

.slideInLeft {
    -webkit-animation-name: slideInLeft;
    animation-name: slideInLeft
}

@-webkit-keyframes slideInRight {
    from {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
        visibility: visible
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

@keyframes slideInRight {
    from {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
        visibility: visible
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

.slideInRight {
    -webkit-animation-name: slideInRight;
    animation-name: slideInRight
}

@-webkit-keyframes slideInUp {
    from {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
        visibility: visible
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

@keyframes slideInUp {
    from {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
        visibility: visible
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

.slideInUp {
    -webkit-animation-name: slideInUp;
    animation-name: slideInUp
}

@-webkit-keyframes slideOutDown {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0)
    }
}

@keyframes slideOutDown {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0)
    }
}

.slideOutDown {
    -webkit-animation-name: slideOutDown;
    animation-name: slideOutDown
}

@-webkit-keyframes slideOutLeft {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0)
    }
}

@keyframes slideOutLeft {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0)
    }
}

.slideOutLeft {
    -webkit-animation-name: slideOutLeft;
    animation-name: slideOutLeft
}

@-webkit-keyframes slideOutRight {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0)
    }
}

@keyframes slideOutRight {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0)
    }
}

.slideOutRight {
    -webkit-animation-name: slideOutRight;
    animation-name: slideOutRight
}

@-webkit-keyframes slideOutUp {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0)
    }
}

@keyframes slideOutUp {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0)
    }
}

.slideOutUp {
    -webkit-animation-name: slideOutUp;
    animation-name: slideOutUp
}

@font-face {
    font-family: 'FontAwesome';
    src: url(https://cdn.jsdelivr.net/gh/bebestmaple/static_resources@latest/zhuzhukeji/css/fontawesome-webfont.eot?v=4.7.0);
    src: url('https://cdn.jsdelivr.net/gh/bebestmaple/static_resources@latest/zhuzhukeji/css/fontawesome-webfont.eot?') format('embedded-opentype'), url(./fontawesome-webfont.woff2?v=4.7.0) format('woff2'), url(https://cdn.jsdelivr.net/gh/bebestmaple/static_resources@latest/zhuzhukeji/css/fontawesome-webfont.woff?v=4.7.0) format('woff'), url(./fontawesome-webfont.ttf?v=4.7.0) format('truetype'), url('https://cdn.jsdelivr.net/gh/bebestmaple/static_resources@latest/zhuzhukeji/css/fontawesome-webfont.svg?v=4.7.0') format('svg');
    font-weight: 400;
    font-style: normal
}

.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.fa-lg {
    font-size: 1.33333333em;
    line-height: .75em;
    vertical-align: -15%
}

.fa-2x {
    font-size: 2em
}

.fa-3x {
    font-size: 3em
}

.fa-4x {
    font-size: 4em
}

.fa-5x {
    font-size: 5em
}

.fa-fw {
    width: 1.28571429em;
    text-align: center
}

.fa-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none
}

.fa-ul>li {
    position: relative
}

.fa-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: .14285714em;
    text-align: center
}

.fa-li.fa-lg {
    left: -1.85714286em
}

.fa-border {
    padding: .2em .25em .15em;
    border: solid .08em #eee;
    border-radius: .1em
}

.fa-pull-left {
    float: left
}

.fa-pull-right {
    float: right
}

.fa.fa-pull-left {
    margin-right: .3em
}

.fa.fa-pull-right {
    margin-left: .3em
}

.pull-right {
    float: right
}

.pull-left {
    float: left
}

.fa.pull-left {
    margin-right: .3em
}

.fa.pull-right {
    margin-left: .3em
}

.fa-spin {
    -webkit-animation: fa-spin 2s infinite linear;
    animation: fa-spin 2s infinite linear
}

.fa-pulse {
    -webkit-animation: fa-spin 1s infinite steps(8);
    animation: fa-spin 1s infinite steps(8)
}

@-webkit-keyframes fa-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}

@keyframes fa-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}

.fa-rotate-90 {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg)
}

.fa-rotate-180 {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg)
}

.fa-rotate-270 {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg)
}

.fa-flip-horizontal {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
    -webkit-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1)
}

.fa-flip-vertical {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
    -webkit-transform: scale(1, -1);
    -ms-transform: scale(1, -1);
    transform: scale(1, -1)
}

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical {
    -webkit-filter: none;
    filter: none
}

.fa-stack {
    position: relative;
    display: inline-block;
    width: 2em;
    height: 2em;
    line-height: 2em;
    vertical-align: middle
}

.fa-stack-1x,
.fa-stack-2x {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center
}

.fa-stack-1x {
    line-height: inherit
}

.fa-stack-2x {
    font-size: 2em
}

.fa-inverse {
    color: #fff
}

.fa-glass:before {
    content: "\f000"
}

.fa-music:before {
    content: "\f001"
}

.fa-search:before {
    content: "\f002"
}

.fa-envelope-o:before {
    content: "\f003"
}

.fa-heart:before {
    content: "\f004"
}

.fa-star:before {
    content: "\f005"
}

.fa-star-o:before {
    content: "\f006"
}

.fa-user:before {
    content: "\f007"
}

.fa-film:before {
    content: "\f008"
}

.fa-th-large:before {
    content: "\f009"
}

.fa-th:before {
    content: "\f00a"
}

.fa-th-list:before {
    content: "\f00b"
}

.fa-check:before {
    content: "\f00c"
}

.fa-remove:before,
.fa-close:before,
.fa-times:before {
    content: "\f00d"
}

.fa-search-plus:before {
    content: "\f00e"
}

.fa-search-minus:before {
    content: "\f010"
}

.fa-power-off:before {
    content: "\f011"
}

.fa-signal:before {
    content: "\f012"
}

.fa-gear:before,
.fa-cog:before {
    content: "\f013"
}

.fa-trash-o:before {
    content: "\f014"
}

.fa-home:before {
    content: "\f015"
}

.fa-file-o:before {
    content: "\f016"
}

.fa-clock-o:before {
    content: "\f017"
}

.fa-road:before {
    content: "\f018"
}

.fa-download:before {
    content: "\f019"
}

.fa-arrow-circle-o-down:before {
    content: "\f01a"
}

.fa-arrow-circle-o-up:before {
    content: "\f01b"
}

.fa-inbox:before {
    content: "\f01c"
}

.fa-play-circle-o:before {
    content: "\f01d"
}

.fa-rotate-right:before,
.fa-repeat:before {
    content: "\f01e"
}

.fa-refresh:before {
    content: "\f021"
}

.fa-list-alt:before {
    content: "\f022"
}

.fa-lock:before {
    content: "\f023"
}

.fa-flag:before {
    content: "\f024"
}

.fa-headphones:before {
    content: "\f025"
}

.fa-volume-off:before {
    content: "\f026"
}

.fa-volume-down:before {
    content: "\f027"
}

.fa-volume-up:before {
    content: "\f028"
}

.fa-qrcode:before {
    content: "\f029"
}

.fa-barcode:before {
    content: "\f02a"
}

.fa-tag:before {
    content: "\f02b"
}

.fa-tags:before {
    content: "\f02c"
}

.fa-book:before {
    content: "\f02d"
}

.fa-bookmark:before {
    content: "\f02e"
}

.fa-print:before {
    content: "\f02f"
}

.fa-camera:before {
    content: "\f030"
}

.fa-font:before {
    content: "\f031"
}

.fa-bold:before {
    content: "\f032"
}

.fa-italic:before {
    content: "\f033"
}

.fa-text-height:before {
    content: "\f034"
}

.fa-text-width:before {
    content: "\f035"
}

.fa-align-left:before {
    content: "\f036"
}

.fa-align-center:before {
    content: "\f037"
}

.fa-align-right:before {
    content: "\f038"
}

.fa-align-justify:before {
    content: "\f039"
}

.fa-list:before {
    content: "\f03a"
}

.fa-dedent:before,
.fa-outdent:before {
    content: "\f03b"
}

.fa-indent:before {
    content: "\f03c"
}

.fa-video-camera:before {
    content: "\f03d"
}

.fa-photo:before,
.fa-image:before,
.fa-picture-o:before {
    content: "\f03e"
}

.fa-pencil:before {
    content: "\f040"
}

.fa-map-marker:before {
    content: "\f041"
}

.fa-adjust:before {
    content: "\f042"
}

.fa-tint:before {
    content: "\f043"
}

.fa-edit:before,
.fa-pencil-square-o:before {
    content: "\f044"
}

.fa-share-square-o:before {
    content: "\f045"
}

.fa-check-square-o:before {
    content: "\f046"
}

.fa-arrows:before {
    content: "\f047"
}

.fa-step-backward:before {
    content: "\f048"
}

.fa-fast-backward:before {
    content: "\f049"
}

.fa-backward:before {
    content: "\f04a"
}

.fa-play:before {
    content: "\f04b"
}

.fa-pause:before {
    content: "\f04c"
}

.fa-stop:before {
    content: "\f04d"
}

.fa-forward:before {
    content: "\f04e"
}

.fa-fast-forward:before {
    content: "\f050"
}

.fa-step-forward:before {
    content: "\f051"
}

.fa-eject:before {
    content: "\f052"
}

.fa-chevron-left:before {
    content: "\f053"
}

.fa-chevron-right:before {
    content: "\f054"
}

.fa-plus-circle:before {
    content: "\f055"
}

.fa-minus-circle:before {
    content: "\f056"
}

.fa-times-circle:before {
    content: "\f057"
}

.fa-check-circle:before {
    content: "\f058"
}

.fa-question-circle:before {
    content: "\f059"
}

.fa-info-circle:before {
    content: "\f05a"
}

.fa-crosshairs:before {
    content: "\f05b"
}

.fa-times-circle-o:before {
    content: "\f05c"
}

.fa-check-circle-o:before {
    content: "\f05d"
}

.fa-ban:before {
    content: "\f05e"
}

.fa-arrow-left:before {
    content: "\f060"
}

.fa-arrow-right:before {
    content: "\f061"
}

.fa-arrow-up:before {
    content: "\f062"
}

.fa-arrow-down:before {
    content: "\f063"
}

.fa-mail-forward:before,
.fa-share:before {
    content: "\f064"
}

.fa-expand:before {
    content: "\f065"
}

.fa-compress:before {
    content: "\f066"
}

.fa-plus:before {
    content: "\f067"
}

.fa-minus:before {
    content: "\f068"
}

.fa-asterisk:before {
    content: "\f069"
}

.fa-exclamation-circle:before {
    content: "\f06a"
}

.fa-gift:before {
    content: "\f06b"
}

.fa-leaf:before {
    content: "\f06c"
}

.fa-fire:before {
    content: "\f06d"
}

.fa-eye:before {
    content: "\f06e"
}

.fa-eye-slash:before {
    content: "\f070"
}

.fa-warning:before,
.fa-exclamation-triangle:before {
    content: "\f071"
}

.fa-plane:before {
    content: "\f072"
}

.fa-calendar:before {
    content: "\f073"
}

.fa-random:before {
    content: "\f074"
}

.fa-comment:before {
    content: "\f075"
}

.fa-magnet:before {
    content: "\f076"
}

.fa-chevron-up:before {
    content: "\f077"
}

.fa-chevron-down:before {
    content: "\f078"
}

.fa-retweet:before {
    content: "\f079"
}

.fa-shopping-cart:before {
    content: "\f07a"
}

.fa-folder:before {
    content: "\f07b"
}

.fa-folder-open:before {
    content: "\f07c"
}

.fa-arrows-v:before {
    content: "\f07d"
}

.fa-arrows-h:before {
    content: "\f07e"
}

.fa-bar-chart-o:before,
.fa-bar-chart:before {
    content: "\f080"
}

.fa-twitter-square:before {
    content: "\f081"
}

.fa-facebook-square:before {
    content: "\f082"
}

.fa-camera-retro:before {
    content: "\f083"
}

.fa-key:before {
    content: "\f084"
}

.fa-gears:before,
.fa-cogs:before {
    content: "\f085"
}

.fa-comments:before {
    content: "\f086"
}

.fa-thumbs-o-up:before {
    content: "\f087"
}

.fa-thumbs-o-down:before {
    content: "\f088"
}

.fa-star-half:before {
    content: "\f089"
}

.fa-heart-o:before {
    content: "\f08a"
}

.fa-sign-out:before {
    content: "\f08b"
}

.fa-linkedin-square:before {
    content: "\f08c"
}

.fa-thumb-tack:before {
    content: "\f08d"
}

.fa-external-link:before {
    content: "\f08e"
}

.fa-sign-in:before {
    content: "\f090"
}

.fa-trophy:before {
    content: "\f091"
}

.fa-github-square:before {
    content: "\f092"
}

.fa-upload:before {
    content: "\f093"
}

.fa-lemon-o:before {
    content: "\f094"
}

.fa-phone:before {
    content: "\f095"
}

.fa-square-o:before {
    content: "\f096"
}

.fa-bookmark-o:before {
    content: "\f097"
}

.fa-phone-square:before {
    content: "\f098"
}

.fa-twitter:before {
    content: "\f099"
}

.fa-facebook-f:before,
.fa-facebook:before {
    content: "\f09a"
}

.fa-github:before {
    content: "\f09b"
}

.fa-unlock:before {
    content: "\f09c"
}

.fa-credit-card:before {
    content: "\f09d"
}

.fa-feed:before,
.fa-rss:before {
    content: "\f09e"
}

.fa-hdd-o:before {
    content: "\f0a0"
}

.fa-bullhorn:before {
    content: "\f0a1"
}

.fa-bell:before {
    content: "\f0f3"
}

.fa-certificate:before {
    content: "\f0a3"
}

.fa-hand-o-right:before {
    content: "\f0a4"
}

.fa-hand-o-left:before {
    content: "\f0a5"
}

.fa-hand-o-up:before {
    content: "\f0a6"
}

.fa-hand-o-down:before {
    content: "\f0a7"
}

.fa-arrow-circle-left:before {
    content: "\f0a8"
}

.fa-arrow-circle-right:before {
    content: "\f0a9"
}

.fa-arrow-circle-up:before {
    content: "\f0aa"
}

.fa-arrow-circle-down:before {
    content: "\f0ab"
}

.fa-globe:before {
    content: "\f0ac"
}

.fa-wrench:before {
    content: "\f0ad"
}

.fa-tasks:before {
    content: "\f0ae"
}

.fa-filter:before {
    content: "\f0b0"
}

.fa-briefcase:before {
    content: "\f0b1"
}

.fa-arrows-alt:before {
    content: "\f0b2"
}

.fa-group:before,
.fa-users:before {
    content: "\f0c0"
}

.fa-chain:before,
.fa-link:before {
    content: "\f0c1"
}

.fa-cloud:before {
    content: "\f0c2"
}

.fa-flask:before {
    content: "\f0c3"
}

.fa-cut:before,
.fa-scissors:before {
    content: "\f0c4"
}

.fa-copy:before,
.fa-files-o:before {
    content: "\f0c5"
}

.fa-paperclip:before {
    content: "\f0c6"
}

.fa-save:before,
.fa-floppy-o:before {
    content: "\f0c7"
}

.fa-square:before {
    content: "\f0c8"
}

.fa-navicon:before,
.fa-reorder:before,
.fa-bars:before {
    content: "\f0c9"
}

.fa-list-ul:before {
    content: "\f0ca"
}

.fa-list-ol:before {
    content: "\f0cb"
}

.fa-strikethrough:before {
    content: "\f0cc"
}

.fa-underline:before {
    content: "\f0cd"
}

.fa-table:before {
    content: "\f0ce"
}

.fa-magic:before {
    content: "\f0d0"
}

.fa-truck:before {
    content: "\f0d1"
}

.fa-pinterest:before {
    content: "\f0d2"
}

.fa-pinterest-square:before {
    content: "\f0d3"
}

.fa-google-plus-square:before {
    content: "\f0d4"
}

.fa-google-plus:before {
    content: "\f0d5"
}

.fa-money:before {
    content: "\f0d6"
}

.fa-caret-down:before {
    content: "\f0d7"
}

.fa-caret-up:before {
    content: "\f0d8"
}

.fa-caret-left:before {
    content: "\f0d9"
}

.fa-caret-right:before {
    content: "\f0da"
}

.fa-columns:before {
    content: "\f0db"
}

.fa-unsorted:before,
.fa-sort:before {
    content: "\f0dc"
}

.fa-sort-down:before,
.fa-sort-desc:before {
    content: "\f0dd"
}

.fa-sort-up:before,
.fa-sort-asc:before {
    content: "\f0de"
}

.fa-envelope:before {
    content: "\f0e0"
}

.fa-linkedin:before {
    content: "\f0e1"
}

.fa-rotate-left:before,
.fa-undo:before {
    content: "\f0e2"
}

.fa-legal:before,
.fa-gavel:before {
    content: "\f0e3"
}

.fa-dashboard:before,
.fa-tachometer:before {
    content: "\f0e4"
}

.fa-comment-o:before {
    content: "\f0e5"
}

.fa-comments-o:before {
    content: "\f0e6"
}

.fa-flash:before,
.fa-bolt:before {
    content: "\f0e7"
}

.fa-sitemap:before {
    content: "\f0e8"
}

.fa-umbrella:before {
    content: "\f0e9"
}

.fa-paste:before,
.fa-clipboard:before {
    content: "\f0ea"
}

.fa-lightbulb-o:before {
    content: "\f0eb"
}

.fa-exchange:before {
    content: "\f0ec"
}

.fa-cloud-download:before {
    content: "\f0ed"
}

.fa-cloud-upload:before {
    content: "\f0ee"
}

.fa-user-md:before {
    content: "\f0f0"
}

.fa-stethoscope:before {
    content: "\f0f1"
}

.fa-suitcase:before {
    content: "\f0f2"
}

.fa-bell-o:before {
    content: "\f0a2"
}

.fa-coffee:before {
    content: "\f0f4"
}

.fa-cutlery:before {
    content: "\f0f5"
}

.fa-file-text-o:before {
    content: "\f0f6"
}

.fa-building-o:before {
    content: "\f0f7"
}

.fa-hospital-o:before {
    content: "\f0f8"
}

.fa-ambulance:before {
    content: "\f0f9"
}

.fa-medkit:before {
    content: "\f0fa"
}

.fa-fighter-jet:before {
    content: "\f0fb"
}

.fa-beer:before {
    content: "\f0fc"
}

.fa-h-square:before {
    content: "\f0fd"
}

.fa-plus-square:before {
    content: "\f0fe"
}

.fa-angle-double-left:before {
    content: "\f100"
}

.fa-angle-double-right:before {
    content: "\f101"
}

.fa-angle-double-up:before {
    content: "\f102"
}

.fa-angle-double-down:before {
    content: "\f103"
}

.fa-angle-left:before {
    content: "\f104"
}

.fa-angle-right:before {
    content: "\f105"
}

.fa-angle-up:before {
    content: "\f106"
}

.fa-angle-down:before {
    content: "\f107"
}

.fa-desktop:before {
    content: "\f108"
}

.fa-laptop:before {
    content: "\f109"
}

.fa-tablet:before {
    content: "\f10a"
}

.fa-mobile-phone:before,
.fa-mobile:before {
    content: "\f10b"
}

.fa-circle-o:before {
    content: "\f10c"
}

.fa-quote-left:before {
    content: "\f10d"
}

.fa-quote-right:before {
    content: "\f10e"
}

.fa-spinner:before {
    content: "\f110"
}

.fa-circle:before {
    content: "\f111"
}

.fa-mail-reply:before,
.fa-reply:before {
    content: "\f112"
}

.fa-github-alt:before {
    content: "\f113"
}

.fa-folder-o:before {
    content: "\f114"
}

.fa-folder-open-o:before {
    content: "\f115"
}

.fa-smile-o:before {
    content: "\f118"
}

.fa-frown-o:before {
    content: "\f119"
}

.fa-meh-o:before {
    content: "\f11a"
}

.fa-gamepad:before {
    content: "\f11b"
}

.fa-keyboard-o:before {
    content: "\f11c"
}

.fa-flag-o:before {
    content: "\f11d"
}

.fa-flag-checkered:before {
    content: "\f11e"
}

.fa-terminal:before {
    content: "\f120"
}

.fa-code:before {
    content: "\f121"
}

.fa-mail-reply-all:before,
.fa-reply-all:before {
    content: "\f122"
}

.fa-star-half-empty:before,
.fa-star-half-full:before,
.fa-star-half-o:before {
    content: "\f123"
}

.fa-location-arrow:before {
    content: "\f124"
}

.fa-crop:before {
    content: "\f125"
}

.fa-code-fork:before {
    content: "\f126"
}

.fa-unlink:before,
.fa-chain-broken:before {
    content: "\f127"
}

.fa-question:before {
    content: "\f128"
}

.fa-info:before {
    content: "\f129"
}

.fa-exclamation:before {
    content: "\f12a"
}

.fa-superscript:before {
    content: "\f12b"
}

.fa-subscript:before {
    content: "\f12c"
}

.fa-eraser:before {
    content: "\f12d"
}

.fa-puzzle-piece:before {
    content: "\f12e"
}

.fa-microphone:before {
    content: "\f130"
}

.fa-microphone-slash:before {
    content: "\f131"
}

.fa-shield:before {
    content: "\f132"
}

.fa-calendar-o:before {
    content: "\f133"
}

.fa-fire-extinguisher:before {
    content: "\f134"
}

.fa-rocket:before {
    content: "\f135"
}

.fa-maxcdn:before {
    content: "\f136"
}

.fa-chevron-circle-left:before {
    content: "\f137"
}

.fa-chevron-circle-right:before {
    content: "\f138"
}

.fa-chevron-circle-up:before {
    content: "\f139"
}

.fa-chevron-circle-down:before {
    content: "\f13a"
}

.fa-html5:before {
    content: "\f13b"
}

.fa-css3:before {
    content: "\f13c"
}

.fa-anchor:before {
    content: "\f13d"
}

.fa-unlock-alt:before {
    content: "\f13e"
}

.fa-bullseye:before {
    content: "\f140"
}

.fa-ellipsis-h:before {
    content: "\f141"
}

.fa-ellipsis-v:before {
    content: "\f142"
}

.fa-rss-square:before {
    content: "\f143"
}

.fa-play-circle:before {
    content: "\f144"
}

.fa-ticket:before {
    content: "\f145"
}

.fa-minus-square:before {
    content: "\f146"
}

.fa-minus-square-o:before {
    content: "\f147"
}

.fa-level-up:before {
    content: "\f148"
}

.fa-level-down:before {
    content: "\f149"
}

.fa-check-square:before {
    content: "\f14a"
}

.fa-pencil-square:before {
    content: "\f14b"
}

.fa-external-link-square:before {
    content: "\f14c"
}

.fa-share-square:before {
    content: "\f14d"
}

.fa-compass:before {
    content: "\f14e"
}

.fa-toggle-down:before,
.fa-caret-square-o-down:before {
    content: "\f150"
}

.fa-toggle-up:before,
.fa-caret-square-o-up:before {
    content: "\f151"
}

.fa-toggle-right:before,
.fa-caret-square-o-right:before {
    content: "\f152"
}

.fa-euro:before,
.fa-eur:before {
    content: "\f153"
}

.fa-gbp:before {
    content: "\f154"
}

.fa-dollar:before,
.fa-usd:before {
    content: "\f155"
}

.fa-rupee:before,
.fa-inr:before {
    content: "\f156"
}

.fa-cny:before,
.fa-rmb:before,
.fa-yen:before,
.fa-jpy:before {
    content: "\f157"
}

.fa-ruble:before,
.fa-rouble:before,
.fa-rub:before {
    content: "\f158"
}

.fa-won:before,
.fa-krw:before {
    content: "\f159"
}

.fa-bitcoin:before,
.fa-btc:before {
    content: "\f15a"
}

.fa-file:before {
    content: "\f15b"
}

.fa-file-text:before {
    content: "\f15c"
}

.fa-sort-alpha-asc:before {
    content: "\f15d"
}

.fa-sort-alpha-desc:before {
    content: "\f15e"
}

.fa-sort-amount-asc:before {
    content: "\f160"
}

.fa-sort-amount-desc:before {
    content: "\f161"
}

.fa-sort-numeric-asc:before {
    content: "\f162"
}

.fa-sort-numeric-desc:before {
    content: "\f163"
}

.fa-thumbs-up:before {
    content: "\f164"
}

.fa-thumbs-down:before {
    content: "\f165"
}

.fa-youtube-square:before {
    content: "\f166"
}

.fa-youtube:before {
    content: "\f167"
}

.fa-xing:before {
    content: "\f168"
}

.fa-xing-square:before {
    content: "\f169"
}

.fa-youtube-play:before {
    content: "\f16a"
}

.fa-dropbox:before {
    content: "\f16b"
}

.fa-stack-overflow:before {
    content: "\f16c"
}

.fa-instagram:before {
    content: "\f16d"
}

.fa-flickr:before {
    content: "\f16e"
}

.fa-adn:before {
    content: "\f170"
}

.fa-bitbucket:before {
    content: "\f171"
}

.fa-bitbucket-square:before {
    content: "\f172"
}

.fa-tumblr:before {
    content: "\f173"
}

.fa-tumblr-square:before {
    content: "\f174"
}

.fa-long-arrow-down:before {
    content: "\f175"
}

.fa-long-arrow-up:before {
    content: "\f176"
}

.fa-long-arrow-left:before {
    content: "\f177"
}

.fa-long-arrow-right:before {
    content: "\f178"
}

.fa-apple:before {
    content: "\f179"
}

.fa-windows:before {
    content: "\f17a"
}

.fa-android:before {
    content: "\f17b"
}

.fa-linux:before {
    content: "\f17c"
}

.fa-dribbble:before {
    content: "\f17d"
}

.fa-skype:before {
    content: "\f17e"
}

.fa-foursquare:before {
    content: "\f180"
}

.fa-trello:before {
    content: "\f181"
}

.fa-female:before {
    content: "\f182"
}

.fa-male:before {
    content: "\f183"
}

.fa-gittip:before,
.fa-gratipay:before {
    content: "\f184"
}

.fa-sun-o:before {
    content: "\f185"
}

.fa-moon-o:before {
    content: "\f186"
}

.fa-archive:before {
    content: "\f187"
}

.fa-bug:before {
    content: "\f188"
}

.fa-vk:before {
    content: "\f189"
}

.fa-weibo:before {
    content: "\f18a"
}

.fa-renren:before {
    content: "\f18b"
}

.fa-pagelines:before {
    content: "\f18c"
}

.fa-stack-exchange:before {
    content: "\f18d"
}

.fa-arrow-circle-o-right:before {
    content: "\f18e"
}

.fa-arrow-circle-o-left:before {
    content: "\f190"
}

.fa-toggle-left:before,
.fa-caret-square-o-left:before {
    content: "\f191"
}

.fa-dot-circle-o:before {
    content: "\f192"
}

.fa-wheelchair:before {
    content: "\f193"
}

.fa-vimeo-square:before {
    content: "\f194"
}

.fa-turkish-lira:before,
.fa-try:before {
    content: "\f195"
}

.fa-plus-square-o:before {
    content: "\f196"
}

.fa-space-shuttle:before {
    content: "\f197"
}

.fa-slack:before {
    content: "\f198"
}

.fa-envelope-square:before {
    content: "\f199"
}

.fa-wordpress:before {
    content: "\f19a"
}

.fa-openid:before {
    content: "\f19b"
}

.fa-institution:before,
.fa-bank:before,
.fa-university:before {
    content: "\f19c"
}

.fa-mortar-board:before,
.fa-graduation-cap:before {
    content: "\f19d"
}

.fa-yahoo:before {
    content: "\f19e"
}

.fa-google:before {
    content: "\f1a0"
}

.fa-reddit:before {
    content: "\f1a1"
}

.fa-reddit-square:before {
    content: "\f1a2"
}

.fa-stumbleupon-circle:before {
    content: "\f1a3"
}

.fa-stumbleupon:before {
    content: "\f1a4"
}

.fa-delicious:before {
    content: "\f1a5"
}

.fa-digg:before {
    content: "\f1a6"
}

.fa-pied-piper-pp:before {
    content: "\f1a7"
}

.fa-pied-piper-alt:before {
    content: "\f1a8"
}

.fa-drupal:before {
    content: "\f1a9"
}

.fa-joomla:before {
    content: "\f1aa"
}

.fa-language:before {
    content: "\f1ab"
}

.fa-fax:before {
    content: "\f1ac"
}

.fa-building:before {
    content: "\f1ad"
}

.fa-child:before {
    content: "\f1ae"
}

.fa-paw:before {
    content: "\f1b0"
}

.fa-spoon:before {
    content: "\f1b1"
}

.fa-cube:before {
    content: "\f1b2"
}

.fa-cubes:before {
    content: "\f1b3"
}

.fa-behance:before {
    content: "\f1b4"
}

.fa-behance-square:before {
    content: "\f1b5"
}

.fa-steam:before {
    content: "\f1b6"
}

.fa-steam-square:before {
    content: "\f1b7"
}

.fa-recycle:before {
    content: "\f1b8"
}

.fa-automobile:before,
.fa-car:before {
    content: "\f1b9"
}

.fa-cab:before,
.fa-taxi:before {
    content: "\f1ba"
}

.fa-tree:before {
    content: "\f1bb"
}

.fa-spotify:before {
    content: "\f1bc"
}

.fa-deviantart:before {
    content: "\f1bd"
}

.fa-soundcloud:before {
    content: "\f1be"
}

.fa-database:before {
    content: "\f1c0"
}

.fa-file-pdf-o:before {
    content: "\f1c1"
}

.fa-file-word-o:before {
    content: "\f1c2"
}

.fa-file-excel-o:before {
    content: "\f1c3"
}

.fa-file-powerpoint-o:before {
    content: "\f1c4"
}

.fa-file-photo-o:before,
.fa-file-picture-o:before,
.fa-file-image-o:before {
    content: "\f1c5"
}

.fa-file-zip-o:before,
.fa-file-archive-o:before {
    content: "\f1c6"
}

.fa-file-sound-o:before,
.fa-file-audio-o:before {
    content: "\f1c7"
}

.fa-file-movie-o:before,
.fa-file-video-o:before {
    content: "\f1c8"
}

.fa-file-code-o:before {
    content: "\f1c9"
}

.fa-vine:before {
    content: "\f1ca"
}

.fa-codepen:before {
    content: "\f1cb"
}

.fa-jsfiddle:before {
    content: "\f1cc"
}

.fa-life-bouy:before,
.fa-life-buoy:before,
.fa-life-saver:before,
.fa-support:before,
.fa-life-ring:before {
    content: "\f1cd"
}

.fa-circle-o-notch:before {
    content: "\f1ce"
}

.fa-ra:before,
.fa-resistance:before,
.fa-rebel:before {
    content: "\f1d0"
}

.fa-ge:before,
.fa-empire:before {
    content: "\f1d1"
}

.fa-git-square:before {
    content: "\f1d2"
}

.fa-git:before {
    content: "\f1d3"
}

.fa-y-combinator-square:before,
.fa-yc-square:before,
.fa-hacker-news:before {
    content: "\f1d4"
}

.fa-tencent-weibo:before {
    content: "\f1d5"
}

.fa-qq:before {
    content: "\f1d6"
}

.fa-wechat:before,
.fa-weixin:before {
    content: "\f1d7"
}

.fa-send:before,
.fa-paper-plane:before {
    content: "\f1d8"
}

.fa-send-o:before,
.fa-paper-plane-o:before {
    content: "\f1d9"
}

.fa-history:before {
    content: "\f1da"
}

.fa-circle-thin:before {
    content: "\f1db"
}

.fa-header:before {
    content: "\f1dc"
}

.fa-paragraph:before {
    content: "\f1dd"
}

.fa-sliders:before {
    content: "\f1de"
}

.fa-share-alt:before {
    content: "\f1e0"
}

.fa-share-alt-square:before {
    content: "\f1e1"
}

.fa-bomb:before {
    content: "\f1e2"
}

.fa-soccer-ball-o:before,
.fa-futbol-o:before {
    content: "\f1e3"
}

.fa-tty:before {
    content: "\f1e4"
}

.fa-binoculars:before {
    content: "\f1e5"
}

.fa-plug:before {
    content: "\f1e6"
}

.fa-slideshare:before {
    content: "\f1e7"
}

.fa-twitch:before {
    content: "\f1e8"
}

.fa-yelp:before {
    content: "\f1e9"
}

.fa-newspaper-o:before {
    content: "\f1ea"
}

.fa-wifi:before {
    content: "\f1eb"
}

.fa-calculator:before {
    content: "\f1ec"
}

.fa-paypal:before {
    content: "\f1ed"
}

.fa-google-wallet:before {
    content: "\f1ee"
}

.fa-cc-visa:before {
    content: "\f1f0"
}

.fa-cc-mastercard:before {
    content: "\f1f1"
}

.fa-cc-discover:before {
    content: "\f1f2"
}

.fa-cc-amex:before {
    content: "\f1f3"
}

.fa-cc-paypal:before {
    content: "\f1f4"
}

.fa-cc-stripe:before {
    content: "\f1f5"
}

.fa-bell-slash:before {
    content: "\f1f6"
}

.fa-bell-slash-o:before {
    content: "\f1f7"
}

.fa-trash:before {
    content: "\f1f8"
}

.fa-copyright:before {
    content: "\f1f9"
}

.fa-at:before {
    content: "\f1fa"
}

.fa-eyedropper:before {
    content: "\f1fb"
}

.fa-paint-brush:before {
    content: "\f1fc"
}

.fa-birthday-cake:before {
    content: "\f1fd"
}

.fa-area-chart:before {
    content: "\f1fe"
}

.fa-pie-chart:before {
    content: "\f200"
}

.fa-line-chart:before {
    content: "\f201"
}

.fa-lastfm:before {
    content: "\f202"
}

.fa-lastfm-square:before {
    content: "\f203"
}

.fa-toggle-off:before {
    content: "\f204"
}

.fa-toggle-on:before {
    content: "\f205"
}

.fa-bicycle:before {
    content: "\f206"
}

.fa-bus:before {
    content: "\f207"
}

.fa-ioxhost:before {
    content: "\f208"
}

.fa-angellist:before {
    content: "\f209"
}

.fa-cc:before {
    content: "\f20a"
}

.fa-shekel:before,
.fa-sheqel:before,
.fa-ils:before {
    content: "\f20b"
}

.fa-meanpath:before {
    content: "\f20c"
}

.fa-buysellads:before {
    content: "\f20d"
}

.fa-connectdevelop:before {
    content: "\f20e"
}

.fa-dashcube:before {
    content: "\f210"
}

.fa-forumbee:before {
    content: "\f211"
}

.fa-leanpub:before {
    content: "\f212"
}

.fa-sellsy:before {
    content: "\f213"
}

.fa-shirtsinbulk:before {
    content: "\f214"
}

.fa-simplybuilt:before {
    content: "\f215"
}

.fa-skyatlas:before {
    content: "\f216"
}

.fa-cart-plus:before {
    content: "\f217"
}

.fa-cart-arrow-down:before {
    content: "\f218"
}

.fa-diamond:before {
    content: "\f219"
}

.fa-ship:before {
    content: "\f21a"
}

.fa-user-secret:before {
    content: "\f21b"
}

.fa-motorcycle:before {
    content: "\f21c"
}

.fa-street-view:before {
    content: "\f21d"
}

.fa-heartbeat:before {
    content: "\f21e"
}

.fa-venus:before {
    content: "\f221"
}

.fa-mars:before {
    content: "\f222"
}

.fa-mercury:before {
    content: "\f223"
}

.fa-intersex:before,
.fa-transgender:before {
    content: "\f224"
}

.fa-transgender-alt:before {
    content: "\f225"
}

.fa-venus-double:before {
    content: "\f226"
}

.fa-mars-double:before {
    content: "\f227"
}

.fa-venus-mars:before {
    content: "\f228"
}

.fa-mars-stroke:before {
    content: "\f229"
}

.fa-mars-stroke-v:before {
    content: "\f22a"
}

.fa-mars-stroke-h:before {
    content: "\f22b"
}

.fa-neuter:before {
    content: "\f22c"
}

.fa-genderless:before {
    content: "\f22d"
}

.fa-facebook-official:before {
    content: "\f230"
}

.fa-pinterest-p:before {
    content: "\f231"
}

.fa-whatsapp:before {
    content: "\f232"
}

.fa-server:before {
    content: "\f233"
}

.fa-user-plus:before {
    content: "\f234"
}

.fa-user-times:before {
    content: "\f235"
}

.fa-hotel:before,
.fa-bed:before {
    content: "\f236"
}

.fa-viacoin:before {
    content: "\f237"
}

.fa-train:before {
    content: "\f238"
}

.fa-subway:before {
    content: "\f239"
}

.fa-medium:before {
    content: "\f23a"
}

.fa-yc:before,
.fa-y-combinator:before {
    content: "\f23b"
}

.fa-optin-monster:before {
    content: "\f23c"
}

.fa-opencart:before {
    content: "\f23d"
}

.fa-expeditedssl:before {
    content: "\f23e"
}

.fa-battery-4:before,
.fa-battery:before,
.fa-battery-full:before {
    content: "\f240"
}

.fa-battery-3:before,
.fa-battery-three-quarters:before {
    content: "\f241"
}

.fa-battery-2:before,
.fa-battery-half:before {
    content: "\f242"
}

.fa-battery-1:before,
.fa-battery-quarter:before {
    content: "\f243"
}

.fa-battery-0:before,
.fa-battery-empty:before {
    content: "\f244"
}

.fa-mouse-pointer:before {
    content: "\f245"
}

.fa-i-cursor:before {
    content: "\f246"
}

.fa-object-group:before {
    content: "\f247"
}

.fa-object-ungroup:before {
    content: "\f248"
}

.fa-sticky-note:before {
    content: "\f249"
}

.fa-sticky-note-o:before {
    content: "\f24a"
}

.fa-cc-jcb:before {
    content: "\f24b"
}

.fa-cc-diners-club:before {
    content: "\f24c"
}

.fa-clone:before {
    content: "\f24d"
}

.fa-balance-scale:before {
    content: "\f24e"
}

.fa-hourglass-o:before {
    content: "\f250"
}

.fa-hourglass-1:before,
.fa-hourglass-start:before {
    content: "\f251"
}

.fa-hourglass-2:before,
.fa-hourglass-half:before {
    content: "\f252"
}

.fa-hourglass-3:before,
.fa-hourglass-end:before {
    content: "\f253"
}

.fa-hourglass:before {
    content: "\f254"
}

.fa-hand-grab-o:before,
.fa-hand-rock-o:before {
    content: "\f255"
}

.fa-hand-stop-o:before,
.fa-hand-paper-o:before {
    content: "\f256"
}

.fa-hand-scissors-o:before {
    content: "\f257"
}

.fa-hand-lizard-o:before {
    content: "\f258"
}

.fa-hand-spock-o:before {
    content: "\f259"
}

.fa-hand-pointer-o:before {
    content: "\f25a"
}

.fa-hand-peace-o:before {
    content: "\f25b"
}

.fa-trademark:before {
    content: "\f25c"
}

.fa-registered:before {
    content: "\f25d"
}

.fa-creative-commons:before {
    content: "\f25e"
}

.fa-gg:before {
    content: "\f260"
}

.fa-gg-circle:before {
    content: "\f261"
}

.fa-tripadvisor:before {
    content: "\f262"
}

.fa-odnoklassniki:before {
    content: "\f263"
}

.fa-odnoklassniki-square:before {
    content: "\f264"
}

.fa-get-pocket:before {
    content: "\f265"
}

.fa-wikipedia-w:before {
    content: "\f266"
}

.fa-safari:before {
    content: "\f267"
}

.fa-chrome:before {
    content: "\f268"
}

.fa-firefox:before {
    content: "\f269"
}

.fa-opera:before {
    content: "\f26a"
}

.fa-internet-explorer:before {
    content: "\f26b"
}

.fa-tv:before,
.fa-television:before {
    content: "\f26c"
}

.fa-contao:before {
    content: "\f26d"
}

.fa-500px:before {
    content: "\f26e"
}

.fa-amazon:before {
    content: "\f270"
}

.fa-calendar-plus-o:before {
    content: "\f271"
}

.fa-calendar-minus-o:before {
    content: "\f272"
}

.fa-calendar-times-o:before {
    content: "\f273"
}

.fa-calendar-check-o:before {
    content: "\f274"
}

.fa-industry:before {
    content: "\f275"
}

.fa-map-pin:before {
    content: "\f276"
}

.fa-map-signs:before {
    content: "\f277"
}

.fa-map-o:before {
    content: "\f278"
}

.fa-map:before {
    content: "\f279"
}

.fa-commenting:before {
    content: "\f27a"
}

.fa-commenting-o:before {
    content: "\f27b"
}

.fa-houzz:before {
    content: "\f27c"
}

.fa-vimeo:before {
    content: "\f27d"
}

.fa-black-tie:before {
    content: "\f27e"
}

.fa-fonticons:before {
    content: "\f280"
}

.fa-reddit-alien:before {
    content: "\f281"
}

.fa-edge:before {
    content: "\f282"
}

.fa-credit-card-alt:before {
    content: "\f283"
}

.fa-codiepie:before {
    content: "\f284"
}

.fa-modx:before {
    content: "\f285"
}

.fa-fort-awesome:before {
    content: "\f286"
}

.fa-usb:before {
    content: "\f287"
}

.fa-product-hunt:before {
    content: "\f288"
}

.fa-mixcloud:before {
    content: "\f289"
}

.fa-scribd:before {
    content: "\f28a"
}

.fa-pause-circle:before {
    content: "\f28b"
}

.fa-pause-circle-o:before {
    content: "\f28c"
}

.fa-stop-circle:before {
    content: "\f28d"
}

.fa-stop-circle-o:before {
    content: "\f28e"
}

.fa-shopping-bag:before {
    content: "\f290"
}

.fa-shopping-basket:before {
    content: "\f291"
}

.fa-hashtag:before {
    content: "\f292"
}

.fa-bluetooth:before {
    content: "\f293"
}

.fa-bluetooth-b:before {
    content: "\f294"
}

.fa-percent:before {
    content: "\f295"
}

.fa-gitlab:before {
    content: "\f296"
}

.fa-wpbeginner:before {
    content: "\f297"
}

.fa-wpforms:before {
    content: "\f298"
}

.fa-envira:before {
    content: "\f299"
}

.fa-universal-access:before {
    content: "\f29a"
}

.fa-wheelchair-alt:before {
    content: "\f29b"
}

.fa-question-circle-o:before {
    content: "\f29c"
}

.fa-blind:before {
    content: "\f29d"
}

.fa-audio-description:before {
    content: "\f29e"
}

.fa-volume-control-phone:before {
    content: "\f2a0"
}

.fa-braille:before {
    content: "\f2a1"
}

.fa-assistive-listening-systems:before {
    content: "\f2a2"
}

.fa-asl-interpreting:before,
.fa-american-sign-language-interpreting:before {
    content: "\f2a3"
}

.fa-deafness:before,
.fa-hard-of-hearing:before,
.fa-deaf:before {
    content: "\f2a4"
}

.fa-glide:before {
    content: "\f2a5"
}

.fa-glide-g:before {
    content: "\f2a6"
}

.fa-signing:before,
.fa-sign-language:before {
    content: "\f2a7"
}

.fa-low-vision:before {
    content: "\f2a8"
}

.fa-viadeo:before {
    content: "\f2a9"
}

.fa-viadeo-square:before {
    content: "\f2aa"
}

.fa-snapchat:before {
    content: "\f2ab"
}

.fa-snapchat-ghost:before {
    content: "\f2ac"
}

.fa-snapchat-square:before {
    content: "\f2ad"
}

.fa-pied-piper:before {
    content: "\f2ae"
}

.fa-first-order:before {
    content: "\f2b0"
}

.fa-yoast:before {
    content: "\f2b1"
}

.fa-themeisle:before {
    content: "\f2b2"
}

.fa-google-plus-circle:before,
.fa-google-plus-official:before {
    content: "\f2b3"
}

.fa-fa:before,
.fa-font-awesome:before {
    content: "\f2b4"
}

.fa-handshake-o:before {
    content: "\f2b5"
}

.fa-envelope-open:before {
    content: "\f2b6"
}

.fa-envelope-open-o:before {
    content: "\f2b7"
}

.fa-linode:before {
    content: "\f2b8"
}

.fa-address-book:before {
    content: "\f2b9"
}

.fa-address-book-o:before {
    content: "\f2ba"
}

.fa-vcard:before,
.fa-address-card:before {
    content: "\f2bb"
}

.fa-vcard-o:before,
.fa-address-card-o:before {
    content: "\f2bc"
}

.fa-user-circle:before {
    content: "\f2bd"
}

.fa-user-circle-o:before {
    content: "\f2be"
}

.fa-user-o:before {
    content: "\f2c0"
}

.fa-id-badge:before {
    content: "\f2c1"
}

.fa-drivers-license:before,
.fa-id-card:before {
    content: "\f2c2"
}

.fa-drivers-license-o:before,
.fa-id-card-o:before {
    content: "\f2c3"
}

.fa-quora:before {
    content: "\f2c4"
}

.fa-free-code-camp:before {
    content: "\f2c5"
}

.fa-telegram:before {
    content: "\f2c6"
}

.fa-thermometer-4:before,
.fa-thermometer:before,
.fa-thermometer-full:before {
    content: "\f2c7"
}

.fa-thermometer-3:before,
.fa-thermometer-three-quarters:before {
    content: "\f2c8"
}

.fa-thermometer-2:before,
.fa-thermometer-half:before {
    content: "\f2c9"
}

.fa-thermometer-1:before,
.fa-thermometer-quarter:before {
    content: "\f2ca"
}

.fa-thermometer-0:before,
.fa-thermometer-empty:before {
    content: "\f2cb"
}

.fa-shower:before {
    content: "\f2cc"
}

.fa-bathtub:before,
.fa-s15:before,
.fa-bath:before {
    content: "\f2cd"
}

.fa-podcast:before {
    content: "\f2ce"
}

.fa-window-maximize:before {
    content: "\f2d0"
}

.fa-window-minimize:before {
    content: "\f2d1"
}

.fa-window-restore:before {
    content: "\f2d2"
}

.fa-times-rectangle:before,
.fa-window-close:before {
    content: "\f2d3"
}

.fa-times-rectangle-o:before,
.fa-window-close-o:before {
    content: "\f2d4"
}

.fa-bandcamp:before {
    content: "\f2d5"
}

.fa-grav:before {
    content: "\f2d6"
}

.fa-etsy:before {
    content: "\f2d7"
}

.fa-imdb:before {
    content: "\f2d8"
}

.fa-ravelry:before {
    content: "\f2d9"
}

.fa-eercast:before {
    content: "\f2da"
}

.fa-microchip:before {
    content: "\f2db"
}

.fa-snowflake-o:before {
    content: "\f2dc"
}

.fa-superpowers:before {
    content: "\f2dd"
}

.fa-wpexplorer:before {
    content: "\f2de"
}

.fa-meetup:before {
    content: "\f2e0"
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%
}

body {
    margin: 0
}

article,
aside,
footer,
header,
nav,
section {
    display: block
}

h1 {
    font-size: 2em;
    margin: .67em 0
}

figcaption,
figure,
main {
    display: block
}

figure {
    margin: 1em 40px
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible
}

pre {
    font-family: monospace, monospace;
    font-size: 1em
}

a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects
}

a:active,
a:hover {
    outline-width: 0
}

abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted
}

b,
strong {
    font-weight: inherit
}

b,
strong {
    font-weight: bolder
}

code,
kbd,
samp {
    font-family: monospace, monospace;
    font-size: 1em
}

dfn {
    font-style: italic
}

mark {
    background-color: #ff0;
    color: #000
}

small {
    font-size: 80%
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

audio,
video {
    display: inline-block
}

audio:not([controls]) {
    display: none;
    height: 0
}

img {
    border-style: none
}

svg:not(:root) {
    overflow: hidden
}

button,
input,
optgroup,
select,
textarea {
    font-family: sans-serif;
    font-size: 100%;
    line-height: 1.15;
    margin: 0
}

button,
input {
    overflow: visible
}

button,
select {
    text-transform: none
}

[type=reset],
[type=submit],
button,
html [type=button] {
    -webkit-appearance: button
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
    border-style: none;
    padding: 0
}

[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring,
button:-moz-focusring {
    outline: 1px dotted ButtonText
}

fieldset {
    border: 1px solid silver;
    margin: 0 2px;
    padding: .35em .625em .75em
}

legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal
}

progress {
    display: inline-block;
    vertical-align: baseline
}

textarea {
    overflow: auto
}

[type=checkbox],
[type=radio] {
    box-sizing: border-box;
    padding: 0
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    -webkit-appearance: textfield;
    outline-offset: -2px
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit
}

details,
menu {
    display: block
}

summary {
    display: list-item
}

canvas {
    display: inline-block
}

template {
    display: none
}

[hidden] {
    display: none
}

@media print {

    *,
    ::after,
    ::before,
    blockquote::first-letter,
    blockquote::first-line,
    div::first-letter,
    div::first-line,
    li::first-letter,
    li::first-line,
    p::first-letter,
    p::first-line {
        text-shadow: none !important;
        box-shadow: none !important
    }

    a,
    a:visited {
        text-decoration: underline
    }

    abbr[title]::after {
        content: " ("attr(title) ")"
    }

    pre {
        white-space: pre-wrap !important
    }

    blockquote,
    pre {
        border: 1px solid #999;
        page-break-inside: avoid
    }

    thead {
        display: table-header-group
    }

    img,
    tr {
        page-break-inside: avoid
    }

    h2,
    h3,
    p {
        orphans: 3;
        widows: 3
    }

    h2,
    h3 {
        page-break-after: avoid
    }

    .navbar {
        display: none
    }

    .badge {
        border: 1px solid #000
    }

    .table {
        border-collapse: collapse !important
    }

    .table td,
    .table th {
        background-color: #fff !important
    }

    .table-bordered td,
    .table-bordered th {
        border: 1px solid #ddd !important
    }
}

html {
    box-sizing: border-box
}

*,
::after,
::before {
    box-sizing: inherit
}

@-ms-viewport {
    width: device-width
}

html {
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: transparent
}

body {
    font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #292b2c;
    background-color: #fff
}

[tabindex="-1"]:focus {
    outline: 0 !important
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: .5rem
}

p {
    margin-top: 0;
    margin-bottom: 1rem
}

abbr[data-original-title],
abbr[title] {
    cursor: help
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit
}

dl,
ol,
ul {
    margin-top: 0;
    margin-bottom: 1rem
}

ol ol,
ol ul,
ul ol,
ul ul {
    margin-bottom: 0
}

dt {
    font-weight: 700
}

dd {
    margin-bottom: .5rem;
    margin-left: 0
}

blockquote {
    margin: 0 0 1rem
}

a {
    color: #0275d8;
    text-decoration: none
}

a:focus,
a:hover {
    color: #014c8c;
    text-decoration: underline
}

a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none
}

a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none
}

a:not([href]):not([tabindex]):focus {
    outline: 0
}

pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto
}

figure {
    margin: 0 0 1rem
}

img {
    vertical-align: middle
}

[role=button] {
    cursor: pointer
}

[role=button],
a,
area,
button,
input,
label,
select,
summary,
textarea {
    -ms-touch-action: manipulation;
    touch-action: manipulation
}

table {
    border-collapse: collapse;
    background-color: transparent
}

caption {
    padding-top: .75rem;
    padding-bottom: .75rem;
    color: #636c72;
    text-align: left;
    caption-side: bottom
}

th {
    text-align: left
}

label {
    display: inline-block;
    margin-bottom: .5rem
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color
}

button,
input,
select,
textarea {
    line-height: inherit
}

input[type=checkbox]:disabled,
input[type=radio]:disabled {
    cursor: not-allowed
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
    -webkit-appearance: listbox
}

textarea {
    resize: vertical
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0
}

legend {
    display: block;
    width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit
}

input[type=search] {
    -webkit-appearance: none
}

output {
    display: inline-block
}

[hidden] {
    display: none !important
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: .5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit
}

.h1,
h1 {
    font-size: 2.5rem
}

.h2,
h2 {
    font-size: 2rem
}

.h3,
h3 {
    font-size: 1.75rem
}

.h4,
h4 {
    font-size: 1.5rem
}

.h5,
h5 {
    font-size: 1.25rem
}

.h6,
h6 {
    font-size: 1rem
}

.lead {
    font-size: 1.25rem;
    font-weight: 300
}

.display-1 {
    font-size: 6rem;
    font-weight: 300;
    line-height: 1.1
}

.display-2 {
    font-size: 5.5rem;
    font-weight: 300;
    line-height: 1.1
}

.display-3 {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.1
}

.display-4 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.1
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, .1)
}

.small,
small {
    font-size: 80%;
    font-weight: 400
}

.mark,
mark {
    padding: .2em;
    background-color: #fcf8e3
}

.list-unstyled {
    padding-left: 0;
    list-style: none
}

.list-inline {
    padding-left: 0;
    list-style: none
}

.list-inline-item {
    display: inline-block
}

.list-inline-item:not(:last-child) {
    margin-right: 5px
}

.initialism {
    font-size: 90%;
    text-transform: uppercase
}

.blockquote {
    padding: .5rem 1rem;
    margin-bottom: 1rem;
    font-size: 1.25rem;
    border-left: .25rem solid #eceeef
}

.blockquote-footer {
    display: block;
    font-size: 80%;
    color: #636c72
}

.blockquote-footer::before {
    content: "\2014 \00A0"
}

.blockquote-reverse {
    padding-right: 1rem;
    padding-left: 0;
    text-align: right;
    border-right: .25rem solid #eceeef;
    border-left: 0
}

.blockquote-reverse .blockquote-footer::before {
    content: ""
}

.blockquote-reverse .blockquote-footer::after {
    content: "\00A0 \2014"
}

.img-fluid {
    max-width: 100%;
    height: auto
}

.img-thumbnail {
    padding: .25rem;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: .25rem;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    max-width: 100%;
    height: auto
}

.figure {
    display: inline-block
}

.figure-img {
    margin-bottom: .5rem;
    line-height: 1
}

.figure-caption {
    font-size: 90%;
    color: #636c72
}

code,
kbd,
pre,
samp {
    font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
}

code {
    padding: .2rem .4rem;
    font-size: 90%;
    color: #bd4147;
    background-color: #f7f7f9;
    border-radius: .25rem
}

a>code {
    padding: 0;
    color: inherit;
    background-color: inherit
}

kbd {
    padding: .2rem .4rem;
    font-size: 90%;
    color: #fff;
    background-color: #292b2c;
    border-radius: .2rem
}

kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700
}

pre {
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 90%;
    color: #292b2c
}

pre code {
    padding: 0;
    font-size: inherit;
    color: inherit;
    background-color: transparent;
    border-radius: 0
}

.pre-scrollable {
    max-height: 340px;
    overflow-y: scroll
}

.container {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-right: 15px;
    padding-left: 15px
}

@media (min-width:576px) {
    .container {
        padding-right: 15px;
        padding-left: 15px
    }
}

@media (min-width:768px) {
    .container {
        padding-right: 15px;
        padding-left: 15px
    }
}

@media (min-width:992px) {
    .container {
        padding-right: 15px;
        padding-left: 15px
    }
}

@media (min-width:1200px) {
    .container {
        padding-right: 15px;
        padding-left: 15px
    }
}

@media (min-width:576px) {
    .container {
        width: 540px;
        max-width: 100%
    }
}

@media (min-width:768px) {
    .container {
        width: 720px;
        max-width: 100%
    }
}

@media (min-width:992px) {
    .container {
        width: 960px;
        max-width: 100%
    }
}

@media (min-width:1200px) {
    .container {
        width: 1140px;
        max-width: 100%
    }
}

.container-fluid {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-right: 15px;
    padding-left: 15px
}

@media (min-width:576px) {
    .container-fluid {
        padding-right: 15px;
        padding-left: 15px
    }
}

@media (min-width:768px) {
    .container-fluid {
        padding-right: 15px;
        padding-left: 15px
    }
}

@media (min-width:992px) {
    .container-fluid {
        padding-right: 15px;
        padding-left: 15px
    }
}

@media (min-width:1200px) {
    .container-fluid {
        padding-right: 15px;
        padding-left: 15px
    }
}

.row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px
}

@media (min-width:576px) {
    .row {
        margin-right: -15px;
        margin-left: -15px
    }
}

@media (min-width:768px) {
    .row {
        margin-right: -15px;
        margin-left: -15px
    }
}

@media (min-width:992px) {
    .row {
        margin-right: -15px;
        margin-left: -15px
    }
}

@media (min-width:1200px) {
    .row {
        margin-right: -15px;
        margin-left: -15px
    }
}

.no-gutters {
    margin-right: 0;
    margin-left: 0
}

.no-gutters>.col,
.no-gutters>[class*=col-] {
    padding-right: 0;
    padding-left: 0
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9 {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px
}

@media (min-width:576px) {

    .col,
    .col-1,
    .col-10,
    .col-11,
    .col-12,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-lg,
    .col-lg-1,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-md,
    .col-md-1,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-sm,
    .col-sm-1,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-xl,
    .col-xl-1,
    .col-xl-10,
    .col-xl-11,
    .col-xl-12,
    .col-xl-2,
    .col-xl-3,
    .col-xl-4,
    .col-xl-5,
    .col-xl-6,
    .col-xl-7,
    .col-xl-8,
    .col-xl-9 {
        padding-right: 15px;
        padding-left: 15px
    }
}

@media (min-width:768px) {

    .col,
    .col-1,
    .col-10,
    .col-11,
    .col-12,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-lg,
    .col-lg-1,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-md,
    .col-md-1,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-sm,
    .col-sm-1,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-xl,
    .col-xl-1,
    .col-xl-10,
    .col-xl-11,
    .col-xl-12,
    .col-xl-2,
    .col-xl-3,
    .col-xl-4,
    .col-xl-5,
    .col-xl-6,
    .col-xl-7,
    .col-xl-8,
    .col-xl-9 {
        padding-right: 15px;
        padding-left: 15px
    }
}

@media (min-width:992px) {

    .col,
    .col-1,
    .col-10,
    .col-11,
    .col-12,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-lg,
    .col-lg-1,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-md,
    .col-md-1,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-sm,
    .col-sm-1,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-xl,
    .col-xl-1,
    .col-xl-10,
    .col-xl-11,
    .col-xl-12,
    .col-xl-2,
    .col-xl-3,
    .col-xl-4,
    .col-xl-5,
    .col-xl-6,
    .col-xl-7,
    .col-xl-8,
    .col-xl-9 {
        padding-right: 15px;
        padding-left: 15px
    }
}

@media (min-width:1200px) {

    .col,
    .col-1,
    .col-10,
    .col-11,
    .col-12,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-lg,
    .col-lg-1,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-md,
    .col-md-1,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-sm,
    .col-sm-1,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-xl,
    .col-xl-1,
    .col-xl-10,
    .col-xl-11,
    .col-xl-12,
    .col-xl-2,
    .col-xl-3,
    .col-xl-4,
    .col-xl-5,
    .col-xl-6,
    .col-xl-7,
    .col-xl-8,
    .col-xl-9 {
        padding-right: 15px;
        padding-left: 15px
    }
}

.col {
    -ms-flex-preferred-size: 0;
    flex-basis: 0%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%
}

.col-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto
}

.col-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%
}

.col-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%
}

.col-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%
}

.col-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%
}

.col-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%
}

.col-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%
}

.col-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%
}

.col-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%
}

.col-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%
}

.col-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%
}

.col-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%
}

.col-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%
}

.pull-0 {
    right: auto
}

.pull-1 {
    right: 8.333333%
}

.pull-2 {
    right: 16.666667%
}

.pull-3 {
    right: 25%
}

.pull-4 {
    right: 33.333333%
}

.pull-5 {
    right: 41.666667%
}

.pull-6 {
    right: 50%
}

.pull-7 {
    right: 58.333333%
}

.pull-8 {
    right: 66.666667%
}

.pull-9 {
    right: 75%
}

.pull-10 {
    right: 83.333333%
}

.pull-11 {
    right: 91.666667%
}

.pull-12 {
    right: 100%
}

.push-0 {
    left: auto
}

.push-1 {
    left: 8.333333%
}

.push-2 {
    left: 16.666667%
}

.push-3 {
    left: 25%
}

.push-4 {
    left: 33.333333%
}

.push-5 {
    left: 41.666667%
}

.push-6 {
    left: 50%
}

.push-7 {
    left: 58.333333%
}

.push-8 {
    left: 66.666667%
}

.push-9 {
    left: 75%
}

.push-10 {
    left: 83.333333%
}

.push-11 {
    left: 91.666667%
}

.push-12 {
    left: 100%
}

.offset-1 {
    margin-left: 8.333333%
}

.offset-2 {
    margin-left: 16.666667%
}

.offset-3 {
    margin-left: 25%
}

.offset-4 {
    margin-left: 33.333333%
}

.offset-5 {
    margin-left: 41.666667%
}

.offset-6 {
    margin-left: 50%
}

.offset-7 {
    margin-left: 58.333333%
}

.offset-8 {
    margin-left: 66.666667%
}

.offset-9 {
    margin-left: 75%
}

.offset-10 {
    margin-left: 83.333333%
}

.offset-11 {
    margin-left: 91.666667%
}

@media (min-width:576px) {
    .col-sm {
        -ms-flex-preferred-size: 0;
        flex-basis: 0%;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%
    }

    .col-sm-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .col-sm-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%
    }

    .col-sm-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%
    }

    .col-sm-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-sm-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%
    }

    .col-sm-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%
    }

    .col-sm-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-sm-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%
    }

    .col-sm-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%
    }

    .col-sm-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-sm-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%
    }

    .col-sm-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%
    }

    .col-sm-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }

    .pull-sm-0 {
        right: auto
    }

    .pull-sm-1 {
        right: 8.333333%
    }

    .pull-sm-2 {
        right: 16.666667%
    }

    .pull-sm-3 {
        right: 25%
    }

    .pull-sm-4 {
        right: 33.333333%
    }

    .pull-sm-5 {
        right: 41.666667%
    }

    .pull-sm-6 {
        right: 50%
    }

    .pull-sm-7 {
        right: 58.333333%
    }

    .pull-sm-8 {
        right: 66.666667%
    }

    .pull-sm-9 {
        right: 75%
    }

    .pull-sm-10 {
        right: 83.333333%
    }

    .pull-sm-11 {
        right: 91.666667%
    }

    .pull-sm-12 {
        right: 100%
    }

    .push-sm-0 {
        left: auto
    }

    .push-sm-1 {
        left: 8.333333%
    }

    .push-sm-2 {
        left: 16.666667%
    }

    .push-sm-3 {
        left: 25%
    }

    .push-sm-4 {
        left: 33.333333%
    }

    .push-sm-5 {
        left: 41.666667%
    }

    .push-sm-6 {
        left: 50%
    }

    .push-sm-7 {
        left: 58.333333%
    }

    .push-sm-8 {
        left: 66.666667%
    }

    .push-sm-9 {
        left: 75%
    }

    .push-sm-10 {
        left: 83.333333%
    }

    .push-sm-11 {
        left: 91.666667%
    }

    .push-sm-12 {
        left: 100%
    }

    .offset-sm-0 {
        margin-left: 0
    }

    .offset-sm-1 {
        margin-left: 8.333333%
    }

    .offset-sm-2 {
        margin-left: 16.666667%
    }

    .offset-sm-3 {
        margin-left: 25%
    }

    .offset-sm-4 {
        margin-left: 33.333333%
    }

    .offset-sm-5 {
        margin-left: 41.666667%
    }

    .offset-sm-6 {
        margin-left: 50%
    }

    .offset-sm-7 {
        margin-left: 58.333333%
    }

    .offset-sm-8 {
        margin-left: 66.666667%
    }

    .offset-sm-9 {
        margin-left: 75%
    }

    .offset-sm-10 {
        margin-left: 83.333333%
    }

    .offset-sm-11 {
        margin-left: 91.666667%
    }
}

@media (min-width:768px) {
    .col-md {
        -ms-flex-preferred-size: 0;
        flex-basis: 0%;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%
    }

    .col-md-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .col-md-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%
    }

    .col-md-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%
    }

    .col-md-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-md-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%
    }

    .col-md-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%
    }

    .col-md-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-md-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%
    }

    .col-md-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%
    }

    .col-md-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-md-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%
    }

    .col-md-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%
    }

    .col-md-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }

    .pull-md-0 {
        right: auto
    }

    .pull-md-1 {
        right: 8.333333%
    }

    .pull-md-2 {
        right: 16.666667%
    }

    .pull-md-3 {
        right: 25%
    }

    .pull-md-4 {
        right: 33.333333%
    }

    .pull-md-5 {
        right: 41.666667%
    }

    .pull-md-6 {
        right: 50%
    }

    .pull-md-7 {
        right: 58.333333%
    }

    .pull-md-8 {
        right: 66.666667%
    }

    .pull-md-9 {
        right: 75%
    }

    .pull-md-10 {
        right: 83.333333%
    }

    .pull-md-11 {
        right: 91.666667%
    }

    .pull-md-12 {
        right: 100%
    }

    .push-md-0 {
        left: auto
    }

    .push-md-1 {
        left: 8.333333%
    }

    .push-md-2 {
        left: 16.666667%
    }

    .push-md-3 {
        left: 25%
    }

    .push-md-4 {
        left: 33.333333%
    }

    .push-md-5 {
        left: 41.666667%
    }

    .push-md-6 {
        left: 50%
    }

    .push-md-7 {
        left: 58.333333%
    }

    .push-md-8 {
        left: 66.666667%
    }

    .push-md-9 {
        left: 75%
    }

    .push-md-10 {
        left: 83.333333%
    }

    .push-md-11 {
        left: 91.666667%
    }

    .push-md-12 {
        left: 100%
    }

    .offset-md-0 {
        margin-left: 0
    }

    .offset-md-1 {
        margin-left: 8.333333%
    }

    .offset-md-2 {
        margin-left: 16.666667%
    }

    .offset-md-3 {
        margin-left: 25%
    }

    .offset-md-4 {
        margin-left: 33.333333%
    }

    .offset-md-5 {
        margin-left: 41.666667%
    }

    .offset-md-6 {
        margin-left: 50%
    }

    .offset-md-7 {
        margin-left: 58.333333%
    }

    .offset-md-8 {
        margin-left: 66.666667%
    }

    .offset-md-9 {
        margin-left: 75%
    }

    .offset-md-10 {
        margin-left: 83.333333%
    }

    .offset-md-11 {
        margin-left: 91.666667%
    }
}

@media (min-width:992px) {
    .col-lg {
        -ms-flex-preferred-size: 0;
        flex-basis: 0%;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%
    }

    .col-lg-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .col-lg-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%
    }

    .col-lg-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%
    }

    .col-lg-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-lg-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%
    }

    .col-lg-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%
    }

    .col-lg-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-lg-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%
    }

    .col-lg-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%
    }

    .col-lg-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-lg-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%
    }

    .col-lg-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%
    }

    .col-lg-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }

    .pull-lg-0 {
        right: auto
    }

    .pull-lg-1 {
        right: 8.333333%
    }

    .pull-lg-2 {
        right: 16.666667%
    }

    .pull-lg-3 {
        right: 25%
    }

    .pull-lg-4 {
        right: 33.333333%
    }

    .pull-lg-5 {
        right: 41.666667%
    }

    .pull-lg-6 {
        right: 50%
    }

    .pull-lg-7 {
        right: 58.333333%
    }

    .pull-lg-8 {
        right: 66.666667%
    }

    .pull-lg-9 {
        right: 75%
    }

    .pull-lg-10 {
        right: 83.333333%
    }

    .pull-lg-11 {
        right: 91.666667%
    }

    .pull-lg-12 {
        right: 100%
    }

    .push-lg-0 {
        left: auto
    }

    .push-lg-1 {
        left: 8.333333%
    }

    .push-lg-2 {
        left: 16.666667%
    }

    .push-lg-3 {
        left: 25%
    }

    .push-lg-4 {
        left: 33.333333%
    }

    .push-lg-5 {
        left: 41.666667%
    }

    .push-lg-6 {
        left: 50%
    }

    .push-lg-7 {
        left: 58.333333%
    }

    .push-lg-8 {
        left: 66.666667%
    }

    .push-lg-9 {
        left: 75%
    }

    .push-lg-10 {
        left: 83.333333%
    }

    .push-lg-11 {
        left: 91.666667%
    }

    .push-lg-12 {
        left: 100%
    }

    .offset-lg-0 {
        margin-left: 0
    }

    .offset-lg-1 {
        margin-left: 8.333333%
    }

    .offset-lg-2 {
        margin-left: 16.666667%
    }

    .offset-lg-3 {
        margin-left: 25%
    }

    .offset-lg-4 {
        margin-left: 33.333333%
    }

    .offset-lg-5 {
        margin-left: 41.666667%
    }

    .offset-lg-6 {
        margin-left: 50%
    }

    .offset-lg-7 {
        margin-left: 58.333333%
    }

    .offset-lg-8 {
        margin-left: 66.666667%
    }

    .offset-lg-9 {
        margin-left: 75%
    }

    .offset-lg-10 {
        margin-left: 83.333333%
    }

    .offset-lg-11 {
        margin-left: 91.666667%
    }
}

@media (min-width:1200px) {
    .col-xl {
        -ms-flex-preferred-size: 0;
        flex-basis: 0%;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%
    }

    .col-xl-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .col-xl-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%
    }

    .col-xl-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%
    }

    .col-xl-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-xl-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%
    }

    .col-xl-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%
    }

    .col-xl-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-xl-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%
    }

    .col-xl-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%
    }

    .col-xl-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-xl-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%
    }

    .col-xl-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%
    }

    .col-xl-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }

    .pull-xl-0 {
        right: auto
    }

    .pull-xl-1 {
        right: 8.333333%
    }

    .pull-xl-2 {
        right: 16.666667%
    }

    .pull-xl-3 {
        right: 25%
    }

    .pull-xl-4 {
        right: 33.333333%
    }

    .pull-xl-5 {
        right: 41.666667%
    }

    .pull-xl-6 {
        right: 50%
    }

    .pull-xl-7 {
        right: 58.333333%
    }

    .pull-xl-8 {
        right: 66.666667%
    }

    .pull-xl-9 {
        right: 75%
    }

    .pull-xl-10 {
        right: 83.333333%
    }

    .pull-xl-11 {
        right: 91.666667%
    }

    .pull-xl-12 {
        right: 100%
    }

    .push-xl-0 {
        left: auto
    }

    .push-xl-1 {
        left: 8.333333%
    }

    .push-xl-2 {
        left: 16.666667%
    }

    .push-xl-3 {
        left: 25%
    }

    .push-xl-4 {
        left: 33.333333%
    }

    .push-xl-5 {
        left: 41.666667%
    }

    .push-xl-6 {
        left: 50%
    }

    .push-xl-7 {
        left: 58.333333%
    }

    .push-xl-8 {
        left: 66.666667%
    }

    .push-xl-9 {
        left: 75%
    }

    .push-xl-10 {
        left: 83.333333%
    }

    .push-xl-11 {
        left: 91.666667%
    }

    .push-xl-12 {
        left: 100%
    }

    .offset-xl-0 {
        margin-left: 0
    }

    .offset-xl-1 {
        margin-left: 8.333333%
    }

    .offset-xl-2 {
        margin-left: 16.666667%
    }

    .offset-xl-3 {
        margin-left: 25%
    }

    .offset-xl-4 {
        margin-left: 33.333333%
    }

    .offset-xl-5 {
        margin-left: 41.666667%
    }

    .offset-xl-6 {
        margin-left: 50%
    }

    .offset-xl-7 {
        margin-left: 58.333333%
    }

    .offset-xl-8 {
        margin-left: 66.666667%
    }

    .offset-xl-9 {
        margin-left: 75%
    }

    .offset-xl-10 {
        margin-left: 83.333333%
    }

    .offset-xl-11 {
        margin-left: 91.666667%
    }
}

.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem
}

.table td,
.table th {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #eceeef
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #eceeef
}

.table tbody+tbody {
    border-top: 2px solid #eceeef
}

.table .table {
    background-color: #fff
}

.table-sm td,
.table-sm th {
    padding: .3rem
}

.table-bordered {
    border: 1px solid #eceeef
}

.table-bordered td,
.table-bordered th {
    border: 1px solid #eceeef
}

.table-bordered thead td,
.table-bordered thead th {
    border-bottom-width: 2px
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05)
}

.table-hover tbody tr:hover {
    background-color: rgba(0, 0, 0, .075)
}

.table-active,
.table-active>td,
.table-active>th {
    background-color: rgba(0, 0, 0, .075)
}

.table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, .075)
}

.table-hover .table-active:hover>td,
.table-hover .table-active:hover>th {
    background-color: rgba(0, 0, 0, .075)
}

.table-success,
.table-success>td,
.table-success>th {
    background-color: #dff0d8
}

.table-hover .table-success:hover {
    background-color: #d0e9c6
}

.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th {
    background-color: #d0e9c6
}

.table-info,
.table-info>td,
.table-info>th {
    background-color: #d9edf7
}

.table-hover .table-info:hover {
    background-color: #c4e3f3
}

.table-hover .table-info:hover>td,
.table-hover .table-info:hover>th {
    background-color: #c4e3f3
}

.table-warning,
.table-warning>td,
.table-warning>th {
    background-color: #fcf8e3
}

.table-hover .table-warning:hover {
    background-color: #faf2cc
}

.table-hover .table-warning:hover>td,
.table-hover .table-warning:hover>th {
    background-color: #faf2cc
}

.table-danger,
.table-danger>td,
.table-danger>th {
    background-color: #f2dede
}

.table-hover .table-danger:hover {
    background-color: #ebcccc
}

.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th {
    background-color: #ebcccc
}

.thead-inverse th {
    color: #fff;
    background-color: #292b2c
}

.thead-default th {
    color: #464a4c;
    background-color: #eceeef
}

.table-inverse {
    color: #fff;
    background-color: #292b2c
}

.table-inverse td,
.table-inverse th,
.table-inverse thead th {
    border-color: #fff
}

.table-inverse.table-bordered {
    border: 0
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar
}

.table-responsive.table-bordered {
    border: 0
}

.form-control {
    display: block;
    width: 100%;
    padding: .5rem .75rem;
    font-size: 1rem;
    line-height: 1.25;
    color: #464a4c;
    background-color: #fff;
    background-image: none;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: .25rem;
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s
}

.form-control::-ms-expand {
    background-color: transparent;
    border: 0
}

.form-control:focus {
    color: #464a4c;
    background-color: #fff;
    border-color: #5cb3fd;
    outline: 0
}

.form-control::-webkit-input-placeholder {
    color: #636c72;
    opacity: 1
}

.form-control:-ms-input-placeholder {
    color: #636c72;
    opacity: 1
}

.form-control::-ms-input-placeholder {
    color: #636c72;
    opacity: 1
}

.form-control::-moz-placeholder {
    color: #636c72;
    opacity: 1
}

.form-control::placeholder {
    color: #636c72;
    opacity: 1
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #eceeef;
    opacity: 1
}

.form-control:disabled {
    cursor: not-allowed
}

select.form-control:not([size]):not([multiple]) {
    height: calc(2.25rem + 2px)
}

select.form-control:focus::-ms-value {
    color: #464a4c;
    background-color: #fff
}

.form-control-file,
.form-control-range {
    display: block
}

.col-form-label {
    padding-top: calc(.5rem - 1px * 2);
    padding-bottom: calc(.5rem - 1px * 2);
    margin-bottom: 0
}

.col-form-label-lg {
    padding-top: calc(.75rem - 1px * 2);
    padding-bottom: calc(.75rem - 1px * 2);
    font-size: 1.25rem
}

.col-form-label-sm {
    padding-top: calc(.25rem - 1px * 2);
    padding-bottom: calc(.25rem - 1px * 2);
    font-size: .875rem
}

.col-form-legend {
    padding-top: .5rem;
    padding-bottom: .5rem;
    margin-bottom: 0;
    font-size: 1rem
}

.form-control-static {
    padding-top: .5rem;
    padding-bottom: .5rem;
    margin-bottom: 0;
    line-height: 1.25;
    border: solid transparent;
    border-width: 1px 0
}

.form-control-static.form-control-lg,
.form-control-static.form-control-sm,
.input-group-lg>.form-control-static.form-control,
.input-group-lg>.form-control-static.input-group-addon,
.input-group-lg>.input-group-btn>.form-control-static.btn,
.input-group-sm>.form-control-static.form-control,
.input-group-sm>.form-control-static.input-group-addon,
.input-group-sm>.input-group-btn>.form-control-static.btn {
    padding-right: 0;
    padding-left: 0
}

.form-control-sm,
.input-group-sm>.form-control,
.input-group-sm>.input-group-addon,
.input-group-sm>.input-group-btn>.btn {
    padding: .25rem .5rem;
    font-size: .875rem;
    border-radius: .2rem
}

.input-group-sm>.input-group-btn>select.btn:not([size]):not([multiple]),
.input-group-sm>select.form-control:not([size]):not([multiple]),
.input-group-sm>select.input-group-addon:not([size]):not([multiple]),
select.form-control-sm:not([size]):not([multiple]) {
    height: 1.8125rem
}

.form-control-lg,
.input-group-lg>.form-control,
.input-group-lg>.input-group-addon,
.input-group-lg>.input-group-btn>.btn {
    padding: .75rem 1.5rem;
    font-size: 1.25rem;
    border-radius: .3rem
}

.input-group-lg>.input-group-btn>select.btn:not([size]):not([multiple]),
.input-group-lg>select.form-control:not([size]):not([multiple]),
.input-group-lg>select.input-group-addon:not([size]):not([multiple]),
select.form-control-lg:not([size]):not([multiple]) {
    height: 3.166667rem
}

.form-group {
    margin-bottom: 1rem
}

.form-text {
    display: block;
    margin-top: .25rem
}

.form-check {
    position: relative;
    display: block;
    margin-bottom: .5rem
}

.form-check.disabled .form-check-label {
    color: #636c72;
    cursor: not-allowed
}

.form-check-label {
    padding-left: 1.25rem;
    margin-bottom: 0;
    cursor: pointer
}

.form-check-input {
    position: absolute;
    margin-top: .25rem;
    margin-left: -1.25rem
}

.form-check-input:only-child {
    position: static
}

.form-check-inline {
    display: inline-block
}

.form-check-inline .form-check-label {
    vertical-align: middle
}

.form-check-inline+.form-check-inline {
    margin-left: .75rem
}

.form-control-feedback {
    margin-top: .25rem
}

.form-control-danger,
.form-control-success,
.form-control-warning {
    padding-right: 2.25rem;
    background-repeat: no-repeat;
    background-position: center right .5625rem;
    background-size: 1.125rem 1.125rem
}

.has-success .col-form-label,
.has-success .custom-control,
.has-success .form-check-label,
.has-success .form-control-feedback,
.has-success .form-control-label {
    color: #5cb85c
}

.has-success .form-control {
    border-color: #5cb85c
}

.has-success .input-group-addon {
    color: #5cb85c;
    border-color: #5cb85c;
    background-color: #eaf6ea
}

.has-success .form-control-success {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%235cb85c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E")
}

.has-warning .col-form-label,
.has-warning .custom-control,
.has-warning .form-check-label,
.has-warning .form-control-feedback,
.has-warning .form-control-label {
    color: #f0ad4e
}

.has-warning .form-control {
    border-color: #f0ad4e
}

.has-warning .input-group-addon {
    color: #f0ad4e;
    border-color: #f0ad4e;
    background-color: #fff
}

.has-warning .form-control-warning {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23f0ad4e' d='M4.4 5.324h-.8v-2.46h.8zm0 1.42h-.8V5.89h.8zM3.76.63L.04 7.075c-.115.2.016.425.26.426h7.397c.242 0 .372-.226.258-.426C6.726 4.924 5.47 2.79 4.253.63c-.113-.174-.39-.174-.494 0z'/%3E%3C/svg%3E")
}

.has-danger .col-form-label,
.has-danger .custom-control,
.has-danger .form-check-label,
.has-danger .form-control-feedback,
.has-danger .form-control-label {
    color: #d9534f
}

.has-danger .form-control {
    border-color: #d9534f
}

.has-danger .input-group-addon {
    color: #d9534f;
    border-color: #d9534f;
    background-color: #fdf7f7
}

.has-danger .form-control-danger {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23d9534f' viewBox='-2 -2 7 7'%3E%3Cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3E%3Ccircle r='.5'/%3E%3Ccircle cx='3' r='.5'/%3E%3Ccircle cy='3' r='.5'/%3E%3Ccircle cx='3' cy='3' r='.5'/%3E%3C/svg%3E")
}

.form-inline {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.form-inline .form-check {
    width: 100%
}

@media (min-width:576px) {
    .form-inline label {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin-bottom: 0
    }

    .form-inline .form-group {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: 0
    }

    .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle
    }

    .form-inline .form-control-static {
        display: inline-block
    }

    .form-inline .input-group {
        width: auto
    }

    .form-inline .form-control-label {
        margin-bottom: 0;
        vertical-align: middle
    }

    .form-inline .form-check {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: auto;
        margin-top: 0;
        margin-bottom: 0
    }

    .form-inline .form-check-label {
        padding-left: 0
    }

    .form-inline .form-check-input {
        position: relative;
        margin-top: 0;
        margin-right: .25rem;
        margin-left: 0
    }

    .form-inline .custom-control {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding-left: 0
    }

    .form-inline .custom-control-indicator {
        position: static;
        display: inline-block;
        margin-right: .25rem;
        vertical-align: text-bottom
    }

    .form-inline .has-feedback .form-control-feedback {
        top: 0
    }
}

.btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.25;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .5rem 1rem;
    font-size: 1rem;
    border-radius: .25rem;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

.btn:focus,
.btn:hover {
    text-decoration: none
}

.btn.focus,
.btn:focus {
    outline: 0;
    box-shadow: 0 0 0 2px rgba(2, 117, 216, .25)
}

.btn.disabled,
.btn:disabled {
    cursor: not-allowed;
    opacity: .65
}

.btn.active,
.btn:active {
    background-image: none
}

a.btn.disabled,
fieldset[disabled] a.btn {
    pointer-events: none
}

.btn-primary {
    color: #fff;
    background-color: #0275d8;
    border-color: #0275d8
}

.btn-primary:hover {
    color: #fff;
    background-color: #025aa5;
    border-color: #01549b
}

.btn-primary.focus,
.btn-primary:focus {
    box-shadow: 0 0 0 2px rgba(2, 117, 216, .5)
}

.btn-primary.disabled,
.btn-primary:disabled {
    background-color: #0275d8;
    border-color: #0275d8
}

.btn-primary.active,
.btn-primary:active,
.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #025aa5;
    background-image: none;
    border-color: #01549b
}

.btn-secondary {
    color: #292b2c;
    background-color: #fff;
    border-color: #ccc
}

.btn-secondary:hover {
    color: #292b2c;
    background-color: #e6e6e6;
    border-color: #adadad
}

.btn-secondary.focus,
.btn-secondary:focus {
    box-shadow: 0 0 0 2px rgba(204, 204, 204, .5)
}

.btn-secondary.disabled,
.btn-secondary:disabled {
    background-color: #fff;
    border-color: #ccc
}

.btn-secondary.active,
.btn-secondary:active,
.show>.btn-secondary.dropdown-toggle {
    color: #292b2c;
    background-color: #e6e6e6;
    background-image: none;
    border-color: #adadad
}

.btn-info {
    color: #fff;
    background-color: #5bc0de;
    border-color: #5bc0de
}

.btn-info:hover {
    color: #fff;
    background-color: #31b0d5;
    border-color: #2aabd2
}

.btn-info.focus,
.btn-info:focus {
    box-shadow: 0 0 0 2px rgba(91, 192, 222, .5)
}

.btn-info.disabled,
.btn-info:disabled {
    background-color: #5bc0de;
    border-color: #5bc0de
}

.btn-info.active,
.btn-info:active,
.show>.btn-info.dropdown-toggle {
    color: #fff;
    background-color: #31b0d5;
    background-image: none;
    border-color: #2aabd2
}

.btn-success {
    color: #fff;
    background-color: #5cb85c;
    border-color: #5cb85c
}

.btn-success:hover {
    color: #fff;
    background-color: #449d44;
    border-color: #419641
}

.btn-success.focus,
.btn-success:focus {
    box-shadow: 0 0 0 2px rgba(92, 184, 92, .5)
}

.btn-success.disabled,
.btn-success:disabled {
    background-color: #5cb85c;
    border-color: #5cb85c
}

.btn-success.active,
.btn-success:active,
.show>.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #449d44;
    background-image: none;
    border-color: #419641
}

.btn-warning {
    color: #fff;
    background-color: #f0ad4e;
    border-color: #f0ad4e
}

.btn-warning:hover {
    color: #fff;
    background-color: #ec971f;
    border-color: #eb9316
}

.btn-warning.focus,
.btn-warning:focus {
    box-shadow: 0 0 0 2px rgba(240, 173, 78, .5)
}

.btn-warning.disabled,
.btn-warning:disabled {
    background-color: #f0ad4e;
    border-color: #f0ad4e
}

.btn-warning.active,
.btn-warning:active,
.show>.btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #ec971f;
    background-image: none;
    border-color: #eb9316
}

.btn-danger {
    color: #fff;
    background-color: #d9534f;
    border-color: #d9534f
}

.btn-danger:hover {
    color: #fff;
    background-color: #c9302c;
    border-color: #c12e2a
}

.btn-danger.focus,
.btn-danger:focus {
    box-shadow: 0 0 0 2px rgba(217, 83, 79, .5)
}

.btn-danger.disabled,
.btn-danger:disabled {
    background-color: #d9534f;
    border-color: #d9534f
}

.btn-danger.active,
.btn-danger:active,
.show>.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #c9302c;
    background-image: none;
    border-color: #c12e2a
}

.btn-outline-primary {
    color: #0275d8;
    background-image: none;
    background-color: transparent;
    border-color: #0275d8
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: #0275d8;
    border-color: #0275d8
}

.btn-outline-primary.focus,
.btn-outline-primary:focus {
    box-shadow: 0 0 0 2px rgba(2, 117, 216, .5)
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: #0275d8;
    background-color: transparent
}

.btn-outline-primary.active,
.btn-outline-primary:active,
.show>.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #0275d8;
    border-color: #0275d8
}

.btn-outline-secondary {
    color: #ccc;
    background-image: none;
    background-color: transparent;
    border-color: #ccc
}

.btn-outline-secondary:hover {
    color: #fff;
    background-color: #ccc;
    border-color: #ccc
}

.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
    box-shadow: 0 0 0 2px rgba(204, 204, 204, .5)
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
    color: #ccc;
    background-color: transparent
}

.btn-outline-secondary.active,
.btn-outline-secondary:active,
.show>.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #ccc;
    border-color: #ccc
}

.btn-outline-info {
    color: #5bc0de;
    background-image: none;
    background-color: transparent;
    border-color: #5bc0de
}

.btn-outline-info:hover {
    color: #fff;
    background-color: #5bc0de;
    border-color: #5bc0de
}

.btn-outline-info.focus,
.btn-outline-info:focus {
    box-shadow: 0 0 0 2px rgba(91, 192, 222, .5)
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
    color: #5bc0de;
    background-color: transparent
}

.btn-outline-info.active,
.btn-outline-info:active,
.show>.btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #5bc0de;
    border-color: #5bc0de
}

.btn-outline-success {
    color: #5cb85c;
    background-image: none;
    background-color: transparent;
    border-color: #5cb85c
}

.btn-outline-success:hover {
    color: #fff;
    background-color: #5cb85c;
    border-color: #5cb85c
}

.btn-outline-success.focus,
.btn-outline-success:focus {
    box-shadow: 0 0 0 2px rgba(92, 184, 92, .5)
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
    color: #5cb85c;
    background-color: transparent
}

.btn-outline-success.active,
.btn-outline-success:active,
.show>.btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #5cb85c;
    border-color: #5cb85c
}

.btn-outline-warning {
    color: #f0ad4e;
    background-image: none;
    background-color: transparent;
    border-color: #f0ad4e
}

.btn-outline-warning:hover {
    color: #fff;
    background-color: #f0ad4e;
    border-color: #f0ad4e
}

.btn-outline-warning.focus,
.btn-outline-warning:focus {
    box-shadow: 0 0 0 2px rgba(240, 173, 78, .5)
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
    color: #f0ad4e;
    background-color: transparent
}

.btn-outline-warning.active,
.btn-outline-warning:active,
.show>.btn-outline-warning.dropdown-toggle {
    color: #fff;
    background-color: #f0ad4e;
    border-color: #f0ad4e
}

.btn-outline-danger {
    color: #d9534f;
    background-image: none;
    background-color: transparent;
    border-color: #d9534f
}

.btn-outline-danger:hover {
    color: #fff;
    background-color: #d9534f;
    border-color: #d9534f
}

.btn-outline-danger.focus,
.btn-outline-danger:focus {
    box-shadow: 0 0 0 2px rgba(217, 83, 79, .5)
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
    color: #d9534f;
    background-color: transparent
}

.btn-outline-danger.active,
.btn-outline-danger:active,
.show>.btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #d9534f;
    border-color: #d9534f
}

.btn-link {
    font-weight: 400;
    color: #0275d8;
    border-radius: 0
}

.btn-link,
.btn-link.active,
.btn-link:active,
.btn-link:disabled {
    background-color: transparent
}

.btn-link,
.btn-link:active,
.btn-link:focus {
    border-color: transparent
}

.btn-link:hover {
    border-color: transparent
}

.btn-link:focus,
.btn-link:hover {
    color: #014c8c;
    text-decoration: underline;
    background-color: transparent
}

.btn-link:disabled {
    color: #636c72
}

.btn-link:disabled:focus,
.btn-link:disabled:hover {
    text-decoration: none
}

.btn-group-lg>.btn,
.btn-lg {
    padding: .75rem 1.5rem;
    font-size: 1.25rem;
    border-radius: .3rem
}

.btn-group-sm>.btn,
.btn-sm {
    padding: .25rem .5rem;
    font-size: .875rem;
    border-radius: .2rem
}

.btn-block {
    display: block;
    width: 100%
}

.btn-block+.btn-block {
    margin-top: .5rem
}

input[type=button].btn-block,
input[type=reset].btn-block,
input[type=submit].btn-block {
    width: 100%
}

.fade {
    opacity: 0;
    -webkit-transition: opacity .15s linear;
    transition: opacity .15s linear
}

.fade.show {
    opacity: 1
}

.collapse {
    display: none
}

.collapse.show {
    display: block
}

tr.collapse.show {
    display: table-row
}

tbody.collapse.show {
    display: table-row-group
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    -webkit-transition: height .35s ease;
    transition: height .35s ease
}

.dropdown,
.dropup {
    position: relative
}

.dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .3em;
    vertical-align: middle;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-left: .3em solid transparent
}

.dropdown-toggle:focus {
    outline: 0
}

.dropup .dropdown-toggle::after {
    border-top: 0;
    border-bottom: .3em solid
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: .5rem 0;
    margin: .125rem 0 0;
    font-size: 1rem;
    color: #292b2c;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: .25rem
}

.dropdown-divider {
    height: 1px;
    margin: .5rem 0;
    overflow: hidden;
    background-color: #eceeef
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: 3px 1.5rem;
    clear: both;
    font-weight: 400;
    color: #292b2c;
    text-align: inherit;
    white-space: nowrap;
    background: 0 0;
    border: 0
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: #1d1e1f;
    text-decoration: none;
    background-color: #f7f7f9
}

.dropdown-item.active,
.dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #0275d8
}

.dropdown-item.disabled,
.dropdown-item:disabled {
    color: #636c72;
    cursor: not-allowed;
    background-color: transparent
}

.show>.dropdown-menu {
    display: block
}

.show>a {
    outline: 0
}

.dropdown-menu-right {
    right: 0;
    left: auto
}

.dropdown-menu-left {
    right: auto;
    left: 0
}

.dropdown-header {
    display: block;
    padding: .5rem 1.5rem;
    margin-bottom: 0;
    font-size: .875rem;
    color: #636c72;
    white-space: nowrap
}

.dropdown-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 990
}

.dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-bottom: .125rem
}

.btn-group,
.btn-group-vertical {
    position: relative;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    vertical-align: middle
}

.btn-group-vertical>.btn,
.btn-group>.btn {
    position: relative;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto
}

.btn-group-vertical>.btn:hover,
.btn-group>.btn:hover {
    z-index: 2
}

.btn-group-vertical>.btn.active,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn:focus,
.btn-group>.btn.active,
.btn-group>.btn:active,
.btn-group>.btn:focus {
    z-index: 2
}

.btn-group .btn+.btn,
.btn-group .btn+.btn-group,
.btn-group .btn-group+.btn,
.btn-group .btn-group+.btn-group,
.btn-group-vertical .btn+.btn,
.btn-group-vertical .btn+.btn-group,
.btn-group-vertical .btn-group+.btn,
.btn-group-vertical .btn-group+.btn-group {
    margin-left: -1px
}

.btn-toolbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.btn-toolbar .input-group {
    width: auto
}

.btn-group>.btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
    border-radius: 0
}

.btn-group>.btn:first-child {
    margin-left: 0
}

.btn-group>.btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0
}

.btn-group>.btn:last-child:not(:first-child),
.btn-group>.dropdown-toggle:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0
}

.btn-group>.btn-group {
    float: left
}

.btn-group>.btn-group:not(:first-child):not(:last-child)>.btn {
    border-radius: 0
}

.btn-group>.btn-group:first-child:not(:last-child)>.btn:last-child,
.btn-group>.btn-group:first-child:not(:last-child)>.dropdown-toggle {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0
}

.btn-group>.btn-group:last-child:not(:first-child)>.btn:first-child {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0
}

.btn-group .dropdown-toggle:active,
.btn-group.open .dropdown-toggle {
    outline: 0
}

.btn+.dropdown-toggle-split {
    padding-right: .75rem;
    padding-left: .75rem
}

.btn+.dropdown-toggle-split::after {
    margin-left: 0
}

.btn-group-sm>.btn+.dropdown-toggle-split,
.btn-sm+.dropdown-toggle-split {
    padding-right: .375rem;
    padding-left: .375rem
}

.btn-group-lg>.btn+.dropdown-toggle-split,
.btn-lg+.dropdown-toggle-split {
    padding-right: 1.125rem;
    padding-left: 1.125rem
}

.btn-group-vertical {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
    width: 100%
}

.btn-group-vertical>.btn+.btn,
.btn-group-vertical>.btn+.btn-group,
.btn-group-vertical>.btn-group+.btn,
.btn-group-vertical>.btn-group+.btn-group {
    margin-top: -1px;
    margin-left: 0
}

.btn-group-vertical>.btn:not(:first-child):not(:last-child) {
    border-radius: 0
}

.btn-group-vertical>.btn:first-child:not(:last-child) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.btn-group-vertical>.btn:last-child:not(:first-child) {
    border-top-right-radius: 0;
    border-top-left-radius: 0
}

.btn-group-vertical>.btn-group:not(:first-child):not(:last-child)>.btn {
    border-radius: 0
}

.btn-group-vertical>.btn-group:first-child:not(:last-child)>.btn:last-child,
.btn-group-vertical>.btn-group:first-child:not(:last-child)>.dropdown-toggle {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.btn-group-vertical>.btn-group:last-child:not(:first-child)>.btn:first-child {
    border-top-right-radius: 0;
    border-top-left-radius: 0
}

[data-toggle=buttons]>.btn input[type=checkbox],
[data-toggle=buttons]>.btn input[type=radio],
[data-toggle=buttons]>.btn-group>.btn input[type=checkbox],
[data-toggle=buttons]>.btn-group>.btn input[type=radio] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none
}

.input-group {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%
}

.input-group .form-control {
    position: relative;
    z-index: 2;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0
}

.input-group .form-control:active,
.input-group .form-control:focus,
.input-group .form-control:hover {
    z-index: 3
}

.input-group .form-control,
.input-group-addon,
.input-group-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.input-group .form-control:not(:first-child):not(:last-child),
.input-group-addon:not(:first-child):not(:last-child),
.input-group-btn:not(:first-child):not(:last-child) {
    border-radius: 0
}

.input-group-addon,
.input-group-btn {
    white-space: nowrap;
    vertical-align: middle
}

.input-group-addon {
    padding: .5rem .75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25;
    color: #464a4c;
    text-align: center;
    background-color: #eceeef;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: .25rem
}

.input-group-addon.form-control-sm,
.input-group-sm>.input-group-addon,
.input-group-sm>.input-group-btn>.input-group-addon.btn {
    padding: .25rem .5rem;
    font-size: .875rem;
    border-radius: .2rem
}

.input-group-addon.form-control-lg,
.input-group-lg>.input-group-addon,
.input-group-lg>.input-group-btn>.input-group-addon.btn {
    padding: .75rem 1.5rem;
    font-size: 1.25rem;
    border-radius: .3rem
}

.input-group-addon input[type=checkbox],
.input-group-addon input[type=radio] {
    margin-top: 0
}

.input-group .form-control:not(:last-child),
.input-group-addon:not(:last-child),
.input-group-btn:not(:first-child)>.btn-group:not(:last-child)>.btn,
.input-group-btn:not(:first-child)>.btn:not(:last-child):not(.dropdown-toggle),
.input-group-btn:not(:last-child)>.btn,
.input-group-btn:not(:last-child)>.btn-group>.btn,
.input-group-btn:not(:last-child)>.dropdown-toggle {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0
}

.input-group-addon:not(:last-child) {
    border-right: 0
}

.input-group .form-control:not(:first-child),
.input-group-addon:not(:first-child),
.input-group-btn:not(:first-child)>.btn,
.input-group-btn:not(:first-child)>.btn-group>.btn,
.input-group-btn:not(:first-child)>.dropdown-toggle,
.input-group-btn:not(:last-child)>.btn-group:not(:first-child)>.btn,
.input-group-btn:not(:last-child)>.btn:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0
}

.form-control+.input-group-addon:not(:first-child) {
    border-left: 0
}

.input-group-btn {
    position: relative;
    font-size: 0;
    white-space: nowrap
}

.input-group-btn>.btn {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 0%;
    flex: 1 1 0%
}

.input-group-btn>.btn+.btn {
    margin-left: -1px
}

.input-group-btn>.btn:active,
.input-group-btn>.btn:focus,
.input-group-btn>.btn:hover {
    z-index: 3
}

.input-group-btn:not(:last-child)>.btn,
.input-group-btn:not(:last-child)>.btn-group {
    margin-right: -1px
}

.input-group-btn:not(:first-child)>.btn,
.input-group-btn:not(:first-child)>.btn-group {
    z-index: 2;
    margin-left: -1px
}

.input-group-btn:not(:first-child)>.btn-group:active,
.input-group-btn:not(:first-child)>.btn-group:focus,
.input-group-btn:not(:first-child)>.btn-group:hover,
.input-group-btn:not(:first-child)>.btn:active,
.input-group-btn:not(:first-child)>.btn:focus,
.input-group-btn:not(:first-child)>.btn:hover {
    z-index: 3
}

.custom-control {
    position: relative;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    min-height: 1.5rem;
    padding-left: 1.5rem;
    margin-right: 1rem;
    cursor: pointer
}

.custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0
}

.custom-control-input:checked~.custom-control-indicator {
    color: #fff;
    background-color: #0275d8
}

.custom-control-input:focus~.custom-control-indicator {
    box-shadow: 0 0 0 1px #fff, 0 0 0 3px #0275d8
}

.custom-control-input:active~.custom-control-indicator {
    color: #fff;
    background-color: #8fcafe
}

.custom-control-input:disabled~.custom-control-indicator {
    cursor: not-allowed;
    background-color: #eceeef
}

.custom-control-input:disabled~.custom-control-description {
    color: #636c72;
    cursor: not-allowed
}

.custom-control-indicator {
    position: absolute;
    top: .25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #ddd;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%
}

.custom-checkbox .custom-control-indicator {
    border-radius: .25rem
}

.custom-checkbox .custom-control-input:checked~.custom-control-indicator {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E")
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-indicator {
    background-color: #0275d8;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E")
}

.custom-radio .custom-control-indicator {
    border-radius: 50%
}

.custom-radio .custom-control-input:checked~.custom-control-indicator {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E")
}

.custom-controls-stacked {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.custom-controls-stacked .custom-control {
    margin-bottom: .25rem
}

.custom-controls-stacked .custom-control+.custom-control {
    margin-left: 0
}

.custom-select {
    display: inline-block;
    max-width: 100%;
    height: calc(2.25rem + 2px);
    padding: .375rem 1.75rem .375rem .75rem;
    line-height: 1.25;
    color: #464a4c;
    vertical-align: middle;
    background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right .75rem center;
    background-size: 8px 10px;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: .25rem;
    -moz-appearance: none;
    -webkit-appearance: none
}

.custom-select:focus {
    border-color: #5cb3fd;
    outline: 0
}

.custom-select:focus::-ms-value {
    color: #464a4c;
    background-color: #fff
}

.custom-select:disabled {
    color: #636c72;
    cursor: not-allowed;
    background-color: #eceeef
}

.custom-select::-ms-expand {
    opacity: 0
}

.custom-select-sm {
    padding-top: .375rem;
    padding-bottom: .375rem;
    font-size: 75%
}

.custom-file {
    position: relative;
    display: inline-block;
    max-width: 100%;
    height: 2.5rem;
    margin-bottom: 0;
    cursor: pointer
}

.custom-file-input {
    min-width: 14rem;
    max-width: 100%;
    height: 2.5rem;
    margin: 0;
    filter: alpha(opacity=0);
    opacity: 0
}

.custom-file-control {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 5;
    height: 2.5rem;
    padding: .5rem 1rem;
    line-height: 1.5;
    color: #464a4c;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: .25rem
}

.custom-file-control:lang(en)::after {
    content: "Choose file..."
}

.custom-file-control::before {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    z-index: 6;
    display: block;
    height: 2.5rem;
    padding: .5rem 1rem;
    line-height: 1.5;
    color: #464a4c;
    background-color: #eceeef;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 0 .25rem .25rem 0
}

.custom-file-control:lang(en)::before {
    content: "Browse"
}

.nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.nav-link {
    display: block;
    padding: .5em 1em
}

.nav-link:focus,
.nav-link:hover {
    text-decoration: none
}

.nav-link.disabled {
    color: #636c72;
    cursor: not-allowed
}

.nav-tabs {
    border-bottom: 1px solid #ddd
}

.nav-tabs .nav-item {
    margin-bottom: -1px
}

.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-right-radius: .25rem;
    border-top-left-radius: .25rem
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border-color: #eceeef #eceeef #ddd
}

.nav-tabs .nav-link.disabled {
    color: #636c72;
    background-color: transparent;
    border-color: transparent
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #464a4c;
    background-color: #fff;
    border-color: #ddd #ddd #fff
}

.nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-right-radius: 0;
    border-top-left-radius: 0
}

.nav-pills .nav-link {
    border-radius: .25rem
}

.nav-pills .nav-item.show .nav-link,
.nav-pills .nav-link.active {
    color: #fff;
    cursor: default;
    background-color: #0275d8
}

.nav-fill .nav-item {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    text-align: center
}

.nav-justified .nav-item {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    text-align: center
}

.tab-content>.tab-pane {
    display: none
}

.tab-content>.active {
    display: block
}

.navbar {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: .5rem 1rem
}

.navbar-brand {
    display: inline-block;
    padding-top: .25rem;
    padding-bottom: .25rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap
}

.navbar-brand:focus,
.navbar-brand:hover {
    text-decoration: none
}

.navbar-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0
}

.navbar-text {
    display: inline-block;
    padding-top: .425rem;
    padding-bottom: .425rem
}

.navbar-toggler {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    padding: .25rem .75rem;
    font-size: 1.25rem;
    line-height: 1;
    background: 0 0;
    border: 1px solid transparent;
    border-radius: .25rem
}

.navbar-toggler:focus,
.navbar-toggler:hover {
    text-decoration: none
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%
}

.navbar-toggler-left {
    position: absolute;
    left: 1rem
}

.navbar-toggler-right {
    position: absolute;
    right: 1rem
}

@media (max-width:575px) {
    .navbar-toggleable .navbar-nav .dropdown-menu {
        position: static;
        float: none
    }

    .navbar-toggleable>.container {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width:576px) {
    .navbar-toggleable {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    .navbar-toggleable .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }

    .navbar-toggleable .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-toggleable>.container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    .navbar-toggleable .navbar-collapse {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        width: 100%
    }

    .navbar-toggleable .navbar-toggler {
        display: none
    }
}

@media (max-width:767px) {
    .navbar-toggleable-sm .navbar-nav .dropdown-menu {
        position: static;
        float: none
    }

    .navbar-toggleable-sm>.container {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width:768px) {
    .navbar-toggleable-sm {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    .navbar-toggleable-sm .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }

    .navbar-toggleable-sm .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-toggleable-sm>.container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    .navbar-toggleable-sm .navbar-collapse {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        width: 100%
    }

    .navbar-toggleable-sm .navbar-toggler {
        display: none
    }
}

@media (max-width:991px) {
    .navbar-toggleable-md .navbar-nav .dropdown-menu {
        position: static;
        float: none
    }

    .navbar-toggleable-md>.container {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width:992px) {
    .navbar-toggleable-md {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    .navbar-toggleable-md .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }

    .navbar-toggleable-md .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-toggleable-md>.container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    .navbar-toggleable-md .navbar-collapse {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        width: 100%
    }

    .navbar-toggleable-md .navbar-toggler {
        display: none
    }
}

@media (max-width:1199px) {
    .navbar-toggleable-lg .navbar-nav .dropdown-menu {
        position: static;
        float: none
    }

    .navbar-toggleable-lg>.container {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width:1200px) {
    .navbar-toggleable-lg {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    .navbar-toggleable-lg .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }

    .navbar-toggleable-lg .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-toggleable-lg>.container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    .navbar-toggleable-lg .navbar-collapse {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        width: 100%
    }

    .navbar-toggleable-lg .navbar-toggler {
        display: none
    }
}

.navbar-toggleable-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.navbar-toggleable-xl .navbar-nav .dropdown-menu {
    position: static;
    float: none
}

.navbar-toggleable-xl>.container {
    padding-right: 0;
    padding-left: 0
}

.navbar-toggleable-xl .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row
}

.navbar-toggleable-xl .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem
}

.navbar-toggleable-xl>.container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.navbar-toggleable-xl .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    width: 100%
}

.navbar-toggleable-xl .navbar-toggler {
    display: none
}

.navbar-light .navbar-brand,
.navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover,
.navbar-light .navbar-toggler:focus,
.navbar-light .navbar-toggler:hover {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, .5)
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    color: rgba(0, 0, 0, .7)
}

.navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, .3)
}

.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.open,
.navbar-light .navbar-nav .open>.nav-link {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-toggler {
    border-color: rgba(0, 0, 0, .1)
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E")
}

.navbar-light .navbar-text {
    color: rgba(0, 0, 0, .5)
}

.navbar-inverse .navbar-brand,
.navbar-inverse .navbar-toggler {
    color: #fff
}

.navbar-inverse .navbar-brand:focus,
.navbar-inverse .navbar-brand:hover,
.navbar-inverse .navbar-toggler:focus,
.navbar-inverse .navbar-toggler:hover {
    color: #fff
}

.navbar-inverse .navbar-nav .nav-link {
    color: rgba(255, 255, 255, .5)
}

.navbar-inverse .navbar-nav .nav-link:focus,
.navbar-inverse .navbar-nav .nav-link:hover {
    color: rgba(255, 255, 255, .75)
}

.navbar-inverse .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, .25)
}

.navbar-inverse .navbar-nav .active>.nav-link,
.navbar-inverse .navbar-nav .nav-link.active,
.navbar-inverse .navbar-nav .nav-link.open,
.navbar-inverse .navbar-nav .open>.nav-link {
    color: #fff
}

.navbar-inverse .navbar-toggler {
    border-color: rgba(255, 255, 255, .1)
}

.navbar-inverse .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E")
}

.navbar-inverse .navbar-text {
    color: rgba(255, 255, 255, .5)
}

.card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem
}

.card-block {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem
}

.card-title {
    margin-bottom: .75rem
}

.card-subtitle {
    margin-top: -.375rem;
    margin-bottom: 0
}

.card-text:last-child {
    margin-bottom: 0
}

.card-link:hover {
    text-decoration: none
}

.card-link+.card-link {
    margin-left: 1.25rem
}

.card>.list-group:first-child .list-group-item:first-child {
    border-top-right-radius: .25rem;
    border-top-left-radius: .25rem
}

.card>.list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.card-header {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: #f7f7f9;
    border-bottom: 1px solid rgba(0, 0, 0, .125)
}

.card-header:first-child {
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0
}

.card-footer {
    padding: .75rem 1.25rem;
    background-color: #f7f7f9;
    border-top: 1px solid rgba(0, 0, 0, .125)
}

.card-footer:last-child {
    border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px)
}

.card-header-tabs {
    margin-right: -.625rem;
    margin-bottom: -.75rem;
    margin-left: -.625rem;
    border-bottom: 0
}

.card-header-pills {
    margin-right: -.625rem;
    margin-left: -.625rem
}

.card-primary {
    background-color: #0275d8;
    border-color: #0275d8
}

.card-primary .card-footer,
.card-primary .card-header {
    background-color: transparent
}

.card-success {
    background-color: #5cb85c;
    border-color: #5cb85c
}

.card-success .card-footer,
.card-success .card-header {
    background-color: transparent
}

.card-info {
    background-color: #5bc0de;
    border-color: #5bc0de
}

.card-info .card-footer,
.card-info .card-header {
    background-color: transparent
}

.card-warning {
    background-color: #f0ad4e;
    border-color: #f0ad4e
}

.card-warning .card-footer,
.card-warning .card-header {
    background-color: transparent
}

.card-danger {
    background-color: #d9534f;
    border-color: #d9534f
}

.card-danger .card-footer,
.card-danger .card-header {
    background-color: transparent
}

.card-outline-primary {
    background-color: transparent;
    border-color: #0275d8
}

.card-outline-secondary {
    background-color: transparent;
    border-color: #ccc
}

.card-outline-info {
    background-color: transparent;
    border-color: #5bc0de
}

.card-outline-success {
    background-color: transparent;
    border-color: #5cb85c
}

.card-outline-warning {
    background-color: transparent;
    border-color: #f0ad4e
}

.card-outline-danger {
    background-color: transparent;
    border-color: #d9534f
}

.card-inverse {
    color: rgba(255, 255, 255, .65)
}

.card-inverse .card-footer,
.card-inverse .card-header {
    background-color: transparent;
    border-color: rgba(255, 255, 255, .2)
}

.card-inverse .card-blockquote,
.card-inverse .card-footer,
.card-inverse .card-header,
.card-inverse .card-title {
    color: #fff
}

.card-inverse .card-blockquote .blockquote-footer,
.card-inverse .card-link,
.card-inverse .card-subtitle,
.card-inverse .card-text {
    color: rgba(255, 255, 255, .65)
}

.card-inverse .card-link:focus,
.card-inverse .card-link:hover {
    color: #fff
}

.card-blockquote {
    padding: 0;
    margin-bottom: 0;
    border-left: 0
}

.card-img {
    border-radius: calc(.25rem - 1px)
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem
}

.card-img-top {
    border-top-right-radius: calc(.25rem - 1px);
    border-top-left-radius: calc(.25rem - 1px)
}

.card-img-bottom {
    border-bottom-right-radius: calc(.25rem - 1px);
    border-bottom-left-radius: calc(.25rem - 1px)
}

@media (min-width:576px) {
    .card-deck {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap
    }

    .card-deck .card {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .card-deck .card:not(:first-child) {
        margin-left: 15px
    }

    .card-deck .card:not(:last-child) {
        margin-right: 15px
    }
}

@media (min-width:576px) {
    .card-group {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap
    }

    .card-group .card {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%
    }

    .card-group .card+.card {
        margin-left: 0;
        border-left: 0
    }

    .card-group .card:first-child {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0
    }

    .card-group .card:first-child .card-img-top {
        border-top-right-radius: 0
    }

    .card-group .card:first-child .card-img-bottom {
        border-bottom-right-radius: 0
    }

    .card-group .card:last-child {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0
    }

    .card-group .card:last-child .card-img-top {
        border-top-left-radius: 0
    }

    .card-group .card:last-child .card-img-bottom {
        border-bottom-left-radius: 0
    }

    .card-group .card:not(:first-child):not(:last-child) {
        border-radius: 0
    }

    .card-group .card:not(:first-child):not(:last-child) .card-img-bottom,
    .card-group .card:not(:first-child):not(:last-child) .card-img-top {
        border-radius: 0
    }
}

@media (min-width:576px) {
    .card-columns {
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;
        -webkit-column-gap: 1.25rem;
        -moz-column-gap: 1.25rem;
        column-gap: 1.25rem
    }

    .card-columns .card {
        display: inline-block;
        width: 100%;
        margin-bottom: .75rem
    }
}

.breadcrumb {
    padding: .75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #eceeef;
    border-radius: .25rem
}

.breadcrumb::after {
    display: block;
    content: "";
    clear: both
}

.breadcrumb-item {
    float: left
}

.breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: .5rem;
    padding-left: .5rem;
    color: #636c72;
    content: "/"
}

.breadcrumb-item+.breadcrumb-item:hover::before {
    text-decoration: underline
}

.breadcrumb-item+.breadcrumb-item:hover::before {
    text-decoration: none
}

.breadcrumb-item.active {
    color: #636c72
}

.pagination {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: .25rem
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-bottom-left-radius: .25rem;
    border-top-left-radius: .25rem
}

.page-item:last-child .page-link {
    border-bottom-right-radius: .25rem;
    border-top-right-radius: .25rem
}

.page-item.active .page-link {
    z-index: 2;
    color: #fff;
    background-color: #0275d8;
    border-color: #0275d8
}

.page-item.disabled .page-link {
    color: #636c72;
    pointer-events: none;
    cursor: not-allowed;
    background-color: #fff;
    border-color: #ddd
}

.page-link {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #0275d8;
    background-color: #fff;
    border: 1px solid #ddd
}

.page-link:focus,
.page-link:hover {
    color: #014c8c;
    text-decoration: none;
    background-color: #eceeef;
    border-color: #ddd
}

.pagination-lg .page-link {
    padding: .75rem 1.5rem;
    font-size: 1.25rem
}

.pagination-lg .page-item:first-child .page-link {
    border-bottom-left-radius: .3rem;
    border-top-left-radius: .3rem
}

.pagination-lg .page-item:last-child .page-link {
    border-bottom-right-radius: .3rem;
    border-top-right-radius: .3rem
}

.pagination-sm .page-link {
    padding: .25rem .5rem;
    font-size: .875rem
}

.pagination-sm .page-item:first-child .page-link {
    border-bottom-left-radius: .2rem;
    border-top-left-radius: .2rem
}

.pagination-sm .page-item:last-child .page-link {
    border-bottom-right-radius: .2rem;
    border-top-right-radius: .2rem
}

.badge {
    display: inline-block;
    padding: .25em .4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem
}

.badge:empty {
    display: none
}

.btn .badge {
    position: relative;
    top: -1px
}

a.badge:focus,
a.badge:hover {
    color: #fff;
    text-decoration: none;
    cursor: pointer
}

.badge-pill {
    padding-right: .6em;
    padding-left: .6em;
    border-radius: 10rem
}

.badge-default {
    background-color: #636c72
}

.badge-default[href]:focus,
.badge-default[href]:hover {
    background-color: #4b5257
}

.badge-primary {
    background-color: #0275d8
}

.badge-primary[href]:focus,
.badge-primary[href]:hover {
    background-color: #025aa5
}

.badge-success {
    background-color: #5cb85c
}

.badge-success[href]:focus,
.badge-success[href]:hover {
    background-color: #449d44
}

.badge-info {
    background-color: #5bc0de
}

.badge-info[href]:focus,
.badge-info[href]:hover {
    background-color: #31b0d5
}

.badge-warning {
    background-color: #f0ad4e
}

.badge-warning[href]:focus,
.badge-warning[href]:hover {
    background-color: #ec971f
}

.badge-danger {
    background-color: #d9534f
}

.badge-danger[href]:focus,
.badge-danger[href]:hover {
    background-color: #c9302c
}

.jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #eceeef;
    border-radius: .3rem
}

@media (min-width:576px) {
    .jumbotron {
        padding: 4rem 2rem
    }
}

.jumbotron-hr {
    border-top-color: #d0d5d8
}

.jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0
}

.alert {
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem
}

.alert-heading {
    color: inherit
}

.alert-link {
    font-weight: 700
}

.alert-dismissible .close {
    position: relative;
    top: -.75rem;
    right: -1.25rem;
    padding: .75rem 1.25rem;
    color: inherit
}

.alert-success {
    background-color: #dff0d8;
    border-color: #d0e9c6;
    color: #3c763d
}

.alert-success hr {
    border-top-color: #c1e2b3
}

.alert-success .alert-link {
    color: #2b542c
}

.alert-info {
    background-color: #d9edf7;
    border-color: #bcdff1;
    color: #31708f
}

.alert-info hr {
    border-top-color: #a6d5ec
}

.alert-info .alert-link {
    color: #245269
}

.alert-warning {
    background-color: #fcf8e3;
    border-color: #faf2cc;
    color: #8a6d3b
}

.alert-warning hr {
    border-top-color: #f7ecb5
}

.alert-warning .alert-link {
    color: #66512c
}

.alert-danger {
    background-color: #f2dede;
    border-color: #ebcccc;
    color: #a94442
}

.alert-danger hr {
    border-top-color: #e4b9b9
}

.alert-danger .alert-link {
    color: #843534
}

@-webkit-keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0
    }

    to {
        background-position: 0 0
    }
}

@keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0
    }

    to {
        background-position: 0 0
    }
}

.progress {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    font-size: .75rem;
    line-height: 1rem;
    text-align: center;
    background-color: #eceeef;
    border-radius: .25rem
}

.progress-bar {
    height: 1rem;
    color: #fff;
    background-color: #0275d8
}

.progress-bar-striped {
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem
}

.progress-bar-animated {
    -webkit-animation: progress-bar-stripes 1s linear infinite;
    animation: progress-bar-stripes 1s linear infinite
}

.media {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

.media-body {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 0%;
    flex: 1 1 0%
}

.list-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0
}

.list-group-item-action {
    width: 100%;
    color: #464a4c;
    text-align: inherit
}

.list-group-item-action .list-group-item-heading {
    color: #292b2c
}

.list-group-item-action:focus,
.list-group-item-action:hover {
    color: #464a4c;
    text-decoration: none;
    background-color: #f7f7f9
}

.list-group-item-action:active {
    color: #292b2c;
    background-color: #eceeef
}

.list-group-item {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: .75rem 1.25rem;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .125)
}

.list-group-item:first-child {
    border-top-right-radius: .25rem;
    border-top-left-radius: .25rem
}

.list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.list-group-item:focus,
.list-group-item:hover {
    text-decoration: none
}

.list-group-item.disabled,
.list-group-item:disabled {
    color: #636c72;
    cursor: not-allowed;
    background-color: #fff
}

.list-group-item.disabled .list-group-item-heading,
.list-group-item:disabled .list-group-item-heading {
    color: inherit
}

.list-group-item.disabled .list-group-item-text,
.list-group-item:disabled .list-group-item-text {
    color: #636c72
}

.list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #0275d8;
    border-color: #0275d8
}

.list-group-item.active .list-group-item-heading,
.list-group-item.active .list-group-item-heading>.small,
.list-group-item.active .list-group-item-heading>small {
    color: inherit
}

.list-group-item.active .list-group-item-text {
    color: #daeeff
}

.list-group-flush .list-group-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0
}

.list-group-flush:first-child .list-group-item:first-child {
    border-top: 0
}

.list-group-flush:last-child .list-group-item:last-child {
    border-bottom: 0
}

.list-group-item-success {
    color: #3c763d;
    background-color: #dff0d8
}

a.list-group-item-success,
button.list-group-item-success {
    color: #3c763d
}

a.list-group-item-success .list-group-item-heading,
button.list-group-item-success .list-group-item-heading {
    color: inherit
}

a.list-group-item-success:focus,
a.list-group-item-success:hover,
button.list-group-item-success:focus,
button.list-group-item-success:hover {
    color: #3c763d;
    background-color: #d0e9c6
}

a.list-group-item-success.active,
button.list-group-item-success.active {
    color: #fff;
    background-color: #3c763d;
    border-color: #3c763d
}

.list-group-item-info {
    color: #31708f;
    background-color: #d9edf7
}

a.list-group-item-info,
button.list-group-item-info {
    color: #31708f
}

a.list-group-item-info .list-group-item-heading,
button.list-group-item-info .list-group-item-heading {
    color: inherit
}

a.list-group-item-info:focus,
a.list-group-item-info:hover,
button.list-group-item-info:focus,
button.list-group-item-info:hover {
    color: #31708f;
    background-color: #c4e3f3
}

a.list-group-item-info.active,
button.list-group-item-info.active {
    color: #fff;
    background-color: #31708f;
    border-color: #31708f
}

.list-group-item-warning {
    color: #8a6d3b;
    background-color: #fcf8e3
}

a.list-group-item-warning,
button.list-group-item-warning {
    color: #8a6d3b
}

a.list-group-item-warning .list-group-item-heading,
button.list-group-item-warning .list-group-item-heading {
    color: inherit
}

a.list-group-item-warning:focus,
a.list-group-item-warning:hover,
button.list-group-item-warning:focus,
button.list-group-item-warning:hover {
    color: #8a6d3b;
    background-color: #faf2cc
}

a.list-group-item-warning.active,
button.list-group-item-warning.active {
    color: #fff;
    background-color: #8a6d3b;
    border-color: #8a6d3b
}

.list-group-item-danger {
    color: #a94442;
    background-color: #f2dede
}

a.list-group-item-danger,
button.list-group-item-danger {
    color: #a94442
}

a.list-group-item-danger .list-group-item-heading,
button.list-group-item-danger .list-group-item-heading {
    color: inherit
}

a.list-group-item-danger:focus,
a.list-group-item-danger:hover,
button.list-group-item-danger:focus,
button.list-group-item-danger:hover {
    color: #a94442;
    background-color: #ebcccc
}

a.list-group-item-danger.active,
button.list-group-item-danger.active {
    color: #fff;
    background-color: #a94442;
    border-color: #a94442
}

.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden
}

.embed-responsive::before {
    display: block;
    content: ""
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0
}

.embed-responsive-21by9::before {
    padding-top: 42.857143%
}

.embed-responsive-16by9::before {
    padding-top: 56.25%
}

.embed-responsive-4by3::before {
    padding-top: 75%
}

.embed-responsive-1by1::before {
    padding-top: 100%
}

.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5
}

.close:focus,
.close:hover {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    opacity: .75
}

button.close {
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0;
    -webkit-appearance: none
}

.modal-open {
    overflow: hidden
}

.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    outline: 0
}

.modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform .3s ease-out;
    transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: transform .3s ease-out, -webkit-transform .3s ease-out;
    transition: transform .3s ease-out, -webkit-transform .3s ease-out;
    -webkit-transform: translate(0, -25%);
    -ms-transform: translate(0, -25%);
    transform: translate(0, -25%)
}

.modal.show .modal-dialog {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0)
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: 10px
}

.modal-content {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .3rem;
    outline: 0
}

.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000
}

.modal-backdrop.fade {
    opacity: 0
}

.modal-backdrop.show {
    opacity: .5
}

.modal-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 15px;
    border-bottom: 1px solid #eceeef
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5
}

.modal-body {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 15px
}

.modal-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 15px;
    border-top: 1px solid #eceeef
}

.modal-footer>:not(:first-child) {
    margin-left: .25rem
}

.modal-footer>:not(:last-child) {
    margin-right: .25rem
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll
}

@media (min-width:576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 30px auto
    }

    .modal-sm {
        max-width: 300px
    }
}

@media (min-width:992px) {
    .modal-lg {
        max-width: 800px
    }
}

.tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    letter-spacing: normal;
    line-break: auto;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    white-space: normal;
    word-break: normal;
    word-spacing: normal;
    font-size: .875rem;
    word-wrap: break-word;
    opacity: 0
}

.tooltip.show {
    opacity: .9
}

.tooltip.bs-tether-element-attached-bottom,
.tooltip.tooltip-top {
    padding: 5px 0;
    margin-top: -3px
}

.tooltip.bs-tether-element-attached-bottom .tooltip-inner::before,
.tooltip.tooltip-top .tooltip-inner::before {
    bottom: 0;
    left: 50%;
    margin-left: -5px;
    content: "";
    border-width: 5px 5px 0;
    border-top-color: #000
}

.tooltip.bs-tether-element-attached-left,
.tooltip.tooltip-right {
    padding: 0 5px;
    margin-left: 3px
}

.tooltip.bs-tether-element-attached-left .tooltip-inner::before,
.tooltip.tooltip-right .tooltip-inner::before {
    top: 50%;
    left: 0;
    margin-top: -5px;
    content: "";
    border-width: 5px 5px 5px 0;
    border-right-color: #000
}

.tooltip.bs-tether-element-attached-top,
.tooltip.tooltip-bottom {
    padding: 5px 0;
    margin-top: 3px
}

.tooltip.bs-tether-element-attached-top .tooltip-inner::before,
.tooltip.tooltip-bottom .tooltip-inner::before {
    top: 0;
    left: 50%;
    margin-left: -5px;
    content: "";
    border-width: 0 5px 5px;
    border-bottom-color: #000
}

.tooltip.bs-tether-element-attached-right,
.tooltip.tooltip-left {
    padding: 0 5px;
    margin-left: -3px
}

.tooltip.bs-tether-element-attached-right .tooltip-inner::before,
.tooltip.tooltip-left .tooltip-inner::before {
    top: 50%;
    right: 0;
    margin-top: -5px;
    content: "";
    border-width: 5px 0 5px 5px;
    border-left-color: #000
}

.tooltip-inner {
    max-width: 200px;
    padding: 3px 8px;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: .25rem
}

.tooltip-inner::before {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid
}

.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: block;
    max-width: 276px;
    padding: 1px;
    font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    letter-spacing: normal;
    line-break: auto;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    white-space: normal;
    word-break: normal;
    word-spacing: normal;
    font-size: .875rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .3rem
}

.popover.bs-tether-element-attached-bottom,
.popover.popover-top {
    margin-top: -10px
}

.popover.bs-tether-element-attached-bottom::after,
.popover.bs-tether-element-attached-bottom::before,
.popover.popover-top::after,
.popover.popover-top::before {
    left: 50%;
    border-bottom-width: 0
}

.popover.bs-tether-element-attached-bottom::before,
.popover.popover-top::before {
    bottom: -11px;
    margin-left: -11px;
    border-top-color: rgba(0, 0, 0, .25)
}

.popover.bs-tether-element-attached-bottom::after,
.popover.popover-top::after {
    bottom: -10px;
    margin-left: -10px;
    border-top-color: #fff
}

.popover.bs-tether-element-attached-left,
.popover.popover-right {
    margin-left: 10px
}

.popover.bs-tether-element-attached-left::after,
.popover.bs-tether-element-attached-left::before,
.popover.popover-right::after,
.popover.popover-right::before {
    top: 50%;
    border-left-width: 0
}

.popover.bs-tether-element-attached-left::before,
.popover.popover-right::before {
    left: -11px;
    margin-top: -11px;
    border-right-color: rgba(0, 0, 0, .25)
}

.popover.bs-tether-element-attached-left::after,
.popover.popover-right::after {
    left: -10px;
    margin-top: -10px;
    border-right-color: #fff
}

.popover.bs-tether-element-attached-top,
.popover.popover-bottom {
    margin-top: 10px
}

.popover.bs-tether-element-attached-top::after,
.popover.bs-tether-element-attached-top::before,
.popover.popover-bottom::after,
.popover.popover-bottom::before {
    left: 50%;
    border-top-width: 0
}

.popover.bs-tether-element-attached-top::before,
.popover.popover-bottom::before {
    top: -11px;
    margin-left: -11px;
    border-bottom-color: rgba(0, 0, 0, .25)
}

.popover.bs-tether-element-attached-top::after,
.popover.popover-bottom::after {
    top: -10px;
    margin-left: -10px;
    border-bottom-color: #f7f7f7
}

.popover.bs-tether-element-attached-top .popover-title::before,
.popover.popover-bottom .popover-title::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 20px;
    margin-left: -10px;
    content: "";
    border-bottom: 1px solid #f7f7f7
}

.popover.bs-tether-element-attached-right,
.popover.popover-left {
    margin-left: -10px
}

.popover.bs-tether-element-attached-right::after,
.popover.bs-tether-element-attached-right::before,
.popover.popover-left::after,
.popover.popover-left::before {
    top: 50%;
    border-right-width: 0
}

.popover.bs-tether-element-attached-right::before,
.popover.popover-left::before {
    right: -11px;
    margin-top: -11px;
    border-left-color: rgba(0, 0, 0, .25)
}

.popover.bs-tether-element-attached-right::after,
.popover.popover-left::after {
    right: -10px;
    margin-top: -10px;
    border-left-color: #fff
}

.popover-title {
    padding: 8px 14px;
    margin-bottom: 0;
    font-size: 1rem;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-top-right-radius: calc(.3rem - 1px);
    border-top-left-radius: calc(.3rem - 1px)
}

.popover-title:empty {
    display: none
}

.popover-content {
    padding: 9px 14px
}

.popover::after,
.popover::before {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid
}

.popover::before {
    content: "";
    border-width: 11px
}

.popover::after {
    content: "";
    border-width: 10px
}

.carousel {
    position: relative
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden
}

.carousel-item {
    position: relative;
    display: none;
    width: 100%
}

@media (-webkit-transform-3d) {
    .carousel-item {
        -webkit-transition: -webkit-transform .6s ease-in-out;
        transition: -webkit-transform .6s ease-in-out;
        transition: transform .6s ease-in-out;
        transition: transform .6s ease-in-out, -webkit-transform .6s ease-in-out;
        transition: transform .6s ease-in-out, -webkit-transform .6s ease-in-out;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-perspective: 1000px;
        perspective: 1000px
    }
}

@supports ((-webkit-transform:translate3d(0, 0, 0)) or (transform:translate3d(0, 0, 0))) {
    .carousel-item {
        -webkit-transition: -webkit-transform .6s ease-in-out;
        transition: -webkit-transform .6s ease-in-out;
        transition: transform .6s ease-in-out;
        transition: transform .6s ease-in-out, -webkit-transform .6s ease-in-out;
        transition: transform .6s ease-in-out, -webkit-transform .6s ease-in-out;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-perspective: 1000px;
        perspective: 1000px
    }
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.carousel-item-next,
.carousel-item-prev {
    position: absolute;
    top: 0
}

@media (-webkit-transform-3d) {

    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    .active.carousel-item-right,
    .carousel-item-next {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0)
    }

    .active.carousel-item-left,
    .carousel-item-prev {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0)
    }
}

@supports ((-webkit-transform:translate3d(0, 0, 0)) or (transform:translate3d(0, 0, 0))) {

    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    .active.carousel-item-right,
    .carousel-item-next {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0)
    }

    .active.carousel-item-left,
    .carousel-item-prev {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0)
    }
}

.carousel-control-next,
.carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: .5
}

.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9
}

.carousel-control-prev {
    left: 0
}

.carousel-control-next {
    right: 0
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: transparent no-repeat center center;
    background-size: 100% 100%
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M4 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E")
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M1.5 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E")
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 10px;
    left: 0;
    z-index: 15;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none
}

.carousel-indicators li {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    max-width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, .5)
}

.carousel-indicators li::before {
    position: absolute;
    top: -10px;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 10px;
    content: ""
}

.carousel-indicators li::after {
    position: absolute;
    bottom: -10px;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 10px;
    content: ""
}

.carousel-indicators .active {
    background-color: #fff
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center
}

.align-baseline {
    vertical-align: baseline !important
}

.align-top {
    vertical-align: top !important
}

.align-middle {
    vertical-align: middle !important
}

.align-bottom {
    vertical-align: bottom !important
}

.align-text-bottom {
    vertical-align: text-bottom !important
}

.align-text-top {
    vertical-align: text-top !important
}

.bg-faded {
    background-color: #f7f7f7
}

.bg-primary {
    background-color: #0275d8 !important
}

a.bg-primary:focus,
a.bg-primary:hover {
    background-color: #025aa5 !important
}

.bg-success {
    background-color: #5cb85c !important
}

a.bg-success:focus,
a.bg-success:hover {
    background-color: #449d44 !important
}

.bg-info {
    background-color: #5bc0de !important
}

a.bg-info:focus,
a.bg-info:hover {
    background-color: #31b0d5 !important
}

.bg-warning {
    background-color: #f0ad4e !important
}

a.bg-warning:focus,
a.bg-warning:hover {
    background-color: #ec971f !important
}

.bg-danger {
    background-color: #d9534f !important
}

a.bg-danger:focus,
a.bg-danger:hover {
    background-color: #c9302c !important
}

.bg-inverse {
    background-color: #292b2c !important
}

a.bg-inverse:focus,
a.bg-inverse:hover {
    background-color: #101112 !important
}

.border-0 {
    border: 0 !important
}

.border-top-0 {
    border-top: 0 !important
}

.border-right-0 {
    border-right: 0 !important
}

.border-bottom-0 {
    border-bottom: 0 !important
}

.border-left-0 {
    border-left: 0 !important
}

.rounded {
    border-radius: .25rem
}

.rounded-top {
    border-top-right-radius: .25rem;
    border-top-left-radius: .25rem
}

.rounded-right {
    border-bottom-right-radius: .25rem;
    border-top-right-radius: .25rem
}

.rounded-bottom {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.rounded-left {
    border-bottom-left-radius: .25rem;
    border-top-left-radius: .25rem
}

.rounded-circle {
    border-radius: 50%
}

.rounded-0 {
    border-radius: 0
}

.clearfix::after {
    display: block;
    content: "";
    clear: both
}

.d-none {
    display: none !important
}

.d-inline {
    display: inline !important
}

.d-inline-block {
    display: inline-block !important
}

.d-block {
    display: block !important
}

.d-table {
    display: table !important
}

.d-table-cell {
    display: table-cell !important
}

.d-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important
}

.d-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important
}

@media (min-width:576px) {
    .d-sm-none {
        display: none !important
    }

    .d-sm-inline {
        display: inline !important
    }

    .d-sm-inline-block {
        display: inline-block !important
    }

    .d-sm-block {
        display: block !important
    }

    .d-sm-table {
        display: table !important
    }

    .d-sm-table-cell {
        display: table-cell !important
    }

    .d-sm-flex {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important
    }

    .d-sm-inline-flex {
        display: -webkit-inline-box !important;
        display: -ms-inline-flexbox !important;
        display: inline-flex !important
    }
}

@media (min-width:768px) {
    .d-md-none {
        display: none !important
    }

    .d-md-inline {
        display: inline !important
    }

    .d-md-inline-block {
        display: inline-block !important
    }

    .d-md-block {
        display: block !important
    }

    .d-md-table {
        display: table !important
    }

    .d-md-table-cell {
        display: table-cell !important
    }

    .d-md-flex {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important
    }

    .d-md-inline-flex {
        display: -webkit-inline-box !important;
        display: -ms-inline-flexbox !important;
        display: inline-flex !important
    }
}

@media (min-width:992px) {
    .d-lg-none {
        display: none !important
    }

    .d-lg-inline {
        display: inline !important
    }

    .d-lg-inline-block {
        display: inline-block !important
    }

    .d-lg-block {
        display: block !important
    }

    .d-lg-table {
        display: table !important
    }

    .d-lg-table-cell {
        display: table-cell !important
    }

    .d-lg-flex {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important
    }

    .d-lg-inline-flex {
        display: -webkit-inline-box !important;
        display: -ms-inline-flexbox !important;
        display: inline-flex !important
    }
}

@media (min-width:1200px) {
    .d-xl-none {
        display: none !important
    }

    .d-xl-inline {
        display: inline !important
    }

    .d-xl-inline-block {
        display: inline-block !important
    }

    .d-xl-block {
        display: block !important
    }

    .d-xl-table {
        display: table !important
    }

    .d-xl-table-cell {
        display: table-cell !important
    }

    .d-xl-flex {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important
    }

    .d-xl-inline-flex {
        display: -webkit-inline-box !important;
        display: -ms-inline-flexbox !important;
        display: inline-flex !important
    }
}

.flex-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1
}

.flex-last {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1
}

.flex-unordered {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0
}

.flex-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important
}

.flex-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important
}

.flex-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important
}

.flex-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important
}

.flex-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important
}

.flex-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important
}

.flex-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important
}

.justify-content-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important
}

.justify-content-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important
}

.justify-content-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important
}

.justify-content-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important
}

.justify-content-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important
}

.align-items-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important
}

.align-items-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important
}

.align-items-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important
}

.align-items-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important
}

.align-items-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important
}

.align-content-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important
}

.align-content-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important
}

.align-content-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important
}

.align-content-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important
}

.align-content-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important
}

.align-content-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important
}

.align-self-auto {
    -webkit-align-self: auto !important;
    -ms-grid-row-align: auto !important;
    -ms-flex-item-align: auto !important;
    align-self: auto !important
}

.align-self-start {
    -webkit-align-self: flex-start !important;
    -ms-flex-item-align: start !important;
    align-self: flex-start !important
}

.align-self-end {
    -webkit-align-self: flex-end !important;
    -ms-flex-item-align: end !important;
    align-self: flex-end !important
}

.align-self-center {
    -webkit-align-self: center !important;
    -ms-grid-row-align: center !important;
    -ms-flex-item-align: center !important;
    align-self: center !important
}

.align-self-baseline {
    -webkit-align-self: baseline !important;
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important
}

.align-self-stretch {
    -webkit-align-self: stretch !important;
    -ms-grid-row-align: stretch !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important
}

@media (min-width:576px) {
    .flex-sm-first {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1
    }

    .flex-sm-last {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1
    }

    .flex-sm-unordered {
        -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0
    }

    .flex-sm-row {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
        flex-direction: row !important
    }

    .flex-sm-column {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important
    }

    .flex-sm-row-reverse {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important
    }

    .flex-sm-column-reverse {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important
    }

    .flex-sm-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important
    }

    .flex-sm-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important
    }

    .flex-sm-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important
    }

    .justify-content-sm-start {
        -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important
    }

    .justify-content-sm-end {
        -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
        justify-content: flex-end !important
    }

    .justify-content-sm-center {
        -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important
    }

    .justify-content-sm-between {
        -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important
    }

    .justify-content-sm-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important
    }

    .align-items-sm-start {
        -webkit-box-align: start !important;
        -ms-flex-align: start !important;
        align-items: flex-start !important
    }

    .align-items-sm-end {
        -webkit-box-align: end !important;
        -ms-flex-align: end !important;
        align-items: flex-end !important
    }

    .align-items-sm-center {
        -webkit-box-align: center !important;
        -ms-flex-align: center !important;
        align-items: center !important
    }

    .align-items-sm-baseline {
        -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
        align-items: baseline !important
    }

    .align-items-sm-stretch {
        -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
        align-items: stretch !important
    }

    .align-content-sm-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important
    }

    .align-content-sm-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important
    }

    .align-content-sm-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important
    }

    .align-content-sm-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important
    }

    .align-content-sm-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important
    }

    .align-content-sm-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important
    }

    .align-self-sm-auto {
        -webkit-align-self: auto !important;
        -ms-grid-row-align: auto !important;
        -ms-flex-item-align: auto !important;
        align-self: auto !important
    }

    .align-self-sm-start {
        -webkit-align-self: flex-start !important;
        -ms-flex-item-align: start !important;
        align-self: flex-start !important
    }

    .align-self-sm-end {
        -webkit-align-self: flex-end !important;
        -ms-flex-item-align: end !important;
        align-self: flex-end !important
    }

    .align-self-sm-center {
        -webkit-align-self: center !important;
        -ms-grid-row-align: center !important;
        -ms-flex-item-align: center !important;
        align-self: center !important
    }

    .align-self-sm-baseline {
        -webkit-align-self: baseline !important;
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important
    }

    .align-self-sm-stretch {
        -webkit-align-self: stretch !important;
        -ms-grid-row-align: stretch !important;
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important
    }
}

@media (min-width:768px) {
    .flex-md-first {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1
    }

    .flex-md-last {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1
    }

    .flex-md-unordered {
        -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0
    }

    .flex-md-row {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
        flex-direction: row !important
    }

    .flex-md-column {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important
    }

    .flex-md-row-reverse {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important
    }

    .flex-md-column-reverse {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important
    }

    .flex-md-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important
    }

    .flex-md-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important
    }

    .flex-md-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important
    }

    .justify-content-md-start {
        -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important
    }

    .justify-content-md-end {
        -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
        justify-content: flex-end !important
    }

    .justify-content-md-center {
        -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important
    }

    .justify-content-md-between {
        -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important
    }

    .justify-content-md-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important
    }

    .align-items-md-start {
        -webkit-box-align: start !important;
        -ms-flex-align: start !important;
        align-items: flex-start !important
    }

    .align-items-md-end {
        -webkit-box-align: end !important;
        -ms-flex-align: end !important;
        align-items: flex-end !important
    }

    .align-items-md-center {
        -webkit-box-align: center !important;
        -ms-flex-align: center !important;
        align-items: center !important
    }

    .align-items-md-baseline {
        -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
        align-items: baseline !important
    }

    .align-items-md-stretch {
        -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
        align-items: stretch !important
    }

    .align-content-md-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important
    }

    .align-content-md-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important
    }

    .align-content-md-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important
    }

    .align-content-md-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important
    }

    .align-content-md-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important
    }

    .align-content-md-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important
    }

    .align-self-md-auto {
        -webkit-align-self: auto !important;
        -ms-grid-row-align: auto !important;
        -ms-flex-item-align: auto !important;
        align-self: auto !important
    }

    .align-self-md-start {
        -webkit-align-self: flex-start !important;
        -ms-flex-item-align: start !important;
        align-self: flex-start !important
    }

    .align-self-md-end {
        -webkit-align-self: flex-end !important;
        -ms-flex-item-align: end !important;
        align-self: flex-end !important
    }

    .align-self-md-center {
        -webkit-align-self: center !important;
        -ms-grid-row-align: center !important;
        -ms-flex-item-align: center !important;
        align-self: center !important
    }

    .align-self-md-baseline {
        -webkit-align-self: baseline !important;
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important
    }

    .align-self-md-stretch {
        -webkit-align-self: stretch !important;
        -ms-grid-row-align: stretch !important;
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important
    }
}

@media (min-width:992px) {
    .flex-lg-first {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1
    }

    .flex-lg-last {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1
    }

    .flex-lg-unordered {
        -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0
    }

    .flex-lg-row {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
        flex-direction: row !important
    }

    .flex-lg-column {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important
    }

    .flex-lg-row-reverse {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important
    }

    .flex-lg-column-reverse {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important
    }

    .flex-lg-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important
    }

    .flex-lg-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important
    }

    .flex-lg-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important
    }

    .justify-content-lg-start {
        -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important
    }

    .justify-content-lg-end {
        -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
        justify-content: flex-end !important
    }

    .justify-content-lg-center {
        -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important
    }

    .justify-content-lg-between {
        -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important
    }

    .justify-content-lg-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important
    }

    .align-items-lg-start {
        -webkit-box-align: start !important;
        -ms-flex-align: start !important;
        align-items: flex-start !important
    }

    .align-items-lg-end {
        -webkit-box-align: end !important;
        -ms-flex-align: end !important;
        align-items: flex-end !important
    }

    .align-items-lg-center {
        -webkit-box-align: center !important;
        -ms-flex-align: center !important;
        align-items: center !important
    }

    .align-items-lg-baseline {
        -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
        align-items: baseline !important
    }

    .align-items-lg-stretch {
        -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
        align-items: stretch !important
    }

    .align-content-lg-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important
    }

    .align-content-lg-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important
    }

    .align-content-lg-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important
    }

    .align-content-lg-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important
    }

    .align-content-lg-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important
    }

    .align-content-lg-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important
    }

    .align-self-lg-auto {
        -webkit-align-self: auto !important;
        -ms-grid-row-align: auto !important;
        -ms-flex-item-align: auto !important;
        align-self: auto !important
    }

    .align-self-lg-start {
        -webkit-align-self: flex-start !important;
        -ms-flex-item-align: start !important;
        align-self: flex-start !important
    }

    .align-self-lg-end {
        -webkit-align-self: flex-end !important;
        -ms-flex-item-align: end !important;
        align-self: flex-end !important
    }

    .align-self-lg-center {
        -webkit-align-self: center !important;
        -ms-grid-row-align: center !important;
        -ms-flex-item-align: center !important;
        align-self: center !important
    }

    .align-self-lg-baseline {
        -webkit-align-self: baseline !important;
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important
    }

    .align-self-lg-stretch {
        -webkit-align-self: stretch !important;
        -ms-grid-row-align: stretch !important;
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important
    }
}

@media (min-width:1200px) {
    .flex-xl-first {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1
    }

    .flex-xl-last {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1
    }

    .flex-xl-unordered {
        -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0
    }

    .flex-xl-row {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
        flex-direction: row !important
    }

    .flex-xl-column {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important
    }

    .flex-xl-row-reverse {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important
    }

    .flex-xl-column-reverse {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important
    }

    .flex-xl-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important
    }

    .flex-xl-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important
    }

    .flex-xl-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important
    }

    .justify-content-xl-start {
        -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important
    }

    .justify-content-xl-end {
        -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
        justify-content: flex-end !important
    }

    .justify-content-xl-center {
        -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important
    }

    .justify-content-xl-between {
        -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important
    }

    .justify-content-xl-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important
    }

    .align-items-xl-start {
        -webkit-box-align: start !important;
        -ms-flex-align: start !important;
        align-items: flex-start !important
    }

    .align-items-xl-end {
        -webkit-box-align: end !important;
        -ms-flex-align: end !important;
        align-items: flex-end !important
    }

    .align-items-xl-center {
        -webkit-box-align: center !important;
        -ms-flex-align: center !important;
        align-items: center !important
    }

    .align-items-xl-baseline {
        -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
        align-items: baseline !important
    }

    .align-items-xl-stretch {
        -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
        align-items: stretch !important
    }

    .align-content-xl-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important
    }

    .align-content-xl-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important
    }

    .align-content-xl-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important
    }

    .align-content-xl-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important
    }

    .align-content-xl-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important
    }

    .align-content-xl-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important
    }

    .align-self-xl-auto {
        -webkit-align-self: auto !important;
        -ms-grid-row-align: auto !important;
        -ms-flex-item-align: auto !important;
        align-self: auto !important
    }

    .align-self-xl-start {
        -webkit-align-self: flex-start !important;
        -ms-flex-item-align: start !important;
        align-self: flex-start !important
    }

    .align-self-xl-end {
        -webkit-align-self: flex-end !important;
        -ms-flex-item-align: end !important;
        align-self: flex-end !important
    }

    .align-self-xl-center {
        -webkit-align-self: center !important;
        -ms-grid-row-align: center !important;
        -ms-flex-item-align: center !important;
        align-self: center !important
    }

    .align-self-xl-baseline {
        -webkit-align-self: baseline !important;
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important
    }

    .align-self-xl-stretch {
        -webkit-align-self: stretch !important;
        -ms-grid-row-align: stretch !important;
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important
    }
}

.float-left {
    float: left !important
}

.float-right {
    float: right !important
}

.float-none {
    float: none !important
}

@media (min-width:576px) {
    .float-sm-left {
        float: left !important
    }

    .float-sm-right {
        float: right !important
    }

    .float-sm-none {
        float: none !important
    }
}

@media (min-width:768px) {
    .float-md-left {
        float: left !important
    }

    .float-md-right {
        float: right !important
    }

    .float-md-none {
        float: none !important
    }
}

@media (min-width:992px) {
    .float-lg-left {
        float: left !important
    }

    .float-lg-right {
        float: right !important
    }

    .float-lg-none {
        float: none !important
    }
}

@media (min-width:1200px) {
    .float-xl-left {
        float: left !important
    }

    .float-xl-right {
        float: right !important
    }

    .float-xl-none {
        float: none !important
    }
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030
}

.sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1030
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto
}

.w-25 {
    width: 25% !important
}

.w-50 {
    width: 50% !important
}

.w-75 {
    width: 75% !important
}

.w-100 {
    width: 100% !important
}

.h-25 {
    height: 25% !important
}

.h-50 {
    height: 50% !important
}

.h-75 {
    height: 75% !important
}

.h-100 {
    height: 100% !important
}

.mw-100 {
    max-width: 100% !important
}

.mh-100 {
    max-height: 100% !important
}

.m-0 {
    margin: 0 0 !important
}

.mt-0 {
    margin-top: 0 !important
}

.mr-0 {
    margin-right: 0 !important
}

.mb-0 {
    margin-bottom: 0 !important
}

.ml-0 {
    margin-left: 0 !important
}

.mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important
}

.my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important
}

.m-1 {
    margin: .25rem .25rem !important
}

.mt-1 {
    margin-top: .25rem !important
}

.mr-1 {
    margin-right: .25rem !important
}

.mb-1 {
    margin-bottom: .25rem !important
}

.ml-1 {
    margin-left: .25rem !important
}

.mx-1 {
    margin-right: .25rem !important;
    margin-left: .25rem !important
}

.my-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important
}

.m-2 {
    margin: .5rem .5rem !important
}

.mt-2 {
    margin-top: .5rem !important
}

.mr-2 {
    margin-right: .5rem !important
}

.mb-2 {
    margin-bottom: .5rem !important
}

.ml-2 {
    margin-left: .5rem !important
}

.mx-2 {
    margin-right: .5rem !important;
    margin-left: .5rem !important
}

.my-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important
}

.m-3 {
    margin: 1rem 1rem !important
}

.mt-3 {
    margin-top: 1rem !important
}

.mr-3 {
    margin-right: 1rem !important
}

.mb-3 {
    margin-bottom: 1rem !important
}

.ml-3 {
    margin-left: 1rem !important
}

.mx-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important
}

.my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important
}

.m-4 {
    margin: 1.5rem 1.5rem !important
}

.mt-4 {
    margin-top: 1.5rem !important
}

.mr-4 {
    margin-right: 1.5rem !important
}

.mb-4 {
    margin-bottom: 1.5rem !important
}

.ml-4 {
    margin-left: 1.5rem !important
}

.mx-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important
}

.my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important
}

.m-5 {
    margin: 3rem 3rem !important
}

.mt-5 {
    margin-top: 3rem !important
}

.mr-5 {
    margin-right: 3rem !important
}

.mb-5 {
    margin-bottom: 3rem !important
}

.ml-5 {
    margin-left: 3rem !important
}

.mx-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important
}

.my-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important
}

.p-0 {
    padding: 0 0 !important
}

.pt-0 {
    padding-top: 0 !important
}

.pr-0 {
    padding-right: 0 !important
}

.pb-0 {
    padding-bottom: 0 !important
}

.pl-0 {
    padding-left: 0 !important
}

.px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important
}

.py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important
}

.p-1 {
    padding: .25rem .25rem !important
}

.pt-1 {
    padding-top: .25rem !important
}

.pr-1 {
    padding-right: .25rem !important
}

.pb-1 {
    padding-bottom: .25rem !important
}

.pl-1 {
    padding-left: .25rem !important
}

.px-1 {
    padding-right: .25rem !important;
    padding-left: .25rem !important
}

.py-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important
}

.p-2 {
    padding: .5rem .5rem !important
}

.pt-2 {
    padding-top: .5rem !important
}

.pr-2 {
    padding-right: .5rem !important
}

.pb-2 {
    padding-bottom: .5rem !important
}

.pl-2 {
    padding-left: .5rem !important
}

.px-2 {
    padding-right: .5rem !important;
    padding-left: .5rem !important
}

.py-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important
}

.p-3 {
    padding: 1rem 1rem !important
}

.pt-3 {
    padding-top: 1rem !important
}

.pr-3 {
    padding-right: 1rem !important
}

.pb-3 {
    padding-bottom: 1rem !important
}

.pl-3 {
    padding-left: 1rem !important
}

.px-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important
}

.py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important
}

.p-4 {
    padding: 1.5rem 1.5rem !important
}

.pt-4 {
    padding-top: 1.5rem !important
}

.pr-4 {
    padding-right: 1.5rem !important
}

.pb-4 {
    padding-bottom: 1.5rem !important
}

.pl-4 {
    padding-left: 1.5rem !important
}

.px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important
}

.py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important
}

.p-5 {
    padding: 3rem 3rem !important
}

.pt-5 {
    padding-top: 3rem !important
}

.pr-5 {
    padding-right: 3rem !important
}

.pb-5 {
    padding-bottom: 3rem !important
}

.pl-5 {
    padding-left: 3rem !important
}

.px-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important
}

.py-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important
}

.m-auto {
    margin: auto !important
}

.mt-auto {
    margin-top: auto !important
}

.mr-auto {
    margin-right: auto !important
}

.mb-auto {
    margin-bottom: auto !important
}

.ml-auto {
    margin-left: auto !important
}

.mx-auto {
    margin-right: auto !important;
    margin-left: auto !important
}

.my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important
}

@media (min-width:576px) {
    .m-sm-0 {
        margin: 0 0 !important
    }

    .mt-sm-0 {
        margin-top: 0 !important
    }

    .mr-sm-0 {
        margin-right: 0 !important
    }

    .mb-sm-0 {
        margin-bottom: 0 !important
    }

    .ml-sm-0 {
        margin-left: 0 !important
    }

    .mx-sm-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }

    .my-sm-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .m-sm-1 {
        margin: .25rem .25rem !important
    }

    .mt-sm-1 {
        margin-top: .25rem !important
    }

    .mr-sm-1 {
        margin-right: .25rem !important
    }

    .mb-sm-1 {
        margin-bottom: .25rem !important
    }

    .ml-sm-1 {
        margin-left: .25rem !important
    }

    .mx-sm-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }

    .my-sm-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }

    .m-sm-2 {
        margin: .5rem .5rem !important
    }

    .mt-sm-2 {
        margin-top: .5rem !important
    }

    .mr-sm-2 {
        margin-right: .5rem !important
    }

    .mb-sm-2 {
        margin-bottom: .5rem !important
    }

    .ml-sm-2 {
        margin-left: .5rem !important
    }

    .mx-sm-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }

    .my-sm-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .m-sm-3 {
        margin: 1rem 1rem !important
    }

    .mt-sm-3 {
        margin-top: 1rem !important
    }

    .mr-sm-3 {
        margin-right: 1rem !important
    }

    .mb-sm-3 {
        margin-bottom: 1rem !important
    }

    .ml-sm-3 {
        margin-left: 1rem !important
    }

    .mx-sm-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }

    .my-sm-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .m-sm-4 {
        margin: 1.5rem 1.5rem !important
    }

    .mt-sm-4 {
        margin-top: 1.5rem !important
    }

    .mr-sm-4 {
        margin-right: 1.5rem !important
    }

    .mb-sm-4 {
        margin-bottom: 1.5rem !important
    }

    .ml-sm-4 {
        margin-left: 1.5rem !important
    }

    .mx-sm-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }

    .my-sm-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .m-sm-5 {
        margin: 3rem 3rem !important
    }

    .mt-sm-5 {
        margin-top: 3rem !important
    }

    .mr-sm-5 {
        margin-right: 3rem !important
    }

    .mb-sm-5 {
        margin-bottom: 3rem !important
    }

    .ml-sm-5 {
        margin-left: 3rem !important
    }

    .mx-sm-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }

    .my-sm-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }

    .p-sm-0 {
        padding: 0 0 !important
    }

    .pt-sm-0 {
        padding-top: 0 !important
    }

    .pr-sm-0 {
        padding-right: 0 !important
    }

    .pb-sm-0 {
        padding-bottom: 0 !important
    }

    .pl-sm-0 {
        padding-left: 0 !important
    }

    .px-sm-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }

    .py-sm-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .p-sm-1 {
        padding: .25rem .25rem !important
    }

    .pt-sm-1 {
        padding-top: .25rem !important
    }

    .pr-sm-1 {
        padding-right: .25rem !important
    }

    .pb-sm-1 {
        padding-bottom: .25rem !important
    }

    .pl-sm-1 {
        padding-left: .25rem !important
    }

    .px-sm-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }

    .py-sm-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }

    .p-sm-2 {
        padding: .5rem .5rem !important
    }

    .pt-sm-2 {
        padding-top: .5rem !important
    }

    .pr-sm-2 {
        padding-right: .5rem !important
    }

    .pb-sm-2 {
        padding-bottom: .5rem !important
    }

    .pl-sm-2 {
        padding-left: .5rem !important
    }

    .px-sm-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }

    .py-sm-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .p-sm-3 {
        padding: 1rem 1rem !important
    }

    .pt-sm-3 {
        padding-top: 1rem !important
    }

    .pr-sm-3 {
        padding-right: 1rem !important
    }

    .pb-sm-3 {
        padding-bottom: 1rem !important
    }

    .pl-sm-3 {
        padding-left: 1rem !important
    }

    .px-sm-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }

    .py-sm-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .p-sm-4 {
        padding: 1.5rem 1.5rem !important
    }

    .pt-sm-4 {
        padding-top: 1.5rem !important
    }

    .pr-sm-4 {
        padding-right: 1.5rem !important
    }

    .pb-sm-4 {
        padding-bottom: 1.5rem !important
    }

    .pl-sm-4 {
        padding-left: 1.5rem !important
    }

    .px-sm-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }

    .py-sm-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .p-sm-5 {
        padding: 3rem 3rem !important
    }

    .pt-sm-5 {
        padding-top: 3rem !important
    }

    .pr-sm-5 {
        padding-right: 3rem !important
    }

    .pb-sm-5 {
        padding-bottom: 3rem !important
    }

    .pl-sm-5 {
        padding-left: 3rem !important
    }

    .px-sm-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }

    .py-sm-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }

    .m-sm-auto {
        margin: auto !important
    }

    .mt-sm-auto {
        margin-top: auto !important
    }

    .mr-sm-auto {
        margin-right: auto !important
    }

    .mb-sm-auto {
        margin-bottom: auto !important
    }

    .ml-sm-auto {
        margin-left: auto !important
    }

    .mx-sm-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-sm-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }
}

@media (min-width:768px) {
    .m-md-0 {
        margin: 0 0 !important
    }

    .mt-md-0 {
        margin-top: 0 !important
    }

    .mr-md-0 {
        margin-right: 0 !important
    }

    .mb-md-0 {
        margin-bottom: 0 !important
    }

    .ml-md-0 {
        margin-left: 0 !important
    }

    .mx-md-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }

    .my-md-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .m-md-1 {
        margin: .25rem .25rem !important
    }

    .mt-md-1 {
        margin-top: .25rem !important
    }

    .mr-md-1 {
        margin-right: .25rem !important
    }

    .mb-md-1 {
        margin-bottom: .25rem !important
    }

    .ml-md-1 {
        margin-left: .25rem !important
    }

    .mx-md-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }

    .my-md-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }

    .m-md-2 {
        margin: .5rem .5rem !important
    }

    .mt-md-2 {
        margin-top: .5rem !important
    }

    .mr-md-2 {
        margin-right: .5rem !important
    }

    .mb-md-2 {
        margin-bottom: .5rem !important
    }

    .ml-md-2 {
        margin-left: .5rem !important
    }

    .mx-md-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }

    .my-md-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .m-md-3 {
        margin: 1rem 1rem !important
    }

    .mt-md-3 {
        margin-top: 1rem !important
    }

    .mr-md-3 {
        margin-right: 1rem !important
    }

    .mb-md-3 {
        margin-bottom: 1rem !important
    }

    .ml-md-3 {
        margin-left: 1rem !important
    }

    .mx-md-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }

    .my-md-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .m-md-4 {
        margin: 1.5rem 1.5rem !important
    }

    .mt-md-4 {
        margin-top: 1.5rem !important
    }

    .mr-md-4 {
        margin-right: 1.5rem !important
    }

    .mb-md-4 {
        margin-bottom: 1.5rem !important
    }

    .ml-md-4 {
        margin-left: 1.5rem !important
    }

    .mx-md-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }

    .my-md-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .m-md-5 {
        margin: 3rem 3rem !important
    }

    .mt-md-5 {
        margin-top: 3rem !important
    }

    .mr-md-5 {
        margin-right: 3rem !important
    }

    .mb-md-5 {
        margin-bottom: 3rem !important
    }

    .ml-md-5 {
        margin-left: 3rem !important
    }

    .mx-md-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }

    .my-md-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }

    .p-md-0 {
        padding: 0 0 !important
    }

    .pt-md-0 {
        padding-top: 0 !important
    }

    .pr-md-0 {
        padding-right: 0 !important
    }

    .pb-md-0 {
        padding-bottom: 0 !important
    }

    .pl-md-0 {
        padding-left: 0 !important
    }

    .px-md-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }

    .py-md-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .p-md-1 {
        padding: .25rem .25rem !important
    }

    .pt-md-1 {
        padding-top: .25rem !important
    }

    .pr-md-1 {
        padding-right: .25rem !important
    }

    .pb-md-1 {
        padding-bottom: .25rem !important
    }

    .pl-md-1 {
        padding-left: .25rem !important
    }

    .px-md-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }

    .py-md-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }

    .p-md-2 {
        padding: .5rem .5rem !important
    }

    .pt-md-2 {
        padding-top: .5rem !important
    }

    .pr-md-2 {
        padding-right: .5rem !important
    }

    .pb-md-2 {
        padding-bottom: .5rem !important
    }

    .pl-md-2 {
        padding-left: .5rem !important
    }

    .px-md-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }

    .py-md-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .p-md-3 {
        padding: 1rem 1rem !important
    }

    .pt-md-3 {
        padding-top: 1rem !important
    }

    .pr-md-3 {
        padding-right: 1rem !important
    }

    .pb-md-3 {
        padding-bottom: 1rem !important
    }

    .pl-md-3 {
        padding-left: 1rem !important
    }

    .px-md-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }

    .py-md-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .p-md-4 {
        padding: 1.5rem 1.5rem !important
    }

    .pt-md-4 {
        padding-top: 1.5rem !important
    }

    .pr-md-4 {
        padding-right: 1.5rem !important
    }

    .pb-md-4 {
        padding-bottom: 1.5rem !important
    }

    .pl-md-4 {
        padding-left: 1.5rem !important
    }

    .px-md-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }

    .py-md-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .p-md-5 {
        padding: 3rem 3rem !important
    }

    .pt-md-5 {
        padding-top: 3rem !important
    }

    .pr-md-5 {
        padding-right: 3rem !important
    }

    .pb-md-5 {
        padding-bottom: 3rem !important
    }

    .pl-md-5 {
        padding-left: 3rem !important
    }

    .px-md-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }

    .py-md-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }

    .m-md-auto {
        margin: auto !important
    }

    .mt-md-auto {
        margin-top: auto !important
    }

    .mr-md-auto {
        margin-right: auto !important
    }

    .mb-md-auto {
        margin-bottom: auto !important
    }

    .ml-md-auto {
        margin-left: auto !important
    }

    .mx-md-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-md-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }
}

@media (min-width:992px) {
    .m-lg-0 {
        margin: 0 0 !important
    }

    .mt-lg-0 {
        margin-top: 0 !important
    }

    .mr-lg-0 {
        margin-right: 0 !important
    }

    .mb-lg-0 {
        margin-bottom: 0 !important
    }

    .ml-lg-0 {
        margin-left: 0 !important
    }

    .mx-lg-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }

    .my-lg-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .m-lg-1 {
        margin: .25rem .25rem !important
    }

    .mt-lg-1 {
        margin-top: .25rem !important
    }

    .mr-lg-1 {
        margin-right: .25rem !important
    }

    .mb-lg-1 {
        margin-bottom: .25rem !important
    }

    .ml-lg-1 {
        margin-left: .25rem !important
    }

    .mx-lg-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }

    .my-lg-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }

    .m-lg-2 {
        margin: .5rem .5rem !important
    }

    .mt-lg-2 {
        margin-top: .5rem !important
    }

    .mr-lg-2 {
        margin-right: .5rem !important
    }

    .mb-lg-2 {
        margin-bottom: .5rem !important
    }

    .ml-lg-2 {
        margin-left: .5rem !important
    }

    .mx-lg-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }

    .my-lg-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .m-lg-3 {
        margin: 1rem 1rem !important
    }

    .mt-lg-3 {
        margin-top: 1rem !important
    }

    .mr-lg-3 {
        margin-right: 1rem !important
    }

    .mb-lg-3 {
        margin-bottom: 1rem !important
    }

    .ml-lg-3 {
        margin-left: 1rem !important
    }

    .mx-lg-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }

    .my-lg-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .m-lg-4 {
        margin: 1.5rem 1.5rem !important
    }

    .mt-lg-4 {
        margin-top: 1.5rem !important
    }

    .mr-lg-4 {
        margin-right: 1.5rem !important
    }

    .mb-lg-4 {
        margin-bottom: 1.5rem !important
    }

    .ml-lg-4 {
        margin-left: 1.5rem !important
    }

    .mx-lg-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }

    .my-lg-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .m-lg-5 {
        margin: 3rem 3rem !important
    }

    .mt-lg-5 {
        margin-top: 3rem !important
    }

    .mr-lg-5 {
        margin-right: 3rem !important
    }

    .mb-lg-5 {
        margin-bottom: 3rem !important
    }

    .ml-lg-5 {
        margin-left: 3rem !important
    }

    .mx-lg-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }

    .my-lg-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }

    .p-lg-0 {
        padding: 0 0 !important
    }

    .pt-lg-0 {
        padding-top: 0 !important
    }

    .pr-lg-0 {
        padding-right: 0 !important
    }

    .pb-lg-0 {
        padding-bottom: 0 !important
    }

    .pl-lg-0 {
        padding-left: 0 !important
    }

    .px-lg-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }

    .py-lg-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .p-lg-1 {
        padding: .25rem .25rem !important
    }

    .pt-lg-1 {
        padding-top: .25rem !important
    }

    .pr-lg-1 {
        padding-right: .25rem !important
    }

    .pb-lg-1 {
        padding-bottom: .25rem !important
    }

    .pl-lg-1 {
        padding-left: .25rem !important
    }

    .px-lg-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }

    .py-lg-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }

    .p-lg-2 {
        padding: .5rem .5rem !important
    }

    .pt-lg-2 {
        padding-top: .5rem !important
    }

    .pr-lg-2 {
        padding-right: .5rem !important
    }

    .pb-lg-2 {
        padding-bottom: .5rem !important
    }

    .pl-lg-2 {
        padding-left: .5rem !important
    }

    .px-lg-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }

    .py-lg-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .p-lg-3 {
        padding: 1rem 1rem !important
    }

    .pt-lg-3 {
        padding-top: 1rem !important
    }

    .pr-lg-3 {
        padding-right: 1rem !important
    }

    .pb-lg-3 {
        padding-bottom: 1rem !important
    }

    .pl-lg-3 {
        padding-left: 1rem !important
    }

    .px-lg-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }

    .py-lg-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .p-lg-4 {
        padding: 1.5rem 1.5rem !important
    }

    .pt-lg-4 {
        padding-top: 1.5rem !important
    }

    .pr-lg-4 {
        padding-right: 1.5rem !important
    }

    .pb-lg-4 {
        padding-bottom: 1.5rem !important
    }

    .pl-lg-4 {
        padding-left: 1.5rem !important
    }

    .px-lg-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }

    .py-lg-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .p-lg-5 {
        padding: 3rem 3rem !important
    }

    .pt-lg-5 {
        padding-top: 3rem !important
    }

    .pr-lg-5 {
        padding-right: 3rem !important
    }

    .pb-lg-5 {
        padding-bottom: 3rem !important
    }

    .pl-lg-5 {
        padding-left: 3rem !important
    }

    .px-lg-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }

    .py-lg-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }

    .m-lg-auto {
        margin: auto !important
    }

    .mt-lg-auto {
        margin-top: auto !important
    }

    .mr-lg-auto {
        margin-right: auto !important
    }

    .mb-lg-auto {
        margin-bottom: auto !important
    }

    .ml-lg-auto {
        margin-left: auto !important
    }

    .mx-lg-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-lg-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }
}

@media (min-width:1200px) {
    .m-xl-0 {
        margin: 0 0 !important
    }

    .mt-xl-0 {
        margin-top: 0 !important
    }

    .mr-xl-0 {
        margin-right: 0 !important
    }

    .mb-xl-0 {
        margin-bottom: 0 !important
    }

    .ml-xl-0 {
        margin-left: 0 !important
    }

    .mx-xl-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }

    .my-xl-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .m-xl-1 {
        margin: .25rem .25rem !important
    }

    .mt-xl-1 {
        margin-top: .25rem !important
    }

    .mr-xl-1 {
        margin-right: .25rem !important
    }

    .mb-xl-1 {
        margin-bottom: .25rem !important
    }

    .ml-xl-1 {
        margin-left: .25rem !important
    }

    .mx-xl-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }

    .my-xl-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }

    .m-xl-2 {
        margin: .5rem .5rem !important
    }

    .mt-xl-2 {
        margin-top: .5rem !important
    }

    .mr-xl-2 {
        margin-right: .5rem !important
    }

    .mb-xl-2 {
        margin-bottom: .5rem !important
    }

    .ml-xl-2 {
        margin-left: .5rem !important
    }

    .mx-xl-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }

    .my-xl-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .m-xl-3 {
        margin: 1rem 1rem !important
    }

    .mt-xl-3 {
        margin-top: 1rem !important
    }

    .mr-xl-3 {
        margin-right: 1rem !important
    }

    .mb-xl-3 {
        margin-bottom: 1rem !important
    }

    .ml-xl-3 {
        margin-left: 1rem !important
    }

    .mx-xl-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }

    .my-xl-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .m-xl-4 {
        margin: 1.5rem 1.5rem !important
    }

    .mt-xl-4 {
        margin-top: 1.5rem !important
    }

    .mr-xl-4 {
        margin-right: 1.5rem !important
    }

    .mb-xl-4 {
        margin-bottom: 1.5rem !important
    }

    .ml-xl-4 {
        margin-left: 1.5rem !important
    }

    .mx-xl-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }

    .my-xl-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .m-xl-5 {
        margin: 3rem 3rem !important
    }

    .mt-xl-5 {
        margin-top: 3rem !important
    }

    .mr-xl-5 {
        margin-right: 3rem !important
    }

    .mb-xl-5 {
        margin-bottom: 3rem !important
    }

    .ml-xl-5 {
        margin-left: 3rem !important
    }

    .mx-xl-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }

    .my-xl-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }

    .p-xl-0 {
        padding: 0 0 !important
    }

    .pt-xl-0 {
        padding-top: 0 !important
    }

    .pr-xl-0 {
        padding-right: 0 !important
    }

    .pb-xl-0 {
        padding-bottom: 0 !important
    }

    .pl-xl-0 {
        padding-left: 0 !important
    }

    .px-xl-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }

    .py-xl-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .p-xl-1 {
        padding: .25rem .25rem !important
    }

    .pt-xl-1 {
        padding-top: .25rem !important
    }

    .pr-xl-1 {
        padding-right: .25rem !important
    }

    .pb-xl-1 {
        padding-bottom: .25rem !important
    }

    .pl-xl-1 {
        padding-left: .25rem !important
    }

    .px-xl-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }

    .py-xl-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }

    .p-xl-2 {
        padding: .5rem .5rem !important
    }

    .pt-xl-2 {
        padding-top: .5rem !important
    }

    .pr-xl-2 {
        padding-right: .5rem !important
    }

    .pb-xl-2 {
        padding-bottom: .5rem !important
    }

    .pl-xl-2 {
        padding-left: .5rem !important
    }

    .px-xl-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }

    .py-xl-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .p-xl-3 {
        padding: 1rem 1rem !important
    }

    .pt-xl-3 {
        padding-top: 1rem !important
    }

    .pr-xl-3 {
        padding-right: 1rem !important
    }

    .pb-xl-3 {
        padding-bottom: 1rem !important
    }

    .pl-xl-3 {
        padding-left: 1rem !important
    }

    .px-xl-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }

    .py-xl-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .p-xl-4 {
        padding: 1.5rem 1.5rem !important
    }

    .pt-xl-4 {
        padding-top: 1.5rem !important
    }

    .pr-xl-4 {
        padding-right: 1.5rem !important
    }

    .pb-xl-4 {
        padding-bottom: 1.5rem !important
    }

    .pl-xl-4 {
        padding-left: 1.5rem !important
    }

    .px-xl-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }

    .py-xl-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .p-xl-5 {
        padding: 3rem 3rem !important
    }

    .pt-xl-5 {
        padding-top: 3rem !important
    }

    .pr-xl-5 {
        padding-right: 3rem !important
    }

    .pb-xl-5 {
        padding-bottom: 3rem !important
    }

    .pl-xl-5 {
        padding-left: 3rem !important
    }

    .px-xl-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }

    .py-xl-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }

    .m-xl-auto {
        margin: auto !important
    }

    .mt-xl-auto {
        margin-top: auto !important
    }

    .mr-xl-auto {
        margin-right: auto !important
    }

    .mb-xl-auto {
        margin-bottom: auto !important
    }

    .ml-xl-auto {
        margin-left: auto !important
    }

    .mx-xl-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-xl-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }
}

.text-justify {
    text-align: justify !important
}

.text-nowrap {
    white-space: nowrap !important
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.text-left {
    text-align: left !important
}

.text-right {
    text-align: right !important
}

.text-center {
    text-align: center !important
}

@media (min-width:576px) {
    .text-sm-left {
        text-align: left !important
    }

    .text-sm-right {
        text-align: right !important
    }

    .text-sm-center {
        text-align: center !important
    }
}

@media (min-width:768px) {
    .text-md-left {
        text-align: left !important
    }

    .text-md-right {
        text-align: right !important
    }

    .text-md-center {
        text-align: center !important
    }
}

@media (min-width:992px) {
    .text-lg-left {
        text-align: left !important
    }

    .text-lg-right {
        text-align: right !important
    }

    .text-lg-center {
        text-align: center !important
    }
}

@media (min-width:1200px) {
    .text-xl-left {
        text-align: left !important
    }

    .text-xl-right {
        text-align: right !important
    }

    .text-xl-center {
        text-align: center !important
    }
}

.text-lowercase {
    text-transform: lowercase !important
}

.text-uppercase {
    text-transform: uppercase !important
}

.text-capitalize {
    text-transform: capitalize !important
}

.font-weight-normal {
    font-weight: 400
}

.font-weight-bold {
    font-weight: 700
}

.font-italic {
    font-style: italic
}

.text-white {
    color: #fff !important
}

.text-muted {
    color: #636c72 !important
}

a.text-muted:focus,
a.text-muted:hover {
    color: #4b5257 !important
}

.text-primary {
    color: #0275d8 !important
}

a.text-primary:focus,
a.text-primary:hover {
    color: #025aa5 !important
}

.text-success {
    color: #5cb85c !important
}

a.text-success:focus,
a.text-success:hover {
    color: #449d44 !important
}

.text-info {
    color: #5bc0de !important
}

a.text-info:focus,
a.text-info:hover {
    color: #31b0d5 !important
}

.text-warning {
    color: #f0ad4e !important
}

a.text-warning:focus,
a.text-warning:hover {
    color: #ec971f !important
}

.text-danger {
    color: #d9534f !important
}

a.text-danger:focus,
a.text-danger:hover {
    color: #c9302c !important
}

.text-gray-dark {
    color: #292b2c !important
}

a.text-gray-dark:focus,
a.text-gray-dark:hover {
    color: #101112 !important
}

.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0
}

.invisible {
    visibility: hidden !important
}

.hidden-xs-up {
    display: none !important
}

@media (max-width:575px) {
    .hidden-xs-down {
        display: none !important
    }
}

@media (min-width:576px) {
    .hidden-sm-up {
        display: none !important
    }
}

@media (max-width:767px) {
    .hidden-sm-down {
        display: none !important
    }
}

@media (min-width:768px) {
    .hidden-md-up {
        display: none !important
    }
}

@media (max-width:991px) {
    .hidden-md-down {
        display: none !important
    }
}

@media (min-width:992px) {
    .hidden-lg-up {
        display: none !important
    }
}

@media (max-width:1199px) {
    .hidden-lg-down {
        display: none !important
    }
}

@media (min-width:1200px) {
    .hidden-xl-up {
        display: none !important
    }
}

.hidden-xl-down {
    display: none !important
}

.visible-print-block {
    display: none !important
}

@media print {
    .visible-print-block {
        display: block !important
    }
}

.visible-print-inline {
    display: none !important
}

@media print {
    .visible-print-inline {
        display: inline !important
    }
}

.visible-print-inline-block {
    display: none !important
}

@media print {
    .visible-print-inline-block {
        display: inline-block !important
    }
}

@media print {
    .hidden-print {
        display: none !important
    }
}

html,
body {
    margin: 0;
    padding: 0;
    height: 100%
}

html {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-size: 16px
}

@media (max-width:991px) {
    html {
        font-size: 15px
    }
}

@media (max-width:767px) {
    html {
        font-size: 14px
    }
}

body {
    background: #fff;
    height: 100%
}

.site {
    background: #FFF;
    position: relative
}

body,
button,
input,
select,
textarea {
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-size: .875rem;
    line-height: 1.7;
    color: #777
}

pre,
code,
input,
textarea {
    font: inherit
}

::-moz-selection {
    background: #000;
    color: #FFF
}

::selection {
    background: #000;
    color: #FFF
}

a {
    color: #03c4eb;
    text-decoration: none;
    outline: none
}

a:hover {
    text-decoration: none;
    color: #777;
    text-decoration: underline
}

a:active,
a:focus,
a:hover {
    outline: none
}

h1,
h2,
h3,
h4,
h5,
h6 {
    clear: both;
    font-family: "Raleway", Helvetica, Arial, sans-serif;
    font-weight: 600;
    margin-bottom: 15px;
    margin-bottom: 15px;
    margin-bottom: .9375rem;
    margin-top: 0;
    color: #333
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    color: #333;
    text-decoration: none
}

h1 {
    line-height: 1.3;
    font-size: 33px;
    font-size: 2.0625rem
}

@media (min-width:768px) {
    h1 {
        font-size: 40px;
        font-size: 2.5rem
    }
}

h1 span {
    font-weight: 700
}

h2 {
    line-height: 1.2;
    font-size: 25px;
    font-size: 1.5625rem
}

@media (min-width:768px) {
    h2 {
        font-size: 32px;
        font-size: 2rem
    }
}

h3 {
    font-size: 20px;
    font-size: 1.25rem;
    font-weight: 600
}

h4 {
    font-size: 17px;
    font-size: 1.0625rem;
    margin-bottom: 12px
}

h5 {
    text-transform: uppercase;
    font-size: 15px;
    font-size: .9375rem;
    font-weight: 700
}

h6 {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 12px;
    font-size: .75rem;
    letter-spacing: 1px
}

ul,
ol,
dl,
p,
details,
address,
.vcard,
figure,
pre,
fieldset,
table,
dt,
dd,
hr {
    margin-bottom: 15px;
    margin-bottom: .9375rem;
    margin-top: 0
}

img {
    height: auto;
    max-width: 100%;
    vertical-align: middle
}

b,
strong {
    font-weight: 700
}

blockquote {
    clear: both;
    margin: 20px 0
}

blockquote p {
    font-style: italic
}

blockquote cite {
    font-style: normal;
    margin-bottom: 20px;
    font-size: 13px
}

dfn,
cite,
em,
i {
    font-style: italic
}

figure {
    margin: 0
}

address {
    margin: 20px 0
}

hr {
    border: 0;
    border-top: 1px solid #e9e9e9;
    height: 1px;
    margin-bottom: 20px
}

tt,
kbd,
pre,
code,
samp,
var {
    font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
    background-color: #e9e9e9;
    padding: 5px 7px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px
}

pre {
    overflow: auto;
    white-space: pre-wrap;
    max-width: 100%;
    line-height: 1.7;
    margin: 20px 0;
    padding: 20px
}

details summary {
    font-weight: 700;
    margin-bottom: 20px
}

details :focus {
    outline: none
}

abbr,
acronym,
dfn {
    cursor: help;
    font-size: .95em;
    text-transform: uppercase;
    border-bottom: 1px dotted #e9e9e9;
    letter-spacing: 1px
}

mark {
    background-color: #fff9c0;
    text-decoration: none
}

small {
    font-size: 82%
}

big {
    font-size: 125%
}

ul,
ol {
    padding-left: 20px
}

ul {
    list-style: disc
}

ol {
    list-style: decimal
}

ul li,
ol li {
    margin: 8px 0
}

dt {
    font-weight: 700
}

dd {
    margin: 0 20px 20px
}

table {
    width: 100%;
    margin-bottom: 20px;
    border: 1px solid #e9e9e9;
    border-collapse: collapse;
    border-spacing: 0
}

table>thead>tr>th,
table>tbody>tr>th,
table>tfoot>tr>th,
table>thead>tr>td,
table>tbody>tr>td,
table>tfoot>tr>td {
    border: 1px solid #e9e9e9;
    line-height: 1.42857;
    padding: 5px;
    vertical-align: middle
}

table>thead>tr>th,
table>thead>tr>td {
    border-bottom-width: 2px
}

table th {
    font-size: 14px;
    letter-spacing: 2px;
    text-transform: uppercase
}

fieldset {
    padding: 20px;
    border: 1px solid #e9e9e9
}

input[type="reset"],
input[type="submit"],
input[type="submit"],
.pirate-forms-submit-button,
.contact-form div.wpforms-container-full .wpforms-form .wpforms-submit {
    cursor: pointer;
    background: #03c4eb;
    border: none;
    display: inline-block;
    color: #FFF;
    letter-spacing: 1px;
    text-transform: uppercase;
    line-height: 1;
    text-align: center;
    padding: 15px 23px 15px 23px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    box-shadow: 0 -1px 0 0 rgba(0, 0, 0, .1) inset;
    margin: 3px 0;
    text-decoration: none;
    font-weight: 600;
    font-size: 13px
}

input[type="reset"]:hover,
input[type="submit"]:hover,
input[type="submit"]:hover,
.pirate-forms-submit-button:hover,
.contact-form div.wpforms-container-full .wpforms-form .wpforms-submit:hover {
    opacity: .8;
    background: #03c4eb;
    border: none
}

input[type="button"]:hover,
input[type="button"]:focus,
input[type="reset"]:hover,
input[type="reset"]:focus,
input[type="submit"]:hover,
input[type="submit"]:focus,
button:hover,
button:focus {
    cursor: pointer
}

textarea {
    resize: vertical
}

select {
    max-width: 100%;
    overflow: auto;
    vertical-align: top;
    outline: none;
    border: 1px solid #e9e9e9;
    padding: 10px
}

textarea,
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"] {
    padding: 10px;
    max-width: 100%;
    border: 0;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .12) inset;
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, .12) inset;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .12) inset;
    -o-box-shadow: 0 1px 2px rgba(0, 0, 0, .12) inset;
    transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    background-color: #f2f2f2;
    border-bottom: 1px solid #fff;
    box-sizing: border-box;
    color: #000
}

textarea:focus,
input[type="date"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="email"]:focus,
input[type="month"]:focus,
input[type="number"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="text"]:focus,
input[type="time"]:focus,
input[type="url"]:focus,
input[type="week"]:focus {
    box-shadow: 0 1px 2px rgba(0, 0, 0, .12) inset;
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, .12) inset;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .12) inset;
    -o-box-shadow: 0 1px 2px rgba(0, 0, 0, .12) inset;
    transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    border-color: #EBEBEB;
    border-bottom: 1px solid #fff !important;
    background: #e9e9e9
}

button::-moz-focus-inner {
    border: 0;
    padding: 0
}

input[type="radio"],
input[type="checkbox"] {
    margin: 0 10px
}

.screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute !important
}

.screen-reader-text:hover,
.screen-reader-text:active,
.screen-reader-text:focus {
    background-color: #f8f9f9;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    clip: auto !important;
    color: #03c4eb;
    display: block;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 17px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000
}

.alignleft {
    display: inline;
    float: left;
    margin-right: 3.5em
}

.alignright {
    display: inline;
    float: right;
    margin-left: 3.5em
}

.aligncenter {
    clear: both;
    display: block;
    margin-left: auto;
    margin-right: auto
}

.clear:before,
.clear:after,
.entry-content:before,
.entry-content:after,
.comment-content:before,
.comment-content:after,
.site-header:before,
.site-header:after,
.site-content:before,
.site-content:after,
.site-footer:before,
.site-footer:after {
    content: "";
    display: table;
    clear: both
}

.clear:after,
.entry-content:after,
.comment-content:after,
.site-header:after,
.site-content:after,
.site-footer:after {
    clear: both
}

.infinite-scroll .posts-navigation,
.infinite-scroll.neverending .site-footer {
    display: none
}

.infinity-end.neverending .site-footer {
    display: block
}

.hide {
    display: none
}

.clearleft {
    clear: left
}

.break,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
dl,
blockquote,
pre {
    word-break: break-word;
    word-wrap: break-word
}

#primary {
    padding-bottom: 40px;
    padding-top: 40px
}

.template-fullwidth-stretched #primary,
.siteorigin-panels #primary {
    padding: 0
}

.template-fullwidth-stretched .entry-content,
.siteorigin-panels .entry-content {
    margin-bottom: 0
}

#secondary {
    padding-bottom: 40px;
    padding-top: 0
}

@media screen and (min-width:940px) {
    #secondary {
        padding-top: 40px
    }
}

.content-area {
    width: 100%;
    float: none
}

@media screen and (min-width:940px) {
    .content-area {
        float: left
    }
}

.sidebar {
    width: 100%;
    float: none
}

@media screen and (min-width:940px) {
    .sidebar {
        width: 25.2252252252%;
        float: left
    }
}

.no-sidebar .content-area {
    width: 100%
}

@media screen and (min-width:940px) {
    .left-sidebar .content-area {
        float: right;
        padding-left: 39px;
        border-left: 1px solid #e9e9e9;
        margin-right: 1px;
        width: 74.7747747748%
    }

    .left-sidebar .sidebar {
        padding-right: 39px;
        border-right: 1px solid #e9e9e9;
        margin-right: -1px
    }

    .right-sidebar .content-area {
        padding-right: 39px;
        border-right: 1px solid #e9e9e9;
        width: 74.7747747748%
    }

    .right-sidebar .sidebar {
        padding-left: 39px;
        border-left: 1px solid #e9e9e9;
        margin-left: -1px
    }
}

.footer-widgets {
    background: #050505
}

.footer-widgets.section-padding {
    padding: 70px 0 35px;
    padding: 4.375rem 0 2.1875rem
}

.footer-widgets table {
    border-collapse: collapse
}

.footer-widgets table>thead>tr>th,
.footer-widgets table>thead>tr>td {
    border-width: 1px
}

.footer-widgets table th,
.footer-widgets table td {
    border-color: rgba(233, 233, 233, .4)
}

.footer-widgets .widget-title {
    color: #fff
}

.footer-widgets .widget-area .widget_categories li,
.footer-widgets .widget-area .widget_archive li,
.footer-widgets .widget-area .widget_meta li {
    width: 100% !important;
    float: none
}

.footer-widgets .widget_recent_entries ul li,
.footer-widgets .widget_recent_comments ul li {
    border-bottom: 0 none;
    padding: 5px 0;
    margin-bottom: 0
}

.footer-widgets .sidebar .widget {
    margin-bottom: 30px
}

.site-header {
    line-height: 75px;
    position: relative;
    width: 100%;
    background: #FFF;
    z-index: 1000;
    -webkit-transition: all 1s ease-out;
    transition: all .5s ease-out;
    box-shadow: 0 5px 10px 0 rgba(50, 50, 50, .06)
}

.site-header.header-fixed {
    top: 0;
    left: 0;
    position: fixed
}

.site-header .custom-logo-transparent {
    display: none
}

.followWrap {
    display: block
}

.header-full-width .container {
    max-width: auto;
    width: auto
}

@media screen and (min-width:940px) {
    .header-full-width .container {
        padding-left: 30px;
        padding-right: 30px
    }
}

.is-transparent .followWrap {
    z-index: 99;
    width: 100%;
    background: transparent;
    position: absolute;
    top: 0;
    display: block
}

.is-transparent .site-header {
    background: transparent;
    position: fixed;
    top: 0
}

.is-transparent .site-header.no-sticky {
    position: absolute;
    top: auto
}

.is-transparent .site-header.no-scroll {
    box-shadow: none
}

.is-transparent .site-header.no-scroll .has-t-logo .custom-logo-transparent {
    display: inline-block
}

.is-transparent .site-header.no-scroll .has-t-logo .custom-logo {
    display: none
}

.is-transparent .site-header.no-scroll #nav-toggle span {
    background-color: #fff
}

.is-transparent .site-header.no-scroll #nav-toggle span::before,
.is-transparent .site-header.no-scroll #nav-toggle span::after {
    background-color: #fff
}

.is-transparent .site-header.no-scroll #nav-toggle.nav-is-visible span {
    background-color: transparent
}

.is-transparent .site-header.header-fixed {
    background: #FFF
}

.is-transparent .site-header.header-fixed.no-scroll {
    background: transparent;
    box-shadow: none
}

@media screen and (min-width:1140px) {
    .is-transparent .no-scroll.site-header .onepress-menu>li>a {
        color: rgba(255, 255, 255, .9)
    }
}

.is-transparent .hero-slideshow-fullscreen .hero__content {
    padding-top: 50px;
    padding-top: 3.125rem
}

.site-branding {
    float: left
}

.site-branding .site-title,
.site-branding .site-text-logo {
    margin: 0;
    line-height: 1;
    font-family: "Raleway", Helvetica, Arial, sans-serif;
    font-size: 23px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #000;
    display: initial;
    vertical-align: middle;
    font-weight: 600
}

.site-branding .site-title:hover,
.site-branding .site-text-logo:hover {
    text-decoration: none
}

.site-branding .site-title {
    display: inline-block
}

.site-branding .site-image-logo {
    display: block
}

.site-branding .site-logo-div {
    line-height: 75px
}

.site-branding .no-logo-img.has-desc,
.site-branding .has-logo-img {
    line-height: 60px
}

.site-branding .no-logo-img.has-desc .site-title,
.site-branding .has-logo-img .site-title {
    margin-top: -5px;
    position: relative
}

.site-branding .no-logo-img.has-desc .site-description,
.site-branding .has-logo-img .site-description {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 0;
    position: relative;
    top: -15px;
    color: #000
}

.home.header-transparent .no-scroll .site-brand-inner,
.home.header-transparent .no-scroll .site-text-logo {
    color: #fff
}

.header-social {
    margin-left: 0;
    float: right
}

@media screen and (min-width:940px) {
    .header-social {
        margin-left: 10px
    }
}

@media screen and (max-width:576px) {
    .header-social {
        display: none
    }
}

.header-social a {
    display: block;
    height: 100%;
    float: left;
    margin-left: 0;
    margin-top: -2px;
    color: #03c4eb
}

@media screen and (min-width:940px) {
    .header-social a {
        margin-left: 3px
    }
}

.header-social a:hover {
    color: #000;
    transition: all 0.2s linear 0s
}

.header-social .fa-stack-1x,
.header-social .fa-stack-2x {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center
}

.header-social .fa-stack-1x {
    line-height: inherit
}

.header-social .fa-stack-2x {
    font-size: 2em
}

.header-social .fa-inverse {
    color: #FFF
}

.header-btn {
    float: right
}

.onepress-menu,
.onepress-menu * {
    margin: 0;
    padding: 0;
    list-style: none
}

.onepress-menu {
    float: right
}

.onepress-menu ul {
    position: absolute;
    display: none;
    top: 100%;
    left: 0;
    z-index: 99
}

.onepress-menu ul ul {
    top: 0;
    left: 100%
}

.onepress-menu li {
    position: relative
}

.onepress-menu>li {
    float: left
}

.onepress-menu li:hover>ul,
.onepress-menu li.sfHover>ul {
    display: block
}

.onepress-menu a {
    display: block;
    position: relative
}

.onepress-menu ul {
    min-width: 12em;
    *width: 12em;
    border: 1px solid #e9e9e9;
    border-top: none
}

.onepress-menu li {
    white-space: nowrap;
    *white-space: normal;
    -webkit-transition: background .2s;
    transition: background .2s
}

.onepress-menu li:hover,
.onepress-menu li.sfHover {
    -webkit-transition: none;
    transition: none
}

.onepress-menu a {
    color: #333;
    font-family: "Raleway", Helvetica, Arial, sans-serif;
    padding-left: 17px;
    padding-right: 17px;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: none;
    zoom: 1;
    letter-spacing: 1px
}

.onepress-menu a:hover {
    color: #03c4eb
}

.onepress-menu ul li {
    line-height: 26px
}

.onepress-menu ul li a {
    border-top: 1px solid #e9e9e9;
    padding: 8px 18px;
    color: #999;
    font-size: 13px;
    text-transform: none;
    background: #fff;
    letter-spacing: 0
}

.onepress-menu ul li a:hover {
    color: #03c4eb
}

.onepress-menu li.onepress-current-item>a {
    color: #03c4eb
}

.onepress-menu ul li.current-menu-item>a {
    color: #03c4eb
}

.onepress-menu>li:last-child>a {
    padding-right: 0
}

.onepress-menu>li a.menu-actived {
    color: #03c4eb
}

.onepress-menu .nav-toggle-subarrow {
    display: none
}

#nav-toggle {
    display: none;
    margin-left: 15px
}

@media screen and (max-width:1140px) {
    .main-navigation {
        position: static
    }

    .main-navigation .onepress-menu {
        width: 100% !important;
        display: none
    }

    .onepress-menu.onepress-menu-mobile {
        display: block !important;
        position: absolute;
        top: 75px;
        right: 0;
        width: 100%;
        background: transparent;
        z-index: 100;
        border-bottom: 1px solid #e9e9e9
    }

    .onepress-menu.onepress-menu-mobile>li>a {
        padding: 12px 20px
    }

    .onepress-menu.onepress-menu-mobile li:hover ul {
        display: none
    }

    .onepress-menu.onepress-menu-mobile ul {
        box-shadow: none;
        padding-bottom: 0;
        border: none;
        position: relative
    }

    .onepress-menu.onepress-menu-mobile ul li:last-child {
        border-bottom: none
    }

    .onepress-menu.onepress-menu-mobile ul li a {
        padding: 12px 35px;
        border-top: none
    }

    .onepress-menu.onepress-menu-mobile ul li a:hover {
        background: transparent
    }

    .onepress-menu.onepress-menu-mobile ul li li a {
        padding: 12px 70px;
        border-top: none
    }

    .onepress-menu.onepress-menu-mobile ul li li li a {
        padding: 12px 105px
    }

    .onepress-menu.onepress-menu-mobile ul ul {
        left: 0
    }

    .onepress-menu.onepress-menu-mobile li {
        line-height: 20px;
        background: #FFF;
        border-top: 1px solid #e9e9e9;
        width: 100% !important
    }

    .onepress-menu.onepress-menu-mobile li.onepress-current-item>a {
        color: #03c4eb
    }

    .onepress-menu.onepress-menu-mobile a {
        color: #777
    }

    .onepress-menu.onepress-menu-mobile a:hover {
        color: #000
    }

    .onepress-menu.onepress-menu-mobile .nav-toggle-subarrow {
        display: block;
        color: #999;
        border-left: 1px solid #e9e9e9;
        font-weight: 400;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        text-align: center;
        padding: 11px 20px;
        z-index: 10;
        font-size: 22px
    }

    .onepress-menu.onepress-menu-mobile .nav-toggle-dropdown>.nav-toggle-subarrow .fa-angle-down:before {
        content: "\f106" !important
    }

    .onepress-menu.onepress-menu-mobile .nav-toggle-dropdown>ul {
        display: block !important
    }

    #nav-toggle {
        display: block
    }
}

#nav-toggle {
    float: right;
    position: relative;
    width: 35px;
    overflow: hidden;
    white-space: nowrap;
    color: transparent
}

#nav-toggle span {
    position: absolute;
    top: 50%;
    right: 5px;
    margin-top: -2px;
    -webkit-transition: rotate 0.2s, translate 0.2s;
    -moz-transition: rotate 0.2s, translate 0.2s;
    transition: rotate 0.2s, translate 0.2s;
    display: inline-block;
    height: 3px;
    width: 24px;
    background: #000
}

#nav-toggle span:before {
    top: -8px
}

#nav-toggle span:after {
    top: 8px
}

#nav-toggle span:before,
#nav-toggle span:after {
    position: absolute;
    display: inline-block;
    height: 3px;
    width: 24px;
    background: #000;
    content: '';
    right: 0;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform-origin: 0% 50%;
    -moz-transform-origin: 0% 50%;
    -ms-transform-origin: 0% 50%;
    -o-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    -webkit-transition: -webkit-transform 0.2s;
    -moz-transition: -moz-transform 0.2s;
    transition: transform 0.2s
}

#nav-toggle.nav-is-visible span {
    background: transparent
}

#nav-toggle.nav-is-visible span:before {
    -webkit-transform: translateX(4px) translateY(-1px) rotate(45deg);
    -moz-transform: translateX(4px) translateY(-1px) rotate(45deg);
    -ms-transform: translateX(4px) translateY(-1px) rotate(45deg);
    -o-transform: translateX(4px) translateY(-1px) rotate(45deg);
    transform: translateX(4px) translateY(-1px) rotate(45deg)
}

#nav-toggle.nav-is-visible span:after {
    -webkit-transform: translateX(4px) translateY(0) rotate(-45deg);
    -moz-transform: translateX(4px) translateY(0) rotate(-45deg);
    -ms-transform: translateX(4px) translateY(0) rotate(-45deg);
    -o-transform: translateX(4px) translateY(0) rotate(-45deg);
    transform: translateX(4px) translateY(0) rotate(-45deg)
}

#nav-toggle.nav-is-visible span:before,
#nav-toggle.nav-is-visible span:after {
    background: #000
}

.site-content {
    border-top: 1px solid #e9e9e9;
    margin-top: -1px
}

.site-content.no-border {
    border-top: none
}

.blog .site-content,
.tax-portfolio_cat .site-content,
.single-post .site-content,
.hiding-page-title .site-content {
    margin-top: 0
}

.page-header {
    padding: 30px 0;
    border-top: 1px solid #e9e9e9;
    background: #f8f9f9;
    border-bottom: 1px solid #e9e9e9
}

.page-header .entry-title,
.page-header .page-title {
    margin-bottom: 0;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase
}

.page-header p {
    margin: 6px 0 0 0
}

.page-header.page--cover {
    position: relative;
    padding: 8% 0;
    text-align: center;
    border-top: 0 none;
    border-bottom: 0 none;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat
}

.page-header.page--cover:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    display: block;
    background: rgba(0, 0, 0, .3);
    content: " "
}

.page-header.page--cover .entry-title {
    font-size: 2.5rem;
    font-weight: 800;
    color: #fff
}

.page-header.page--cover .entry-tagline {
    color: #fff;
    font-style: italic;
    font-weight: 300
}

@media screen and (min-width:1140px) {
    .page-header.page--cover .entry-tagline {
        font-size: 20px
    }
}

@media screen and (max-width:940px) {
    .page-header.page--cover .entry-tagline {
        font-size: 18px
    }
}

@media screen and (max-width:720px) {
    .page-header.page--cover .entry-tagline {
        font-size: 16px
    }
}

.page-header.page--cover.is-t-above .container {
    margin-top: 30px
}

.site-footer {
    background: #111;
    color: #BBB;
    font-size: 14px
}

.site-footer a {
    color: #03c4eb
}

.site-footer .footer-connect {
    padding: 90px 0;
    padding: 80px 0;
    padding: 5rem 0;
    text-align: center
}

@media (max-width:767px) {
    .site-footer .footer-connect .footer-subscribe {
        margin-bottom: 40px
    }
}

.site-footer .footer-connect .follow-heading {
    color: #FFF;
    margin-bottom: 20px;
    letter-spacing: .8px
}

.site-footer .footer-connect .subs_input {
    background-color: rgba(255, 255, 255, .145);
    color: #ccc;
    border-bottom: none;
    box-shadow: none;
    padding: 12px 15px 12px;
    border-radius: 2px;
    width: 60%;
    font-size: 13px
}

.site-footer .footer-connect .subs_input:focus {
    border: none
}

@media screen and (max-width:1140px) {
    .site-footer .footer-connect .subs_input {
        width: 100%;
        margin-bottom: 4px
    }
}

@media screen and (max-width:940px) {
    .site-footer .footer-connect .subs_input {
        width: 70%;
        margin-bottom: 4px
    }
}

@media screen and (max-width:720px) {
    .site-footer .footer-connect .subs_input {
        width: 100%;
        margin-bottom: 4px
    }
}

.site-footer .footer-connect .subs-button {
    margin: 0;
    position: relative;
    top: 0;
    padding: 16px 23px 16px
}

@media screen and (max-width:1140px) {
    .site-footer .footer-connect .subs-button {
        width: 100%
    }
}

@media screen and (max-width:940px) {
    .site-footer .footer-connect .subs-button {
        width: 70%
    }
}

@media screen and (max-width:720px) {
    .site-footer .footer-connect .subs-button {
        width: 100%
    }
}

.site-footer .footer-connect input[type="text"]:focus {
    border: none !important
}

.site-footer .footer-social {
    text-align: center
}

.site-footer .footer-social a {
    font-size: 30px;
    margin: 0 13px;
    color: #FFF
}

.site-footer .footer-social a:hover {
    color: #03c4eb
}

.site-footer .site-info {
    position: relative;
    text-align: center;
    padding: 35px 0;
    background: #222;
    font-weight: 500;
    color: #bbb
}

.site-footer .site-info a {
    color: #ddd
}

.site-footer .site-info a:hover {
    text-decoration: underline
}

.site-footer .site-info .container {
    position: initial
}

.site-footer .btt a {
    border-radius: 0 20px 0 0;
    color: #999;
    background: #222;
    display: block;
    font-size: 30px;
    height: 50px;
    line-height: 50px;
    margin: auto;
    position: absolute;
    right: 60px;
    top: -25px;
    transition: all 0.2s linear 0s;
    width: 50px;
    z-index: 99;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg)
}

.site-footer .btt a:hover {
    color: #03c4eb
}

.site-footer .btt a i {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    margin-left: 3px
}

@media screen and (max-width:940px) {
    .site-footer .btt a {
        right: 0;
        left: 0
    }
}

.error-404 .search-form,
.error-404 .widget {
    margin-bottom: 40px
}

.error-404 .widgettitle,
.error-404 .widget-title {
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 13px;
    font-weight: 700
}

.error-404 ul {
    padding-left: 0
}

.error-404 ul li {
    list-style: none
}

.search-results .hentry {
    border-bottom: 1px solid #e9e9e9;
    padding-bottom: 25px;
    margin-bottom: 25px
}

.search-results .entry-summary p {
    margin-bottom: 0
}

.search-results .entry-header .entry-title {
    font-size: 22px;
    line-height: 1.5;
    font-weight: 500
}

.search-results .entry-header .entry-title a:hover {
    text-decoration: none
}

.entry-header .entry-title {
    font-weight: 500;
    text-transform: none;
    letter-spacing: -.6px;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 25px;
    line-height: 1.3
}

@media screen and (min-width:940px) {
    .entry-header .entry-title {
        font-size: 32px;
        line-height: 1.5
    }
}

.entry-thumbnail {
    margin-bottom: 30px
}

.single .entry-header .entry-title {
    margin-bottom: 10px
}

.highlight {
    color: #03c4eb
}

.entry-content {
    margin-bottom: 30px
}

.entry-content blockquote {
    padding: 30px;
    position: relative;
    background: #f8f9f9;
    border-left: 3px solid #03c4eb;
    font-style: italic
}

.entry-content blockquote p {
    margin: 0
}

.entry-meta {
    margin-bottom: 30px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-size: 12px;
    font-weight: 600;
    padding-bottom: 30px;
    border-bottom: 1px solid #e9e9e9
}

.entry-footer {
    margin-bottom: 30px;
    padding-top: 30px;
    border-top: 1px solid #e9e9e9
}

.entry-footer .cat-links,
.entry-footer .tags-links {
    display: block;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-size: 12px;
    font-weight: 600;
    margin-top: 5px
}

.nav-links {
    padding: 30px 0;
    border-left: none;
    border-right: none;
    margin-bottom: 50px
}

.nav-links .nav-previous {
    float: left
}

.nav-links .nav-next {
    float: right
}

.nav-links a {
    background: #ccc;
    color: #FFF;
    padding: 12px 20px;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    border-radius: 2px
}

.nav-links a:hover {
    background: #03c4eb;
    text-decoration: none
}

.bypostauthor {
    margin: 0
}

.sticky .entry-title {
    padding-left: 20px;
    position: relative
}

.sticky .entry-title:after {
    content: "\f276";
    display: inline-block;
    font-family: "FontAwesome";
    font-style: normal;
    font-weight: 400;
    width: 12px;
    height: 12px;
    position: absolute;
    left: 0;
    top: 2px;
    font-size: 22px;
    color: #aaa
}

.wp-caption {
    max-width: 100%;
    font-style: italic;
    line-height: 1.35;
    margin-bottom: 15px;
    margin-top: 5px
}

.wp-caption img[class*="wp-image-"] {
    display: block;
    max-width: 100%
}

.wp-caption .wp-caption-text {
    margin: 10px 0
}

.wp-caption-text,
.entry-thumbnail-caption,
.cycle-caption {
    font-style: italic;
    line-height: 1.35;
    font-size: 13px
}

.gallery {
    margin-bottom: 1.5em
}

.gallery-item {
    display: inline-block;
    text-align: center;
    vertical-align: top;
    width: 100%
}

.gallery-columns-2 .gallery-item {
    max-width: 50%
}

.gallery-columns-3 .gallery-item {
    max-width: 33.33%
}

.gallery-columns-4 .gallery-item {
    max-width: 25%
}

.gallery-columns-5 .gallery-item {
    max-width: 20%
}

.gallery-columns-6 .gallery-item {
    max-width: 16.66%
}

.gallery-columns-7 .gallery-item {
    max-width: 14.28%
}

.gallery-columns-8 .gallery-item {
    max-width: 12.5%
}

.gallery-columns-9 .gallery-item {
    max-width: 11.11%
}

.gallery-caption {
    display: block
}

#comments {
    padding-top: 30px;
    border-top: 1px solid #e9e9e9
}

#comments .comments-title {
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 1.5px;
    text-transform: uppercase
}

#comments .comment-list {
    list-style: none;
    padding-left: 0
}

#comments .comment-list .pingback {
    border-bottom: 1px solid #e9e9e9;
    padding: 20px 0;
    margin: 0
}

#comments .comment-list .pingback p {
    margin: 0
}

#comments .comment-list .pingback:last-child {
    margin-bottom: 40px
}

#comments .comment-content.entry-content {
    margin-bottom: 0
}

#comments .comment {
    list-style: none;
    margin: 30px 0
}

#comments .comment .avatar {
    width: 60px;
    float: left;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px
}

#comments .comment .comment-wrapper {
    margin-left: 90px;
    padding: 25px 30px 15px 30px;
    background: #f8f9f9;
    position: relative
}

#comments .comment .comment-wrapper:before {
    border-color: rgba(0, 0, 0, 0) #f6f7f9 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
    border-style: solid;
    border-width: 0 10px 10px 0;
    content: "";
    height: 0;
    left: -9px;
    position: absolute;
    top: 0;
    width: 0
}

#comments .comment .comment-wrapper .comment-meta .comment-time,
#comments .comment .comment-wrapper .comment-meta .comment-reply-link,
#comments .comment .comment-wrapper .comment-meta .comment-edit-link {
    color: #aaa;
    text-transform: uppercase;
    letter-spacing: .3px;
    font-size: 11px
}

#comments .comment .comment-wrapper .comment-meta .comment-time:hover,
#comments .comment .comment-wrapper .comment-meta .comment-reply-link:hover,
#comments .comment .comment-wrapper .comment-meta .comment-edit-link:hover {
    color: #03c4eb
}

#comments .comment .comment-wrapper .comment-meta .comment-time:after,
#comments .comment .comment-wrapper .comment-meta .comment-reply-link:after,
#comments .comment .comment-wrapper .comment-meta .comment-edit-link:after {
    content: "/";
    padding: 0 5px
}

#comments .comment .comment-wrapper .comment-meta a:last-child:after {
    content: ""
}

#comments .comment .comment-wrapper .comment-meta cite .fn {
    font-weight: 700;
    font-style: normal;
    margin-right: 5px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-size: 14px
}

#comments .comment .comment-wrapper .comment-meta cite span {
    padding: 3px 10px;
    background: #e9e9e9;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    margin-right: 10px
}

#comments .comment .comment-wrapper a {
    text-decoration: none
}

#comments .comment .children {
    padding-left: 30px
}

#comments .comment .children .children {
    padding-left: 30px
}

#comments .comment .children .children .children {
    padding-left: 0
}

@media screen and (min-width:940px) {
    #comments .comment .children {
        padding-left: 90px
    }

    #comments .comment .children .children {
        padding-left: 90px
    }

    #comments .comment .children .children .children {
        padding-left: 90px
    }
}

#comments .form-allowed-tags {
    display: none
}

#comments a {
    text-decoration: none
}

#comments a:hover {
    text-decoration: underline
}

.comment-respond textarea,
.comment-respond textarea {
    width: 100%
}

#respond {
    padding-top: 20px
}

#respond .comment-form label {
    display: block;
    margin-bottom: 4px
}

#respond .form-allowed-tags {
    font-size: 12px
}

#respond .form-allowed-tags code {
    background: none
}

#respond .comment-reply-title {
    font-size: 18px;
    letter-spacing: 1.5px;
    margin-bottom: 20px;
    text-transform: uppercase
}

#respond .comment-notes {
    display: none
}

#respond label {
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 1.5px
}

.full-screen .comments-area {
    max-width: 1110px;
    margin: 0 auto
}

.woocommerce div.product form.cart .variations td.label {
    color: #777
}

.btn {
    text-transform: uppercase;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    box-shadow: 0 -1px 0 0 rgba(0, 0, 0, .1) inset;
    font-size: 13px;
    font-size: .8125rem;
    font-weight: 600
}

.btn:active,
.btn:focus {
    outline: none
}

.btn.btn-secondary-outline {
    box-shadow: none;
    border-color: rgba(255, 255, 255, .9);
    color: rgba(255, 255, 255, .9)
}

.btn.btn-secondary-outline:hover {
    background: #FFF;
    color: #333
}

.btn-lg {
    font-size: 17px;
    font-size: 1.0625rem
}

.btn-theme-primary {
    background: #03c4eb;
    color: #FFF
}

.btn-theme-primary:hover {
    color: #FFF
}

.btn-theme-primary-outline {
    background-color: transparent;
    background-image: none;
    border-color: #03c4eb;
    color: #03c4eb
}

.btn-theme-primary-outline:hover {
    color: #FFF;
    background-color: #03c4eb;
    border-color: #03c4eb
}

.slider-spinner {
    width: 40px;
    height: 40px;
    position: relative;
    margin: 100px auto
}

.double-bounce1,
.double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #fff;
    opacity: .6;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out
}

.double-bounce2 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s
}

@-webkit-keyframes sk-bounce {

    0%,
    100% {
        -webkit-transform: scale(0)
    }

    50% {
        -webkit-transform: scale(1)
    }
}

@keyframes sk-bounce {

    0%,
    100% {
        transform: scale(0);
        -webkit-transform: scale(0)
    }

    50% {
        transform: scale(1);
        -webkit-transform: scale(1)
    }
}

section>.customize-partial-edit-shortcut {
    z-index: 95
}

section>.customize-partial-edit-shortcut button {
    left: 30px
}

.sidebar .widget {
    margin-bottom: 40px
}

.sidebar .widget .widget-title {
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 13px;
    font-weight: 700
}

.sidebar .widget ul {
    list-style: none;
    padding: 0
}

.sidebar .widget ul:after {
    clear: both;
    content: ".";
    display: block;
    height: 0;
    visibility: hidden
}

.sidebar .widget a {
    color: #777;
    font-size: 14px
}

.sidebar .widget a:hover {
    color: #03c4eb;
    text-decoration: underline
}

.sidebar .widget .search-form .search-field {
    width: 100% !important;
    padding: 10px 15px;
    padding: .625rem .9375rem
}

.sidebar .widget .search-form .search-submit {
    padding: 12px 23px 12px;
    width: 93%
}

.widget_recent_comments ul {
    margin-top: 25px
}

.widget_recent_comments ul li {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e9e9e9
}

.widget_recent_comments ul a {
    font-style: italic
}

.widget_recent_comments .comment-author-link a {
    font-weight: 700;
    font-style: normal
}

.widget-area .widget_categories li,
.widget-area .widget_archive li,
.widget-area .widget_meta li {
    float: left;
    margin-bottom: 0;
    width: 50%
}

@media screen and (min-width:720px) {

    .widget-area .widget_categories li,
    .widget-area .widget_archive li,
    .widget-area .widget_meta li {
        width: 50%
    }
}

@media screen and (min-width:940px) {

    .widget-area .widget_categories li,
    .widget-area .widget_archive li,
    .widget-area .widget_meta li {
        width: 100%;
        float: none
    }
}

@media screen and (min-width:1140px) {

    .widget-area .widget_categories li,
    .widget-area .widget_archive li,
    .widget-area .widget_meta li {
        width: 50%;
        float: left
    }
}

.widget_recent_entries ul li {
    margin: 0;
    padding: 10px 0 10px 0;
    border-bottom: 1px solid #e9e9e9
}

.sidebar .widget_calendar a {
    color: #03c4eb;
    text-decoration: underline
}

.section-padding {
    padding: 70px 0;
    padding: 4.375rem 0
}

.section-padding-lg {
    padding: 90px 0;
    padding: 5.625rem 0
}

.section-padding-larger {
    padding: 140px 0;
    padding: 8.75rem 0
}

.section-meta {
    background-color: #f8f9f9
}

.onepage-section {
    position: relative;
    overflow: hidden
}

.onepage-section:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    content: " ";
    z-index: 1
}

.onepage-section .container-fluid,
.onepage-section .container {
    position: relative;
    z-index: 5
}

.section-inverse {
    background: #222
}

.section-inverse .section-title-area .section-title {
    color: rgba(255, 255, 255, .99)
}

.section-inverse .section-title-area .section-subtitle {
    color: rgba(255, 255, 255, .7)
}

.section-inverse .section-title-area .section-subtitle::after {
    background: rgba(255, 255, 255, .8)
}

.section-inverse-extended {
    background: #333
}

.section-inverse-extended .section-title-area .section-title {
    color: rgba(255, 255, 255, .99)
}

.section-inverse-extended .section-title-area .section-subtitle {
    color: rgba(255, 255, 255, .7)
}

.section-inverse-extended .section-title-area .section-subtitle::after {
    background: rgba(255, 255, 255, .8)
}

.section-inverse {
    color: rgba(255, 255, 255, .5)
}

.section-inverse h1,
.section-inverse h2,
.section-inverse h3,
.section-inverse h4,
.section-inverse h5,
.section-inverse h6 {
    color: rgba(255, 255, 255, .9)
}

.section-inverse p {
    color: rgba(255, 255, 255, .5)
}

.section-title-area {
    margin-bottom: 60px;
    text-align: center
}

.section-title-area .section-title {
    font-size: 30px;
    letter-spacing: 2px;
    font-weight: 800;
    position: relative;
    margin-bottom: 0;
    text-transform: uppercase
}

@media screen and (min-width:940px) {
    .section-title-area .section-title {
        font-size: 40px;
        letter-spacing: 3.5px
    }
}

.section-title-area .section-subtitle {
    color: #aaa;
    font-size: 12px;
    display: inline-block;
    position: relative;
    margin-bottom: 13px;
    margin-bottom: .8125rem;
    text-transform: uppercase;
    letter-spacing: 2px
}

@media screen and (min-width:940px) {
    .section-title-area .section-subtitle {
        font-size: 14px
    }
}

.section-desc {
    font-size: 20px;
    font-size: 1.25rem;
    margin-top: 40px;
    margin-top: 2.5rem
}

.hero-slideshow-wrapper {
    width: 100%;
    position: relative;
    background-color: #222;
    overflow: hidden
}

.hero-slideshow-wrapper:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .3);
    display: block;
    content: ""
}

.hero-slideshow-wrapper .hero__content .hero-large-text,
.hero-slideshow-wrapper .hero__content .hero-small-text,
.hero-slideshow-wrapper .hero__content .btn,
.hero-slideshow-wrapper .hero__content .hcl2-content {
    visibility: hidden;
    opacity: 0;
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
    -webkit-transition: all .5s linear;
    transition: all .5s linear
}

.hero-slideshow-wrapper .hero__content .hero-large-text {
    -webkit-transition-delay: 0.7s;
    transition-delay: 0.7s
}

.hero-slideshow-wrapper .hero__content .hero-small-text {
    -webkit-transition-delay: 0.8s;
    transition-delay: 0.8s
}

.hero-slideshow-wrapper .hero__content .btn {
    -webkit-transition-delay: 1s;
    transition-delay: 1s
}

.hero-slideshow-wrapper .hero__content .hcl2-content {
    -webkit-transition-delay: 0.7s;
    transition-delay: 0.7s
}

.hero-slideshow-wrapper.loaded .container .hero__content:hover .hero-large-text,
.hero-slideshow-wrapper.loaded .container .hero__content:hover .hero-small-text,
.hero-slideshow-wrapper.loaded .container .hero__content:hover .btn {
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s
}

.hero-slideshow-wrapper.loaded .container .hero__content:hover .hero-large-text {
    -webkit-transition-delay: 0s;
    transition-delay: 0s
}

.hero-slideshow-wrapper.loaded .container .hero__content:hover .hero-small-text {
    -webkit-transition-delay: 0s;
    transition-delay: 0s
}

.hero-slideshow-wrapper.loaded .container .hero__content:hover .btn {
    -webkit-transition-delay: 0s;
    transition-delay: 0s
}

.hero-slideshow-wrapper.loaded .container .hero__content .hero-large-text,
.hero-slideshow-wrapper.loaded .container .hero__content .hero-small-text,
.hero-slideshow-wrapper.loaded .container .hero__content .btn,
.hero-slideshow-wrapper.loaded .container .hero__content .hcl2-content {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

.hero-slideshow-wrapper .slider-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 9999999;
    margin: 0;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

.animation-disable .hero-slideshow-wrapper .hero__content .hero-large-text,
.animation-disable .hero-slideshow-wrapper .hero__content .hero-small-text,
.animation-disable .hero-slideshow-wrapper .hero__content .btn,
.animation-disable .hero-slideshow-wrapper .hero__content .hcl2-content {
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

.animation-disable .hero-slideshow-wrapper .hero__content .hero-large-text {
    -webkit-transition-delay: 0s;
    transition-delay: 0s
}

.animation-disable .hero-slideshow-wrapper .hero__content .hero-small-text {
    -webkit-transition-delay: 0s;
    transition-delay: 0s
}

.animation-disable .hero-slideshow-wrapper .hero__content .btn {
    -webkit-transition-delay: 0s;
    transition-delay: 0s
}

.animation-disable .hero-slideshow-wrapper .hero__content .hcl2-content {
    -webkit-transition-delay: 0s;
    transition-delay: 0s
}

.animation-disable .hero-slideshow-wrapper.loaded .container .hero__content:hover .hero-large-text,
.animation-disable .hero-slideshow-wrapper.loaded .container .hero__content:hover .hero-small-text,
.animation-disable .hero-slideshow-wrapper.loaded .container .hero__content:hover .btn,
.animation-disable .hero-slideshow-wrapper.loaded .container .hero__content:hover .hcl2-content {
    -webkit-transition-delay: 0s;
    transition-delay: 0s
}

.animation-disable .hero-slideshow-wrapper.loaded .container .hero__content:hover .hero-large-text {
    -webkit-transition-delay: 0s;
    transition-delay: 0s
}

.animation-disable .hero-slideshow-wrapper.loaded .container .hero__content:hover .hero-small-text {
    -webkit-transition-delay: 0s;
    transition-delay: 0s
}

.animation-disable .hero-slideshow-wrapper.loaded .container .hero__content:hover .btn {
    -webkit-transition-delay: 0s;
    transition-delay: 0s
}

.animation-disable .hero-slideshow-wrapper.loaded .container .hero__content:hover .hcl2-content {
    -webkit-transition-delay: 0s;
    transition-delay: 0s
}

.animation-disable .hero-slideshow-wrapper .slider-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 9999999;
    margin: 0;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

.js-rotating {
    display: none
}

.js-rotating.morphext {
    display: inline-block
}

.is-customize-preview .slider-spinner {
    display: none !important
}

.is-customize-preview .hero-slideshow-wrapper .hero__content .hero-large-text,
.is-customize-preview .hero-slideshow-wrapper .hero__content .hero-small-text,
.is-customize-preview .hero-slideshow-wrapper .hero__content .btn,
.is-customize-preview .hero-slideshow-wrapper .hero__content .hcl2-content {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition: all 0s linear;
    transition: all 0s linear
}

.is-customize-preview .hero-slideshow-wrapper .hero__content .hero-large-text {
    -webkit-transition-delay: 0s;
    transition-delay: 0s
}

.is-customize-preview .hero-slideshow-wrapper .hero__content .hero-small-text {
    -webkit-transition-delay: 0s;
    transition-delay: 0s
}

.is-customize-preview .hero-slideshow-wrapper .hero__content .btn {
    -webkit-transition-delay: 0s;
    transition-delay: 0s
}

.is-customize-preview .hero-slideshow-wrapper .hero__content .hcl2-content {
    -webkit-transition-delay: 0s;
    transition-delay: 0s
}

.is-customize-preview .hero-slideshow-wrapper.loaded .container .hero__content:hover .hero-large-text,
.is-customize-preview .hero-slideshow-wrapper.loaded .container .hero__content:hover .hero-small-text,
.is-customize-preview .hero-slideshow-wrapper.loaded .container .hero__content:hover .btn,
.is-customize-preview .hero-slideshow-wrapper.loaded .container .hero__content:hover .hcl2-content {
    -webkit-transition-duration: 0s;
    transition-duration: 0s
}

.is-customize-preview .hero-slideshow-wrapper.loaded .container .hero__content:hover .hero-large-text {
    -webkit-transition-delay: 0s;
    transition-delay: 0s
}

.is-customize-preview .hero-slideshow-wrapper.loaded .container .hero__content:hover .hero-small-text {
    -webkit-transition-delay: 0s;
    transition-delay: 0s
}

.is-customize-preview .hero-slideshow-wrapper.loaded .container .hero__content:hover .btn {
    -webkit-transition-delay: 0s;
    transition-delay: 0s
}

.is-customize-preview .hero-slideshow-wrapper.loaded .container .hero__content:hover .hcl2-content {
    -webkit-transition-delay: 0s;
    transition-delay: 0s
}

.is-customize-preview .hero-slideshow-wrapper.loaded .container .hero__content .hero-large-text,
.is-customize-preview .hero-slideshow-wrapper.loaded .container .hero__content .hero-small-text,
.is-customize-preview .hero-slideshow-wrapper.loaded .container .hero__content .btn,
.is-customize-preview .hero-slideshow-wrapper.loaded .container .hero__content .hcl2-content {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

.hero-slideshow-normal .container {
    padding: 10% 0;
    position: relative;
    z-index: 80
}

.hero-slideshow-fullscreen .container {
    z-index: 80;
    position: relative;
    top: 50%;
    transform: translateY(-50%)
}

.hero__content {
    color: #FFF;
    text-shadow: 0 2px 8px rgba(0, 0, 0, .15);
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 300
}

.hero__content h1,
.hero__content h2,
.hero__content h3,
.hero__content h4,
.hero__content h5,
.hero__content h6 {
    color: #FFF;
    letter-spacing: 2px;
    text-transform: uppercase
}

.hero__content a.btn {
    margin: 10px 10px 20px 0;
    margin: .625rem .625rem 1.25rem 0
}

.hero__content p {
    margin-bottom: 20px;
    margin-bottom: 1.25rem
}

.hero-content-style2 h1 {
    font-weight: 800;
    font-size: 50px;
    font-size: 3.125rem;
    letter-spacing: 3px
}

.hero-content-style2 .hcl2-content {
    padding: 50px 0;
    padding: 3.125rem 0
}

.hero-content-style2 .hcl2-image {
    text-align: right
}

@media (max-width:1199px) {

    .hero-content-style2 .hcl2-content,
    .hero-content-style2 .hcl2-image {
        text-align: center
    }
}

.hero-content-style1 {
    text-align: center
}

.hero-content-style1 h2 {
    color: #FFF;
    font-size: 35px;
    letter-spacing: 2px;
    line-height: 1.3;
    text-shadow: 0 2px 8px rgba(0, 0, 0, .15);
    font-weight: 800;
    text-transform: uppercase
}

@media screen and (min-width:720px) {
    .hero-content-style1 h2 {
        font-size: 60px;
        letter-spacing: 3px
    }
}

@media screen and (min-width:940px) {
    .hero-content-style1 h2 {
        font-size: 75px;
        letter-spacing: 4px
    }
}

.hero-content-style1 h2 strong {
    font-weight: 900
}

.hero-content-style1 p {
    font-size: 14px;
    font-style: italic;
    letter-spacing: .4px;
    text-shadow: 0 2px 8px rgba(0, 0, 0, .15);
    color: #FFF;
    text-align: center;
    font-weight: 100;
    margin-bottom: 30px;
    margin-bottom: 1.875rem
}

@media screen and (min-width:940px) {
    .hero-content-style1 p {
        font-size: 18px
    }
}

@media screen and (min-width:1140px) {
    .hero-content-style1 p {
        font-size: 22px
    }
}

.hero-content-style1 p strong {
    letter-spacing: -.6px
}

@media (max-width:767px) {
    .hero-content-style1 .btn {
        display: inline-block
    }
}

.hero-content-style1 a:active,
.hero-content-style1 a:focus,
.hero-content-style1 .btn:active,
.hero-content-style1 .btn:focus {
    outline: none
}

#hero .btn {
    box-shadow: none
}

#hero .btn-theme-primary:focus {
    color: #fff
}

.h-on-top.is-transparent #hero .container .hero__content {
    margin-top: 75px
}

.morphext>.animated {
    display: inline-block
}

.icon-background-default {
    color: #03c4eb
}

.feature-item {
    text-align: center
}

.feature-item .feature-icon {
    color: #fff
}

.feature-item .fa-5x {
    font-size: 4em
}

.feature-item:hover .icon-background-default {
    color: #333;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1)
}

@media screen and (min-width:940px) {

    .feature-item.col-lg-3:nth-child(4n+1),
    .feature-item.col-lg-4:nth-child(3n+1),
    .feature-item.col-lg-6:nth-child(2n+1) {
        clear: left
    }
}

.feature-media {
    text-align: center;
    margin-bottom: 20px
}

.section-about .about-image {
    text-align: center
}

.section-about .about-image img {
    margin-bottom: 30px
}

.section-about .section-title-area {
    margin-bottom: 30px
}

.section-about .row>div {
    margin-top: 30px
}

.section-services .service-item {
    background: #FFF;
    padding: 30px;
    margin-bottom: 30px;
    position: relative;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .08);
    -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, .08);
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .08);
    transition: all 100ms ease-out 0s
}

@media screen and (max-width:720px) {
    .section-services .service-item {
        padding: 20px
    }
}

.section-services .service-item:after {
    content: "";
    display: table;
    clear: both;
    clear: both
}

.section-services .service-item:hover {
    box-shadow: 0 6px 9px rgba(0, 0, 0, .09)
}

.section-services .service-item .service-image {
    float: left;
    margin-right: 30px
}

.section-services .service-item .service-image img {
    max-width: 120px;
    height: auto
}

.section-services .service-item .service-image i {
    color: #03c4eb
}

@media screen and (max-width:720px) {
    .section-services .service-item .service-image {
        float: none;
        margin-bottom: 30px;
        margin-top: 10px;
        margin-right: 0;
        text-align: center
    }
}

.section-services .service-item .service-thumbnail {
    margin-bottom: 25px;
    margin-bottom: 1.5625rem
}

.section-services .service-item .service-content {
    overflow: hidden
}

.section-services .service-item .service-content p {
    margin: 0;
    font-size: 14px;
    color: #777
}

@media screen and (max-width:720px) {
    .section-services .service-item .service-content {
        text-align: center;
        overflow: auto
    }
}

.section-services .service-item .service-title {
    margin-bottom: 10px;
    margin-bottom: .625rem;
    color: #333
}

.section-services .service-item .service-link:before {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    content: ""
}

.counter_item {
    text-align: center;
    margin-bottom: 35px;
    margin-bottom: 2.1875rem
}

.counter_item .counter__number {
    font-size: 50px;
    font-size: 3.125rem;
    font-weight: 800;
    color: #03c4eb;
    line-height: 1;
    font-family: serif, sans-serif;
    text-transform: uppercase
}

.counter_item .counter_title {
    font-size: 18px;
    font-size: 1.125rem
}

#parallax-hero {
    position: relative;
    background: none;
    overflow: hidden
}

#parallax-hero .hero-slideshow-wrapper {
    background: none
}

#parallax-hero .hero-slideshow-wrapper::after {
    background: none
}

.section-parallax,
#parallax-hero {
    background: none;
    position: relative;
    overflow: hidden
}

.section-parallax>section,
.section-parallax .onepage-section,
.section-parallax .section-inverse,
#parallax-hero>section,
#parallax-hero .onepage-section,
#parallax-hero .section-inverse {
    background: none
}

.section-parallax .parallax-bg,
#parallax-hero .parallax-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    min-height: 100%;
    display: block;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: 50% 50%
}

.section-parallax .parallax-bg:before,
#parallax-hero .parallax-bg:before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .3);
    display: block;
    width: 100%;
    height: 100%;
    z-index: 2
}

.section-parallax .parallax-bg img,
#parallax-hero .parallax-bg img {
    position: absolute;
    left: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    max-width: 9999999px
}

.section-parallax .parallax-bg.no-img,
#parallax-hero .parallax-bg.no-img {
    height: 120%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-attachment: scroll;
    background-position: 50% 0
}

.parallax_bg_overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 5;
    opacity: .4
}

.parallax-content {
    z-index: 10;
    position: relative;
    padding: 100px 0;
    text-align: center
}

.parallax-content h1,
.parallax-content h2,
.parallax-content h3,
.parallax-content h4,
.parallax-content h5,
.parallax-content h6 {
    color: #FFF;
    line-height: 1.5
}

.parallax-content p,
.parallax-content a {
    color: #FFF
}

.section-team .team-member {
    padding: 0 .5px;
    text-align: center
}

.section-team .row {
    margin-left: -.5px;
    margin-right: -.5px
}

.team-members.row {
    margin-left: 0;
    margin-left: 0rem;
    margin-right: 0;
    margin-right: 0rem
}

@media screen and (min-width:940px) {
    .team-members.row.team-layout-4 .team-member {
        width: 25%
    }

    .team-members.row.team-layout-4 .team-member:nth-child(4n+1) {
        clear: left
    }

    .team-members.row.team-layout-3 .team-member {
        width: 33.3333333333%
    }

    .team-members.row.team-layout-3 .team-member:nth-child(3n+1) {
        clear: left
    }

    .team-members.row.team-layout-2 .team-member {
        width: 50%
    }

    .team-members.row.team-layout-2 .team-member:nth-child(2n+1) {
        clear: left
    }
}

@media screen and (max-width:940px) {
    .team-members.row .team-member {
        width: 50%
    }

    .team-members.row .team-member:nth-child(2n+1) {
        clear: left
    }
}

@media screen and (max-width:720px) {
    .team-members.row .team-member {
        width: 50%
    }

    .team-members.row .team-member:nth-child(2n+1) {
        clear: left
    }
}

@media screen and (max-width:576px) {
    .team-members.row .team-member {
        width: 100%
    }
}

.team-member {
    margin-bottom: 20px;
    float: left
}

.team-member .member-thumb {
    margin-bottom: 20px;
    position: relative;
    overflow: hidden
}

.team-member .member-thumb:hover .member-profile {
    bottom: 8px;
    transition: all 0.1s linear
}

.team-member .member-thumb:hover img {
    opacity: .7;
    transition: all 0.1s linear
}

.team-member .member-thumb .member-profile {
    position: absolute;
    text-align: center;
    bottom: -35px;
    left: auto;
    right: auto;
    width: 100%
}

.team-member .member-thumb .member-profile a {
    color: #000
}

.team-member .member-thumb .member-profile a .fa-inverse {
    color: #fff
}

.team-member .member-thumb .member-profile a:hover {
    color: #03c4eb
}

.team-member .member-info {
    margin-top: 0;
    position: relative;
    z-index: 30;
    text-align: center
}

.team-member .member-name {
    letter-spacing: 2px;
    margin-bottom: 15px;
    position: relative
}

.team-member .member-name:after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    margin-left: -15%;
    bottom: -11px;
    width: 30%;
    height: 1px;
    background: #ccc
}

.team-member .member-position {
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 1.3px
}

.all-news {
    text-align: center;
    margin-top: 40px;
    display: block
}

.section-news .list-article,
.archive .list-article,
.blog .list-article {
    border-top: 1px solid #e9e9e9;
    padding: 25px 0
}

.section-news .list-article:last-of-type,
.archive .list-article:last-of-type,
.blog .list-article:last-of-type {
    border-bottom: 1px solid #e9e9e9
}

.section-news .list-article-thumb,
.archive .list-article-thumb,
.blog .list-article-thumb {
    float: left;
    margin-right: 30px
}

.section-news .list-article-thumb a img:hover,
.archive .list-article-thumb a img:hover,
.blog .list-article-thumb a img:hover {
    opacity: .8
}

@media screen and (max-width:940px) {

    .section-news .list-article-thumb,
    .archive .list-article-thumb,
    .blog .list-article-thumb {
        display: none
    }
}

@media screen and (max-width:720px) {

    .section-news .list-article-thumb,
    .archive .list-article-thumb,
    .blog .list-article-thumb {
        width: 100%;
        float: none;
        margin-right: 0;
        margin-bottom: 30px;
        display: block
    }

    .section-news .list-article-thumb img,
    .archive .list-article-thumb img,
    .blog .list-article-thumb img {
        width: 100%
    }
}

.section-news .list-article-content,
.archive .list-article-content,
.blog .list-article-content {
    float: none;
    overflow: hidden
}

.section-news .list-article-meta,
.archive .list-article-meta,
.blog .list-article-meta {
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    margin-bottom: 10px
}

.section-news .entry-title,
.archive .entry-title,
.blog .entry-title {
    font-size: 22px;
    line-height: 1.5
}

.section-news .entry-title a:hover,
.archive .entry-title a:hover,
.blog .entry-title a:hover {
    text-decoration: none
}

.section-news .entry-excerpt p,
.archive .entry-excerpt p,
.blog .entry-excerpt p {
    margin-bottom: 0
}

.archive .site-main .list-article:first-child,
.blog .site-main .list-article:first-of-type {
    border-top: none;
    padding-top: 0
}

.page-template-template-blog .section-news .list-article {
    padding: 30px 0
}

.page-template-template-blog .section-news .list-article:first-of-type {
    border-top: none;
    padding-top: 0
}

.page-template-template-blog .section-news .list-article:last-of-type {
    border-bottom: none
}

.section-news.section-inverse .list-article {
    border-top: 1px solid rgba(255, 255, 255, .2)
}

.section-news.section-inverse .list-article:last-of-type {
    border-bottom: 1px solid rgba(255, 255, 255, .2)
}

.section-news.section-inverse h1 a,
.section-news.section-inverse h2 a,
.section-news.section-inverse h3 a,
.section-news.section-inverse h4 a,
.section-news.section-inverse h5 a {
    color: rgba(255, 255, 255, .8)
}

.section-news.section-inverse h1 a:hover,
.section-news.section-inverse h2 a:hover,
.section-news.section-inverse h3 a:hover,
.section-news.section-inverse h4 a:hover,
.section-news.section-inverse h5 a:hover {
    color: #FFF
}

.section-contact input,
.section-contact textarea {
    width: 100%
}

.section-contact .address-contact {
    margin-top: 5px;
    margin-bottom: 15px
}

.section-contact .address-contact span {
    float: left;
    color: #aaa
}

.section-contact .address-contact .address-content {
    display: block;
    padding-left: 45px;
    font-style: italic;
    line-height: 2
}

.section-contact .address-contact .fa-inverse {
    font-size: 12px
}

.section-contact textarea {
    max-height: 190px
}

.wpcf7-form {
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 2px;
    font-weight: 600
}

.wpcf7-form br {
    margin-bottom: 5px
}

.wpcf7-form label {
    width: 100%
}

div.wpcf7-validation-errors {
    margin: 20px 0;
    padding: 15px
}

div.wpcf7-response-output {
    margin: 20px 0
}

.section-videolightbox {
    text-align: center
}

.section-videolightbox .videolightbox__icon {
    margin-bottom: 40px;
    margin-bottom: 2.5rem
}

.section-videolightbox .videolightbox__heading {
    text-transform: uppercase;
    font-size: 38px;
    font-size: 2.375rem;
    letter-spacing: 0;
    font-weight: 500
}

.section-videolightbox .videolightbox__heading strong {
    font-weight: 800
}

.section-videolightbox .video_icon {
    background: rgba(0, 0, 0, .2) none repeat scroll 0 0;
    border: 4px solid #fff;
    border-radius: 100px;
    height: 50px;
    line-height: 1;
    padding: 10px;
    width: 50px;
    display: inline-block
}

.section-videolightbox .video_icon i {
    color: #fff;
    font-size: 20px;
    margin-left: 6px;
    margin-top: 1px;
    line-height: 1
}

.section-videolightbox .video_icon:hover {
    background: #FFF
}

.section-videolightbox .video_icon:hover i {
    color: #000
}

.gallery-grid .g-item {
    padding: 10px;
    float: left
}

.all-gallery {
    text-align: center;
    margin-top: 40px;
    display: block
}

.g-zoom-in .g-item {
    display: block;
    overflow: hidden
}

.g-zoom-in .g-item .inner-content {
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden
}

.g-zoom-in .g-item img {
    display: block;
    overflow: hidden;
    -webkit-transition: all 0.5s linear;
    transition: all 0.5s linear
}

.g-zoom-in .g-item:hover img {
    -ms-transform: scale(1.5, 1.5);
    -webkit-transform: scale(1.5, 1.5);
    transform: scale(1.5, 1.5)
}

.g-layout-full-width.container {
    width: 100%;
    max-width: none;
    padding: 0
}

@media screen and (min-width:720px) {
    .g-col-1 .g-item {
        width: 100%
    }

    .g-col-1 .g-item:nth-child(1n+1) {
        clear: left
    }

    .g-col-2 .g-item {
        width: 50%
    }

    .g-col-2 .g-item:nth-child(2n+1) {
        clear: left
    }

    .g-col-3 .g-item {
        width: 33.3333333333%
    }

    .g-col-3 .g-item:nth-child(3n+1) {
        clear: left
    }

    .g-col-4 .g-item {
        width: 25%
    }

    .g-col-4 .g-item:nth-child(4n+1) {
        clear: left
    }

    .g-col-5 .g-item {
        width: 20%
    }

    .g-col-5 .g-item:nth-child(5n+1) {
        clear: left
    }

    .g-col-6 .g-item {
        width: 16.6666666667%
    }

    .g-col-6 .g-item:nth-child(6n+1) {
        clear: left
    }
}

@media screen and (min-width:576px) and (max-width:720px) {
    .g-col-1 .g-item {
        width: 100%
    }

    .g-col-1 .g-item:nth-child(1n+1) {
        clear: left
    }

    .g-col-2 .g-item {
        width: 50%
    }

    .g-col-2 .g-item:nth-child(2n+1) {
        clear: left
    }

    .g-col-3 .g-item {
        width: 33.3333333333%
    }

    .g-col-3 .g-item:nth-child(3n+1) {
        clear: left
    }

    .g-col-4 .g-item {
        width: 25%
    }

    .g-col-4 .g-item:nth-child(4n+1) {
        clear: left
    }

    .g-col-5 .g-item {
        width: 20%
    }

    .g-col-5 .g-item:nth-child(5n+1) {
        clear: left
    }

    .g-col-6 .g-item {
        width: 16.6666666667%
    }

    .g-col-6 .g-item:nth-child(6n+1) {
        clear: left
    }
}

@media screen and (max-width:576px) {
    .g-col-1 .g-item {
        width: 100%
    }

    .g-col-1 .g-item:nth-child(1n+1) {
        clear: left
    }

    .g-col-2 .g-item {
        width: 50%
    }

    .g-col-2 .g-item:nth-child(2n+1) {
        clear: left
    }

    .g-col-3 .g-item {
        width: 33.3333333333%
    }

    .g-col-3 .g-item:nth-child(3n+1) {
        clear: left
    }

    .g-col-4 .g-item {
        width: 25%
    }

    .g-col-4 .g-item:nth-child(4n+1) {
        clear: left
    }

    .g-col-5 .g-item {
        width: 20%
    }

    .g-col-5 .g-item:nth-child(5n+1) {
        clear: left
    }

    .g-col-6 .g-item {
        width: 16.6666666667%
    }

    .g-col-6 .g-item:nth-child(6n+1) {
        clear: left
    }
}

.gallery-justified {
    width: 100%;
    position: relative;
    overflow: hidden
}

.gallery-justified img {
    max-width: none;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0;
    padding: 0;
    border: none
}

.gallery-justified>a,
.gallery-justified>div {
    position: absolute;
    display: inline-block;
    overflow: hidden;
    float: left
}

.gallery-masonry {
    width: 100%;
    position: relative;
    display: block
}

.gallery-masonry:affter {
    clear: both
}

.gallery-masonry .inner {
    display: block
}

.mfp-bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1042;
    overflow: hidden;
    position: fixed;
    background: #0b0b0b;
    opacity: .8;
    filter: alpha(opacity=80)
}

.mfp-wrap {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1043;
    position: fixed;
    outline: none !important;
    -webkit-backface-visibility: hidden
}

.mfp-container {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0 8px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.mfp-container:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle
}

.mfp-align-top .mfp-container:before {
    display: none
}

.mfp-content {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0 auto;
    text-align: left;
    z-index: 1045
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
    width: 100%;
    cursor: auto
}

.mfp-ajax-cur {
    cursor: progress
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
    cursor: -moz-zoom-out;
    cursor: -webkit-zoom-out;
    cursor: zoom-out
}

.mfp-zoom {
    cursor: pointer;
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
    cursor: zoom-in
}

.mfp-auto-cursor .mfp-content {
    cursor: auto
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.mfp-loading.mfp-figure {
    display: none
}

.mfp-hide {
    display: none !important
}

.mfp-preloader {
    color: #CCC;
    position: absolute;
    top: 50%;
    width: auto;
    text-align: center;
    margin-top: -.8em;
    left: 8px;
    right: 8px;
    z-index: 1044
}

.mfp-preloader a {
    color: #CCC
}

.mfp-preloader a:hover {
    color: #FFF
}

.mfp-s-ready .mfp-preloader {
    display: none
}

.mfp-s-error .mfp-content {
    display: none
}

button.mfp-close,
button.mfp-arrow {
    overflow: visible;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
    display: block;
    outline: none;
    padding: 0;
    z-index: 1046;
    -webkit-box-shadow: none;
    box-shadow: none
}

button::-moz-focus-inner {
    padding: 0;
    border: 0
}

.mfp-close {
    width: 44px;
    height: 44px;
    line-height: 44px;
    position: absolute;
    right: 0;
    top: 0;
    text-decoration: none;
    text-align: center;
    opacity: .65;
    filter: alpha(opacity=65);
    padding: 0 0 18px 10px;
    color: #FFF;
    font-style: normal;
    font-size: 28px;
    font-family: Arial, Baskerville, monospace
}

.mfp-close:hover,
.mfp-close:focus {
    opacity: 1;
    filter: alpha(opacity=100)
}

.mfp-close:active {
    top: 1px
}

.mfp-close-btn-in .mfp-close {
    color: #333
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
    color: #FFF;
    right: -6px;
    text-align: right;
    padding-right: 6px;
    width: 100%
}

.mfp-counter {
    position: absolute;
    top: 0;
    right: 0;
    color: #CCC;
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap
}

.mfp-arrow {
    position: absolute;
    opacity: .65;
    filter: alpha(opacity=65);
    margin: 0;
    top: 50%;
    margin-top: -55px;
    padding: 0;
    width: 90px;
    height: 110px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.mfp-arrow:active {
    margin-top: -54px
}

.mfp-arrow:hover,
.mfp-arrow:focus {
    opacity: 1;
    filter: alpha(opacity=100)
}

.mfp-arrow:before,
.mfp-arrow:after,
.mfp-arrow .mfp-b,
.mfp-arrow .mfp-a {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent
}

.mfp-arrow:after,
.mfp-arrow .mfp-a {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px
}

.mfp-arrow:before,
.mfp-arrow .mfp-b {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: .7
}

.mfp-arrow-left {
    left: 0
}

.mfp-arrow-left:after,
.mfp-arrow-left .mfp-a {
    border-right: 17px solid #FFF;
    margin-left: 31px
}

.mfp-arrow-left:before,
.mfp-arrow-left .mfp-b {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F
}

.mfp-arrow-right {
    right: 0
}

.mfp-arrow-right:after,
.mfp-arrow-right .mfp-a {
    border-left: 17px solid #FFF;
    margin-left: 39px
}

.mfp-arrow-right:before,
.mfp-arrow-right .mfp-b {
    border-left: 27px solid #3F3F3F
}

.mfp-iframe-holder {
    padding-top: 40px;
    padding-bottom: 40px
}

.mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px
}

.mfp-iframe-holder .mfp-close {
    top: -40px
}

.mfp-iframe-scaler {
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: 56.25%
}

.mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, .6);
    background: #000
}

img.mfp-img {
    width: auto;
    max-width: 100%;
    height: auto;
    display: block;
    line-height: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 40px 0 40px;
    margin: 0 auto
}

.mfp-figure {
    line-height: 0
}

.mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, .6);
    background: #444
}

.mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px
}

.mfp-figure figure {
    margin: 0
}

.mfp-bottom-bar {
    margin-top: -36px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    cursor: auto
}

.mfp-title {
    text-align: left;
    line-height: 18px;
    color: #F3F3F3;
    word-wrap: break-word;
    padding-right: 36px
}

.mfp-image-holder .mfp-content {
    max-width: 100%
}

.mfp-gallery .mfp-image-holder .mfp-figure {
    cursor: pointer
}

@media screen and (max-width:800px) and (orientation:landscape),
screen and (max-height:300px) {
    .mfp-img-mobile .mfp-image-holder {
        padding-left: 0;
        padding-right: 0
    }

    .mfp-img-mobile img.mfp-img {
        padding: 0
    }

    .mfp-img-mobile .mfp-figure:after {
        top: 0;
        bottom: 0
    }

    .mfp-img-mobile .mfp-figure small {
        display: inline;
        margin-left: 5px
    }

    .mfp-img-mobile .mfp-bottom-bar {
        background: rgba(0, 0, 0, .6);
        bottom: 0;
        margin: 0;
        top: auto;
        padding: 3px 5px;
        position: fixed;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box
    }

    .mfp-img-mobile .mfp-bottom-bar:empty {
        padding: 0
    }

    .mfp-img-mobile .mfp-counter {
        right: 5px;
        top: 3px
    }

    .mfp-img-mobile .mfp-close {
        top: 0;
        right: 0;
        width: 35px;
        height: 35px;
        line-height: 35px;
        background: rgba(0, 0, 0, .6);
        position: fixed;
        text-align: center;
        padding: 0
    }
}

@media all and (max-width:900px) {
    .mfp-arrow {
        -webkit-transform: scale(.75);
        transform: scale(.75)
    }

    .mfp-arrow-left {
        -webkit-transform-origin: 0;
        transform-origin: 0
    }

    .mfp-arrow-right {
        -webkit-transform-origin: 100%;
        transform-origin: 100%
    }

    .mfp-container {
        padding-left: 6px;
        padding-right: 6px
    }
}

.mfp-ie7 .mfp-img {
    padding: 0
}

.mfp-ie7 .mfp-bottom-bar {
    width: 600px;
    left: 50%;
    margin-left: -300px;
    margin-top: 5px;
    padding-bottom: 5px
}

.mfp-ie7 .mfp-container {
    padding: 0
}

.mfp-ie7 .mfp-content {
    padding-top: 44px
}

.mfp-ie7 .mfp-close {
    top: 0;
    right: 0;
    padding-top: 0
}

.woocommerce-result-count {
    font-style: italic
}

.woocommerce .woocommerce-ordering,
.woocommerce .woocommerce-result-count {
    margin-bottom: 1.5em
}

.woocommerce-ordering select {
    padding: 7px;
    margin-top: -10px
}

.woocommerce ul.products li.product h3 {
    font-size: 18px;
    font-size: 1.125rem;
    margin-bottom: 7px
}

.woocommerce #respond input#submit,
.woocommerce a.button,
.woocommerce button.button,
.woocommerce input.button,
.woocommerce button.button.alt {
    background-color: #03c4eb;
    padding: 10px 20px;
    padding: .625rem 1.25rem;
    color: #FFF;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .5px;
    font-size: 13px;
    font-size: .8125rem;
    border-radius: 2px
}

.woocommerce #respond input#submit:hover,
.woocommerce a.button:hover,
.woocommerce button.button:hover,
.woocommerce input.button:hover,
.woocommerce button.button.alt:hover {
    background: #222;
    color: #FFF;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out
}

.woocommerce table.shop_table {
    border-collapse: collapse
}

.woocommerce .quantity .qty {
    padding: 5px
}

.woocommerce span.onsale {
    padding: inherit
}

.related.products h2,
#tab-description h2 {
    font-size: 17px;
    font-size: 1.0625rem;
    margin-bottom: 20px;
    text-transform: uppercase;
    letter-spacing: 1px
}

.woocommerce nav.woocommerce-pagination ul li a,
.woocommerce nav.woocommerce-pagination ul li span {
    padding: 9px 14px;
    font-weight: 700
}

.woocommerce #customer_details .col-1,
.woocommerce #customer_details .col-2,
.woocommerce #customer_login .col-1,
.woocommerce #customer_login .col-2,
.woocommerce .woocommerce-Addresses .col-1,
.woocommerce .woocommerce-Addresses .col-2,
.woocommerce .woocommerce-customer-details .col-1,
.woocommerce .woocommerce-customer-details .col-2 {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0
}

.woocommerce .woocommerce-additional-fields h3 {
    margin-top: 20px
}

.woocommerce .cart_totals th {
    text-align: left
}

.woocommerce .cart_totals h2 {
    font-size: 20px;
    font-size: 1.25rem
}

.woocommerce .wc-proceed-to-checkout {
    text-align: center
}

.woocommerce .woocommerce-order-details__title,
.woocommerce .woocommerce-customer-details h2 {
    font-size: 20px;
    font-size: 1.25rem
}

.woocommerce ul.products li.product .price del {
    display: inline-block
}

.woocommerce .cart-collaterals .cart_totals,
.woocommerce-page .cart-collaterals .cart_totals {
    width: 100%;
    float: none
}

#add_payment_method .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-checkout .wc-proceed-to-checkout a.checkout-button {
    display: inline-block;
    margin: 0 auto
}

.woocommerce #respond input#submit.alt,
.woocommerce a.button.alt,
.woocommerce button.button.alt,
.woocommerce input.button.alt {
    background-color: #03c4eb
}

.woocommerce #respond input#submit.alt:hover,
.woocommerce a.button.alt:hover,
.woocommerce button.button.alt:hover,
.woocommerce input.button.alt:hover {
    background-color: #03c4eb;
    opacity: .8
}

.woocommerce-MyAccount-navigation ul {
    margin: 0;
    padding-left: 0
}

.woocommerce-MyAccount-navigation li {
    list-style: none
}

.breadcrumbs {
    font-size: 10px;
    padding: 15px 0;
    border-bottom: 1px solid #e9e9e9;
    color: #858585
}

@media screen and (min-width:720px) {
    .breadcrumbs {
        font-size: 12px
    }
}

.breadcrumbs i {
    margin: 0 6px
}

.breadcrumbs a {
    text-decoration: none;
    color: #777;
    font-weight: 600
}

.breadcrumbs a:hover {
    text-decoration: underline
}

.breadcrumbs span {
    margin-right: 20px;
    position: relative
}

@media screen and (min-width:720px) {
    .breadcrumbs span {
        margin-right: 40px
    }
}

.breadcrumbs span span {
    margin-right: 0
}

.breadcrumbs a:before {
    border-bottom: 6px solid transparent;
    border-left: 6px solid rgba(0, 0, 0, .2);
    border-top: 6px solid transparent;
    content: "";
    display: inline-block;
    position: absolute;
    right: -11px;
    top: 2px
}

@media screen and (min-width:720px) {
    .breadcrumbs a:before {
        right: -23px;
        top: 3px
    }
}

.breadcrumbs a:after {
    border-bottom: 5px solid transparent;
    border-left: 5px solid #fff;
    border-top: 5px solid transparent;
    content: "";
    display: inline-block;
    position: absolute;
    right: -10px;
    top: 3px
}

@media screen and (min-width:720px) {
    .breadcrumbs a:after {
        right: -22px;
        top: 4px
    }
}

.owl-carousel {
    display: none;
    width: 100%;
    -webkit-tap-highlight-color: transparent;
    position: relative;
    z-index: 1
}

.owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
    touch-action: manipulation;
    -moz-backface-visibility: hidden
}

.owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0
}

.owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    -webkit-transform: translate3d(0, 0, 0)
}

.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0)
}

.owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none
}

.owl-carousel .owl-item img {
    display: block;
    width: 100%
}

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
    display: none
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
    cursor: pointer;
    cursor: hand;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.owl-carousel.owl-loaded {
    display: block
}

.owl-carousel.owl-loading {
    opacity: 0;
    display: block
}

.owl-carousel.owl-hidden {
    opacity: 0
}

.owl-carousel.owl-refresh .owl-item {
    visibility: hidden
}

.owl-carousel.owl-drag .owl-item {
    -ms-touch-action: none;
    touch-action: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.owl-carousel.owl-grab {
    cursor: move;
    cursor: grab
}

.owl-carousel.owl-rtl {
    direction: rtl
}

.owl-carousel.owl-rtl .owl-item {
    float: right
}

.no-js .owl-carousel {
    display: block
}

.owl-carousel .animated {
    -webkit-animation-duration: 1000ms;
    animation-duration: 1000ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

.owl-carousel .owl-animated-in {
    z-index: 0
}

.owl-carousel .owl-animated-out {
    z-index: 1
}

.owl-carousel .fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1
    }

    100% {
        opacity: 0
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1
    }

    100% {
        opacity: 0
    }
}

.owl-height {
    transition: height 500ms ease-in-out
}

.owl-carousel .owl-item .owl-lazy {
    opacity: 0;
    transition: opacity 400ms ease
}

.owl-carousel .owl-item img.owl-lazy {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d
}

.owl-carousel .owl-video-wrapper {
    position: relative;
    height: 100%;
    background: #000
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
    display: none
}

.owl-carousel .owl-video-tn {
    opacity: 0;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    transition: opacity 400ms ease
}

.owl-carousel .owl-video-frame {
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%
}

.owl-theme {
    position: relative
}

.owl-theme .owl-nav button {
    position: absolute;
    top: 50%;
    background-color: rgba(0, 0, 0, .45);
    border-radius: 2px;
    color: #999;
    cursor: pointer;
    display: block;
    font-size: 22px;
    margin-top: 0;
    padding: 4px 10px 5px;
    transform: translateY(-50%);
    border: 0 none
}

.owl-theme .owl-nav button:focus {
    box-shadow: none;
    outline: none
}

.owl-theme .owl-nav:hover {
    background-color: rgba(0, 0, 0, .6);
    color: #fff
}

.owl-theme .owl-nav .owl-prev {
    left: 15px
}

.owl-theme .owl-nav .owl-prev .lg-icon::before {
    content: "\e094"
}

.owl-theme .owl-nav .owl-next {
    right: 15px
}

.owl-theme .owl-nav .owl-next .lg-icon::before {
    content: "\e095"
}

.owl-theme .owl-nav {
    margin-top: 0;
    text-align: center;
    -webkit-tap-highlight-color: transparent
}

.gallery-carousel.owl-theme .owl-nav [class*='owl-'] {
    color: #FFF;
    font-size: 14px;
    margin: 5px;
    padding: 4px 7px;
    display: inline-block;
    cursor: pointer;
    border-radius: 3px
}

.owl-theme .owl-nav [class*='owl-']:hover {
    background: #869791;
    color: #FFF;
    text-decoration: none
}

.owl-theme .owl-nav .disabled {
    opacity: .5;
    cursor: default
}

.owl-theme .owl-nav.disabled+.owl-dots {
    margin-top: 10px
}

.owl-theme .owl-dots {
    text-align: center;
    -webkit-tap-highlight-color: transparent
}

.owl-theme .owl-dots .owl-dot {
    display: inline-block;
    zoom: 1;
    *display: inline;
    background: none !important;
    box-shadow: none !important;
    outline: none !important;
    border: 0 none;
    padding: 0
}

.owl-theme .owl-dots .owl-dot span {
    width: 10px;
    height: 10px;
    margin: 5px 7px;
    background: #D6D6D6;
    display: block;
    -webkit-backface-visibility: visible;
    transition: opacity 200ms ease;
    border-radius: 30px
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
    background: #869791
}

div.wpforms-container .wpforms-form .wpforms-field .wpforms-field-label,
div.wpforms-container .wpforms-form .wpforms-field .wpforms-field-sublabel {
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 2px;
    font-weight: 600
}

div.wpforms-container .wpforms-form .wpforms-field-row.wpforms-field-medium {
    max-width: 100% !important
}

div.wpforms-container .wpforms-form .wpforms-field input[type=date],
div.wpforms-container .wpforms-form .wpforms-field input[type=datetime],
div.wpforms-container .wpforms-form .wpforms-field input[type=datetime-local],
div.wpforms-container .wpforms-form .wpforms-field input[type=email],
div.wpforms-container .wpforms-form .wpforms-field input[type=month],
div.wpforms-container .wpforms-form .wpforms-field input[type=number],
div.wpforms-container .wpforms-form .wpforms-field input[type=password],
div.wpforms-container .wpforms-form .wpforms-field input[type=range],
div.wpforms-container .wpforms-form .wpforms-field input[type=search],
div.wpforms-container .wpforms-form .wpforms-field input[type=tel],
div.wpforms-container .wpforms-form .wpforms-field input[type=text],
div.wpforms-container .wpforms-form .wpforms-field input[type=time],
div.wpforms-container .wpforms-form .wpforms-field input[type=url],
div.wpforms-container .wpforms-form .wpforms-field input[type=week],
div.wpforms-container .wpforms-form .wpforms-field select,
div.wpforms-container .wpforms-form .wpforms-field textarea {
    padding: 10px;
    max-width: 100%;
    border: 0;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .12) inset;
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, .12) inset;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .12) inset;
    -o-box-shadow: 0 1px 2px rgba(0, 0, 0, .12) inset;
    transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    background-color: #f2f2f2;
    border-bottom: 1px solid #fff;
    box-sizing: border-box;
    color: #000;
    border-bottom: 1px solid #fff
}

div.wpforms-container .wpforms-form .wpforms-field input[type=date]:hover,
div.wpforms-container .wpforms-form .wpforms-field input[type=date]:focus,
div.wpforms-container .wpforms-form .wpforms-field input[type=datetime]:hover,
div.wpforms-container .wpforms-form .wpforms-field input[type=datetime]:focus,
div.wpforms-container .wpforms-form .wpforms-field input[type=datetime-local]:hover,
div.wpforms-container .wpforms-form .wpforms-field input[type=datetime-local]:focus,
div.wpforms-container .wpforms-form .wpforms-field input[type=email]:hover,
div.wpforms-container .wpforms-form .wpforms-field input[type=email]:focus,
div.wpforms-container .wpforms-form .wpforms-field input[type=month]:hover,
div.wpforms-container .wpforms-form .wpforms-field input[type=month]:focus,
div.wpforms-container .wpforms-form .wpforms-field input[type=number]:hover,
div.wpforms-container .wpforms-form .wpforms-field input[type=number]:focus,
div.wpforms-container .wpforms-form .wpforms-field input[type=password]:hover,
div.wpforms-container .wpforms-form .wpforms-field input[type=password]:focus,
div.wpforms-container .wpforms-form .wpforms-field input[type=range]:hover,
div.wpforms-container .wpforms-form .wpforms-field input[type=range]:focus,
div.wpforms-container .wpforms-form .wpforms-field input[type=search]:hover,
div.wpforms-container .wpforms-form .wpforms-field input[type=search]:focus,
div.wpforms-container .wpforms-form .wpforms-field input[type=tel]:hover,
div.wpforms-container .wpforms-form .wpforms-field input[type=tel]:focus,
div.wpforms-container .wpforms-form .wpforms-field input[type=text]:hover,
div.wpforms-container .wpforms-form .wpforms-field input[type=text]:focus,
div.wpforms-container .wpforms-form .wpforms-field input[type=time]:hover,
div.wpforms-container .wpforms-form .wpforms-field input[type=time]:focus,
div.wpforms-container .wpforms-form .wpforms-field input[type=url]:hover,
div.wpforms-container .wpforms-form .wpforms-field input[type=url]:focus,
div.wpforms-container .wpforms-form .wpforms-field input[type=week]:hover,
div.wpforms-container .wpforms-form .wpforms-field input[type=week]:focus,
div.wpforms-container .wpforms-form .wpforms-field select:hover,
div.wpforms-container .wpforms-form .wpforms-field select:focus,
div.wpforms-container .wpforms-form .wpforms-field textarea:hover,
div.wpforms-container .wpforms-form .wpforms-field textarea:focus {
    border: none;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .12) inset;
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, .12) inset;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .12) inset;
    -o-box-shadow: 0 1px 2px rgba(0, 0, 0, .12) inset;
    transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    border-color: #EBEBEB;
    border-bottom: 1px solid #fff !important;
    background: #e9e9e9
}

div.wpforms-container .wpforms-form .wpforms-field textarea {
    min-height: 215px
}

div.wpforms-container .wpforms-form button[type=submit] {
    width: 100%;
    text-align: center;
    display: block
}

.pirate_forms .pirate-forms-fields-container .form_field_wrap {
    width: 100%;
    max-width: 100%;
    padding: 0
}

.pirate_forms .pirate-forms-fields-container .form_field_wrap .form-control {
    border-radius: 0
}

.pirate_forms .pirate-forms-fields-container .form_field_wrap .pirate-forms-submit-button {
    display: block;
    width: 100%
}

.pirate_forms .contact_message_wrap #pirate-forms-contact-message {
    border-radius: 0;
    background-color: #f2f2f2;
    border: 0 none;
    min-height: 245px;
    max-height: 999px
}

[class*="c-bully"],
[class*="c-bully"]:before,
[class*="c-bully"]:after {
    box-sizing: border-box
}

.c-bully {
    position: fixed;
    top: 50%;
    right: 28px;
    font-size: 12px;
    transform: translate(0, -50%);
    z-index: 2900;
    color: #333;
    text-transform: uppercase
}

@media screen and (max-width:940px) {
    .c-bully {
        right: 10px
    }
}

.c-bully.c-bully--inversed {
    color: #FFF
}

.c-bully__bullet {
    padding: .5em;
    opacity: 0;
    cursor: pointer;
    position: relative
}

.c-bully__bullet:hover .c-bully__title {
    opacity: 1;
    transform: scale(1)
}

.c-bully__title {
    position: absolute;
    right: 26px;
    top: 1px;
    left: auto;
    background: rgba(0, 0, 0, .7);
    padding: 1px 10px;
    border-radius: 2px;
    transition: all 0.2s ease-out;
    display: block;
    opacity: 0;
    white-space: nowrap;
    color: rgba(255, 255, 255, .8);
    transform: scale(.6)
}

.c-bully__bullet:after {
    content: "";
    display: block;
    width: 1em;
    height: 1em;
    border: 2px solid currentColor;
    border-radius: 50%;
    will-change: transform
}

.c-bully__bullet--active {
    position: absolute;
    top: 0;
    left: 0;
    transition: top 0.2s ease-out
}

.c-bully__bullet--active:before {
    content: "";
    width: 1em;
    height: 1em;
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 14px;
    transform: translate(-50%, -50%);
    background: currentColor;
    border-radius: 50%
}

.c-bully__bullet--active:after {
    border-color: transparent
}

.c-bully__bullet--pop {
    animation: bully-pop 0.6s cubic-bezier(.485, 1.63, .43, 2) forwards, fade-in 0.2s 0.2s ease-out forwards
}

.c-bully__bullet--squash {
    opacity: 1;
    animation: bully-squash 0.2s ease-out forwards
}

@keyframes fade-in {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

@keyframes bully-pop {
    50% {
        transform: scale(.7)
    }

    100% {
        transform: scale(1)
    }
}

@keyframes bully-squash {
    50% {
        transform: scale(.6, 1.8)
    }

    100% {
        transform: scale(1)
    }
}

@keyframes fade-in {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes editor_region_focus {
    0% {
        box-shadow: inset 0 0 0 0 #33b3db
    }

    to {
        box-shadow: inset 0 0 0 4px #33b3db
    }
}

@keyframes rotation {
    0% {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(1turn)
    }
}

[class*="wp-block"] {
    margin-top: 1.5em;
    margin-bottom: 1.5em
}

.wp-block-code {
    padding: .8em 1em;
    border: 1px solid #e2e4e7;
    border-radius: 4px
}

.wp-block-code,
.wp-block-preformatted pre {
    color: #23282d
}

.wp-block-separator {
    border: none;
    border-bottom: 2px solid #8f98a1;
    margin: 1.65em auto
}

.wp-block-separator:not(.is-style-wide):not(.is-style-dots) {
    max-width: 100px
}

.wp-block-table {
    overflow-x: auto;
    display: block;
    border-collapse: collapse;
    width: 100%
}

.wp-block-table tbody,
.wp-block-table tfoot,
.wp-block-table thead {
    width: 100%;
    min-width: 240px;
    display: table
}

.wp-block-table td,
.wp-block-table th {
    padding: .5em;
    border: 1px solid currentColor
}

.aligncenter img {
    display: block;
    margin: 0 auto
}

p.has-drop-cap:first-letter {
    font-size: 64px;
    font-size: 4rem;
    margin-right: .1em
}

.wp-block-image {
    margin-bottom: 1.5em;
    text-align: center
}

.wp-block-image figcaption {
    font-style: italic;
    font-size: 14px;
    font-size: .875rem;
    margin: .75em auto
}

.entry-content .wp-block-gallery {
    padding-left: 0;
    margin-left: -8px;
    margin-right: -8px
}

.wp-block-quote {
    margin-bottom: 1.5em
}

.wp-block-cover-image {
    margin: 0 0 1.5em
}

.wp-block-cover-image.alignfull {
    transform: translateX(0)
}

.wp-block-video {
    margin: 0 0 1.5em;
    text-align: center
}

.wp-block-video video {
    display: block;
    max-width: 100%;
    margin: 0 auto
}

.wp-block-pullquote {
    border-top: 4px solid #555d66;
    border-bottom: 4px solid #555d66;
    color: #40464d
}

.wp-block-pullquote blockquote {
    border: none;
    font-style: normal;
    background: transparent
}

.wp-block-pullquote blockquote p {
    font-style: normal
}

.wp-block-pullquote__citation,
.wp-block-pullquote cite,
.wp-block-pullquote footer {
    color: #40464d;
    text-transform: uppercase;
    font-size: 13px;
    font-style: italic
}

.wp-block-quote {
    margin: 20px 0
}

.wp-block-quote__citation,
.wp-block-quote cite,
.wp-block-quote footer {
    color: #6c7781;
    font-size: 13px;
    margin-top: 1em;
    position: relative;
    font-style: normal
}

.wp-block-quote:not(.is-large):not(.is-style-large) {
    border-left: 4px solid #000;
    padding-left: 1em
}

@media screen and (min-width:37.5em) {
    .wp-block-pullquote {
        padding: 1.5em .75em
    }

    .wp-block-pullquote>p {
        font-size: 36px;
        font-size: 2.25rem;
        margin: 0 0 .75em
    }
}

.wp-block-pullquote.alignleft>p,
.wp-block-pullquote.alignright>p {
    font-size: 24px;
    font-size: 1.5rem
}

.wp-block-pullquote.alignleft {
    text-align: left
}

.wp-block-pullquote.alignright {
    text-align: right
}

@media screen and (min-width:780px) {
    .wp-block-pullquote.alignleft {
        margin-left: -12.5vw;
        text-align: center
    }

    .wp-block-pullquote.alignright {
        margin-right: -12.5vw;
        text-align: center
    }

    .wp-block-pullquote.alignwide {
        padding: 1.5em 2em
    }

    .wp-block-pullquote.alignfull {
        padding: 2em 3em
    }
}

.wp-block-table {
    display: table
}

.wp-block-table.alignleft,
.wp-block-table.alignright {
    max-width: 50%
}

.wp-block-verse {
    border: 0;
    font-family: inherit;
    padding: 0
}

.wp-block-verse.aligncenter {
    text-align: center
}

.wp-block-verse.alignright {
    text-align: right
}

.wp-block-separator {
    background-color: transparent;
    border-bottom: 3px solid #e9e9e9;
    border-top: 0;
    margin-bottom: 1.5em;
    width: 75%;
    max-width: 75%
}

.no-sidebar .wp-block-separator.alignwide {
    width: 75vw;
    max-width: 75vw
}

.no-sidebar .wp-block-separator.alignfull {
    width: 100vw;
    max-width: 100vw
}

.wp-block-button {
    margin-bottom: 1.5em;
    text-align: center
}

.wp-block-latest-posts__post-date {
    color: #888
}

@media (min-width:768px) {
    .no-sidebar .alignfull {
        clear: both;
        left: 50%;
        transform: translateX(-50%);
        position: relative;
        width: 100vw;
        max-width: 100vw;
        overflow-x: hidden;
        margin: 0 0 1.5em
    }

    .no-sidebar .wp-block-cover-image {
        left: 50%;
        margin-left: -50vw;
        width: 100vw;
        position: relative;
        overflow-x: hidden;
        max-width: 100vw
    }

    .no-sidebar .alignwide {
        left: 50%;
        margin-left: -37.5vw;
        clear: both;
        position: relative;
        width: 75vw;
        max-width: 75vw
    }

    .alignfull .jetpack-video-wrapper iframe,
    .alignwide .jetpack-video-wrapper iframe {
        margin-left: auto !important;
        margin-right: auto !important
    }

    .alignwide img,
    .alignfull img {
        display: block;
        margin: 0 auto
    }

    .wp-block-quote {
        font-size: 24px
    }

    .wp-block-image.alignright {
        margin-right: -12.5vw
    }

    .wp-block-image.alignleft {
        margin-left: -12.5vw
    }
}

@font-face {
    font-family: 'lg';
    src: url(../../../themes/onepress/assets/fonts/lg.eot?n1z373);
    src: url("../../../themes/onepress/assets/fonts/lg.eot?") format("embedded-opentype"), url(../../../themes/onepress/assets/fonts/lg.woff?n1z373) format("woff"), url(../../../themes/onepress/assets/fonts/lg.ttf?n1z373) format("truetype"), url("../../../themes/onepress/assets/fonts/lg.svg?n1z373") format("svg");
    font-weight: 400;
    font-style: normal
}

.lg-icon {
    font-family: 'lg';
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.lg-actions .lg-next,
.lg-actions .lg-prev {
    background-color: rgba(0, 0, 0, .45);
    border-radius: 2px;
    color: #999;
    cursor: pointer;
    display: block;
    font-size: 22px;
    margin-top: -10px;
    padding: 8px 10px 9px;
    position: absolute;
    top: 50%;
    z-index: 1080
}

.lg-actions .lg-next.disabled,
.lg-actions .lg-prev.disabled {
    pointer-events: none;
    opacity: .5
}

.lg-actions .lg-next:hover,
.lg-actions .lg-prev:hover {
    color: #FFF
}

.lg-actions .lg-next {
    right: 20px
}

.lg-actions .lg-next:before {
    content: "\e095"
}

.lg-actions .lg-prev {
    left: 20px
}

.lg-actions .lg-prev:after {
    content: "\e094"
}

@-webkit-keyframes lg-right-end {
    0% {
        left: 0
    }

    50% {
        left: -30px
    }

    100% {
        left: 0
    }
}

@keyframes lg-right-end {
    0% {
        left: 0
    }

    50% {
        left: -30px
    }

    100% {
        left: 0
    }
}

@-webkit-keyframes lg-left-end {
    0% {
        left: 0
    }

    50% {
        left: 30px
    }

    100% {
        left: 0
    }
}

@keyframes lg-left-end {
    0% {
        left: 0
    }

    50% {
        left: 30px
    }

    100% {
        left: 0
    }
}

.lg-outer.lg-right-end .lg-object {
    -webkit-animation: lg-right-end 0.3s;
    animation: lg-right-end 0.3s;
    position: relative
}

.lg-outer.lg-left-end .lg-object {
    -webkit-animation: lg-left-end 0.3s;
    animation: lg-left-end 0.3s;
    position: relative
}

.lg-toolbar {
    z-index: 1082;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, .45)
}

.lg-toolbar .lg-icon {
    color: #999;
    cursor: pointer;
    float: right;
    font-size: 24px;
    height: 47px;
    line-height: 27px;
    padding: 10px 0;
    text-align: center;
    width: 50px;
    text-decoration: none !important;
    outline: medium none;
    -webkit-transition: color 0.2s linear;
    transition: color 0.2s linear
}

.lg-toolbar .lg-icon:hover {
    color: #FFF
}

.lg-toolbar .lg-close:after {
    content: "\e070"
}

.lg-toolbar .lg-download:after {
    content: "\e0f2"
}

.lg-sub-html {
    background-color: rgba(0, 0, 0, .45);
    bottom: 0;
    color: #EEE;
    font-size: 16px;
    left: 0;
    padding: 10px 40px;
    position: fixed;
    right: 0;
    text-align: center;
    z-index: 1080
}

.lg-sub-html h4 {
    margin: 0;
    font-size: 13px;
    font-weight: 700
}

.lg-sub-html p {
    font-size: 12px;
    margin: 5px 0 0
}

#lg-counter {
    color: #999;
    display: inline-block;
    font-size: 16px;
    padding-left: 20px;
    padding-top: 12px;
    vertical-align: middle
}

.lg-toolbar,
.lg-prev,
.lg-next {
    opacity: 1;
    -webkit-transition: -webkit-transform 0.35s cubic-bezier(0, 0, .25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, .25, 1) 0s, color 0.2s linear;
    -webkit-transition: opacity 0.35s cubic-bezier(0, 0, .25, 1) 0s, color 0.2s linear, -webkit-transform 0.35s cubic-bezier(0, 0, .25, 1) 0s;
    transition: opacity 0.35s cubic-bezier(0, 0, .25, 1) 0s, color 0.2s linear, -webkit-transform 0.35s cubic-bezier(0, 0, .25, 1) 0s;
    transition: transform 0.35s cubic-bezier(0, 0, .25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, .25, 1) 0s, color 0.2s linear;
    transition: transform 0.35s cubic-bezier(0, 0, .25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, .25, 1) 0s, color 0.2s linear, -webkit-transform 0.35s cubic-bezier(0, 0, .25, 1) 0s
}

.lg-hide-items .lg-prev {
    opacity: 0;
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0)
}

.lg-hide-items .lg-next {
    opacity: 0;
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0)
}

.lg-hide-items .lg-toolbar {
    opacity: 0;
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0)
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-object {
    -webkit-transform: scale3d(.5, .5, .5);
    transform: scale3d(.5, .5, .5);
    opacity: 0;
    -webkit-transition: -webkit-transform 250ms cubic-bezier(0, 0, .25, 1) 0s, opacity 250ms cubic-bezier(0, 0, .25, 1) !important;
    -webkit-transition: opacity 250ms cubic-bezier(0, 0, .25, 1), -webkit-transform 250ms cubic-bezier(0, 0, .25, 1) 0s !important;
    transition: opacity 250ms cubic-bezier(0, 0, .25, 1), -webkit-transform 250ms cubic-bezier(0, 0, .25, 1) 0s !important;
    transition: transform 250ms cubic-bezier(0, 0, .25, 1) 0s, opacity 250ms cubic-bezier(0, 0, .25, 1) !important;
    transition: transform 250ms cubic-bezier(0, 0, .25, 1) 0s, opacity 250ms cubic-bezier(0, 0, .25, 1), -webkit-transform 250ms cubic-bezier(0, 0, .25, 1) 0s !important;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item.lg-complete .lg-object {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    opacity: 1
}

.lg-outer .lg-thumb-outer {
    background-color: #0D0A0A;
    bottom: 0;
    position: absolute;
    width: 100%;
    z-index: 1080;
    max-height: 350px;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, .25, 1) 0s;
    transition: -webkit-transform 0.25s cubic-bezier(0, 0, .25, 1) 0s;
    transition: transform 0.25s cubic-bezier(0, 0, .25, 1) 0s;
    transition: transform 0.25s cubic-bezier(0, 0, .25, 1) 0s, -webkit-transform 0.25s cubic-bezier(0, 0, .25, 1) 0s
}

.lg-outer .lg-thumb-outer.lg-grab .lg-thumb-item {
    cursor: -webkit-grab;
    cursor: -o-grab;
    cursor: -ms-grab;
    cursor: grab
}

.lg-outer .lg-thumb-outer.lg-grabbing .lg-thumb-item {
    cursor: move;
    cursor: -webkit-grabbing;
    cursor: -o-grabbing;
    cursor: -ms-grabbing;
    cursor: grabbing
}

.lg-outer .lg-thumb-outer.lg-dragging .lg-thumb {
    -webkit-transition-duration: 0s !important;
    transition-duration: 0s !important
}

.lg-outer.lg-thumb-open .lg-thumb-outer {
    -webkit-transform: translate3d(0, 0%, 0);
    transform: translate3d(0, 0%, 0)
}

.lg-outer .lg-thumb {
    padding: 10px 0;
    height: 100%;
    margin-bottom: -5px
}

.lg-outer .lg-thumb-item {
    border-radius: 5px;
    cursor: pointer;
    float: left;
    overflow: hidden;
    height: 100%;
    border: 2px solid #FFF;
    border-radius: 4px;
    margin-bottom: 5px
}

@media (min-width:1025px) {
    .lg-outer .lg-thumb-item {
        -webkit-transition: border-color 0.25s ease;
        transition: border-color 0.25s ease
    }
}

.lg-outer .lg-thumb-item.active,
.lg-outer .lg-thumb-item:hover {
    border-color: #a90707
}

.lg-outer .lg-thumb-item img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.lg-outer.lg-has-thumb .lg-item {
    padding-bottom: 120px
}

.lg-outer.lg-can-toggle .lg-item {
    padding-bottom: 0
}

.lg-outer.lg-pull-caption-up .lg-sub-html {
    -webkit-transition: bottom 0.25s ease;
    transition: bottom 0.25s ease
}

.lg-outer.lg-pull-caption-up.lg-thumb-open .lg-sub-html {
    bottom: 100px
}

.lg-outer .lg-toogle-thumb {
    background-color: #0D0A0A;
    border-radius: 2px 2px 0 0;
    color: #999;
    cursor: pointer;
    font-size: 24px;
    height: 39px;
    line-height: 27px;
    padding: 5px 0;
    position: absolute;
    right: 20px;
    text-align: center;
    top: -39px;
    width: 50px
}

.lg-outer .lg-toogle-thumb:after {
    content: "\e1ff"
}

.lg-outer .lg-toogle-thumb:hover {
    color: #FFF
}

.lg-outer .lg-video-cont {
    display: inline-block;
    vertical-align: middle;
    max-width: 1140px;
    max-height: 100%;
    width: 100%;
    padding: 0 5px
}

.lg-outer .lg-video {
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
    position: relative
}

.lg-outer .lg-video .lg-object {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important
}

.lg-outer .lg-video .lg-video-play {
    width: 84px;
    height: 59px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -42px;
    margin-top: -30px;
    z-index: 1080;
    cursor: pointer
}

.lg-outer .lg-has-html5 .lg-video-play {
    height: 64px;
    margin-left: -32px;
    margin-top: -32px;
    width: 64px;
    opacity: .8
}

.lg-outer .lg-has-html5:hover .lg-video-play {
    opacity: 1
}

.lg-outer .lg-video-object {
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    top: 0;
    left: 0
}

.lg-outer .lg-has-video .lg-video-object {
    visibility: hidden
}

.lg-outer .lg-has-video.lg-video-playing .lg-object,
.lg-outer .lg-has-video.lg-video-playing .lg-video-play {
    display: none
}

.lg-outer .lg-has-video.lg-video-playing .lg-video-object {
    visibility: visible
}

.lg-progress-bar {
    background-color: #333;
    height: 5px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1083;
    opacity: 0;
    -webkit-transition: opacity 0.08s ease 0s;
    transition: opacity 0.08s ease 0s
}

.lg-progress-bar .lg-progress {
    background-color: #a90707;
    height: 5px;
    width: 0
}

.lg-progress-bar.lg-start .lg-progress {
    width: 100%
}

.lg-show-autoplay .lg-progress-bar {
    opacity: 1
}

.lg-autoplay-button:after {
    content: "\e01d"
}

.lg-show-autoplay .lg-autoplay-button:after {
    content: "\e01a"
}

.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-img-wrap,
.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-image {
    -webkit-transition-duration: 0s;
    transition-duration: 0s
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
    -webkit-transition: left 0.3s cubic-bezier(0, 0, .25, 1) 0s, top 0.3s cubic-bezier(0, 0, .25, 1) 0s;
    transition: left 0.3s cubic-bezier(0, 0, .25, 1) 0s, top 0.3s cubic-bezier(0, 0, .25, 1) 0s;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, .25, 1) 0s, opacity 0.15s !important;
    -webkit-transition: opacity 0.15s, -webkit-transform 0.3s cubic-bezier(0, 0, .25, 1) 0s !important;
    transition: opacity 0.15s, -webkit-transform 0.3s cubic-bezier(0, 0, .25, 1) 0s !important;
    transition: transform 0.3s cubic-bezier(0, 0, .25, 1) 0s, opacity 0.15s !important;
    transition: transform 0.3s cubic-bezier(0, 0, .25, 1) 0s, opacity 0.15s, -webkit-transform 0.3s cubic-bezier(0, 0, .25, 1) 0s !important;
    -webkit-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

#lg-zoom-in:after {
    content: "\e311"
}

#lg-actual-size {
    font-size: 20px
}

#lg-actual-size:after {
    content: "\e033"
}

#lg-zoom-out {
    opacity: .5;
    pointer-events: none
}

#lg-zoom-out:after {
    content: "\e312"
}

.lg-zoomed #lg-zoom-out {
    opacity: 1;
    pointer-events: auto
}

.lg-outer .lg-pager-outer {
    bottom: 60px;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    z-index: 1080;
    height: 10px
}

.lg-outer .lg-pager-outer.lg-pager-hover .lg-pager-cont {
    overflow: visible
}

.lg-outer .lg-pager-cont {
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    position: relative;
    vertical-align: top;
    margin: 0 5px
}

.lg-outer .lg-pager-cont:hover .lg-pager-thumb-cont {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.lg-outer .lg-pager-cont.lg-pager-active .lg-pager {
    box-shadow: 0 0 0 2px white inset
}

.lg-outer .lg-pager-thumb-cont {
    background-color: #fff;
    color: #FFF;
    bottom: 100%;
    height: 83px;
    left: 0;
    margin-bottom: 20px;
    margin-left: -60px;
    opacity: 0;
    padding: 5px;
    position: absolute;
    width: 120px;
    border-radius: 3px;
    -webkit-transition: opacity 0.15s ease 0s, -webkit-transform 0.15s ease 0s;
    transition: opacity 0.15s ease 0s, -webkit-transform 0.15s ease 0s;
    transition: opacity 0.15s ease 0s, transform 0.15s ease 0s;
    transition: opacity 0.15s ease 0s, transform 0.15s ease 0s, -webkit-transform 0.15s ease 0s;
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0)
}

.lg-outer .lg-pager-thumb-cont img {
    width: 100%;
    height: 100%
}

.lg-outer .lg-pager {
    background-color: rgba(255, 255, 255, .5);
    border-radius: 50%;
    box-shadow: 0 0 0 8px rgba(255, 255, 255, .7) inset;
    display: block;
    height: 12px;
    -webkit-transition: box-shadow 0.3s ease 0s;
    transition: box-shadow 0.3s ease 0s;
    width: 12px
}

.lg-outer .lg-pager:hover,
.lg-outer .lg-pager:focus {
    box-shadow: 0 0 0 8px white inset
}

.lg-outer .lg-caret {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px dashed;
    bottom: -10px;
    display: inline-block;
    height: 0;
    left: 50%;
    margin-left: -5px;
    position: absolute;
    vertical-align: middle;
    width: 0
}

.lg-fullscreen:after {
    content: "\e20c"
}

.lg-fullscreen-on .lg-fullscreen:after {
    content: "\e20d"
}

.lg-outer #lg-dropdown-overlay {
    background-color: rgba(0, 0, 0, .25);
    bottom: 0;
    cursor: default;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1081;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
    transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s
}

.lg-outer.lg-dropdown-active .lg-dropdown,
.lg-outer.lg-dropdown-active #lg-dropdown-overlay {
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
    -ms-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
    visibility: visible
}

.lg-outer.lg-dropdown-active #lg-share {
    color: #FFF
}

.lg-outer .lg-dropdown {
    background-color: #fff;
    border-radius: 2px;
    font-size: 14px;
    list-style-type: none;
    margin: 0;
    padding: 10px 0;
    position: absolute;
    right: 0;
    text-align: left;
    top: 50px;
    opacity: 0;
    visibility: hidden;
    -ms-transform: translate3d(0, 5px, 0);
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
    -webkit-transition: -webkit-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
    -webkit-transition: visibility 0s linear 0.5s, opacity 0.18s linear 0s, -webkit-transform 0.18s linear 0s;
    transition: visibility 0s linear 0.5s, opacity 0.18s linear 0s, -webkit-transform 0.18s linear 0s;
    transition: transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
    transition: transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s, -webkit-transform 0.18s linear 0s
}

.lg-outer .lg-dropdown:after {
    content: "";
    display: block;
    height: 0;
    width: 0;
    position: absolute;
    border: 8px solid transparent;
    border-bottom-color: #FFF;
    right: 16px;
    top: -16px
}

.lg-outer .lg-dropdown>li:last-child {
    margin-bottom: 0
}

.lg-outer .lg-dropdown>li:hover a,
.lg-outer .lg-dropdown>li:hover .lg-icon {
    color: #333
}

.lg-outer .lg-dropdown a {
    color: #333;
    display: block;
    white-space: pre;
    padding: 4px 12px;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px
}

.lg-outer .lg-dropdown a:hover {
    background-color: rgba(0, 0, 0, .07)
}

.lg-outer .lg-dropdown .lg-dropdown-text {
    display: inline-block;
    line-height: 1;
    margin-top: -3px;
    vertical-align: middle
}

.lg-outer .lg-dropdown .lg-icon {
    color: #333;
    display: inline-block;
    float: none;
    font-size: 20px;
    height: auto;
    line-height: 1;
    margin-right: 8px;
    padding: 0;
    vertical-align: middle;
    width: auto
}

.lg-outer #lg-share {
    position: relative
}

.lg-outer #lg-share:after {
    content: "\e80d"
}

.lg-outer #lg-share-facebook .lg-icon {
    color: #3b5998
}

.lg-outer #lg-share-facebook .lg-icon:after {
    content: "\e901"
}

.lg-outer #lg-share-twitter .lg-icon {
    color: #00aced
}

.lg-outer #lg-share-twitter .lg-icon:after {
    content: "\e904"
}

.lg-outer #lg-share-googleplus .lg-icon {
    color: #dd4b39
}

.lg-outer #lg-share-googleplus .lg-icon:after {
    content: "\e902"
}

.lg-outer #lg-share-pinterest .lg-icon {
    color: #cb2027
}

.lg-outer #lg-share-pinterest .lg-icon:after {
    content: "\e903"
}

.group {
    *zoom: 1
}

.group:before,
.group:after {
    display: table;
    content: "";
    line-height: 0
}

.group:after {
    clear: both
}

.lg-outer {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    opacity: 0;
    -webkit-transition: opacity 0.15s ease 0s;
    transition: opacity 0.15s ease 0s
}

.lg-outer * {
    box-sizing: border-box
}

.lg-outer.lg-visible {
    opacity: 1
}

.lg-outer.lg-css3 .lg-item.lg-prev-slide,
.lg-outer.lg-css3 .lg-item.lg-next-slide,
.lg-outer.lg-css3 .lg-item.lg-current {
    -webkit-transition-duration: inherit !important;
    transition-duration: inherit !important;
    -webkit-transition-timing-function: inherit !important;
    transition-timing-function: inherit !important
}

.lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide,
.lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide,
.lg-outer.lg-css3.lg-dragging .lg-item.lg-current {
    -webkit-transition-duration: 0s !important;
    transition-duration: 0s !important;
    opacity: 1
}

.lg-outer.lg-grab img.lg-object {
    cursor: -webkit-grab;
    cursor: -o-grab;
    cursor: -ms-grab;
    cursor: grab
}

.lg-outer.lg-grabbing img.lg-object {
    cursor: move;
    cursor: -webkit-grabbing;
    cursor: -o-grabbing;
    cursor: -ms-grabbing;
    cursor: grabbing
}

.lg-outer .lg {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    max-height: 100%
}

.lg-outer .lg-inner {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    white-space: nowrap
}

.lg-outer .lg-item {
    background: url(../../../../../wp-content/themes/onepress/assets/images/loading.gif) no-repeat scroll center center transparent;
    display: none !important
}

.lg-outer.lg-css3 .lg-prev-slide,
.lg-outer.lg-css3 .lg-current,
.lg-outer.lg-css3 .lg-next-slide {
    display: inline-block !important
}

.lg-outer.lg-css .lg-current {
    display: inline-block !important
}

.lg-outer .lg-item,
.lg-outer .lg-img-wrap {
    display: inline-block;
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%
}

.lg-outer .lg-item:before,
.lg-outer .lg-img-wrap:before {
    content: "";
    display: inline-block;
    height: 50%;
    width: 1px;
    margin-right: -1px
}

.lg-outer .lg-img-wrap {
    position: absolute;
    padding: 0 5px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0
}

.lg-outer .lg-item.lg-complete {
    background-image: none
}

.lg-outer .lg-item.lg-current {
    z-index: 1060
}

.lg-outer .lg-image {
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    max-height: 100%;
    width: auto !important;
    height: auto !important
}

.lg-outer.lg-show-after-load .lg-item .lg-object,
.lg-outer.lg-show-after-load .lg-item .lg-video-play {
    opacity: 0;
    -webkit-transition: opacity 0.15s ease 0s;
    transition: opacity 0.15s ease 0s
}

.lg-outer.lg-show-after-load .lg-item.lg-complete .lg-object,
.lg-outer.lg-show-after-load .lg-item.lg-complete .lg-video-play {
    opacity: 1
}

.lg-outer .lg-empty-html {
    display: none
}

.lg-outer.lg-hide-download #lg-download {
    display: none
}

.lg-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1040;
    background-color: #000;
    opacity: 0;
    -webkit-transition: opacity 0.15s ease 0s;
    transition: opacity 0.15s ease 0s
}

.lg-backdrop.in {
    opacity: 1
}

.lg-css3.lg-no-trans .lg-prev-slide,
.lg-css3.lg-no-trans .lg-next-slide,
.lg-css3.lg-no-trans .lg-current {
    -webkit-transition: none 0s ease 0s !important;
    transition: none 0s ease 0s !important
}

.lg-css3.lg-use-css3 .lg-item {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.lg-css3.lg-use-left .lg-item {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.lg-css3.lg-fade .lg-item {
    opacity: 0
}

.lg-css3.lg-fade .lg-item.lg-current {
    opacity: 1
}

.lg-css3.lg-fade .lg-item.lg-prev-slide,
.lg-css3.lg-fade .lg-item.lg-next-slide,
.lg-css3.lg-fade .lg-item.lg-current {
    -webkit-transition: opacity 0.1s ease 0s;
    transition: opacity 0.1s ease 0s
}

.lg-css3.lg-slide.lg-use-css3 .lg-item {
    opacity: 0
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0)
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0)
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide,
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide,
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, .25, 1) 0s, opacity 0.1s ease 0s;
    -webkit-transition: opacity 0.1s ease 0s, -webkit-transform 1s cubic-bezier(0, 0, .25, 1) 0s;
    transition: opacity 0.1s ease 0s, -webkit-transform 1s cubic-bezier(0, 0, .25, 1) 0s;
    transition: transform 1s cubic-bezier(0, 0, .25, 1) 0s, opacity 0.1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, .25, 1) 0s, opacity 0.1s ease 0s, -webkit-transform 1s cubic-bezier(0, 0, .25, 1) 0s
}

.lg-css3.lg-slide.lg-use-left .lg-item {
    opacity: 0;
    position: absolute;
    left: 0
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
    left: -100%
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide {
    left: 100%
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
    left: 0;
    opacity: 1
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide,
.lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide,
.lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
    -webkit-transition: left 1s cubic-bezier(0, 0, .25, 1) 0s, opacity 0.1s ease 0s;
    transition: left 1s cubic-bezier(0, 0, .25, 1) 0s, opacity 0.1s ease 0s
}

body.lg-on .lg-backdrop {
    z-index: 99999
}

body.lg-on .lg-outer {
    z-index: 999999
}

.wpforms-clear:before {
    content: " ";
    display: table
}

.wpforms-clear:after {
    clear: both;
    content: " ";
    display: table
}

div.wpforms-container-full,
div.wpforms-container-full .wpforms-form * {
    background: none;
    border: 0 none;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    float: none;
    font-size: 100%;
    height: auto;
    letter-spacing: normal;
    list-style: none;
    outline: none;
    position: static;
    text-decoration: none;
    text-indent: 0;
    text-shadow: none;
    text-transform: none;
    width: auto;
    visibility: visible;
    overflow: visible;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none
}

div.wpforms-container-full .wpforms-form input,
div.wpforms-container-full .wpforms-form label,
div.wpforms-container-full .wpforms-form select,
div.wpforms-container-full .wpforms-form button,
div.wpforms-container-full .wpforms-form textarea {
    margin: 0;
    border: 0;
    padding: 0;
    display: inline-block;
    vertical-align: middle;
    background: none;
    height: auto;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

div.wpforms-container-full .wpforms-form textarea {
    width: 100%
}

div.wpforms-container-full .wpforms-form ul,
div.wpforms-container-full .wpforms-form ul li {
    background: none !important;
    border: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    list-style: none !important
}

div.wpforms-container-full .wpforms-form ul li {
    margin-bottom: 5px !important
}

div.wpforms-container-full .wpforms-form ul li:last-of-type {
    margin-bottom: 0 !important
}

div.wpforms-container-full .wpforms-form hr {
    border-top: 1px solid #ccc;
    margin: .5em auto
}

div.wpforms-container-full .wpforms-form input.wpforms-field-medium,
div.wpforms-container-full .wpforms-form select.wpforms-field-medium,
div.wpforms-container-full .wpforms-form .wpforms-field-row.wpforms-field-medium {
    max-width: 60%
}

div.wpforms-container-full .wpforms-form textarea.wpforms-field-medium {
    height: 120px
}

div.wpforms-container-full .wpforms-form input.wpforms-field-small,
div.wpforms-container-full .wpforms-form select.wpforms-field-small,
div.wpforms-container-full .wpforms-form .wpforms-field-row.wpforms-field-small {
    max-width: 25%
}

div.wpforms-container-full .wpforms-form textarea.wpforms-field-small {
    height: 70px
}

div.wpforms-container-full .wpforms-form input.wpforms-field-large,
div.wpforms-container-full .wpforms-form select.wpforms-field-large,
div.wpforms-container-full .wpforms-form .wpforms-field-row.wpforms-field-large {
    max-width: 100%
}

div.wpforms-container-full .wpforms-form textarea.wpforms-field-large {
    height: 220px
}

div.wpforms-container-full .wpforms-form .wpforms-field {
    padding: 10px 0;
    clear: both
}

div.wpforms-container-full .wpforms-form .wpforms-field-description {
    font-size: 13px;
    line-height: 1.3;
    margin: 8px 0 0 0
}

div.wpforms-container-full .wpforms-form .wpforms-field-description.wpforms-disclaimer-description {
    background-color: #fff;
    border: 1px solid #ddd;
    color: #444;
    padding: 15px 15px 0;
    margin-top: 15px;
    height: 125px;
    overflow-y: scroll;
    overflow-x: hidden;
    font-size: 12px
}

div.wpforms-container-full .wpforms-form .wpforms-field-description.wpforms-disclaimer-description p {
    color: #444;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 15px
}

div.wpforms-container-full .wpforms-form .wpforms-field-description-before,
div.wpforms-container-full .wpforms-form .wpforms-field-description.before {
    margin: 0 0 8px 0
}

div.wpforms-container-full .wpforms-form .wpforms-field-label {
    display: block;
    font-weight: 700;
    font-size: 16px;
    float: none;
    line-height: 1.3;
    margin: 0 0 4px 0;
    padding: 0
}

div.wpforms-container-full .wpforms-form .wpforms-field-sublabel {
    display: block;
    font-size: 13px;
    float: none;
    font-weight: 400;
    line-height: 1.3;
    margin: 4px 0 0;
    padding: 0
}

div.wpforms-container-full .wpforms-form .wpforms-field-sublabel.before {
    margin: 0 0 4px 0
}

div.wpforms-container-full .wpforms-form .wpforms-field-label-inline {
    display: inline;
    vertical-align: baseline;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.3
}

div.wpforms-container-full .wpforms-form .wpforms-field-label.wpforms-label-hide,
div.wpforms-container-full .wpforms-form .wpforms-field-sublabel.wpforms-sublabel-hide {
    position: absolute;
    left: -99999px
}

div.wpforms-container-full .wpforms-form .wpforms-required-label {
    color: red;
    font-weight: 400
}

div.wpforms-container-full .wpforms-form .wpforms-field-row {
    margin-bottom: 8px;
    position: relative
}

div.wpforms-container-full .wpforms-form .wpforms-field .wpforms-field-row:last-of-type {
    margin-bottom: 0
}

div.wpforms-container-full .wpforms-form .wpforms-field-row:before {
    content: " ";
    display: table
}

div.wpforms-container-full .wpforms-form .wpforms-field-row:after {
    clear: both;
    content: " ";
    display: table
}

div.wpforms-container-full .wpforms-form .wpforms-five-sixths,
div.wpforms-container-full .wpforms-form .wpforms-four-sixths,
div.wpforms-container-full .wpforms-form .wpforms-four-fifths,
div.wpforms-container-full .wpforms-form .wpforms-one-fifth,
div.wpforms-container-full .wpforms-form .wpforms-one-fourth,
div.wpforms-container-full .wpforms-form .wpforms-one-half,
div.wpforms-container-full .wpforms-form .wpforms-one-sixth,
div.wpforms-container-full .wpforms-form .wpforms-one-third,
div.wpforms-container-full .wpforms-form .wpforms-three-fourths,
div.wpforms-container-full .wpforms-form .wpforms-three-fifths,
div.wpforms-container-full .wpforms-form .wpforms-three-sixths,
div.wpforms-container-full .wpforms-form .wpforms-two-fourths,
div.wpforms-container-full .wpforms-form .wpforms-two-fifths,
div.wpforms-container-full .wpforms-form .wpforms-two-sixths,
div.wpforms-container-full .wpforms-form .wpforms-two-thirds {
    float: left;
    margin-left: 4%;
    clear: none
}

div.wpforms-container-full .wpforms-form .wpforms-one-half,
div.wpforms-container-full .wpforms-form .wpforms-three-sixths,
div.wpforms-container-full .wpforms-form .wpforms-two-fourths {
    width: 48%
}

div.wpforms-container-full .wpforms-form .wpforms-one-third,
div.wpforms-container-full .wpforms-form .wpforms-two-sixths {
    width: 30.6666666667%
}

div.wpforms-container-full .wpforms-form .wpforms-four-sixths,
div.wpforms-container-full .wpforms-form .wpforms-two-thirds {
    width: 65.3333333333%
}

div.wpforms-container-full .wpforms-form .wpforms-one-fourth {
    width: 22%
}

div.wpforms-container-full .wpforms-form .wpforms-three-fourths {
    width: 74%
}

div.wpforms-container-full .wpforms-form .wpforms-one-fifth {
    width: 16.8%
}

div.wpforms-container-full .wpforms-form .wpforms-two-fifths {
    width: 37.6%
}

div.wpforms-container-full .wpforms-form .wpforms-three-fifths {
    width: 58.4%
}

div.wpforms-container-full .wpforms-form .wpforms-four-fifths {
    width: 79.2%
}

div.wpforms-container-full .wpforms-form .wpforms-one-sixth {
    width: 13.3333333333%
}

div.wpforms-container-full .wpforms-form .wpforms-five-sixths {
    width: 82.6666666667%
}

div.wpforms-container-full .wpforms-form .wpforms-first {
    clear: both !important;
    margin-left: 0 !important
}

div.wpforms-container-full .wpforms-form .wpforms-checkbox-2-columns ul,
div.wpforms-container-full .wpforms-form .wpforms-multiplechoice-2-columns ul,
div.wpforms-container-full .wpforms-form .wpforms-list-2-columns ul,
div.wpforms-container-full .wpforms-form .wpforms-checkbox-3-columns ul,
div.wpforms-container-full .wpforms-form .wpforms-multiplechoice-3-columns ul,
div.wpforms-container-full .wpforms-form .wpforms-list-3-columns ul {
    display: -ms-flex;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap
}

div.wpforms-container-full .wpforms-form .wpforms-checkbox-2-columns ul li,
div.wpforms-container-full .wpforms-form .wpforms-multiplechoice-2-columns ul li,
div.wpforms-container-full .wpforms-form .wpforms-list-2-columns ul li {
    width: 50%;
    display: block;
    padding-right: 26px !important
}

div.wpforms-container-full .wpforms-form .wpforms-checkbox-3-columns ul li,
div.wpforms-container-full .wpforms-form .wpforms-multiplechoice-3-columns ul li,
div.wpforms-container-full .wpforms-form .wpforms-list-3-columns ul li {
    width: 33.3333%;
    display: block;
    padding-right: 26px !important
}

div.wpforms-container-full .wpforms-form .wpforms-list-inline ul li {
    display: inline-block;
    vertical-align: top;
    margin-right: 20px !important
}

div.wpforms-container-full .wpforms-form .wpforms-field.wpforms-first-half {
    float: left;
    width: 48%;
    clear: both
}

div.wpforms-container-full .wpforms-form .wpforms-field.wpforms-last-half {
    float: right;
    width: 48%;
    clear: none
}

div.wpforms-container-full .wpforms-form .wpforms-field.wpforms-first-third {
    float: left;
    width: 30.666666667%;
    clear: both
}

div.wpforms-container-full .wpforms-form .wpforms-field.wpforms-middle-third {
    float: left;
    width: 30.666666667%;
    margin-left: 4%;
    clear: none
}

div.wpforms-container-full .wpforms-form .wpforms-field.wpforms-last-third {
    float: right;
    width: 30.666666667%;
    clear: none
}

div.wpforms-container-full .wpforms-form div.wpforms-last {
    float: right !important;
    margin-right: 0 !important;
    clear: none
}

div.wpforms-container-full.inline-fields {
    overflow: hidden
}

div.wpforms-container-full.inline-fields .wpforms-form .wpforms-field-container {
    display: table;
    width: calc(100% - 160px);
    float: left
}

div.wpforms-container-full.inline-fields .wpforms-form .wpforms-field {
    display: table-cell;
    padding-right: 2%;
    vertical-align: top
}

div.wpforms-container-full.inline-fields .wpforms-form .wpforms-submit-container {
    float: right;
    width: 160px;
    clear: none
}

div.wpforms-container-full.inline-fields .wpforms-form .wpforms-submit {
    display: block;
    width: 100%
}

div.wpforms-container-full.inline-fields .wpforms-form input.wpforms-field-medium,
div.wpforms-container-full.inline-fields .wpforms-form select.wpforms-field-medium,
div.wpforms-container-full.inline-fields .wpforms-form .wpforms-field-row.wpforms-field-medium {
    max-width: 100%
}

div.wpforms-container-full .wpforms-form input[type=date],
div.wpforms-container-full .wpforms-form input[type=datetime],
div.wpforms-container-full .wpforms-form input[type=datetime-local],
div.wpforms-container-full .wpforms-form input[type=email],
div.wpforms-container-full .wpforms-form input[type=month],
div.wpforms-container-full .wpforms-form input[type=number],
div.wpforms-container-full .wpforms-form input[type=password],
div.wpforms-container-full .wpforms-form input[type=range],
div.wpforms-container-full .wpforms-form input[type=search],
div.wpforms-container-full .wpforms-form input[type=tel],
div.wpforms-container-full .wpforms-form input[type=text],
div.wpforms-container-full .wpforms-form input[type=time],
div.wpforms-container-full .wpforms-form input[type=url],
div.wpforms-container-full .wpforms-form input[type=week],
div.wpforms-container-full .wpforms-form select,
div.wpforms-container-full .wpforms-form textarea {
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 2px;
    color: #333;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    display: block;
    float: none;
    font-size: 16px;
    border: 1px solid #ccc;
    padding: 6px 10px;
    height: 38px;
    width: 100%;
    line-height: 1.3
}

div.wpforms-container-full .wpforms-form input[type=checkbox],
div.wpforms-container-full .wpforms-form input[type=radio] {
    border: 1px solid #ccc;
    background-color: #fff;
    width: 14px;
    height: 14px;
    margin: 0 10px 0 3px;
    display: inline-block;
    vertical-align: baseline
}

div.wpforms-container-full .wpforms-form input[type=radio] {
    border-radius: 50%
}

div.wpforms-container-full .wpforms-form select {
    max-width: 100%;
    text-transform: none;
    white-space: nowrap
}

div.wpforms-container-full .wpforms-form input[type=submit],
div.wpforms-container-full .wpforms-form button[type=submit],
div.wpforms-container-full .wpforms-form .wpforms-page-button {
    background-color: #eee;
    border: 1px solid #ddd;
    color: #333;
    font-size: 1em;
    padding: 10px 15px
}

div.wpforms-container-full .wpforms-form .wpforms-page-button {
    font-size: .9em;
    font-weight: 400;
    margin: 0 5px;
    min-width: 90px;
    text-align: center
}

div.wpforms-container-full .wpforms-form input[type=submit]:hover,
div.wpforms-container-full .wpforms-form input[type=submit]:active,
div.wpforms-container-full .wpforms-form button[type=submit]:hover,
div.wpforms-container-full .wpforms-form button[type=submit]:active,
div.wpforms-container-full .wpforms-form .wpforms-page-button:hover,
div.wpforms-container-full .wpforms-form .wpforms-page-button:active {
    background-color: #ddd;
    border: 1px solid #ccc;
    cursor: pointer
}

div.wpforms-container-full .wpforms-form input:focus,
div.wpforms-container-full .wpforms-form textarea:focus,
div.wpforms-container-full .wpforms-form select:focus {
    border: 1px solid #999
}

div.wpforms-container-full .wpforms-form input:disabled,
div.wpforms-container-full .wpforms-form textarea:disabled,
div.wpforms-container-full .wpforms-form select:disabled {
    background-color: #f9f9f9;
    border-color: #ddd;
    color: #999;
    cursor: not-allowed
}

div.wpforms-container-full .wpforms-form .wpforms-error-container {
    color: #900
}

div.wpforms-container-full .wpforms-form label.wpforms-error {
    display: block;
    color: #900;
    font-size: 12px;
    float: none
}

div.wpforms-container-full .wpforms-form .wpforms-field input.wpforms-error,
div.wpforms-container-full .wpforms-form .wpforms-field textarea.wpforms-error,
div.wpforms-container-full .wpforms-form .wpforms-field select.wpforms-error {
    border: 1px solid #c00
}

div.wpforms-container-full .wpforms-form .wpforms-field-credit-card-expiration label.wpforms-error,
div.wpforms-container-full .wpforms-form .wpforms-field-credit-card-code label.wpforms-error {
    display: none !important
}

div.wpforms-container-full .wpforms-form .wpforms-error-alert {
    color: #b94a48;
    background-color: #f2dede;
    border: 1px solid #eed3d7;
    text-shadow: 0 1px 0 rgba(255, 255, 255, .5);
    padding: 10px 15px;
    font-size: 13px;
    margin: 0 0 10px 0
}

.wpforms-confirmation-container-full {
    color: #333;
    margin: 0 0 24px 0;
    background: #e0ffc7;
    border: 1px solid #b4d39b;
    padding: 15px 15px
}

.wpforms-confirmation-container-full p:last-of-type {
    margin: 0
}

div.wpforms-container-full .wpforms-form .wpforms-recaptcha-container {
    padding: 10px 0 20px 0;
    clear: both
}

div.wpforms-container-full .wpforms-form .wpforms-recaptcha-container iframe {
    width: 100%
}

div.wpforms-container-full .wpforms-form .wpforms-title {
    font-size: 26px;
    margin: 0 0 10px 0
}

div.wpforms-container-full .wpforms-form .wpforms-description {
    margin: 0 0 10px 0
}

div.wpforms-container-full .wpforms-form .wpforms-submit-container {
    padding: 10px 0 0 0;
    clear: both;
    position: relative
}

div.wpforms-container-full .wpforms-form .wpforms-pagebreak-center {
    text-align: center
}

div.wpforms-container-full .wpforms-form .wpforms-pagebreak-left {
    text-align: left
}

div.wpforms-container-full .wpforms-form .wpforms-pagebreak-left .wpforms-page-button {
    margin: 0 10px 0 0
}

div.wpforms-container-full .wpforms-form .wpforms-pagebreak-right {
    text-align: right
}

div.wpforms-container-full .wpforms-form .wpforms-pagebreak-right .wpforms-page-button {
    margin: 0 0 0 10px
}

div.wpforms-container-full .wpforms-form .wpforms-pagebreak-split .wpforms-page-prev {
    float: left;
    margin: 0
}

div.wpforms-container-full .wpforms-form .wpforms-pagebreak-split .wpforms-page-next {
    float: right;
    margin: 0
}

div.wpforms-container-full .wpforms-form .wpforms-field-credit-card-number {
    margin-right: 100px
}

div.wpforms-container-full .wpforms-form .wpforms-field-credit-card-code {
    position: absolute;
    right: 0;
    top: 0;
    width: 90px
}

div.wpforms-container-full .wpforms-form .wpforms-field-credit-card-name {
    margin-right: 170px
}

div.wpforms-container-full .wpforms-form .wpforms-field-credit-card-expiration {
    position: absolute;
    right: 0;
    top: 0;
    width: 160px
}

div.wpforms-container-full .wpforms-form .wpforms-field-credit-card-expiration select {
    width: 45%;
    float: left;
    display: block
}

div.wpforms-container-full .wpforms-form .wpforms-field-credit-card-expiration span {
    float: left;
    width: 10%;
    text-align: center;
    line-height: 38px
}

div.wpforms-container-full .wpforms-form .wpforms-field-html ul,
div.wpforms-container-full .wpforms-form .wpforms-field-html ol {
    margin: 0 0 20px !important
}

div.wpforms-container-full .wpforms-form .wpforms-field-html ul li,
div.wpforms-container-full .wpforms-form .wpforms-field-html ol li {
    margin: 0 0 5px 0 !important
}

div.wpforms-container-full .wpforms-form .wpforms-field-html ul li {
    list-style: disc !important
}

div.wpforms-container-full .wpforms-form .wpforms-field-html ol li {
    list-style: decimal !important
}

div.wpforms-container-full .wpforms-form .wpforms-field-html li>ul,
div.wpforms-container-full .wpforms-form .wpforms-field-html li>ol {
    margin: 6px 0 0 20px !important
}

div.wpforms-container-full .wpforms-field-date-time-date-sep {
    display: inline-block;
    padding: 0 5px
}

div.wpforms-container-full .wpforms-form .wpforms-field-date-time-date-year,
div.wpforms-container-full .wpforms-form .wpforms-field-date-time-date-day,
div.wpforms-container-full .wpforms-form .wpforms-field-date-time-date-month {
    display: inline-block;
    width: auto
}

div.wpforms-container-full .wpforms-form .wpforms-captcha-math input {
    display: inline-block;
    width: 70px;
    vertical-align: inherit;
    margin: 0 0 0 5px
}

div.wpforms-container-full .wpforms-form .wpforms-captcha-equation {
    font-size: 16px
}

div.wpforms-container-full .wpforms-form .wpforms-captcha-question {
    margin: 0 0 4px 0
}

div.wpforms-container-full .wpforms-form .wpforms-field-rating-item {
    padding-right: 6px
}

div.wpforms-container-full .wpforms-form .wpforms-field-rating svg {
    cursor: pointer;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px transparent;
    opacity: .6
}

div.wpforms-container-full .wpforms-form .wpforms-field-rating-item.selected svg,
div.wpforms-container-full .wpforms-form .wpforms-field-rating-item.hover svg,
div.wpforms-container-full .wpforms-form .wpforms-field-rating-item input:focus+svg {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
    opacity: 1
}

div.wpforms-container-full .wpforms-form ul.wpforms-image-choices label {
    cursor: pointer
}

div.wpforms-container-full .wpforms-form .wpforms-list-inline ul.wpforms-image-choices-modern li {
    margin: 5px 5px 5px 5px !important
}

div.wpforms-container-full .wpforms-form ul.wpforms-image-choices-modern img {
    display: inline;
    margin: 0 auto;
    max-width: 100%
}

div.wpforms-container-full .wpforms-form ul.wpforms-image-choices-modern label {
    background-color: #fff;
    display: inline-block;
    margin: 0 auto;
    padding: 0;
    border: 1px solid #fff;
    border-radius: 3px;
    padding: 20px 20px 18px 20px;
    transition: all 0.5s;
    text-align: center
}

div.wpforms-container-full .wpforms-form ul.wpforms-image-choices-modern label:hover {
    border: 1px solid #ddd
}

div.wpforms-container-full .wpforms-form ul.wpforms-image-choices-modern .wpforms-selected label {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, .1)
}

div.wpforms-container-full .wpforms-form ul.wpforms-image-choices-modern .wpforms-image-choices-image:after {
    content: "\2714";
    font-size: 22px;
    line-height: 32px;
    color: #fff;
    background: green;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -16px 0 0 -16px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    transition: all 0.5s
}

div.wpforms-container-full .wpforms-form ul.wpforms-image-choices-modern .wpforms-selected .wpforms-image-choices-image:after {
    opacity: 1
}

div.wpforms-container-full .wpforms-form ul.wpforms-image-choices-modern .wpforms-image-choices-image {
    display: block;
    position: relative
}

div.wpforms-container-full .wpforms-form ul.wpforms-image-choices-modern .wpforms-selected .wpforms-image-choices-label {
    font-weight: 700
}

div.wpforms-container-full .wpforms-form ul.wpforms-image-choices-modern .wpforms-image-choices-label {
    display: block;
    margin-top: 12px
}

div.wpforms-container-full .wpforms-form .wpforms-list-inline ul.wpforms-image-choices-classic li {
    margin: 0 10px 10px 0 !important
}

div.wpforms-container-full .wpforms-form ul.wpforms-image-choices-classic img {
    display: inline;
    margin: 0 auto;
    max-width: 100%
}

div.wpforms-container-full .wpforms-form ul.wpforms-image-choices-classic label {
    background-color: #fff;
    display: inline-block;
    margin: 0 auto;
    padding: 0;
    border: 2px solid #fff;
    padding: 10px;
    text-align: center
}

div.wpforms-container-full .wpforms-form ul.wpforms-image-choices-classic label:hover {
    border-color: #ddd
}

div.wpforms-container-full .wpforms-form ul.wpforms-image-choices-classic .wpforms-image-choices-image {
    display: block
}

div.wpforms-container-full .wpforms-form ul.wpforms-image-choices-classic .wpforms-selected label {
    border-color: #666
}

div.wpforms-container-full .wpforms-form ul.wpforms-image-choices-classic .wpforms-image-choices-label {
    display: block;
    margin-top: 8px
}

div.wpforms-container-full .wpforms-form .wpforms-page-indicator {
    margin: 0 0 20px 0;
    overflow: hidden
}

div.wpforms-container-full .wpforms-form .wpforms-page-indicator.circles {
    border-top: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
    padding: 15px 10px
}

div.wpforms-container-full .wpforms-form .wpforms-page-indicator.circles .wpforms-page-indicator-page {
    float: left;
    margin: 0 20px 0 0
}

div.wpforms-container-full .wpforms-form .wpforms-page-indicator.circles .wpforms-page-indicator-page:last-of-type {
    margin: 0
}

div.wpforms-container-full .wpforms-form .wpforms-page-indicator.circles .wpforms-page-indicator-page-number {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: inline-block;
    margin: 0 10px 0 0;
    line-height: 40px;
    text-align: center;
    background-color: #ddd;
    color: #666
}

div.wpforms-container-full .wpforms-form .wpforms-page-indicator.circles .active .wpforms-page-indicator-page-number {
    color: #fff
}

div.wpforms-container-full .wpforms-form .wpforms-page-indicator.connector .wpforms-page-indicator-page {
    float: left;
    text-align: center;
    font-size: 16px;
    line-height: 1.2
}

div.wpforms-container-full .wpforms-form .wpforms-page-indicator.connector .wpforms-page-indicator-page-number {
    display: block;
    text-indent: -9999px;
    height: 6px;
    background-color: #ddd;
    margin: 0 0 16px 0;
    position: relative
}

div.wpforms-container-full .wpforms-form .wpforms-page-indicator.connector .wpforms-page-indicator-page-triangle {
    position: absolute;
    top: 100%;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -5px;
    border-style: solid;
    border-width: 6px 5px 0 5px;
    border-color: transparent transparent transparent transparent
}

div.wpforms-container-full .wpforms-form .wpforms-page-indicator.connector .wpforms-page-indicator-page-title {
    display: inline-block;
    padding: 0 15px;
    font-size: 16px
}

div.wpforms-container-full .wpforms-form .wpforms-page-indicator.progress {
    font-size: 18px
}

div.wpforms-container-full .wpforms-form .wpforms-page-indicator.progress .wpforms-page-indicator-page-progress-wrap {
    display: block;
    width: 100%;
    background-color: #ddd;
    height: 18px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    margin: 5px 0 0
}

div.wpforms-container-full .wpforms-form .wpforms-page-indicator.progress .wpforms-page-indicator-page-progress {
    height: 18px;
    position: absolute;
    left: 0;
    top: 0
}

div.wpforms-container-full .wpforms-notice {
    background-color: #fff;
    border: 1px solid #ddd;
    border-left-width: 12px;
    color: #333;
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 30px;
    padding: 20px 36px 20px 26px;
    position: relative
}

div.wpforms-container-full .wpforms-notice .wpforms-delete {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-color: rgba(10, 10, 10, .2);
    border: none;
    border-radius: 290486px;
    cursor: pointer;
    display: inline-block;
    height: 20px;
    margin: 0;
    padding: 0;
    outline: none;
    vertical-align: top;
    width: 20px;
    position: absolute;
    right: 10px;
    top: 10px
}

div.wpforms-container-full .wpforms-notice .wpforms-delete:before,
div.wpforms-container-full .wpforms-notice .wpforms-delete:after {
    background-color: #fff;
    content: "";
    display: block;
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    -webkit-transform-origin: center center;
    transform-origin: center center
}

div.wpforms-container-full .wpforms-notice .wpforms-delete:before {
    height: 2px;
    width: 50%
}

div.wpforms-container-full .wpforms-notice .wpforms-delete:after {
    height: 50%;
    width: 2px
}

div.wpforms-container-full .wpforms-notice .wpforms-delete:hover,
div.wpforms-container-full .wpforms-notice .wpforms-delete:focus {
    background-color: rgba(10, 10, 10, .3)
}

div.wpforms-container-full .wpforms-notice a {
    text-decoration: underline
}

div.wpforms-container-full .wpforms-notice p {
    margin: 0 0 20px 0
}

div.wpforms-container-full .wpforms-notice p:last-of-type {
    margin-bottom: 0
}

div.wpforms-container-full .wpforms-notice.wpforms-info .wpforms-notice-action,
div.wpforms-container-full .wpforms-notice.wpforms-info {
    border-color: #3273dc
}

div.wpforms-container-full .wpforms-notice.wpforms-success .wpforms-notice-action,
div.wpforms-container-full .wpforms-notice.wpforms-success {
    border-color: #23d160
}

div.wpforms-container-full .wpforms-notice.wpforms-warning .wpforms-notice-action,
div.wpforms-container-full .wpforms-notice.wpforms-warning {
    border-color: #ffdd57
}

div.wpforms-container-full .wpforms-notice.wpforms-error .wpforms-notice-action,
div.wpforms-container-full .wpforms-notice.wpforms-error {
    border-color: #ff3860
}

div.wpforms-container-full .wpforms-notice .wpforms-notice-actions {
    margin-top: 20px
}

div.wpforms-container-full .wpforms-notice .wpforms-notice-action {
    border: 2px solid;
    margin-right: 20px;
    padding: 5px;
    text-decoration: none
}

div.wpforms-container-full .wpforms-notice .wpforms-notice-action:hover,
div.wpforms-container-full .wpforms-notice .wpforms-notice-action:focus,
div.wpforms-container-full .wpforms-notice .wpforms-notice-action:active {
    color: #fff
}

div.wpforms-container-full .wpforms-notice.wpforms-info .wpforms-notice-action:hover,
div.wpforms-container-full .wpforms-notice.wpforms-info .wpforms-notice-action:focus,
div.wpforms-container-full .wpforms-notice.wpforms-info .wpforms-notice-action:active {
    background-color: #3273dc
}

div.wpforms-container-full .wpforms-notice.wpforms-success .wpforms-notice-action:hover,
div.wpforms-container-full .wpforms-notice.wpforms-success .wpforms-notice-action:focus,
div.wpforms-container-full .wpforms-notice.wpforms-success .wpforms-notice-action:active {
    background-color: #23d160
}

div.wpforms-container-full .wpforms-notice.wpforms-warning .wpforms-notice-action:hover,
div.wpforms-container-full .wpforms-notice.wpforms-warning .wpforms-notice-action:focus,
div.wpforms-container-full .wpforms-notice.wpforms-warning .wpforms-notice-action:active {
    background-color: #ffdd57;
    color: inherit
}

div.wpforms-container-full .wpforms-notice.wpforms-error .wpforms-notice-action:hover,
div.wpforms-container-full .wpforms-notice.wpforms-error .wpforms-notice-action:focus,
div.wpforms-container-full .wpforms-notice.wpforms-error .wpforms-notice-action:active {
    background-color: #ff3860
}

div.wpforms-container-full {
    margin-bottom: 24px
}

div.wpforms-container-full .wpforms-form h3 {
    font-size: 24px
}

div.wpforms-container-full .wpforms-form .wpforms-field-hp {
    display: none !important;
    position: absolute !important;
    left: -9000px !important
}

div.wpforms-container-full .wpforms-form .wpforms-field-hidden {
    display: none
}

div.wpforms-container-full .wpforms-form .wpforms-screen-reader-element {
    position: absolute !important;
    clip: rect(0, 0, 0, 0) !important;
    height: 1px !important;
    width: 1px !important;
    border: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden !important;
    word-wrap: normal !important
}

@media only screen and (max-width:600px) {
    div.wpforms-container-full .wpforms-form .wpforms-mobile-full {
        width: 100%;
        margin-left: 0;
        float: none
    }

    div.wpforms-container-full .wpforms-form input.wpforms-field-medium,
    div.wpforms-container-full .wpforms-form select.wpforms-field-medium,
    div.wpforms-container-full .wpforms-form .wpforms-field-row.wpforms-field-medium,
    div.wpforms-container-full .wpforms-form input.wpforms-field-small,
    div.wpforms-container-full .wpforms-form select.wpforms-field-small,
    div.wpforms-container-full .wpforms-form.wpforms-field-row.wpforms-field-small,
    div.wpforms-container-full .wpforms-form input.wpforms-field-large,
    div.wpforms-container-full .wpforms-form select.wpforms-field-large,
    div.wpforms-container-full .wpforms-form .wpforms-field-row.wpforms-field-large {
        max-width: 100%
    }

    div.wpforms-container-full .wpforms-form .wpforms-checkbox-2-columns ul li,
    div.wpforms-container-full .wpforms-form .wpforms-multiplechoice-2-columns ul li,
    div.wpforms-container-full .wpforms-form .wpforms-list-2-columns ul li,
    div.wpforms-container-full .wpforms-form .wpforms-checkbox-3-columns ul li,
    div.wpforms-container-full .wpforms-form .wpforms-multiplechoice-3-columns ul li,
    div.wpforms-container-full .wpforms-form .wpforms-list-3-columns ul li {
        float: none;
        width: 100%
    }

    div.wpforms-container-full .wpforms-form .wpforms-page-indicator.circles .wpforms-page-indicator-page {
        float: none;
        display: block;
        margin: 0 0 10px 0
    }

    div.wpforms-container-full .wpforms-form .wpforms-page-indicator.circles .wpforms-page-indicator-page-number {
        width: 30px;
        height: 30px;
        line-height: 30px
    }

    div.wpforms-container-full .wpforms-form .wpforms-page-indicator.connector .wpforms-page-indicator-page {
        width: 100% !important;
        padding: 5px 10px
    }

    div.wpforms-container-full .wpforms-form .wpforms-page-indicator.connector .wpforms-page-indicator-page.active {
        font-weight: 700
    }

    div.wpforms-container-full .wpforms-form .wpforms-page-indicator.connector .wpforms-page-indicator-page-number {
        display: none
    }

    div.wpforms-container-full .wpforms-form .wpforms-field-date-time .wpforms-one-half {
        width: 100%;
        margin-left: 0;
        float: none
    }

    div.wpforms-container-full .wpforms-form .wpforms-field-date-time .wpforms-one-half:first-of-type {
        margin-bottom: 8px
    }

    div.wpforms-container-full.inline-fields .wpforms-form .wpforms-field-container,
    div.wpforms-container-full.inline-fields .wpforms-form .wpforms-field {
        display: block;
        width: 100%
    }

    div.wpforms-container-full.inline-fields .wpforms-form .wpforms-submit-container {
        width: 100%
    }
}

.edit-post-visual-editor .wpforms-gutenberg-form-selector-wrap h2 {
    width: 100%;
    margin-bottom: .5em
}

.edit-post-visual-editor .wpforms-gutenberg-form-selector-wrap img {
    margin-right: 25px
}

.edit-post-visual-editor .wpforms-gutenberg-form-selector-wrap .components-base-control {
    width: 100%
}

div.wpforms-gutenberg-form-selector .wpforms-form input:disabled,
div.wpforms-gutenberg-form-selector .wpforms-form textarea:disabled,
div.wpforms-gutenberg-form-selector .wpforms-form select:disabled {
    background-color: #fff;
    border-color: #ccc;
    color: #333;
    cursor: not-allowed
}

div.wpforms-gutenberg-form-selector .wpforms-form button[type=submit]:disabled {
    cursor: not-allowed
}